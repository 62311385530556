<template>
  <field-panel :title="title">
    <div v-if="examination" class="panel-body">
      <div class="mb-3">
        <p>
          {{ trans("aiFeatures.aiDiagnosisFeatureRequirements") }}
        </p>
        <p>
          {{ trans("aiFeatures.aiDiagnosisFeatureDescription") }}
        </p>
      </div>

      <clip-loader v-show="loading" />

      <button
        v-if="!showDiagnosis || !aiDiagnosisAvailable"
        :class="[
          aiDiagnosisAvailable ? 'btn-primary' : 'btn-grey',
          'btn btn-block btn-lg mt-3',
        ]"
        :disabled="!aiDiagnosisAvailable"
        @click="getAiDiagnosis"
      >
        {{ trans("aiFeatures.showAiDiagnosisProbability") }}
      </button>

      <div v-if="showDiagnosis && aiDiagnosisAvailable">
        <strong
          >{{ trans("aiFeatures.aiDiagnosisProbabilityText") }}
          {{ trans(`aiFeatures.${aiDiagnosis.ai_diagnosis}`) }}
          {{ trans("aiFeatures.diagnosisProbabilityIs") }}
          <span>{{ aiDiagnosis.ai_probability }}%</span></strong
        >
        <div class="progress my-2">
          <div
            class="progress-bar"
            role="progressbar"
            :style="`width: ${aiDiagnosis.ai_probability}%;`"
            :aria-valuenow="aiDiagnosis.ai_probability"
            aria-valuemin="0"
            aria-valuemax="100"
          >
            {{ aiDiagnosis.ai_probability }}%
          </div>
        </div>
      </div>
    </div>
  </field-panel>
</template>

<script>
import FieldPanel from "./components/FieldPanel.vue";
import UserErrorMessage from "../../../../mixins/UserErrorMessage.vue";

export default {
  components: {
    FieldPanel,
  },
  mixins: [UserErrorMessage],
  props: {
    fields: Array,
    examination: Object,
  },
  data: function () {
    return {
      title: "aiFeatures.aiDiagnosisHeader",
      loading: false,
      aiDiagnosis: null,
      showDiagnosis: false,
    };
  },
  computed: {
    aiDiagnosisAvailable: function () {
      if (
        this.examination.diagnosis.dermatoscopic_diagnosis_id &&
        this.examination.diagnosis.planned_diagnosis_id
      ) {
        return true;
      } else {
        return false;
      }
    },
  },
  methods: {
    getAiDiagnosis: function () {
      this.loading = true;

      axios
        .get(`/api/internal/ai/diagnosis/${this.examination.id}`)
        .then((data) => {
          let collection = data.data;
          this.aiDiagnosis = collection;
          this.loading = false;
          this.showDiagnosis = true;
        })
        .catch((error) => {
          this.loading = false;
          this.showDiagnosis = false;
          this.userErrorMessage(error);
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.btn:disabled {
  opacity: 1;
}

.progress {
  height: 20px;

  .progress-bar {
    background-color: #b92844;
    font-size: 11px;
  }
}
</style>
