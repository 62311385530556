<template>
  <field-panel
    title="examination.diagnosiskHeadline"
    :updated-date="examination.diagnosis.diagnosis_updated_at"
    :info-popup-text="trans('tooltip.moleUkDiagnosisBox')"
  >
    <div class="panel-body">
      <div class="m-b-20 col-md-12">
        <strong>{{
          trans("examination.diagnosisCodeDermatoskopiskLabel")
        }}</strong>

        <der-select
          v-model="examination.diagnosis.dermatoscopic_diagnosis_id"
          :disabled="!$can('diagnose')"
          :data-options="
            examination.diagnosis.diagnosis_code_dermatoscopic_list
          "
          :data-get-option-value="(option) => option.id"
          :data-get-option-label="(option) => option.text"
          :data-placeholder="trans('forms.generalSelectPlaceholder')"
          @input="setDiagnosis($event, 'diagnosis_code_dermatoscopic')"
        />
      </div>

      <div class="m-b-20 col-md-12">
        <label>{{ trans("examination.diagnosiskPlanedHeadline") }}</label>
        <der-select
          v-if="!plannedToAction.active"
          v-model="examination.diagnosis.planned_diagnosis_id"
          :disabled="!$can('diagnose')"
          :data-options="examination.diagnosis.planned_to_action_list"
          :data-get-option-value="(option) => option.id"
          :data-get-option-label="(option) => option.text"
          :data-placeholder="trans('forms.generalSelectPlaceholder')"
          @input="setDiagnosis($event, 'diagnosis_code_planed')"
        />
        <input
          v-if="plannedToAction.active"
          disabled
          type="text"
          name="channelName"
          :value="trans('examination.diagnosiskPlanedToAnotherDepartmentLabel')"
          class="form-control"
          :disable="plannedToAction.active"
        />
      </div>

      <div
        :class="[
          plannedToAction.active ? 'card-footer pb-0' : 'mb-1',
          'col-md-12',
        ]"
      >
        <DerToggleWithLabel
          v-model="plannedToAction.active"
          :label="trans('examination.diagnosiskPlanedToAnotherDepartmentLabel')"
          :disabled="!$can('diagnose')"
        />
      </div>

      <div
        v-if="plannedToAction.active"
        class="card-header mb-4"
        :class="plannedToAction.active ? 'pt-0' : ''"
      >
        <div class="m-b-20 col-md-12">
          <label>{{ trans("examination.diagnosiskPlanedReferTo") }}</label>
          <input
            v-model="plannedToAction.plannedToActionReferto"
            name="planedToActionReferto"
            :disabled="!$can('diagnose')"
            type="text"
            class="form-control"
            :placeholder="trans('examination.diagnosiskPlanedReferTo')"
            @change="updatePlannedAction"
          />
        </div>
        <div class="m-b-20 col-md-12">
          <label>
            {{ trans("examination.diagnosiskPlanedReasonForReferral") }}</label
          >
          <input
            v-model="plannedToAction.plannedToActionReasonForReferral"
            name="planedToActionReasonForReferral"
            :disabled="!$can('diagnose')"
            type="text"
            class="form-control"
            :placeholder="
              trans('examination.diagnosiskPlanedReasonForReferral')
            "
            @change="updatePlannedAction"
          />
        </div>
      </div>

      <div class="m-b-20 col-md-12">
        <strong>{{
          trans("examination.diagnosiscodeHistopatologiskLabel")
        }}</strong>
        <der-select
          v-model="examination.diagnosis.histopathological_diagnosis_id"
          :data-options="
            examination.diagnosis.diagnosis_code_histopathological_list
          "
          :data-get-option-value="(option) => option.id"
          :data-get-option-label="(option) => option.text"
          :data-placeholder="trans('forms.generalSelectPlaceholder')"
          @input="setDiagnosis($event, 'diagnosis_code_histopathological')"
        />
      </div>

      <div class="m-b-20 col-md-12">
        <strong>{{ trans("examination.tagsLabel") }}</strong>
        <v-select
          v-model="examination.diagnosis.tags"
          multiple
          name="tags"
          :options="examination.diagnosis.tags_list"
          :reduce="(tag) => tag.id"
          :value="examination.diagnosis.tags_list.text"
          label="text"
          append-to-body
          :calculate-position="calculatePositionWithPopper"
          :placeholder="trans('forms.generalSelectPlaceholder')"
          @update:model-value="setDiagnosis($event, 'tags')"
        />
      </div>
    </div>
  </field-panel>
</template>

<script>
import FieldPanel from "./components/FieldPanel.vue";
import DerSelect from "../../../Utils/DerSelect.vue";
import { calculatePositionWithPopper } from "../../../../helpers/popper";
import Notifications from "../../../Utils/notifications";
import DerToggleWithLabel from "../../../../design-system/DerToggleWithLabel.vue";

export default {
  components: {
    DerSelect,
    FieldPanel,
    DerToggleWithLabel,
  },
  props: {
    fields: Array,
    examination: Object,
  },
  data() {
    return {
      plannedToAction: this.examination.plannedToAction,
    };
  },
  watch: {
    "plannedToAction.active": function (val, oldVal) {
      let instance = this;
      if (val.active) {
        this.examination.case.diagnosisCodePlaned = null;
        this.plannedToAction.active = true;
      } else if (!this.plannedToAction.active) {
        this.$vueAlert
          .confirm(this.trans("general.modalCancel"), undefined, undefined, {
            confirmButtonText: this.trans("general.modalConfirmButton"),
            cancelButtonText: this.trans("general.modalCancelButton"),
          })
          .then(() => {
            instance.plannedToAction.active = false;
            instance.plannedToAction.plannedToActionReferto = "";
            instance.plannedToAction.plannedToActionReasonForReferral = "";

            return axios.post(
              `/api/internal/moles-uk/${instance.$route.params.id}/planned-action`,
              instance.plannedToAction,
            );
          })
          .then(() => {
            this.$notify({
              text: this.trans("general.notificationSaved"),
              type: "success",
              duration: 1000,
            });
          })
          .catch(() => {
            instance.plannedToAction.active = true;
          });
      }
    },
  },
  methods: {
    calculatePositionWithPopper,
    setDiagnosis: function (diagnosisCode, diagnosisType) {
      let formData = {
        key: diagnosisType,
        resources: diagnosisCode,
      };

      axios
        .post(
          "/api/internal/moles-uk/" + this.examination.id + "/save-diagnostic",
          formData,
        )
        .then(() => {
          Notifications.saveSuccess();
          this.updateExamination();
        })
        .catch((error) => {
          console.log(error);
          this.userErrorMessage(error);
        });
    },
    updateExamination: function () {
      axios
        .get(`/api/internal/tumour-wound/${this.$route.params.id}`)
        .then((data) => {
          let collection = data.data.examination;

          this.examination.diagnosis.diagnosis_updated_at =
            collection.diagnosis.diagnosis_updated_at;
        });
    },
    updatePlannedAction({ target }) {
      if (target.name == "plannedToActionReferto") {
        this.plannedToAction.plannedToActionReferto = target.value;
      } else if (target.name == "plannedToActionReasonForReferral") {
        this.plannedToAction.plannedToActionReasonForReferral = target.value;
      }

      this.plannedToAction.active = true;

      axios
        .post(
          "/api/internal/moles-uk/" + this.$route.params.id + "/planned-action",
          this.plannedToAction,
        )
        .then((data) => {
          this.$notify({
            text: this.trans("general.notificationSaved"),
            type: "success",
            duration: 1000,
          });
        });
    },
  },
};
</script>
