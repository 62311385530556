<template>
  <div @click="openInfoModal">
    <i class="fal fa-info-circle popover-link" />
    <info-modal
      v-if="showInfoModal"
      :info-header-text="props.headerText"
      :info-text="props.infoText"
      :info-h-t-m-l-content="props.infoHTMLContent"
      @close-info-modal="closeInfoModal"
    />
  </div>
</template>

<script setup lang="ts">
import { ref } from "vue";
import InfoModal from "./InfoModal.vue";

const props = defineProps<{
  headerText?: string;
  infoText?: string;
  infoHTMLContent?: string;
}>();

const showInfoModal = ref(false);

function openInfoModal() {
  showInfoModal.value = true;
}

function closeInfoModal() {
  showInfoModal.value = false;
}
</script>

<style lang="scss" scoped>
.popover-link {
  border: none;
}
</style>
