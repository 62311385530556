<template>
  <ResearchAdminCasesList
    :key="refreshKey"
    :sending-bulk-invites="sendingBulkInvites"
    @action:send-invites="onSendInvites"
  />
</template>
<script setup lang="ts">
import { computed, ref, watch } from "vue";
import { ROUTES, useRouteOf } from "../routing/routes";
import ResearchAdminCasesList from "../../components/Research/ResearchAdmin/ResearchAdminCasesList.vue";
import { studySendInvites } from "../../store/core/studies";
import { userErrorMessage } from "../../mixins/UserErrorMessage.vue";
import { lang } from "../../i18n";
import { v4 as uuidV4 } from "uuid";
import { notify } from "@kyvg/vue3-notification";

const route = useRouteOf<(typeof ROUTES)["researchAdmin.studyCases"]>();
/**
 * @todo Remove force re-render once old component uses best-practices on data loading!
 */
const studyId = ref<string>();
watch(
  () => route.params.studyId,
  (value) => (studyId.value = value),
  { immediate: true },
);

/**
 * @todo Remove force re-render once we can update the store resources that are updated.
 */
const refreshUuid = ref<string>(uuidV4());
const refreshKey = computed(() => `${studyId.value}-${refreshUuid.value}`);
const sendingBulkInvites = ref(false);

function onSendInvites(studyId: number) {
  if (sendingBulkInvites.value) return;
  sendingBulkInvites.value = true;
  studySendInvites(studyId)
    .then(() => {
      sendingBulkInvites.value = false;
      refreshUuid.value = uuidV4();
      notify({
        text: lang.get("action.study.sendInvites.success"),
        type: "success",
        duration: 1000,
      });
    })
    .catch(userErrorMessage);
}
</script>
