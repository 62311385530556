<template>
  <div class="tw-space-y-6">
    <SingleSelectionInput
      :title="title"
      :disabled="disabled"
      :required="required"
      :data="data"
      :form-value="formValue"
      :extra-options="{ ...extraOptions, onValueChange: onChange }"
      @is-valid="valueValid = $event"
    />
    <multiple-selection-input
      v-if="aiTrustProps && value === 'yes'"
      key="aiTrust"
      v-bind="aiTrustProps"
      @field-unmount="clearValueOf(aiTrustProps)"
      @is-valid="aiTrustValueValid = $event"
    />
    <multiple-selection-input
      v-if="aiNonTrustProps && value === 'no'"
      key="aiNonTrust"
      v-bind="aiNonTrustProps"
      @field-unmount="clearValueOf(aiNonTrustProps)"
      @is-valid="aiNonTrustValueValid = $event"
    />
    <div v-if="aiNonTrustCondition" class="tw-space-y-6">
      <DerRangeSlider
        v-if="probabilityThresholdProps"
        v-bind="probabilityThresholdProps"
        @field-unmount="clearValueOf(probabilityThresholdProps)"
        @is-valid="probabilityThresholdValueValid = $event"
      />
      <Checkbox
        v-if="probabilityThresholdNoProps"
        v-bind="probabilityThresholdNoProps"
        @field-unmount="clearValueOf(probabilityThresholdNoProps)"
        @is-valid="probabilityThresholdNoValueValid = $event"
      />
    </div>
  </div>
</template>
<script setup lang="ts">
import {
  computed,
  shallowRef,
  ShallowRef,
  toRefs,
  watch,
  defineEmits,
} from "vue";
import MultipleSelectionInput from "./MultipleSelectionInput.vue";
import DerRangeSlider from "./DerRangeSlider.vue";
import Checkbox from "./Checkbox.vue";
import { lang } from "../../../../../i18n";
import { FixedProps } from "../../../../../lib-vue/fixed-props";
import _map from "lodash/fp/map";
import SingleSelectionInput from "./SingleSelectionInput.vue";

type Option = { value: string; label: string };
type AiTrustExplanation = {
  name: string;
  title: string;
  options: Array<Option>;
  extra_options?: {
    other_value?: string;
    other_key?: string;
    other_label?: string;
    other_required?: boolean;
  };
};

const emit = defineEmits<{
  (e: "isValid", payload: boolean): void;
}>();

const props = defineProps<{
  title?: string;
  required?: boolean;
  disabled?: boolean;
  formValue?: string;
  data: Array<{ value: string }>;
  extraOptions?: {
    key?: string;
    onValueChange: (payload: { key?: string; value?: string | number }) => void;
    fieldOptions?: {
      options: Array<Option>;
      value?: string;
      label?: string;
      min?: number;
      max?: number;
      interval?: number;
      ai_trust?: AiTrustExplanation;
      ai_non_trust?: AiTrustExplanation & {
        probability_threshold: {
          name: string;
          title: string;
          no_key: string;
          no_label: string;
        };
      };
    };
  };
}>();

function translateOption({ label, ...option }: Option) {
  return { ...option, label: lang.get(label) };
}

function onValueChangeOf(valueRef: ShallowRef | null) {
  return (payload: { key?: string; value?: string }) => {
    if (valueRef) {
      valueRef.value = payload.value;
    }

    /**
     * With the conditional fields there are several values that need to be
     * reset when other values change.
     *
     * When the checkbox for 'I would never have changed my diagnosis' is checked,
     * we need to reset the probability threshold slider value, so that it isn't included
     * in the form submission.
     */
    if (
      payload.key === probabilityThresholdNoProps.value.extraOptions.key &&
      payload.value === true
    ) {
      probabilityThresholdProps.value?.extraOptions.onValueChange({
        key: probabilityThresholdProps.value?.extraOptions.key,
        value: null,
      });
    }
    extraOptions?.value?.onValueChange(payload);
  };
}

function clearValueOf(props: any) {
  return props.extraOptions.onValueChange({
    key: props.extraOptions.key,
    value: null,
  });
}

function aiTrustExplanation(
  valueRef: ShallowRef,
  trustOptions?: AiTrustExplanation,
) {
  return (
    trustOptions && {
      title: lang.get(trustOptions.title),
      data: _map(translateOption, trustOptions.options),
      reduce: (optionValue: { value: string }) => optionValue.value,
      disabled: disabled.value,
      required: true,
      extraOptions: {
        key: trustOptions.name,
        onValueChange: onValueChangeOf(valueRef),
        fieldOptions: {
          ...trustOptions.extra_options,
          other_onValueChange: onValueChangeOf(null),
        },
      },
    }
  );
}

const { formValue, extraOptions, required, disabled } = toRefs(
  props as FixedProps<typeof props>,
);

const valueValid = shallowRef(false);
const value = shallowRef();
watch(formValue, () => (value.value = formValue?.value), { immediate: true });
const onChange = onValueChangeOf(value);

const aiTrustValueValid = shallowRef(false);
const aiTrustFormValue = shallowRef();
const aiTrustProps = computed(() =>
  aiTrustExplanation(
    aiTrustFormValue,
    extraOptions.value?.fieldOptions?.ai_trust,
  ),
);

const aiNonTrustValueValid = shallowRef(false);
const aiNonTrustFormValue = shallowRef<Array<Option["value"]>>([]);
const aiNonTrustProps = computed(() =>
  aiTrustExplanation(
    aiNonTrustFormValue,
    extraOptions.value?.fieldOptions?.ai_non_trust,
  ),
);

const aiNonTrustCondition = computed(() =>
  aiNonTrustFormValue.value?.some(
    (formValue) => formValue === "lowProbability",
  ),
);

const probabilityThresholdValueValid = shallowRef(false);
const probabilityThresholdValue = shallowRef();
const probabilityThresholdProps = computed(() => {
  const options =
    extraOptions.value?.fieldOptions?.ai_non_trust?.probability_threshold;
  return (
    options && {
      title: lang.get(options?.title),
      disabled: disabled.value || probabilityThresholdNoValue.value === true,
      required: true,
      formValue: probabilityThresholdValue.value,
      extraOptions: {
        key: options?.name,
        fieldOptions: { min: 0, max: 100, interval: 1 },
        onValueChange: onValueChangeOf(probabilityThresholdValue),
      },
    }
  );
});

const probabilityThresholdNoValueValid = shallowRef(false);
const probabilityThresholdNoValue = shallowRef();
const probabilityThresholdNoProps = computed(() => {
  const options =
    extraOptions.value?.fieldOptions?.ai_non_trust?.probability_threshold;
  return {
    title: lang.get(options?.no_label),
    disabled: disabled.value,
    required: true,
    extraOptions: {
      key: options?.no_key,
      onValueChange: onValueChangeOf(probabilityThresholdNoValue),
    },
  };
});

const isValid = computed(() => {
  if (!required.value) {
    return true;
  }
  if (!valueValid.value) {
    return false;
  }
  if (aiTrustProps.value && value.value === "yes" && !aiTrustValueValid.value) {
    return false;
  }
  if (
    aiNonTrustProps.value &&
    value.value === "no" &&
    !aiNonTrustValueValid.value
  ) {
    return false;
  }
  if (
    aiNonTrustCondition.value &&
    probabilityThresholdValueValid.value ===
      probabilityThresholdNoValueValid.value
  ) {
    return false;
  }
  return true;
});
watch(isValid, (newValue) => emit("isValid", newValue), { immediate: true });
</script>
