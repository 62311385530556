<template>
  <div class="form-group">
    <label :for="regionIdInputId">
      {{ trans("region.region") }}
    </label>
    <select2
      :id="regionIdInputId"
      name="regionId"
      :options="dataAvailableRegions"
      :placeholder="trans('forms.chooseRegionPlaceholderOptional')"
    />
  </div>
</template>
<script setup>
import { v4 as uuidV4 } from "uuid";

const regionIdInputId = uuidV4();

defineProps({
  dataAvailableRegions: {
    type: /** @type {Array<{ id: number, text: string }>} */ Array,
    required: true,
  },
});
</script>
