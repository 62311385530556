<template>
  <div class="dropdown">
    <button
      id="dropdownMenuButton"
      class="btn btn-custom"
      type="button"
      data-toggle="dropdown"
      aria-haspopup="true"
      aria-expanded="false"
      :disabled="loading || !actions"
    >
      {{ trans("general.action") }}
    </button>
    <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
      <a
        v-for="(item, index) in actions"
        :key="index"
        class="dropdown-item"
        href="#"
        @click.prevent="onAction(item)"
      >
        {{ item.title }}
      </a>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    loading: Boolean,
    actions: Array,
    errorUpdatingActions: Boolean,
  },
  methods: {
    onAction: function (item) {
      this.$emit("on-action", item);
    },
  },
};
</script>
