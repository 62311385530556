<template>
  <div class="panel panel-default panel-fill">
    <div class="panel-heading">
      <h3 class="panel-title">
        {{ trans("users.tabMenuLabelProducts") }}
      </h3>
    </div>
    <div class="panel-body">
      <div
        v-if="Object.keys(products).length > 0"
        class="row col-md-12 tw-gap-y-8"
      >
        <div
          v-for="(product, key) in products"
          :key="key"
          class="col-md-2 col-sm-3"
        >
          <DerToggleWithLabel
            v-model="product.active"
            :label="trans(`general.${key}`)"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import DerToggleWithLabel from "../../../design-system/DerToggleWithLabel.vue";

export default {
  components: {
    DerToggleWithLabel,
  },
  props: {
    products: Object,
    organisation: String,
  },
};
</script>
