<template>
  <VuePaginate
    :model-value="modelValue"
    :click-handler="onChange"
    :page-count="pageCount"
    prev-text="‹"
    next-text="›"
    container-class="pagination"
    page-class="page-item"
    page-link-class="page-link"
    active-class="page-item active"
    prev-class="page-item"
    prev-link-class="page-link"
    next-class="page-item"
    next-link-class="page-link"
    @update:model-value="emit('update:modelValue', $event)"
  />
</template>

<script setup lang="ts">
import { VuePaginate } from "@svifty7/vue-paginate";

defineProps<{
  modelValue: number;
  pageCount: number;
}>();

function onChange(page: number) {
  emit("change", page);
}

const emit = defineEmits<{
  (event: "update:modelValue", payload: number): void;
  (event: "change", payload: number): void;
}>();
</script>
