<template>
  <div>
    <Teleport to="#chat-btn-portal">
      <button
        type="button"
        class="tw-fixed tw-top-3 tw-right-4 tw-z-[3] tw-border-0 tw-bg-transparent tw-m-0 tw-p-1 xl:tw-top-4 xl:tw-right-0 xl:tw-p-0 xl:tw-z-[11]"
        :class="{ 'open-chat-sidebar-btn': chatIsOpen }"
        @click="handleChatToggle"
      >
        <div
          class="xl:tw-hidden tw-flex tw-items-center tw-text-primary tw-font-light tw-gap-1"
        >
          <div class="tw-h-6 tw-w-6">
            <ChatIcon />
          </div>
          <span v-if="chatNotifications">({{ chatNotifications }})</span>
        </div>
        <div
          class="max-xl:tw-hidden tw-bg-primary tw-text-white tw-px-4 tw-py-2 tw-font-light tw-rounded tw-rounded-tr-none tw-rounded-br-none"
        >
          <i
            class="tw-mr-1"
            :class="
              chatIsOpen
                ? 'far fa-angle-double-right'
                : 'far fa-angle-double-left'
            "
          />
          <span v-if="!chatIsOpen"
            >{{ trans("chat.chat") }}
            <span v-if="chatNotifications">({{ chatNotifications }})</span>
          </span>
        </div>
      </button>
    </Teleport>
    <Teleport to="#chat-portal">
      <chat-window
        :chat-is-open="chatIsOpen"
        :edit-save-uri="editSaveUri"
        @update-top-navigation="updateTopNavigation"
      />
    </Teleport>
  </div>
</template>

<script>
import ChatWindow from "./ChatWindow.vue";
import ChatIcon from "../../../Layout/Icons/ChatIcon.vue";

export default {
  components: {
    ChatWindow,
    ChatIcon,
  },
  props: {
    editSaveUri: String,
    chatNotifications: Number,
  },
  computed: {
    chatIsOpen() {
      return this.$store.state.chatIsOpen;
    },
  },
  methods: {
    updateTopNavigation: function () {
      this.$emit("updateTopNavigation");
    },
    handleChatToggle() {
      this.$store.commit("toggleChat");
    },
  },
};
</script>
