<template>
  <div class="educations mt-4">
    <clip-loader v-show="loading" />

    <div v-if="!loading && hasEducationActive">
      <clip-loader v-show="loading" />

      <div class="table-responsive">
        <table class="table table-hover table-actions-bar interactive-table">
          <thead class="sortable-table-header">
            <tr>
              <th
                class="sort-by"
                :class="sortBySelection === 'id' ? 'table-active' : ''"
                @click="sortBy('id', $event)"
              >
                {{ trans("evaluation.tableColumnCaseNo") }}
                <i class="fas fa-caret-down" />
              </th>
              <th>
                {{ trans("evaluation.tableColumnStatus") }}
              </th>
              <th
                v-if="hasMultipleFeatures"
                class="sort-by"
                :class="sortBySelection === 'type' ? 'table-active' : ''"
                @click="sortBy('type', $event)"
              >
                {{ trans("evaluation.tableColumnProduct") }}
                <i class="fas fa-caret-down" />
              </th>
              <th
                class="sort-by"
                :class="sortBySelection === 'createdAt' ? 'table-active' : ''"
                @click="sortBy('createdAt', $event)"
              >
                {{ trans("evaluation.tableColumnCreatedAt") }}
                <i class="fas fa-caret-down" />
              </th>
            </tr>
          </thead>
          <tbody>
            <tr
              v-for="(examination, index) in examinations"
              :key="index"
              @click="openExamination(examination)"
            >
              <td>{{ examination.caseNo }}</td>
              <td>
                <case-status :examination="examination" :hide-label="true" />
              </td>
              <td>{{ examination.createdAt }}</td>
            </tr>
          </tbody>
        </table>
      </div>

      <div class="row-fluid float-right">
        <DerPaginate
          v-model="paginate.current_page"
          :page-count="paginate.last_page"
          @change="getResults"
        />
      </div>
    </div>

    <div v-if="!hasEducationActive && !loading" class="educations-bg-image">
      <div
        v-if="salesInfo !== undefined"
        v-dompurify-html="salesInfo.data"
        class="educations-text"
      />
    </div>
  </div>
</template>
<script setup>
import { useAsyncState } from "@vueuse/core";
import { educationModuleSalesInfoGet } from "../../app/practitioner-portal/education/store/education-module-sales-info";
import DerPaginate from "../../design-system/DerPaginate.vue";

const { state: salesInfo } = useAsyncState(
  educationModuleSalesInfoGet(),
  undefined,
);
</script>
<script>
import CaseStatus from "../Examinations/Examination/ExaminationComponents/components/CaseStatus.vue";

export default {
  components: {
    CaseStatus,
  },
  data() {
    return {
      loading: true,
      dermicusPlatform: window.dermicusPlatform,
      hasEducationActive: false,
      examinations: [],
      hasMultipleFeatures: false,
      isAdvancedSearchOpen: false,
      searchOptions: {
        centers: [],
        users: [],
        products: [],
      },
      isSearching: false,
      paginate: {
        current_page: 0,
        total: 0,
        last_page: 0,
      },
      searchFields: {
        query: "",
        sort: {
          field: "id",
          orderBy: "DESC",
        },
        currentPage: 1,
      },
      sortBySelection: "createdAt",
    };
  },
  created: function () {
    this.getResults();
  },
  methods: {
    getResults(page) {
      var instance = this;
      instance.loading = true;

      this.searchFields.currentPage = page
        ? page
        : this.searchFields.currentPage;
      localStorage.setItem(
        "last_education_query_uri",
        JSON.stringify(this.searchFields),
      );

      let queryUri =
        "?q=" +
        this.searchFields.query +
        "&page=" +
        this.searchFields.currentPage +
        "&orderByField=" +
        this.searchFields.sort.field +
        "&orderBy=" +
        this.searchFields.sort.orderBy;

      axios
        .get("/api/internal/educations" + queryUri)
        .then((data) => {
          let collection = data.data;
          instance.examinations = collection.data;
          instance.paginate = collection.paginate;
          this.hasEducationActive = true;
          instance.loading = false;
        })
        .catch((error) => {
          instance.loading = false;
          instance.hasEducationActive ? instance.userErrorMessage(error) : null;
        });
    },
    openExamination: function (examination) {
      this.$router.push({ path: `/consultations${examination.editUrl}` });
    },
    sortBy: function (field, event) {
      var instance = this;
      this.sortBySelection = field;

      instance.loading = true;
      // Reset all to bottom
      if (field !== instance.searchFields.sort.field) {
        $("i.fas.fa-caret-up")
          .removeClass("fa-caret-up")
          .addClass("fa-caret-down");
        instance.searchFields.sort.orderBy = "DESC";
      }
      instance.searchFields.sort.field = field;
      if (this.searchFields.sort.orderBy == "ASC") {
        instance.searchFields.sort.orderBy = "DESC";
        $(event.currentTarget)
          .find("i.fas.fa-caret-up")
          .removeClass("fa-caret-up")
          .addClass("fa-caret-down");
      } else {
        instance.searchFields.sort.orderBy = "ASC";
        $(event.currentTarget)
          .find("i.fas.fa-caret-down")
          .removeClass("fa-caret-down")
          .addClass("fa-caret-up");
      }
      return this.getResults(this.currentPage);
    },
  },
};
</script>
