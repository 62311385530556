<template>
  <div>
    <der-select
      :id="id"
      :model-value="mainValue"
      :disabled="disabled"
      :data-options="mainOptions"
      :data-get-option-value="getOptionValue"
      :data-get-option-label="getOptionLabel"
      :data-placeholder="dataPlaceholder"
      @update:model-value="onInput"
    />
    <div
      v-if="!!subOptions"
      :style="{
        display: 'grid',
        gridTemplateColumns: 'auto 1fr',
        gridGap: '.25em',
        alignItems: 'center',
      }"
    >
      <svg viewBox="0 0 14 14" width="1.5rem" height="1.5rem">
        <line x1="5" y1="0" x2="5" y2="7" stroke="gray" />
        <line x1="5" y1="7" x2="14" y2="7" stroke="gray" />
      </svg>
      <der-select
        :model-value="subValue"
        :disabled="disabled"
        :data-options="subOptions"
        :data-get-option-value="getOptionValue"
        :data-get-option-label="getOptionLabel"
        :data-placeholder="subPlaceholder"
        @update:model-value="onSubInput"
      />
    </div>
  </div>
</template>
<script>
import _overSome from "lodash/fp/overSome";
import DerSelect from "./DerSelect.vue";

/**
 * Uses 2 **DerSelect** components to select an item or a sub-item.
 */
export default {
  components: { DerSelect },
  props: {
    /**
     * `string | undefined`
     */
    id: { type: String },
    /**
     * `string | number | undefined`
     */
    modelValue: { required: true },
    /**
     * `boolean | undefined`
     */
    disabled: { type: Boolean },
    /**
     * `Array<T & { children?: T[] }>`
     */
    dataOptions: { type: Array, required: true },
    /**
     * `(option: T) => string | number | undefined`
     *
     * Uses `id` property as the default.
     */
    dataGetOptionValue: { type: Function },
    /**
     * `(option: T) => string`
     *
     * Uses `text` property as the default.
     */
    dataGetOptionLabel: { type: Function },
    /**
     * `string | undefined`
     */
    dataPlaceholder: { type: String },
    /**
     * `string | undefined`
     *
     * Uses the "main" placeholder as fallback value.
     */
    dataSubPlaceholder: { type: String },
  },
  emits: ["update:modelValue", "input"],
  computed: {
    getOptionValue() {
      return this.dataGetOptionValue ?? ((option) => option.id);
    },
    getOptionLabel() {
      return this.dataGetOptionLabel ?? ((option) => option.text);
    },
    subPlaceholder() {
      return this.dataSubPlaceholder ?? this.dataPlaceholder;
    },
    mainOptions() {
      return this.dataOptions;
    },
    mainValue() {
      const mainOption = this.mainOptions.find(
        _overSome([
          (option) => this.isSelected(option),
          (option) =>
            option.children?.some((option) => this.isSelected(option)),
        ]),
      );
      return mainOption && this.getOptionValue(mainOption);
    },
    subOptions() {
      return this.mainOptions.find((option) =>
        this.isOptionFor(this.mainValue, option),
      )?.children;
    },
    subValue() {
      return this.subOptions?.find((option) => this.isSelected(option));
    },
  },
  methods: {
    isOptionFor(value, option) {
      return this.getOptionValue(option) === value;
    },
    isSelected(option) {
      return this.isOptionFor(this.modelValue, option);
    },
    onInput($event) {
      /**
       * `string | number | undefined`
       */
      this.$emit("input", $event);
      this.$emit("update:modelValue", $event);
    },
    onSubInput($event) {
      this.onInput($event || this.mainValue);
    },
  },
};
</script>
