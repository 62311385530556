const getEncodedImage = async (caseId, imgId, cancelToken) => {
  if (caseId && imgId) {
    let encodedImg = await axios
      .get(`/api/internal/${caseId}/images/${imgId}`, {
        cancelToken: cancelToken,
      })
      .then((data) => {
        let imgData = data.data.image;
        return imgData;
      })
      .catch((error) => {
        console.log("Error loading encoded case image:", error);
      });
    return encodedImg;
  }
};

export default getEncodedImage;
