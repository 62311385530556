<script>
import Bugsnag from "@bugsnag/browser";
import { lang } from "../../i18n";

export default {
  model: { prop: "modelValue", event: "update:modelValue" },
  props: ["options", "modelValue", "placeholder", "changeEvent"],
  watch: {
    modelValue: function (value) {
      if (!value) return;
      // update value
      let newValue = value.toString();
      if (newValue.indexOf("|") !== -1) {
        $(this.$el).val(newValue.split("|")).trigger("change");
      } else {
        $(this.$el).val(newValue).trigger("change");
      }

      if (typeof this.$parent.updateSelect2Event == "function") {
        this.$parent.updateSelect2Event(value);
      }
    },
    options: function (options) {
      let placeholderText = this.placeholder
        ? this.placeholder
        : lang.get("examination.externalResourceSelectPlaceholder");
      // update options
      $(this.$el).empty();
      $(this.$el).select2({ data: options });

      if (this.modelValue) {
        let newValue = this.modelValue.toString();
        if (newValue.indexOf("|") !== -1) {
          $(this.$el).val(newValue.split("|")).trigger("change");
        } else {
          $(this.$el).val(newValue).trigger("change");
        }
      } else {
        $(this.$el).val("").trigger("change");
      }

      $(this.$el).select2({
        allowClear: true,
        placeholder: placeholderText,
      });
    },
  },
  mounted: function () {
    var vm = this;
    var $select2 = $(this.$el);

    let placeholderText = this.placeholder
      ? this.placeholder
      : lang.get("examination.externalResourceSelectPlaceholder");
    $select2
      .select2({
        data: this.options,
        allowClear: true,
        placeholder: placeholderText,
        width: "100%",
      })
      // emit event on change.
      .on("change", function () {
        vm.$emit("update:modelValue", $select2.val());
      })
      .on("select2:close", function () {
        vm.$emit("close", $select2.val());
      });

    // Disable clearing selected option if select2 has attribute disable-clear="true"
    if ($select2.attr("disable-clear")) {
      $select2.select2({
        allowClear: false,
      });
    }

    if (this.modelValue) {
      let newValue = this.modelValue.toString();
      if (newValue.indexOf("|") !== -1) {
        $select2.val(newValue.split("|")).trigger("change");
      } else {
        $select2.val(newValue).trigger("change");
      }
    } else {
      $select2.val("").trigger("change");
    }

    $select2.on("change", function (e) {
      var values = $(this).val();
      var saveUrl = $(this).data("save-url");

      if ($(this).hasClass("hold") || !saveUrl) {
        return;
      }

      let formData = {
        _token: Laravel.csrfToken,
        resources: values,
        key: $(this).attr("name"),
      };

      axios.post(saveUrl, formData).then((data) => {
        $select2.select2("close");

        // for select2's with this property, the notification will get refreshed instead of stacked
        if ($(this).attr("data-doNotStackNotifications")) {
          $(".custom-notification-class.success").hide();
        }

        vm.$notify({
          text: lang.get("general.notificationSaved"),
          type: "success",
          duration: 1000,
        });

        // Add event on parent if needed.
        if (typeof vm.$parent.updateSelect2Event == "function") {
          vm.$parent.updateSelect2Event();
        }
      });
    });
  },
  unmounted: function () {
    try {
      Bugsnag.leaveBreadcrumb("select2 destroyed", {
        id: this.$el.id,
        name: this.$el.name,
        class: this.$el.className,
      });
      $(this.$el).off().select2("destroy");
    } catch (e) {
      Bugsnag.notify(e);
    }
  },
  template:
    '<select class="select2 form-control responsive"><slot></slot></select>',
};
</script>
