<template>
  <InputSwitch
    :input-id="id"
    :pt="preset"
    :model-value="modelValue"
    @update:model-value="$emit('update:modelValue', $event)"
  />
</template>

<script setup lang="ts">
import InputSwitch, {
  InputSwitchPassThroughMethodOptions,
} from "primevue/inputswitch";

const props = defineProps<{
  id?: string;
  modelValue: boolean;
}>();

const emit = defineEmits<{
  (event: "update:modelValue", value: boolean): void;
}>();

const preset = {
  root: ({ props: e }: InputSwitchPassThroughMethodOptions) => ({
    class: [
      "tw-inline-flex tw-relative",
      "tw-shrink-0",
      "tw-rounded-2xl",
      "tw-h-5 tw-w-9",
      {
        "tw-opacity-60 tw-select-none tw-pointer-events-none tw-cursor-default":
          e.disabled,
      },
    ],
  }),
  slider: ({ props: e }: InputSwitchPassThroughMethodOptions) => ({
    class: [
      "tw-absolute tw-top-0 tw-left-0 tw-right-0 tw-bottom-0",
      "tw-rounded-2xl",
      "before:tw-content-['']",
      "before:tw-absolute before:tw-top-1/2",
      "before:-tw-mt-2",
      "before:tw-h-4 before:tw-w-4",
      "before:tw-rounded-full",
      "before:tw-duration-200 before:tw-transition before:tw-ease-in-out",
      "before:tw-bg-white",
      "before:tw-shadow",
      {
        "before:tw-transform before:tw-translate-x-4":
          e.modelValue == e.trueValue,
      },
      "tw-border-2 tw-border-transparent tw-border-solid",
      {
        "tw-bg-gray-200": e.modelValue != e.trueValue,
        "tw-bg-primary": e.modelValue == e.trueValue,
      },
      {
        "peer-hover:tw-bg-gray-300": e.modelValue != e.trueValue && !e.disabled,
      },
      {
        "peer-hover:tw-bg-primary": e.modelValue == e.trueValue && !e.disabled,
      },
      "peer-focus-visible:tw-ring-2 peer-focus-visible:tw-ring-primary",
      "tw-transition-colors tw-duration-200",
      "tw-cursor-pointer",
    ],
  }),
  input: {
    class: [
      "tw-peer",
      "tw-w-full",
      "tw-h-full",
      "tw-absolute",
      "tw-top-0 tw-left-0",
      "tw-p-0",
      "tw-m-0",
      "tw-rounded",
      "tw-border",
      "tw-opacity-0",
      "tw-rounded-md",
      "tw-outline-none",
      "tw-border-2 tw-border-gray-300 tw-border-solid",
      "tw-appareance-none",
    ],
  },
};
</script>
