<template>
  <div>
    <chat-container
      :key="$route.fullPath"
      :edit-save-uri="'/api/internal/' + $route.params.id + '/patient-channels'"
      :chat-notifications="chatNotifications"
      @update-top-navigation="updateTopNavigation"
    />

    <header-patient />

    <router-view
      class="tw-pt-8"
      :data-patient="dataPatient"
      @update-patient-chat-notifications="updatePatientChatNotifications"
    />
  </div>
</template>

<script>
import HeaderPatient from "./Header.vue";
import ChatContainer from "../Examinations/AddOns/Chat/ChatContainer.vue";
import PatientOverview from "./PatientOverview.vue";
import PatientImageCompare from "./PatientImageCompare.vue";
import RelatedExaminations from "./RelatedExaminations.vue";

export default {
  components: {
    HeaderPatient,
    ChatContainer,
    PatientOverview,
    PatientImageCompare,
    RelatedExaminations,
  },
  props: {
    dataPatient: {
      type: Object,
    },
  },
  data: function () {
    return {
      chatNotifications: null,
    };
  },
  created() {
    localStorage.setItem("last_visited_patient_id", this.$route.params.id);
  },
  methods: {
    updatePatientChatNotifications: function (notifications) {
      this.chatNotifications = notifications;
    },
    updateTopNavigation: function () {
      this.$emit("updateTopNavigation");
    },
  },
};
</script>
