import { AppModule } from "../routing/app-module";
import { ROUTES } from "../routing/routes";
import CenterSettingsRoute from "./CenterSettingsRoute.vue";
import CenterUsersRoute from "./CenterUsersRoute.vue";

export const center: AppModule = {
  routes: [
    {
      ...ROUTES.centerSettings,
      component: CenterSettingsRoute,
      meta: {
        requiresAuth: true,
        centerAuth: true,
      },
    },
    {
      ...ROUTES.centerUsers,
      component: CenterUsersRoute,
      meta: {
        requiresAuth: true,
        centerAuth: true,
      },
    },
  ],
};
