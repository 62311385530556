<template>
  <div>
    <header-admin :active-section="5" />

    <div class="text-right my-2">
      <router-link to="/admin/educations/new" class="btn btn-primary">
        Lägg till nya ärende
      </router-link>
    </div>

    <div id="custom-search-input">
      <form class="input-group col-md-12" @submit.prevent="search">
        <input
          id="search-field"
          type="text"
          class="form-control input-lg"
          placeholder="Sök efter centernamn..."
        />
        <span class="input-group-btn">
          <button
            v-if="!isSearching"
            class="btn"
            type="button"
            @click.stop="search"
          >
            <i class="far fa-search" />
          </button>
          <button
            v-if="isSearching"
            class="btn"
            type="button"
            @click.stop="clearSearch"
          >
            <i class="fal fa-times" />
          </button>
        </span>
      </form>
    </div>

    <div id="evaluations">
      <div class="table-responsive">
        <clip-loader v-show="loading" />

        <table class="table table-hover m-0 table-actions-bar cases-list">
          <thead class="sortable-table-header">
            <tr>
              <th>Centernamn</th>
              <th>Antal provcase</th>
            </tr>
          </thead>
          <tbody>
            <tr
              v-for="(center, index) in centers"
              :key="index"
              @click="openCenter(center.id)"
            >
              <td>{{ center.name }}</td>
              <td>{{ center.total_cases }}</td>
            </tr>
          </tbody>
        </table>
      </div>

      <div class="row-fluid float-right">
        <DerPaginate
          v-model="paginate.current_page"
          :page-count="paginate.last_page"
          @change="getResults"
        />
      </div>
    </div>
  </div>
</template>

<script>
import DerPaginate from "../../../design-system/DerPaginate.vue";
import HeaderAdmin from "./../Header.vue";

export default {
  components: {
    HeaderAdmin,
    DerPaginate,
  },
  props: ["examinationsCollection"],
  data() {
    return {
      loading: true,
      centers: [],
      paginate: {
        current_page: 0,
        total: 0,
        last_page: 0,
      },
      isSearching: false,
    };
  },
  created: function () {
    this.getResults();
  },
  methods: {
    getResults(page) {
      var instance = this;
      instance.loading = true;

      if (typeof page === "undefined") {
        page = 1;
      }

      axios
        .get("/api/internal/admin/educations?page=" + page)
        .then((data) => {
          var collection = data.data;
          instance.paginate = collection.paginate;
          instance.centers = collection.data;
          instance.loading = false;
        })
        .catch((error) => {
          instance.userErrorMessage(error);
          instance.loading = false;
        });
    },
    clearSearch: function () {
      $("#search-field").val("");
      this.isSearching = false;
      this.getResults();
    },
    search: function () {
      var instance = this;

      instance.loading = true;
      instance.isSearching = true;
      var query = $("#search-field").val();
      axios
        .get("/api/internal/admin/centers?q=" + query)
        .then((data) => {
          var collection = data.data;
          instance.data = collection.paginate;
          instance.centers = collection.data;
          instance.paginate = collection.paginate;
          instance.loading = false;
        })
        .catch((error) => {
          instance.userErrorMessage(error);
          instance.loading = false;
        });
    },
    openCenter: function (id) {
      this.$router.push({ name: "AdminEducationsEdit", params: { id: id } });
    },
  },
};
</script>
