<template>
  <div>
    <div class="form-loop mb-2">
      <form role="form">
        <div class="row">
          <div
            v-for="(field, index) in definition"
            :key="index"
            class="mb-2 col-lg-6"
          >
            <div
              class="form-group"
              :class="{ 'has-error': errors[field.name] }"
            >
              <label :for="formData[field.title]"
                >{{ trans("forms." + field.title) }}
                <i v-if="field.required === true" class="far fa-asterisk"
              /></label>
              <info-popup
                v-if="field.hasInfoPopup"
                :text-content="trans('forms.' + field.infoPopup)"
              />

              <input
                v-if="field.inputType === 'input'"
                :id="field.title"
                v-model="formData[field.name]"
                :type="field.type"
                :placeholder="trans('forms.' + field.placeholder)"
                :disabled="field.disabled"
                :required="field.required"
                class="form-control"
                @blur="
                  onBlur(
                    field.validationType,
                    formData[field.name],
                    field.required,
                  )
                "
              />

              <div
                v-if="field.inputType === 'switch'"
                class="row align-items-center"
              >
                <DerToggle
                  :id="field.title"
                  v-model="formData[field.name]"
                  :disabled="field.disabled"
                  :required="field.required"
                  class="my-2 mx-3"
                />
                <label
                  :for="formData[field.title]"
                  class="mx-4 align-self-center"
                >
                  {{
                    formData[field.name]
                      ? trans("forms.yes")
                      : trans("forms.no")
                  }}
                </label>
              </div>

              <div v-if="field.inputType === 'checkbox_round'" class="round">
                <input
                  :id="field.title"
                  v-model="formData[field.name]"
                  :type="field.type"
                  :placeholder="trans('forms.' + field.placeholder)"
                  :disabled="field.disabled"
                  :required="field.required"
                  class="form-control my-2"
                />
                <label :for="field.title" />
              </div>

              <form-error
                v-if="errors[field.name]"
                :errors="errors[field.name]"
              />
            </div>
          </div>
        </div>
        <div class="row btn-div">
          <button
            class="col-12 mb-4 btn btn-primary btn-block btn-lg"
            :disabled="!formData.userHasFilledOutForm"
            @click.prevent="onSubmit"
          >
            {{ trans("forms.submit") }}
          </button>
        </div>
      </form>
    </div>

    <div class="form-footer text-left">
      <p><i class="far fa-asterisk" /> {{ trans("forms.requiredStar") }}</p>
    </div>
  </div>
</template>

<script>
import DerToggle from "../../design-system/DerToggle.vue";
import {
  validateInput,
  validatePasswordIsSame,
  FORM_TYPES,
} from "../../helpers/formValidation";
import InfoPopup from "../Utils/InfoPopup.vue";

export default {
  components: {
    DerToggle,
    InfoPopup,
  },
  props: {
    definition: {},
    formData: {},
    isValidLink: undefined,
  },
  data: function () {
    return {
      errors: {},
      FORM_TYPES: FORM_TYPES,
    };
  },
  methods: {
    onBlur: function (key, input, required) {
      const error =
        key === FORM_TYPES.PASSWORD_CONFIRM
          ? validatePasswordIsSame(this.formData.password, input)
          : validateInput(key, input, required);
      if (error) {
        this.errors = {
          ...this.errors,
          [key]: error,
        };
      } else if (key in this.errors) {
        delete this.errors[key];
        this.errors = { ...this.errors };
      }
    },
    onValidate: function () {
      this.definition.forEach((field) => {
        if (field.name in this.formData) {
          let error = validateInput(
            field.validationType,
            this.formData[field.name],
            field.required,
          );

          if (!error && field.name === FORM_TYPES.PASSWORD_CONFIRM) {
            error = validatePasswordIsSame(
              this.formData.password,
              this.formData[field.name],
            );
          }

          if (error) {
            this.errors[field.name] = error;
          }
        }
      });
      if (Object.keys(this.errors).length === 0) {
        return true;
      } else {
        return false;
      }
    },
    onSubmit: function () {
      this.errors = {};
      let isValid = this.onValidate();
      isValid
        ? this.$emit("submit", this.formData)
        : this.$notify({
            text: this.trans("errorMessages.HTTPStatus.422"),
            duration: 5000,
            type: "warn",
            ignoreDuplicates: true,
          });
    },
  },
};
</script>

<style lang="scss" scoped>
@use "/resources/assets/sass/abstracts/variables";

.round {
  position: relative;
}

.round label {
  background-color: #ffffff;
  border: 2px solid #cecece;
  border-radius: 50%;
  cursor: pointer;
  height: 28px;
  position: absolute;
  top: 10px;
  width: 28px;
  z-index: 1;
}

.round label:after {
  border: 2px solid variables.$dermicusBlue;
  border-top: none;
  border-right: none;
  content: "";
  height: 6px;
  left: 7px;
  opacity: 0;
  position: absolute;
  top: 8px;
  transform: rotate(-45deg);
  width: 12px;
}

.round input[type="checkbox"] {
  visibility: hidden;
}

.round input[type="checkbox"]:checked + label {
  border-color: variables.$dermicusBlue;
}

.round input[type="checkbox"]:checked + label:after,
.round input[type="checkbox"] + label:before {
  opacity: 1;
}
</style>
