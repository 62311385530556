<template>
  <div v-if="products.length > 0" class="row">
    <div v-for="(product, index) in products" :key="index" class="col-md-6">
      <div class="panel panel-default hover-effect">
        <div class="panel-heading p-0">
          <div
            :class="[{ grayscale: !product.active }, 'widget-img']"
            :style="{
              background: `url('/images/products/${product.image}') center right no-repeat`,
            }"
          >
            {{ product.display_name }}
          </div>
        </div>
        <div class="panel-body">
          <div class="row col-md-12">
            <div class="col-md-12">
              <DerToggleWithLabel
                v-model="product.active"
                :label="trans('users.notificationActive')"
              />
            </div>
          </div>

          <horizontal-line
            v-if="product.active"
            class="mb-3"
            :text="trans('users.notificationHeadline')"
          />

          <div v-if="product.active" class="row col-md-12 tw-gap-y-8">
            <div
              v-for="notification in product.notifications"
              :key="notification.id"
              class="col-4"
            >
              <DerToggleWithLabel
                v-model="notification.active"
                :label="trans(notification.trans)"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import HorizontalLine from "../../../Utils/HorizontalLine.vue";
import DerToggleWithLabel from "../../../../design-system/DerToggleWithLabel.vue";
import { practitionerHttpService } from "../../../../app/practitioner-portal/store/practitioner-http-service";

export default {
  components: {
    HorizontalLine,
    DerToggleWithLabel,
  },
  props: {
    userId: {
      required: true,
    },
    source: {
      type: String,
      required: true,
      validator(value) {
        return ["settings", "center", "region", "admin"].includes(value);
      },
    },
    context: {
      type: String,
      required: false,
    },
  },
  data() {
    return {
      products: [],
    };
  },
  mounted() {
    this.fetchProducts();
  },
  methods: {
    fetchProducts() {
      let url = "";

      switch (this.source) {
        case "settings":
          url = "/api/internal/auth/products";
          break;
        case "center":
          url = `/api/internal/centers/${this.context}/user/${this.userId}/products`;
          break;
        case "region":
          if (this.userId === "new") {
            url = `/api/internal/region-admin/user/${this.userId}/${this.context}/products`;
          } else {
            url = `/api/internal/region-admin/user/${this.userId}/products`;
          }
          break;
        case "admin":
          url = `/api/internal/admin/user/${this.userId}/products`;
          break;
      }

      practitionerHttpService
        .get(url)
        .then((response) => {
          this.products = response.data.settings_products;
        })
        .catch((error) => {
          console.error("Error fetching products:", error);
        });
    },
    getProducts() {
      return this.products;
    },
  },
};
</script>
