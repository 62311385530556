import Bugsnag from "@bugsnag/js";
import BugsnagPluginVue from "@bugsnag/plugin-vue";

const init = ({ apiKey, appVersion, releaseStage }) => {
  if (!window.Vue) {
    console.error("Vue has to be initialized before Bugsnag");
    return;
  }

  if (!apiKey) {
    console.warn(
      "Missing Bugsnag API key - skipping Bugsnag client integration",
    );
    return;
  }

  Bugsnag.start({
    appType: "client",
    plugins: [new BugsnagPluginVue()],
    collectUserIp: false,
    apiKey,
    appVersion,
    releaseStage,
    redactedKeys: [
      "password",
      "props", // Filter out "props" metadata included by Vue plugin
    ],
    onBreadcrumb: function (breadcrumb) {
      if (breadcrumb.type === "user") {
        breadcrumb.metadata.targetText = "HIDDEN"; // Avoid logging PII data
      }
    },
  });
};

export default init;
