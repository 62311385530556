import { ErrorFeedback } from "../lib/error-feedback";
import { IntentVariant, StatusVariant, VisualVariant } from "./tokens/variants";

/**
 * @deprecated Use the new specific variant types instead!
 */
export type DataVariant = IntentVariant | StatusVariant | VisualVariant;

type VariantMap<T extends string> = Record<T, DataVariant>;

function fromVariant<T extends string>(map: VariantMap<T>) {
  return (variant: T) => map[variant];
}

export const fromVariantErrorFeedback = fromVariant<ErrorFeedback["variant"]>({
  error: "danger",
  warning: "warning",
});
