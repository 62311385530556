<template>
  <div class="chart-header">
    <div>
      <h6>{{ trans("statistics." + headerName) }}</h6>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    headerName: String,
  },
};
</script>
