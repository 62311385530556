<template>
  <div>
    <div class="panel panel-default">
      <div class="panel-heading panel-heading-underline">
        <h3 class="panel-title tw-flex tw-justify-between">
          {{ trans("aiFeatures.aiResultsPresentationPanelHeader") }}
          <info-popup-large
            :info-h-t-m-l-content="trans('data.mcdStudy.chartTooltipContent')"
          />
        </h3>
      </div>
      <div class="panel-body add-ai-results-panel-body">
        <bar-chart
          v-if="aiData.datasets"
          :chartdata="aiData"
          :options="aiDataOptions"
        />
      </div>
    </div>
  </div>
</template>

<script>
import BarChart from "../../Statistics/StatisticsComponents/ChartTypes/BarChart.vue";
import InfoPopupLarge from "../../Utils/InfoPopupLarge.vue";

import { infoPopupText } from "../../../helpers/misc";
import { lang } from "../../../i18n";

export default {
  components: { BarChart, InfoPopupLarge },
  props: {
    examination: {
      study_results: Array,
    },
  },

  data() {
    return {
      aiData: {
        labels: null,
        datasets: null,
      },
      labelMap: {
        nv: {
          short: lang.get("data.mcdStudy.chartNVLabel"),
          long: lang.get("data.mcdStudy.chartNVTooltip"),
        },
        mel: {
          short: lang.get("data.mcdStudy.chartMELLabel"),
          long: lang.get("data.mcdStudy.chartMELTooltip"),
        },
        sk: {
          short: lang.get("data.mcdStudy.chartSKLabel"),
          long: lang.get("data.mcdStudy.chartSKTooltip"),
        },
        df: {
          short: lang.get("data.mcdStudy.chartDFLabel"),
          long: lang.get("data.mcdStudy.chartDFTooltip"),
        },
        ak: {
          short: lang.get("data.mcdStudy.chartAKIECLabel"),
          long: lang.get("data.mcdStudy.chartAKIECTooltip"),
        },
        scc: {
          short: lang.get("data.mcdStudy.chartSCCLabel"),
          long: lang.get("data.mcdStudy.chartSCCTooltip"),
        },
        bcc: {
          short: lang.get("data.mcdStudy.chartBCCLabel"),
          long: lang.get("data.mcdStudy.chartBCCTooltip"),
        },
        vasc: {
          short: lang.get("data.mcdStudy.chartVASCLabel"),
          long: lang.get("data.mcdStudy.chartVASCTooltip"),
        },
      },
    };
  },
  computed: {
    aiDataOptions() {
      const vm = this;
      return {
        responsive: true,
        maintainAspectRatio: true,
        legend: {
          display: true,
          position: "top",
          labels: {
            boxWidth: 10,
            padding: 20,
            usePointStyle: true,
          },
        },
        tooltips: {
          titleFontColor: "rgba(0, 0, 0, 1)",
          bodyFontColor: "rgba(0, 0, 0, 1)",
          backgroundColor: "rgba(245, 245, 245, 1)",
          xPadding: 10,
          yPadding: 10,
        },
        scales: {
          y: {
            min: 0,
            max: 100,
          },
          x: {
            ticks: {
              callback: function (value) {
                const l = this.getLabelForValue(value);
                return Object.values(vm.labelMap).find(
                  (item) => item.long === l,
                )?.short;
              },
            },
          },
        },
      };
    },
  },
  mounted: function () {
    this.getAiData();
  },

  methods: {
    getAiData: function () {
      const { study_results } = this.examination;
      if (study_results.length === 0) return;
      const studyResult = study_results.find((res) => {
        return res && "type" in res && res.type === "external_result";
      });
      if (studyResult) {
        const result = studyResult.results;

        let labels = Object.keys(result).map((key) => result[key].label);

        const longLabels = labels.map((label) => this.labelMap[label]?.long);

        let aiResults = Object.keys(result).map(
          (key) => +(result[key].value * 100).toFixed(2),
        );

        this.aiData = {
          labels: longLabels,
          datasets: [
            {
              data: aiResults,
              label: this.trans("aiFeatures.aiResultsPresentationChartName"),
              backgroundColor: "#706d75",
            },
          ],
        };
      }
    },
    getInfoPopupText: function () {
      return infoPopupText(this.aiData.labels);
    },
  },
};
</script>
