<template>
  <div class="dropdown">
    <button
      id="dropdownMenuButton"
      class="btn"
      type="button"
      data-toggle="dropdown"
      aria-haspopup="true"
      aria-expanded="false"
      :disabled="loading"
      :class="btnStyle"
    >
      {{ title }}
    </button>
    <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
      <a
        v-for="(item, index) in items"
        :key="index"
        class="dropdown-item"
        href="#"
        @click.prevent="onItemClick(item)"
      >
        {{ item.title }}
      </a>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    title: String,
    items: Array,
    loading: Boolean,
    btnStyle: String,
  },
  methods: {
    onItemClick(item) {
      this.$emit("on-item-click", item);
    },
  },
};
</script>
