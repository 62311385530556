<template>
  <div class="my-3">
    <label>{{ title }}</label>
    <i v-if="required" class="far fa-asterisk" />
    <v-select
      v-model="value"
      :name="title"
      :options="options"
      label="text"
      :disabled="disabled"
      @update:model-value="input"
    />
  </div>
</template>

<script>
export default {
  props: {
    title: String,
    disabled: Boolean,
    formValue: [Object, String],
    required: Boolean,
    extraOptions: {
      key: String,
      onValueChange: Function,
      examination: Object,
    },
  },
  emits: ["isValid"],
  data() {
    return {
      value: undefined,
      options: [],
    };
  },
  computed: {
    isValid: function () {
      return (
        !this.required ||
        (this.value?.id !== undefined && this.value?.id !== null)
      );
    },
  },
  watch: {
    isValid: {
      handler: function (newValue) {
        this.$emit("isValid", newValue);
      },
      immediate: true,
    },
  },
  mounted: function () {
    this.value = this.formValue;
    this.getOptions();
  },
  methods: {
    getOptions: function () {
      if ("key" in this.extraOptions.fieldOptions) {
        const key = this.extraOptions.fieldOptions.key;
        const { examination } = this.extraOptions;
        if ("diagnosis" in examination && key in examination.diagnosis) {
          this.options = examination.diagnosis[key];
        }
      }
    },
    input: function (event) {
      this.extraOptions.onValueChange({
        key: this.extraOptions.key,
        value: event?.id,
      });
    },
  },
};
</script>
