<template>
  <Alert
    v-bind="$attrs"
    :data-variant="studyResultsImportStatisticsVariant(dataStatistics)"
    :data-heading="
      lang.get('model.studyResultsImportStatistics.status', dataStatistics)
    "
  >
    <div v-if="dataStatistics.failureCount" class="tw-mt-2">
      <strong>
        {{ lang.get("model.studyResultsImportStatistics.failures.label") }}
      </strong>
      <table class="table table-sm">
        <thead>
          <tr>
            <th>
              {{
                lang.get(
                  "model.studyResultsImportStatistics.failures.row.label",
                )
              }}
            </th>
            <th>
              {{
                lang.get(
                  "model.studyResultsImportStatistics.failures.attribute.label",
                )
              }}
            </th>
            <th>
              {{
                lang.get(
                  "model.studyResultsImportStatistics.failures.errors.label",
                )
              }}
            </th>
          </tr>
        </thead>
        <tbody>
          <tr
            v-for="failure in dataStatistics.failures"
            :key="`${failure.row}${failure.attribute}`"
          >
            <td>{{ failure.row }}</td>
            <td>{{ failure.attribute }}</td>
            <td>
              <ul class="tw-m-0">
                <li v-for="(error, index) in failure.errors" :key="index">
                  {{ error }}
                </li>
              </ul>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <div v-if="dataStatistics.errorCount" class="tw-mt-2">
      <strong>{{
        lang.get("model.studyResultsImportStatistics.errors.label")
      }}</strong>
      <table class="table table-sm">
        <thead>
          <tr>
            <th>
              {{
                lang.get("model.studyResultsImportStatistics.errors.row.label")
              }}
            </th>
            <th>
              {{
                lang.get(
                  "model.studyResultsImportStatistics.errors.errorCode.label",
                )
              }}
            </th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="error in dataStatistics.errors" :key="error.row">
            <td>{{ error.row }}</td>
            <td>{{ error.errorCode.display }}</td>
          </tr>
        </tbody>
      </table>
    </div>
  </Alert>
</template>
<script lang="ts">
export default { inheritAttrs: false };
</script>
<script setup lang="ts">
import Alert from "../design-system/Alert.vue";
import { StudyResultsImportStatistics } from "../../../../app/Imports/StudyResultsImportStatistics";
import { studyResultsImportStatisticsVariant } from "./study-results-import-statistics";
import { lang } from "../i18n";

defineProps<{ dataStatistics: StudyResultsImportStatistics }>();
</script>
