<template>
  <div>
    <div v-if="isLoading" class="splash-screen">
      <img src="/images/dermicus-logo.svg" />
    </div>
    <!-- Using `:key` with the current locale will force a refresh when it changes! -->
    <!-- This could be done better, but with the existing setup, this should work. -->
    <AppLayout
      v-if="isAuthenticated"
      :key="currentLocale"
      :sidebar-is-open="sidebarIsOpen"
      :chat-is-open="chatIsOpen"
      @sidebar-toggle="handleSidebarToggle"
    >
      <template #sidebar-left>
        <Sidebar
          :user="user"
          :is-authenticated="isAuthenticated"
          :primary-center-name="primaryCenterName"
          :sidebar-is-open="sidebarIsOpen"
          @logout-user="logoutUser"
          @start-page="startPage"
          @mobile-nav-toggle="handleSidebarToggle"
        />
      </template>

      <template #main-content>
        <div>
          <router-view
            v-if="$route.meta.keepAlive"
            :key="rebuildKey"
            v-slot="{ Component }"
          >
            <keep-alive>
              <component :is="Component" />
            </keep-alive>
          </router-view>
          <router-view v-if="!$route.meta.keepAlive" :key="rebuildKey" />
        </div>
      </template>
      <template #sidebar-right>
        <div id="chat-portal" />
      </template>
    </AppLayout>

    <!-- Login router view -->
    <div v-if="!isAuthenticated && !isLoading">
      <router-view :key="rebuildKey" />
    </div>

    <notifications classes="custom-notification-class" />
  </div>
</template>
<script setup>
import { currentLocale } from "./i18n";
import { useRoute } from "vue-router";
import { shallowRef, watch } from "vue";

const route = useRoute();
/**
 * It appears that the ":key" technique was utilized to force whole re-renders.
 * I could track this to before 2020, then I stopped searching. It seems that
 * this was added as response to how data loading is done inside components,
 * which is "load the data on mount". This does mean however that route param
 * changes have no effect, as data isn't triggered to reload when the input for
 * it changes. And thus this `:key` technique is used to ensure that when the
 * url changes, the whole route tree gets re-rendered, and thus the mount hooks
 * trigger again to load the new data based on the new params. Apart from this
 * being bad practise and very hard to maintain, even poor performance, it will
 * not be easy to get rid of this, as essentially we have to adapt all loading
 * to use techniques that reloads data when the input for that data changes.
 * This must eventually be gone, but in the meantime, everytime we convert a
 * component tree to use the best-practises on data-loading (store), we can
 * update this calculation to exclude the sub-routes that are good, and thus
 * avoid complete re-renders, improve performance and maintainability!
 *
 * @todo Work further to remove this reloading by using data best-practises!
 */
const rebuildKey = shallowRef("/");
watch(
  () => route.fullPath,
  (newFullPath) => {
    if (newFullPath.startsWith("/research-admin")) {
      rebuildKey.value = "NO_REBUILD_ROUTE";
    } else {
      rebuildKey.value = newFullPath;
    }
  },
  { immediate: true },
);
</script>
<script>
import AppLayout from "./AppLayout.vue";
import Sidebar from "./components/Layout/Sidebar.vue";

export default {
  components: { Sidebar, AppLayout },
  computed: {
    isAuthenticated: function () {
      return this.$store.state.isAuthenticated;
    },
    user: function () {
      const user = this.$store.state.user;
      return user;
    },
    primaryCenterName: function () {
      return this.$store.state.primaryCenterName;
    },
    isLoading: function () {
      return this.$store.state.isSplashScreen;
    },
    dermicusPlatform: function () {
      return this.$store.state.dermicusPlatform;
    },
    sidebarIsOpen() {
      return this.$store.state.sidebarIsOpen;
    },
    chatIsOpen() {
      return this.$store.state.chatIsOpen;
    },
  },
  watch: {
    $route() {
      this.$store.commit("closeSidebar");
      this.$store.commit("closeChat");
    },
  },
  methods: {
    startPage: function () {
      localStorage.removeItem("last_visited_examination_id");
      localStorage.removeItem("last_query_uri");
    },
    logoutUser: function () {
      this.$router.push({ name: "Logout" });
    },
    handleSidebarToggle() {
      this.$store.commit("toggleSidebar");
    },
  },
};
</script>

<style lang="scss" scoped>
.page-wrapper__main {
  width: 100%;
}
</style>
