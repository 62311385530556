import { lang } from "../../i18n";

function messageFor(examination) {
  if (examination.status === 5) {
    return lang.get("action.examination.cancel.confirm");
  } else if (examination.status !== 1) {
    return lang.get("action.examination.close.confirm");
  } else if (/wound/i.test(examination.type?.name)) {
    return lang.get("action.examination.closeNew.confirm.wound");
  } else if (/mole/i.test(examination.type?.name) || !examination.type) {
    return lang.get("action.examination.closeNew.confirm.mole");
  } else {
    return lang.get("action.examination.closeNew.confirm.default");
  }
}

export function examinationCloseConfirm(confirm, examination) {
  return confirm(messageFor(examination), undefined, undefined, {
    confirmButtonText: lang.get("general.modalConfirmButton"),
    cancelButtonText: lang.get("general.modalCancelButton"),
  });
}
