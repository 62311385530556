<template>
  <login-form-container>
    <div class="text-center container">
      <h5 class="pt-5 pb-3">
        {{
          errorType >= 500
            ? trans("errorMessages.HTTPStatus.5XX")
            : trans("forms.badRequestError")
        }}
      </h5>
      <p class="pb-5">
        {{ errorType >= 500 ? "" : trans("forms.badRequestErrorMsg") }}
      </p>
    </div>
  </login-form-container>
</template>

<script>
import LoginFormContainer from "../Auth/LoginFormContainer.vue";

export default {
  components: {
    LoginFormContainer,
  },
  props: {
    errorType: Number,
  },
};
</script>
