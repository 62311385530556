import {
  displaysFor,
  StatusVariant,
  variantsFor,
} from "../design-system/tokens/variants";
import { lang } from "../i18n";
import { StudyConsentStatus } from "../../../../app/Dermicus/PatientPortal/StudyConsent";

export const studyConsentStatusVariant = variantsFor<
  StudyConsentStatus,
  StatusVariant
>({
  proposed: "warning",
  active: "success",
  rejected: "danger",
});

export const studyConsentStatusDisplay = displaysFor<StudyConsentStatus>({
  proposed: () => lang.get("model.studyConsentStatus.proposed.display"),
  active: () => lang.get("model.studyConsentStatus.active.display"),
  rejected: () => lang.get("model.studyConsentStatus.rejected.display"),
});
