<template>
  <fieldset>
    <div class="form-group">
      <DerToggleWithLabel
        v-model="withDateRange"
        :label="trans('region.customExportDates')"
      />
    </div>
    <div v-if="withDateRange" class="form-row">
      <div
        class="form-group col-md-6"
        :class="{ 'has-error': !!dataFormErrors?.fromDate }"
      >
        <label :for="dateFromInputId">
          {{ trans("evaluation.tableColumnCreatedAtFrom") }}
        </label>
        <CustomDatepicker
          :id="dateFromInputId"
          name="dateFrom"
          :placeholder="trans('evaluation.tableColumnCreatedAtFrom')"
        />
        <FormError
          v-if="!!dataFormErrors?.fromDate"
          :errors="dataFormErrors?.fromDate"
        />
      </div>
      <div
        class="form-group col-md-6"
        :class="{ 'has-error': !!dataFormErrors?.untilDate }"
      >
        <label :for="dateUntilInputId">
          {{ trans("evaluation.tableColumnCreatedAtTo") }}
        </label>
        <CustomDatepicker
          :id="dateUntilInputId"
          name="dateUntil"
          :placeholder="trans('evaluation.tableColumnCreatedAtTo')"
        />
        <FormError
          v-if="!!dataFormErrors?.untilDate"
          :errors="dataFormErrors?.untilDate"
        />
      </div>
    </div>
  </fieldset>
</template>
<script setup>
import { ref } from "vue";
import { v4 as uuidV4 } from "uuid";
import CustomDatepicker from "../../../../../components/Utils/Datepicker.vue";
import FormError from "../../../../../components/Utils/FormError.vue";
import DerToggleWithLabel from "../../../../../design-system/DerToggleWithLabel.vue";

const withDateRange = ref(false);
const dateFromInputId = uuidV4();
const dateUntilInputId = uuidV4();

defineProps({
  dataFormErrors: {
    type: /** @type {Record<'fromDate'|'untilDate', string|string[]>} */ Object,
  },
});
</script>
