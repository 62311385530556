/**
 * Storage interface for a single item, for easy interaction.
 *
 * @see {@link https://developer.mozilla.org/en-US/docs/Web/API/Storage}
 */
export type ItemStorage = {
  set(value: string): void;
  isSet(): boolean;
  get(): string | null;
  remove(): void;
};

/**
 * Creates storage for a single item, for easy interaction.
 *
 * Use this to create an item storage instead of using local- or sessionStorage
 * directly, as it gives us better maintainability and readability, as in:
 *
 * - Easier to test code units where storage is used, as it can be injected.
 * - No need to reference the key used, just explicitly use the item storage.
 * - Reduce chance on logic errors by explicit usage of the run-time instance.
 * - Ability to configure the level of storage without impacting the usages.
 * - Ability to create a custom storage without impacting the usages.
 */
export function itemStorageProvider(config: {
  storage: Pick<Storage, "setItem" | "getItem" | "removeItem">;
  key: string;
}): ItemStorage {
  return {
    set: config.storage.setItem.bind(config.storage, config.key),
    isSet: () => config.storage.getItem(config.key) !== null,
    get: config.storage.getItem.bind(config.storage, config.key),
    remove: config.storage.removeItem.bind(config.storage, config.key),
  };
}
