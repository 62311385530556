<template>
  <examination-documentation
    :examination-id="examinationId"
    :enabled-types="extraOptions.enabledTypes"
  />
</template>

<script>
import ExaminationDocumentation from "../ExaminationDocumentation.vue";

export default {
  components: { ExaminationDocumentation },
  props: {
    examinationId: Number,
    extraOptions: {
      enabledTypes: Array,
    },
  },
};
</script>
