<template>
  <div>
    <StudyConsentsView
      v-if="studyConsents !== undefined"
      :data-study-consents="studyConsents"
      @download-consent="onDownloadConsent"
      @to-study-consent="onToStudyConsent"
    />
    <ErrorAlert v-if="!!error" :data-error="error" />
  </div>
</template>
<script setup>
import StudyConsentsView from "./StudyConsentsView.vue";
import { useAsyncState } from "@vueuse/core";
import { useRouter } from "vue-router";
import _noop from "lodash/fp/noop";
import { patientHttpService } from "./store/patient-http-service";
import { studyConsentDownload } from "./store/study-consent";
import { locationOf, ROUTES } from "../routing/routes";
import ErrorAlert from "../../design-system/ErrorAlert.vue";

const router = useRouter();
const { state: studyConsents, error } = useAsyncState(
  patientHttpService
    .get("/v1/study-consents")
    .then((response) => response.data.data),
  undefined,
);
const onDownloadConsent = studyConsentDownload;
const onToStudyConsent = ({ id }) => {
  router
    .push(
      locationOf(ROUTES["patient.studyConsent"], {
        params: { studyConsentId: id },
      }),
    )
    .catch(_noop);
};
</script>
