<template>
  <div>
    <div id="custom-search-input" class="mb-3">
      <form class="input-group col-md-12" @submit.prevent="search">
        <input
          id="admin-users-search"
          v-model="searchFields.q"
          type="text"
          class="form-control input-lg"
          :placeholder="trans('general.searchUserPlaceholder')"
          :title="trans('general.search')"
        />
        <span class="input-group-btn">
          <button
            v-if="!isSearching"
            class="btn"
            type="button"
            @click.stop="search"
          >
            <i class="far fa-search" />
          </button>
          <button
            v-if="isSearching"
            class="btn"
            type="button"
            @click.stop="clearSearch"
          >
            <i class="fal fa-times" />
          </button>
        </span>
      </form>
    </div>

    <div class="table-responsive">
      <clip-loader v-show="loading" />

      <table class="table table-hover interactive-table">
        <thead class="sortable-table-header">
          <tr>
            <th>{{ trans("forms.hsaId") }}</th>
            <th>{{ trans("forms.email") }}</th>
            <th>{{ trans("forms.firstname") }}</th>
            <th>{{ trans("forms.lastname") }}</th>
            <th v-if="usersType === 'preliminary'">RiksSår login</th>
          </tr>
        </thead>
        <tbody>
          <tr
            v-for="(user, index) in usersList"
            :key="index"
            :class="{
              last: isLastVisited(user.id),
            }"
            @click="openUser(user.id)"
          >
            <td>{{ user.hsa_id }}</td>
            <td>{{ user.email }}</td>
            <td>{{ user.firstname }}</td>
            <td>{{ user.lastname }}</td>
            <td v-if="usersType === 'preliminary'">
              <i v-if="hasRikssar(user)" class="far fa-check" />
            </td>
          </tr>
        </tbody>
      </table>
    </div>

    <div class="row-fluid float-right">
      <DerPaginate
        v-model="paginate.current_page"
        :page-count="paginate.last_page"
        @change="getUsers"
      />
    </div>
  </div>
</template>

<script>
import DerPaginate from "../../../../design-system/DerPaginate.vue";
import { queryStringBuilder } from "../../../../helpers/misc";
import HeaderAdmin from "../../Header.vue";

export default {
  components: {
    HeaderAdmin,
    DerPaginate,
  },
  props: {
    usersType: String,
  },
  data: function () {
    return {
      loading: true,
      usersList: [],
      paginate: {
        current_page: 0,
        total: 0,
        last_page: 0,
      },
      isSearching: false,
      searchFields: {
        page: 1,
        q: null,
        lastVisitedId: null,
      },
    };
  },
  created: function () {
    this.setQueryFields();
    this.getUsers();
  },
  methods: {
    getUsers: function (page) {
      this.loading = true;

      this.searchFields.page = page || this.searchFields.page;

      localStorage.setItem(
        `${this.usersType}_users`,
        JSON.stringify(this.searchFields),
      );

      axios
        .get(
          `/api/internal/admin/users?${this.usersType}&` +
            queryStringBuilder(this.searchFields),
        )
        .then((data) => {
          const collection = data.data;
          this.paginate = collection.paginate;
          this.usersList = collection.data;
          this.loading = false;

          this.setQueryFields();
        })
        .catch((error) => {
          this.loading = false;
          this.userErrorMessage(error);
        });
    },
    setQueryFields: function () {
      const queriesFromLocalStorage = localStorage.getItem(
        `${this.usersType}_users`,
      );

      if (queriesFromLocalStorage) {
        this.searchFields = JSON.parse(queriesFromLocalStorage);
      }

      if (this.searchFields.q) {
        this.isSearching = true;
      }
    },

    clearSearch: function () {
      this.searchFields = {
        page: 1,
        q: null,
      };
      this.isSearching = false;
      this.getUsers(1);
    },
    search: function () {
      this.loading = true;
      this.isSearching = true;
      this.getUsers(1);
    },
    openUser: function (id) {
      const routeName = this.isTentative()
        ? "AdminPreliminaryUsersActivate"
        : "AdminUsersEdit";
      this.$router.push({
        name: routeName,
        params: { id: id },
      });
      this.searchFields.lastVisitedId = id;
      localStorage.setItem(
        `${this.usersType}_users`,
        JSON.stringify(this.searchFields),
      );
    },
    isLastVisited: function (userId) {
      return (
        JSON.parse(localStorage.getItem(`${this.usersType}_users`))
          .lastVisitedId == userId
      );
    },
    isTentative: function () {
      if (this.usersType === "preliminary") {
        return true;
      } else {
        return false;
      }
    },
    hasRikssar: function (user) {
      return JSON.parse(user.extra_settings)["has_rikssar"];
    },
  },
};
</script>
