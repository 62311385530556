<template>
  <field-panel
    :title="title"
    :info-popup-text="getInfoPopupText()"
    :custom-actions-menu="
      actions
        ? {
            onAction: onAction,
            actions: actions,
            loading: loading,
          }
        : undefined
    "
  >
    <edit-examination
      v-if="editMedicalHistory"
      :visible="editMedicalHistory"
      :loading="loading"
      :examination="latestExamination"
      :product-definition="product"
      edit-type="medical_history"
      @on-cancel="onCancelEditMedicalHistory"
      @on-save="onSaveEditMedicalHistory"
    />

    <div class="panel-body">
      <div v-if="examinationData.length > 0" class="row">
        <column v-for="(field, index) in examinationData" :key="index">
          <field
            :examination-id="latestExamination.id"
            :title="field.title"
            :data="field.data"
            :type="field.type"
            :extra-options="field.extraOptions"
          />
        </column>
      </div>
    </div>
  </field-panel>
</template>

<script>
import Column from "./components/Column.vue";
import FieldPanel from "./components/FieldPanel.vue";
import EditExamination from "../../../Examinations/Examination/ExaminationComponents/components/EditExamination.vue";

import { FIELD_CONSTANTS } from "../fieldMap";
import { getFieldType } from "../../../../helpers/product";
import { infoPopupText } from "../../../../helpers/misc";
import {
  PRODUCT_ACTION_NAMES,
  STORE_CONSTANTS,
} from "../../../../helpers/definitions";
import Notifications from "../../../Utils/notifications";

import { productHasPermission } from "../../../../helpers/product";
import {
  PRODUCT_ACTIONS,
  PRODUCT_PERMISSIONS,
} from "../../../../helpers/definitions";

import { getLastUpdatedExamination } from "../../../../helpers/product";

export default {
  components: {
    Column,
    FieldPanel,
    EditExamination,
  },
  props: {
    fields: Array,
    examination: {
      id: Number,
      case_data: Object,
    },
    product: Object,
    viewOption: String,
  },
  data() {
    return {
      examinationData: [],
      FIELD_CONSTANTS: FIELD_CONSTANTS,
      numberOfColumns: 2,
      title: "examination.medicalHistory",
      editMedicalHistory: false,
      loading: false,
      latestExamination: this.examination,
      actions: null,
    };
  },
  watch: {
    latestExamination: function () {
      this.mapData();
    },
  },
  mounted: function () {
    this.mapData();
    this.mapActions();
  },
  methods: {
    mapData: function () {
      const { fields } = this;

      // nested object values don't get detected in vue2 and don't update from mapper, so to update medical history with new values we need local data variable
      const examination = this.latestExamination;

      const examinationData = [];
      fields.forEach((field) => {
        const hasValue =
          examination?.medical_history &&
          field.name in examination.medical_history;

        if (field.visible) {
          const valueOptions = JSON.parse(
            JSON.stringify(JSON.parse(field.value_options)),
          );
          examinationData.push({
            title: field.name,
            data: hasValue ? examination.medical_history[field.name] : "-",
            type: getFieldType(field),
            disabled: field.disabled,
            extraOptions: {
              valueOptions: valueOptions,
              examination: examination.medical_history,
            },
          });
        }
      });
      this.examinationData = examinationData;
    },
    getInfoPopupText: function () {
      return this.examinationData.length > 0
        ? infoPopupText(this.examinationData)
        : "";
    },
    onAction: function (action) {
      switch (action.name) {
        case PRODUCT_ACTION_NAMES.EDIT_MEDICAL_HISTORY:
          this.editMedicalHistory = true;
          break;
      }
    },
    onCancelEditMedicalHistory: function () {
      this.editMedicalHistory = false;
    },
    onSaveEditMedicalHistory: async function (values) {
      this.loading = true;

      await axios
        .post(
          `/api/internal/tumour-wound/edit-medical-history/${this.latestExamination.id}`,
          values,
        )
        .then(async () => {
          await this.$store.dispatch(STORE_CONSTANTS.LOAD_PATIENT_CASES);

          // nested object values don't get detected in vue2 and don't update from mapper, so to update medical history with new values we need local data variable and manual update
          this.latestExamination = getLastUpdatedExamination(
            this.$store.state.currentPatient.patientCases,
          );

          this.loading = false;
          this.editMedicalHistory = false;
          $(`#editExaminationModal-${this.latestExamination.caseNo}`).modal(
            "hide",
          );
          Notifications.saveSuccess();
        })
        .catch((error) => {
          this.loading = false;
          this.editMedicalHistory = true;
          this.userErrorMessage(error);
        });
    },
    mapActions: function () {
      let medicalHistoryActions = [];
      const specificActionName = PRODUCT_PERMISSIONS.EDIT_MEDICAL_HISTORY;

      let actionDefinition = PRODUCT_ACTIONS.find(
        (action) => action.name === specificActionName,
      );

      const enabled =
        productHasPermission(actionDefinition.name, this.product) &&
        this.$can(actionDefinition.name);

      if (enabled) {
        medicalHistoryActions.push({ ...actionDefinition, enabled });
      }

      this.actions = medicalHistoryActions.length
        ? medicalHistoryActions
        : null;
    },
  },
};
</script>
