import Notifications from "../components/Utils/notifications";

// TODO, lets discuss if this is a good way forward to organise all request. POC
const post = async (url, value, context) => {
  try {
    const result = await axios.post(url, value);
    if (result.status === 200) {
      Notifications.saveSuccess(context);
      return result.data;
    }
  } catch (e) {
    context.userErrorMessage(e);
  }
};

export default {
  post,
};
