import { AxiosInstance } from "axios";
import { ItemStorage } from "../../lib/item-storage";

export function authServiceProvider(config: {
  httpService: AxiosInstance;
  authToken: ItemStorage;
}) {
  return {
    isAuthenticated: () => config.authToken.isSet(),
    getLoginUrl: (options: { callbackUrl: string }) =>
      config.httpService.getUri({
        method: "GET",
        // Bug in axios that doesn't add the base url here.
        // See https://github.com/axios/axios/issues/2468
        // TODO: But we should upgrade as we're far behind, and there
        //  are security fixes included during these many releases....
        url: "/api/patient-portal/v1/auth/login",
        params: options,
      }),
    loginConfirm: (data: { accessToken: string }) => {
      // TODO: Use a secure solution to retrieve the access token!
      config.authToken.set(data.accessToken);
    },
    /**
     * Logout the principal, revoking the API token on server if not forced.
     *
     * A forced logout can happen when we get 401 UNAUTHORIZED errors, and then
     * revoking the API token is useless and even ends up in a continuous loop.
     */
    async logout(force: boolean) {
      if (!force) {
        await config.httpService.post("/v1/auth/logout");
      }
      config.authToken.remove();
    },
  };
}
