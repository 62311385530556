import { ExaminationSummary } from "../Examinations/ExaminationSummary";
import { StudyExaminationAttributeStatus } from "./StudyExaminationAttributeStatus";
import { StudyWorkflowSummary } from "./StudyWorkflowSummary";
import _flatMap from "lodash/fp/flatMap";
import _get from "lodash/fp/get";
import _map from "lodash/fp/map";
import _uniq from "lodash/fp/uniq";
import _sortBy from "lodash/fp/sortBy";
import _identity from "lodash/fp/identity";
import _flow from "lodash/fp/flow";

export type StudyExaminationSummary = {
  id: number;
  examination: ExaminationSummary;
  study_workflows: StudyWorkflowSummary[];
} & StudyExaminationAttributeStatus;

export const getStudyWorkflowTypes = _flow(
  _flatMap(_get("study_workflows")),
  _map("name"),
  _uniq,
  _sortBy(_identity)
);
