<template>
  <div class="icon-loader" data-testid="clip-loader">
    <svg viewBox="0 0 69 78" xmlns="http://www.w3.org/2000/svg">
      <path
        class="icon-loader-blob blob1"
        d="M26.9288 1.58717C31.3967 -1.45995 36.6846 -0.0210349 39.7103 5.0575C41.9159 9.43068 42.1139 14.5656 40.191 19.0799C40.191 19.0799 36.8825 30.563 25.6563 29.0113C21.1602 28.3059 17.5972 27.1773 15.5612 24.0174C14.5715 22.4374 14.1473 20.5752 14.4018 18.7131C14.8543 15.3839 18.4455 7.76605 26.9005 1.55895"
        fill="#5E3880"
      />
      <path
        class="icon-loader-blob blob2"
        d="M65.245 22.6066C63.52 20.7726 61.4275 19.3055 59.1087 18.2898C58.0625 17.8666 56.9313 17.5562 55.8002 17.4434C53.227 17.0766 50.5972 17.7537 48.5329 19.3337C43.0753 23.4248 39.9365 34.2025 42.3684 40.5789C44.6871 46.6732 50.2296 48.5917 55.7437 45.1778C56.4224 44.7546 57.0445 44.3032 57.6666 43.7953C60.664 41.6793 63.3504 39.1118 65.556 36.1775C66.8002 34.5693 67.6203 32.6508 67.9031 30.6476C68.3838 27.6851 67.3941 24.6944 65.245 22.6066"
        fill="#4D7DBB"
      />
      <path
        class="icon-loader-blob blob3"
        d="M29.7565 57.5356C30.2655 56.5764 30.8876 55.7017 31.5946 54.8835C35.8079 50.2282 41.8594 50.0589 46.5534 54.686C51.191 58.7771 52.9159 65.2663 50.9082 71.1066C49.5509 76.0159 44.4609 78.8937 39.5406 77.5112C39.4558 77.5112 39.3426 77.4548 39.2578 77.4266C31.4532 75.4234 25.6845 66.8463 28.8234 59.4824C29.1061 58.8053 29.4172 58.1281 29.7565 57.4792"
        fill="#4D7DBB"
      />
      <path
        class="icon-loader-blob blob4"
        d="M20.9339 33.9768C16.8902 32.3404 9.87737 30.8733 6.173 33.7793C4.30668 35.2747 2.86452 37.1933 1.95963 39.3939C1.6203 40.1275 1.33752 40.8893 1.1113 41.6511C0.206419 44.3878 -0.16119 47.2939 0.0650309 50.1717C0.347807 54.4885 3.5149 58.0435 7.75655 58.9181C14.9108 60.357 22.1781 57.2252 26.0522 51.0746C28.5972 47.0682 29.8979 43.0618 27.3529 38.6322C26.5612 37.3061 25.3735 36.2058 23.9596 35.5286C22.9982 34.9361 21.9802 34.4283 20.9339 33.9768"
        fill="#4D7DBB"
      />
    </svg>
  </div>
</template>

<script>
export default {
  name: "ClipLoader",
  props: {
    loading: {
      type: Boolean,
      default: true,
    },
  },
};
</script>

<style lang="scss">
@use "sass:math";
$width: 24;

.icon-loader {
  width: $width + 0px;
  position: relative;
  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
  margin: 20px 0px;
}

.icon-loader-blob {
  will-change: transform;
  animation-name: grow;
  animation-duration: 0.5s;
  animation-timing-function: ease-in-out;
  animation-iteration-count: infinite;
  animation-direction: alternate;
  transform-origin: center;
  transform-box: fill-box;

  &.blob2 {
    animation-delay: 125ms;
  }

  &.blob3 {
    animation-delay: 250ms;
  }

  &.blob4 {
    animation-delay: 375ms;
  }
}

@keyframes grow {
  to {
    transform: scale(0.3);
  }
}

.overlay {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: #ffffff87;
  z-index: 2;
}
</style>
