<template>
  <div>
    <ul class="tw-list-none tw-m-0 tw-p-0 tw-border-gray-100 tw-border-solid">
      <li
        v-for="attachment in dataAttachments"
        class="tw-border-0 tw-border-gray-200 tw-border-b tw-border-solid tw-p-3 even:tw-bg-primary-100 last:tw-border-b-0"
      >
        <span
          class="tw-text-primary hover:tw-text-primary-dark hover:tw-cursor-pointer"
          @click="handleDocumentationClick(attachment)"
        >
          {{ attachment.name }}
        </span>
      </li>
    </ul>
  </div>
</template>

<script setup lang="ts">
import { ExaminationAttachment } from "../../../../../../../app/Dermicus/Examinations/ExaminationAttachment";

defineProps<{
  dataAttachments: Array<ExaminationAttachment>;
}>();

const emit = defineEmits<{
  (event: "attachmentClick", item: ExaminationAttachment): void;
}>();

function handleDocumentationClick(item: ExaminationAttachment) {
  emit("attachmentClick", item);
}
</script>
