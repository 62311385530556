<template>
  <div>
    <header-admin :active-section="2" />
    <div>
      <div class="float-right space-x-2">
        <!--        <router-link to="/admin/users/invite" class="btn btn-secondary mt-1">-->
        <!--          {{ trans("center.inviteNewUser") }}</router-link-->
        <!--        >-->
        <router-link to="/admin/users/new" class="btn btn-primary mt-1">
          {{ trans("center.addNewUser") }}
        </router-link>
      </div>

      <div class="mt-3">
        <ul id="usersTab" class="nav nav-tabs" role="tablist">
          <li class="nav-item">
            <router-link
              active-class="active"
              class="nav-link"
              to="/admin/users/active-users"
            >
              {{ trans("users.activeUsersLabel") }}
            </router-link>
          </li>
          <li class="nav-item">
            <router-link
              active-class="active"
              class="nav-link"
              to="/admin/users/inactive-users"
            >
              {{ trans("users.inactiveUsersLabel") }}
            </router-link>
          </li>

          <!--
            @see {@link https://gnosco.atlassian.net/browse/DER-4059}

            <li class="nav-item">
            <router-link
              active-class="active"
              class="nav-link"
              to="/admin/users/preliminary-users"
            >
              {{ trans("users.preliminaryUsersLabel") }}
            </router-link>
          </li> -->
        </ul>
      </div>

      <router-view />
    </div>
  </div>
</template>

<script>
import HeaderAdmin from "../../Header.vue";

export default {
  components: {
    HeaderAdmin,
  },
};
</script>
