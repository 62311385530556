<template>
  <div>
    <div v-if="!loading && !formSubmitted && isValidLink">
      <div class="bg-image" />

      <div class="row form-component justify-content-center">
        <div v-show="loading" class="col justify-content-center">
          <clip-loader />
        </div>

        <div class="form-header row mb-2 justify-content-center">
          <img src="/images/dermicus-logo.svg" height="50" />
          <div class="my-3 col-12">
            <div class="text-center">
              <h5>
                {{ trans("forms.registrationIntro") }}
              </h5>
              <p>
                {{ trans("forms.registrationIntroMsg") }}
              </p>
            </div>
          </div>
        </div>

        <div class="col-sm-12">
          <user-form
            :errors="errors"
            :definition="formDefinition"
            :form-data="formData"
            :is-valid-link="isValidLink"
            @submit="handleSubmit"
          />
        </div>
      </div>
    </div>

    <registration-error-message
      v-if="!loading && !isValidLink"
      :error-type="errorType"
    />

    <registration-confirmation v-if="!loading && formSubmitted" />
  </div>
</template>

<script>
import UserForm from "./UserForm.vue";
import RegistrationConfirmation from "./RegistrationConfirmation.vue";
import RegistrationErrorMessage from "./RegistrationErrorMessage.vue";

import { getRegistrationFormDefinition } from "./registrationFormDefinitions";

export default {
  components: {
    UserForm,
    RegistrationConfirmation,
    RegistrationErrorMessage,
  },
  data() {
    return {
      formSubmitted: false,
      isValidLink: undefined,
      formDefinition: undefined,
      loading: true,
      errors: [],
      errorType: undefined,
      formData: {},
      prePopulatedValues: [],
    };
  },
  mounted: function () {
    this.loadForm();
  },
  methods: {
    loadForm: async function () {
      await this.getNewUser();
      this.initializeFormData();
      this.loading = false;
    },
    getNewUser: async function () {
      try {
        this.loading = true;
        const response = await axios.get(
          `/api/internal/validateRegistration/${this.$route.params.key}`,
        );
        const prePopulatedValues = [];
        const token = { name: "token", value: this.$route.params.key };
        prePopulatedValues.push(token);
        Object.entries(response.data).forEach(([key, value]) => {
          const object = { name: key, value: value };
          prePopulatedValues.push(object);
        });
        this.prePopulatedValues = prePopulatedValues;
        this.isValidLink = true;
      } catch (error) {
        this.loading = false;
        this.isValidLink = false;
        this.errorType = error.response.status;
      }
    },
    initializeFormData: function () {
      const definition = getRegistrationFormDefinition();
      this.formDefinition = definition.formData;

      const formData = {};
      definition.formData.forEach((field) => {
        formData[field.name] = field.defaultValue;
      });
      this.prePopulatedValues.forEach((field) => {
        formData[field.name] = field.value;
      });
      this.formData = formData;
    },
    handleSubmit: async function (data) {
      try {
        await axios.post("/api/internal/register", data);
        this.formSubmitted = true;
      } catch (error) {
        this.errors = error.response.data;
        this.userErrorMessage(error);
      }
    },
  },
};
</script>

<style scoped>
.form-component {
  background-color: white;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 2;
  max-height: 90vh;
  min-height: auto;
  width: 50%;

  padding: 20px;
  overflow-y: scroll;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}
.form-component::-webkit-scrollbar {
  display: none;
}
@media (max-width: 768px) {
  .form-component {
    width: 80%;
  }
}

@media (min-width: 768px) {
  .form-component {
    width: 70%;
  }
}

@media (min-width: 992px) {
  .form-component {
    width: 50%;
  }
}

@media (min-width: 1200px) {
  .form-component {
    width: 50%;
  }
}
</style>
