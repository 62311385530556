<template>
  <info-popup :text-content="getPopoverContent()" />
</template>

<script>
import DropdownRole from "../../../mixins/DropdownRole.vue";
import InfoPopup from "../../Utils/InfoPopup.vue";

export default {
  components: { InfoPopup },
  mixins: [DropdownRole],
  props: {
    product: String,
  },
  data: function () {
    return {
      platform: this.$store.state.dermicusPlatform,
      userRole: this.dropdownRole(this.$store.state.user),
    };
  },
  methods: {
    getPopoverContent: function () {
      let extraConsultantsFeaturesInfo = this.trans(
        "tooltip.consultantsGeneralBox",
      );
      return this.userRole && this.userRole.includes("consult")
        ? this.platformAndProductsBasedContent() + extraConsultantsFeaturesInfo
        : this.platformAndProductsBasedContent();
    },

    platformAndProductsBasedContent: function () {
      if (this.platform === "stockholm" && this.product === "mole") {
        return this.trans("tooltip.moleStockholmGeneralBox");
      } else {
        // all other platforms
        if (this.product === "moleUk") {
          return this.trans("tooltip.moleUkGeneralBox");
        } else {
          // all other products
          return this.trans("tooltip.moleGeneralBox");
        }
      }
    },
  },
};
</script>
