<template>
  <ClipLoader v-if="isLoading" />
  <div v-else-if="isReady">
    <slot />
  </div>
  <ErrorAlert v-else :data-error="error" />
</template>
<script setup lang="ts" generic="T, E">
import ErrorAlert from "./ErrorAlert.vue";
import ClipLoader from "../components/Utils/ClipLoader.vue";
import { computed, toRefs } from "vue";
import { QueryObserverResult } from "@tanstack/vue-query";

const props = defineProps<{
  dataResource: readonly QueryObserverResult<T, E>[];
}>();
const { dataResource } = toRefs(props);
const isLoading = computed(() =>
  dataResource.value.some((result) => result.isLoading),
);
const error = computed(
  () => dataResource.value.find((result) => result.error)?.error,
);
const isReady = computed(() => !isLoading.value && !error.value);
</script>
