<template>
  <div class="app-patient-login-layout content-scroll">
    <main class="app-patient-login-main">
      <div class="app-patient-login-container space-y-4">
        <h1 class="app-patient-login-title">
          {{ lang.get("patientPortal.login.title") }}
        </h1>
        <div class="content-for-reading">
          <div
            v-dompurify-html="lang.get('patientPortal.login.intro')"
            class="app-patient-login-intro"
          />
          <a
            :href="loginUrl"
            class="app-patient-login-provider btn btn-primary"
          >
            <BankIDLogo />{{ lang.get("patientPortal.login.login") }}
          </a>
        </div>
        <div
          v-dompurify-html="lang.get('patientPortal.login.notes')"
          class="content-for-reading app-patient-login-notes"
        />
        <footer class="app-patient-login-footer">
          <PatientPortalBrandLogo />
        </footer>
      </div>
    </main>
  </div>
</template>
<script setup lang="ts">
import BankIDLogo from "../../../../images/BankID_logo_white.svg";
import { patientAuthService } from "./patient-auth-service";
import PatientPortalBrandLogo from "../comp/PatientPortalBrandLogo.vue";
import { useRoute } from "vue-router";
import { lang } from "../../../i18n";
import _isString from "lodash/fp/isString";

const callbackUrl = useRoute().query.callbackUrl;
const loginUrl = patientAuthService.getLoginUrl({
  callbackUrl: _isString(callbackUrl) ? callbackUrl : "",
});
</script>
<style lang="scss">
@use "/resources/assets/sass/abstracts/variables";

.app-patient-login-layout {
  display: grid;

  position: absolute;
  width: 100%;
  height: 100%;

  background-image: url("/resources/assets/images/image-bg.jpg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.app-patient-login-main {
  display: grid;
  align-content: center;
  justify-content: center;
  padding: 1em;
}

.app-patient-login-container {
  background-color: variables.$dermicusWhite;
  border-radius: 5px;
  padding: 2em;
  padding-bottom: 1em;
  max-width: 32em;
}

.app-patient-login-title {
  font-size: 1.5em;
  font-weight: variables.$font-weight-light !important;
  color: variables.$dermicusBlue;
  line-height: 1.4;
}

.app-patient-login-intro {
  color: variables.$dermicusDarkBlue;
}

.app-patient-login-provider {
  display: flex;
  align-items: center;
  justify-content: center;

  svg {
    width: 4.5em;
  }
}

.app-patient-login-notes {
  color: variables.$dermicusBlue;
  text-align: center;
  font-size: 0.75em;
  font-weight: variables.$font-weight-light !important;
}

.app-patient-login-footer {
  display: flex;
  justify-content: center;

  svg {
    width: 12em;
  }
}
</style>
