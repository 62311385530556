import _set from "lodash/fp/set";
import { ItemStorage } from "./item-storage";

export function httpRequestAuthTokenInjectorProvider(config: {
  authToken: ItemStorage;
}) {
  return (requestConfig: Record<string, unknown>) =>
    config.authToken.isSet()
      ? _set(
          "headers.common.Authorization",
          `Bearer ${config.authToken.get()}`,
          requestConfig,
        )
      : requestConfig;
}
