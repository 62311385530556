import { ClassValue } from "class-variance-authority/dist/types";

export const INTENT_VARIANT_VALUES = ["primary", "secondary"] as const;

export type IntentVariant = (typeof INTENT_VARIANT_VALUES)[number];

export const STATUS_VARIANT_VALUES = [
  "success",
  "info",
  "warning",
  "danger",
] as const;

export type StatusVariant = (typeof STATUS_VARIANT_VALUES)[number];

export const VISUAL_VARIANT_VALUES = ["light", "dark"] as const;

export type VisualVariant = (typeof VISUAL_VARIANT_VALUES)[number];

/**
 * Utility function to ensure that we add all variants needed.
 *
 * At runtime, this does nothing, but it will fail the TypeScript type checking
 * when there are variants missing from the config, which is what we want/need.
 *
 * The `cva` function in use to define classes based on input is harder to type
 * and ensure this extra check. If that improves, we can use that instead later.
 *
 * When an extra variable is used to hold the config for the variant, you can
 * use the `Record` type directly instead, but it increases cognitive overload,
 * and you won't get the extra help from inline usage and the `cva` typings.
 */
export function classValuesFor<T extends string>(
  config: Record<T, ClassValue>,
) {
  return config;
}

/**
 * Utility function to help in mapping things to their variant.
 *
 * TypeScript helps us here to ensure we configure all possible values,
 * and that the variants are from the possible ones.
 */
export function variantsFor<T extends string, V extends string>(
  map: Record<T, V>,
) {
  return (value: T) => map[value];
}

/**
 * Utility function to help in mapping things to their display.
 *
 * TypeScript helps us here to ensure we configure all possible values, which
 * must be functions to invoke, as then you can get help from IDE plugins with
 * the available translations keys, and thus improved DX.
 */
export function displaysFor<T extends string>(map: Record<T, () => string>) {
  return (value: T) => map[value]();
}
