<template>
  <div>
    <chart-header header-name="patients" />

    <div>
      <clip-loader v-if="loading" />

      <bar-chart
        v-if="patientsData.datasets && !loading"
        class="p-3 m-3 patients-chart"
        :chartdata="patientsData"
        :options="patientsOptions"
      />
    </div>
  </div>
</template>

<script>
import BarChart from "./ChartTypes/BarChart.vue";
import ChartHeader from "./StatisticsUtils/ChartHeader.vue";

export default {
  components: {
    BarChart,
    ChartHeader,
  },
  props: {
    patients: {
      label: String,
      value: Number,
      percentage: Number,
    },
    loading: Boolean,
  },
  data: function () {
    return {
      patientsData: {
        labels: null,
        datasets: null,
      },
      patientsOptions: {
        responsive: true,
        maintainAspectRatio: true,
        legend: {
          display: true,
          position: "top",
          labels: {
            boxWidth: 10,
            padding: 20,
            usePointStyle: true,
          },
        },
        tooltips: {
          titleFontColor: "rgba(0, 0, 0, 1)",
          bodyFontColor: "rgba(0, 0, 0, 1)",
          backgroundColor: "rgba(245, 245, 245, 1)",
          xPadding: 10,
          yPadding: 10,
        },
        scales: {
          x: {
            gridLines: {
              display: false,
            },
          },
        },
      },
    };
  },
  watch: {
    patients: function () {
      this.getData();
    },
  },
  created: function () {
    this.getData();
  },
  methods: {
    getData: function () {
      if (this.patients) {
        let labels = Object.keys(this.patients);

        let capitalizedLabels = labels.map((label) => {
          return label.charAt(0).toUpperCase() + label.slice(1);
        });

        let newPatients = Object.keys(this.patients).map(
          (key) => this.patients[key].new,
        );
        let returningPatients = Object.keys(this.patients).map(
          (key) => this.patients[key].returning,
        );

        this.patientsData = {
          labels: capitalizedLabels,
          datasets: [
            {
              base: 0,
              label: this.trans("statistics.newPatients"),
              data: newPatients,
              order: 1,
              backgroundColor: "#4d7dbb",
              barThickness: 10,
            },
            {
              base: 0,
              label: this.trans("statistics.returningPatients"),
              data: returningPatients,
              order: 2,
              backgroundColor: "#B92844",
              barThickness: 10,
            },
          ],
        };
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.patients-chart {
  max-height: 250px;
}

@media (max-width: 820px) {
  .patients-chart {
    max-height: auto;
  }
}
</style>
