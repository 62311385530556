import {
  displaysFor,
  IntentVariant,
  StatusVariant,
  variantsFor,
} from "../design-system/tokens/variants";
import { lang } from "../i18n";
import { StudyResultStatus } from "../../../../app/Dermicus/Studies/StudyResultStatus";

export const studyResultStatusVariant = variantsFor<
  StudyResultStatus,
  IntentVariant | StatusVariant
>({
  new: "primary",
  "research-answered": "success",
});

export const studyResultStatusDisplay = displaysFor<StudyResultStatus>({
  new: () => lang.get("model.studyResultStatus.new.display"),
  "research-answered": () =>
    lang.get("model.studyResultStatus.research-answered.display"),
});
