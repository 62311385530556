import { App } from "vue";
import VueDOMPurifyHTML, { DirectiveConfig } from "vue-dompurify-html";

export const DOMPURIFY_CONFIG_NAME = {
  fullName: "fullName",
  consultCommentUsers: "consultCommentUsers",
};

/**
 * Applies the DOMPurify directive with our custom configuration.
 *
 * @see {@link https://github.com/LeSuisse/vue-dompurify-html/tree/main/packages/vue-dompurify-html#readme}
 */
export function applyDOMPurify<T>(app: App<T>) {
  return app.use(VueDOMPurifyHTML, {
    default: {
      FORBID_TAGS: [
        /**
         * We have a use-case where style tags can be saved into the name of
         * the patient, which should later be improved in the data layer.
         */
        "style",
      ],
    },
    namedConfigurations: {
      /**
       * Patient names can contain <u> tags, to identify the order of names for
       * Swedish patients, as naming rules are different there.
       */
      [DOMPURIFY_CONFIG_NAME.fullName]: { ALLOWED_TAGS: ["u"] },
      /**
       * Chat badges are currently rendered using HTML returned from the api.
       * @todo: Move UI rendering for chat badges from BE to FE.
       */
      [DOMPURIFY_CONFIG_NAME.consultCommentUsers]: { ALLOWED_TAGS: ["span"] },
    },
  } satisfies DirectiveConfig);
}
