<template>
  <div class="custom-view-wrapper">
    <div class="dg-content-body">
      <div class="dg-content">
        <div class="form-group primaryCenterSelection">
          <label for="primaryCenter">
            {{ lang.get("users.primaryCenterLabel") }}
          </label>
          <i class="far fa-asterisk" />
          <der-select
            v-model="primaryCenterId"
            :data-options="props.dataCenters"
            :data-get-option-value="(option) => option.id"
            :data-get-option-label="(option) => option.text"
            :clearable="false"
          />
        </div>
      </div>
    </div>

    <div class="dg-content-footer tw-flex tw-justify-between">
      <button class="dg-btn dg-btn--cancel" @click="$emit('close')">
        <span>{{ lang.get("general.modalCancelButton") }}</span>
      </button>
      <button
        class="dg-btn dg-btn--ok"
        :disabled="submitDisabled"
        @click="handleSubmit"
      >
        <span>{{ lang.get("general.formSaveLabel") }}</span>
      </button>
    </div>
  </div>
</template>

<script setup lang="ts">
import { ref } from "vue";
import { lang } from "../../i18n";
import DerSelect from "../Utils/DerSelect.vue";
import Alert from "../../design-system/Alert.vue";

const emit = defineEmits(["submit", "close"]);

const props = defineProps<{
  dataCenters: Array<{
    id: number;
    text: string;
  }>;
  dataPrimaryCenterId: number;
  submitDisabled: boolean;
}>();

const primaryCenterId = ref(props.dataPrimaryCenterId);

function handleSubmit() {
  emit("submit", primaryCenterId.value);
}
</script>
