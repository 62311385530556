<template>
  <login-form-container v-if="errorMessage">
    <div class="text-center mt-4 mb-5">
      <div class="my-5 text-danger">
        <h6 class="mb-3">
          {{ trans("errorMessages.loginErrorHeader") }}
        </h6>
        <p>
          {{ errorMessage }}
        </p>
      </div>
      <router-link to="/" class="btn btn-primary">
        {{ trans("errorMessages.loginErrorButtonText") }}
      </router-link>
    </div>
  </login-form-container>
</template>

<script>
import LoginFormContainer from "./LoginFormContainer.vue";
import { practitionerAuthToken } from "../../app/practitioner-portal/auth/practitioner-auth-token";

export default {
  components: {
    LoginFormContainer,
  },
  data: function () {
    return {
      errorMessage: null,
    };
  },
  mounted: function () {
    this.handleTakeCareLogin();
  },
  methods: {
    handleTakeCareLogin: function () {
      if (this.$route.query.oauthCode) {
        axios
          .get(
            "/api/internal/auth/takecare/verify-with-oauth-code?oauthCode=" +
              this.$route.query.oauthCode,
          )
          .then((response) => {
            practitionerAuthToken.set(response.data.accessToken);

            if (this.$route.query.allPatients === "1") {
              this.$router.push({
                path: "/patients/",
              });
            } else if (this.$route.query.patientId > 0) {
              this.$router.push({
                path: "/patients/" + this.$route.query.patientId + "/images",
              });
            } else {
              this.$router.push({ path: "/patients/no-access-take-care" });
            }
          })
          .catch((error) => {
            this.errorMessage = error.response.data.error
              ? this.trans(error.response.data.error)
                ? this.trans(error.response.data.error)
                : error.response.data.error
              : this.trans("errorMessages.HTTPStatus.XXX");
          });
      } else {
        this.$router.push({ name: "Auth" });
      }
    },
  },
};
</script>
