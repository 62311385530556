<template>
  <div>
    <header-admin :active-section="1" />
    <div>
      <div class="float-right">
        <router-link to="/admin/centers/new" class="btn btn-primary mt-1">
          {{ trans("center.addNewCenter") }}
        </router-link>
      </div>

      <div class="mt-3">
        <ul id="myTab" class="nav nav-tabs" role="tablist">
          <li class="nav-item">
            <router-link
              active-class="active"
              class="nav-link"
              to="/admin/centers/active-centers"
            >
              {{ trans("center.activeCenters") }}
            </router-link>
          </li>
          <li class="nav-item">
            <router-link
              active-class="active"
              class="nav-link"
              to="/admin/centers/inactive-centers"
            >
              {{ trans("center.inactiveCenters") }}
            </router-link>
          </li>
        </ul>
      </div>

      <router-view />
    </div>
  </div>
</template>

<script>
import HeaderAdmin from "../../Header.vue";

export default {
  components: {
    HeaderAdmin,
  },
};
</script>
