<template>
  <UseStoreResource :data-resource="centersResource">
    <move-user-dialog
      :data-centers="centerOptions"
      :data-primary-center-id="options.primaryCenter.id"
      :submit-disabled="submitDisabled"
      @submit="handleSubmit"
      @close="$emit('close')"
    />
  </UseStoreResource>
</template>

<script setup lang="ts">
import { useMutation, useQuery } from "@tanstack/vue-query";
import { computed, toRefs } from "vue";
import UseStoreResource from "../../../design-system/UseStoreResource.vue";
import { userPrimaryCenterUpdate } from "../../../store/user";
import MoveUserDialog from "../../User/MoveUserDialog.vue";
import { userErrorMessage } from "../../../mixins/UserErrorMessage.vue";
import { regionCentersSearch } from "../../../store/core/regions";
import { FixedProps } from "../../../lib-vue/fixed-props";
import { store } from "../../../store";

const emit = defineEmits(["close", "userSettingsChanges"]);

const props = defineProps<{
  options: {
    userId: number;
    primaryCenter: {
      id: number;
    };
  };
}>();

const { options } = toRefs(props as FixedProps<typeof props>);

const centersResource = useQuery(
  computed(() =>
    regionCentersSearch(store.state.user.primaryCenter.region_id, {
      notHasConnectedUser: options.value.userId,
    }),
  ),
);

const mutation = useMutation({
  mutationFn: ({ userId, centerId }) =>
    userPrimaryCenterUpdate(userId, centerId).catch(userErrorMessage),
  onSuccess: () => {
    emit("close");
    emit("userSettingsChanges");
  },
});

const submitDisabled = computed(() => {
  return mutation.isLoading.value;
});

const centerOptions = computed(() => {
  return centersResource.data.value?.data.map((center) => {
    return {
      id: center.id,
      text: center.name,
    };
  });
});

function handleSubmit(centerId: number) {
  mutation.mutate({ userId: props.options.userId, centerId });
}
</script>
