import { AxiosInstance } from "axios";
import { patientHttpService } from "./patient-http-service";
import { saveAs } from "file-saver";
import contentDisposition from "content-disposition";

export function studyConsentGiveUrlProvider(config: {
  httpService: AxiosInstance;
}) {
  return function studyConsentGiveUrl(
    identity: { id: string },
    options: { callbackUrl: string },
  ) {
    return config.httpService.getUri({
      method: "GET",
      // Bug in axios that doesn't add the base url here.
      // See https://github.com/axios/axios/issues/2468
      // TODO: But we should upgrade as we're far behind, and there
      //  are security fixes included during these many releases....
      url: `/api/patient-portal/v1/study-consents/${identity.id}/give`,
      params: options,
    });
  };
}

export const studyConsentGiveUrl = studyConsentGiveUrlProvider({
  httpService: patientHttpService,
});

export function studyConsentDownloadProvider(config: {
  httpService: AxiosInstance;
}) {
  return function studyConsentDownload(identity: { id: string }) {
    return config.httpService
      .request({
        method: "GET",
        url: `/v1/study-consents/${identity.id}/document`,
        responseType: "arraybuffer",
      })
      .then((response) => {
        saveAs(
          new Blob([response.data], { type: "application/pdf;charset=utf-8" }),
          contentDisposition.parse(response.headers["content-disposition"])
            .parameters.filename,
        );
      });
  };
}

export const studyConsentDownload = studyConsentDownloadProvider({
  httpService: patientHttpService,
});
