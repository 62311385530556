<template>
  <form v-bind="$attrs">
    <div class="form-group" :class="{ 'has-error': !!dataFormErrors?.hsaId }">
      <label :for="hsaIdInputId">{{ trans("users.hsaIdLabel") }}</label>
      <i class="far fa-asterisk" />
      <input :id="hsaIdInputId" name="hsaId" class="form-control" />
      <FormError
        v-if="!!dataFormErrors?.hsaId"
        :errors="dataFormErrors?.hsaId"
      />
    </div>
    <DateRangeFieldset :data-form-errors="dataFormErrors" />
    <slot />
    <RequiredFieldsNotice class="mt-3" />
  </form>
</template>
<script setup>
import { v4 as uuidV4 } from "uuid";
import RequiredFieldsNotice from "../../admin/comp/RequiredFieldsNotice.vue";
import DateRangeFieldset from "./comp/DateRangeFieldset.vue";
import FormError from "../../../../components/Utils/FormError.vue";

const hsaIdInputId = uuidV4();

defineProps({
  dataFormErrors: {
    type: /** @type {Record<'hsaId'|'fromDate'|'untilDate', string|string[]>} */ Object,
  },
});
</script>
