import { createRouter, createWebHistory } from "vue-router";

/**
 * The main app router instance for use throughout the application.
 *
 * The routes themselves will be added dynamically during startup,
 * to avoid circular dependencies with other service instances.
 * @see {@link https://v3.router.vuejs.org/api/#router-addroute-2}
 *
 * An example of such circular dependency is the `patientHttpService`, which
 * navigates to the logout route on 401 UNAUTHORIZED errors with interceptor,
 * while route hooks in turn need the `patientHttpService` for requests.
 */
export const appRouter = createRouter({
  history: createWebHistory(),
  routes: [],
});
