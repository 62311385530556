import { FORM_TYPES } from "../../helpers/formValidation";

const defaultFormDefinition = {
  formData: [
    {
      title: "email",
      type: "email",
      inputType: "input",
      hasInfoPopup: true,
      infoPopup: "filledOutByAdminInfo",
      defaultValue: "",
      disabled: true,
      placeholder: "email",
      name: "email",
    },
    {
      title: "centername",
      type: "text",
      inputType: "input",
      hasInfoPopup: true,
      infoPopup: "filledOutByAdminInfo",
      defaultValue: "",
      disabled: true,
      placeholder: "centername",
      name: "center_name",
    },
    {
      title: "firstname",
      type: "text",
      inputType: "input",
      required: true,
      defaultValue: "",
      errorMessage: "freetextErrorMsg",
      placeholder: "firstname",
      validationType: FORM_TYPES.FIRST_NAME,
      name: "firstname",
    },
    {
      title: "lastname",
      type: "text",
      inputType: "input",
      required: true,
      defaultValue: "",
      errorMessage: "freetextErrorMsg",
      placeholder: "lastname",
      validationType: FORM_TYPES.LAST_NAME,
      name: "lastname",
    },
    {
      title: "username",
      type: "text",
      inputType: "input",
      required: true,
      hasInfoPopup: true,
      infoPopup: "usernameInfoMsg",
      defaultValue: "",
      errorMessage: "usernameError",
      placeholder: "username",
      validationType: FORM_TYPES.USER_NAME,
      name: "username",
    },
    {
      title: "hsaId",
      type: "text",
      inputType: "input",
      hasInfoPopup: true,
      infoPopup: "hsaIdInfo",
      defaultValue: "",
      errorMessage: "freetextErrorMsg",
      placeholder: "hsaIdPlaceholder",
      name: "hsa_id",
    },
    {
      title: "jobTitle",
      type: "text",
      inputType: "input",
      required: true,
      defaultValue: "",
      errorMessage: "freetextErrorMsg",
      placeholder: "jobTitle",
      validationType: FORM_TYPES.JOB_TITLE,
      name: "title",
    },
    {
      title: "phone",
      type: "text",
      inputType: "input",
      required: true,
      hasInfoPopup: true,
      infoPopup: "phoneNumberInfoMsg",
      defaultValue: "+46",
      errorMessage: "phoneErrorMsg",
      placeholder: "phoneNumber",
      validationType: FORM_TYPES.PHONE,
      name: "phone_no",
    },
    {
      title: "password",
      type: "password",
      inputType: "input",
      required: true,
      hasInfoPopup: true,
      infoPopup: "passwordInfo",
      defaultValue: "",
      errorMessage: "freetextErrorMsg",
      placeholder: "password",
      validationType: FORM_TYPES.PASSWORD,
      name: "password",
    },
    {
      title: "passwordConfirmation",
      type: "password",
      inputType: "input",
      required: true,
      defaultValue: "",
      errorMessage: "freetextErrorMsg",
      placeholder: "passwordConfirmation",
      validationType: FORM_TYPES.PASSWORD_CONFIRM,
      name: "password_confirmation",
    },
    {
      title: "rsLogin",
      inputType: "switch",
      defaultValue: false,
      placeholder: "rsLogin",
      name: "has_rikssar",
    },
    {
      title: "userHasFilledOutForm",
      type: "checkbox",
      inputType: "checkbox_round",
      required: true,
      defaultValue: false,
      placeholder: "userHasFilledOutForm",
      name: "userHasFilledOutForm",
    },
  ],
};
const ukFormDefinition = {
  formData: [
    {
      title: "email",
      type: "email",
      inputType: "input",
      hasInfoPopup: true,
      infoPopup: "filledOutByAdminInfo",
      defaultValue: "",
      disabled: true,
      placeholder: "email",
      name: "email",
    },
    {
      title: "centername",
      type: "text",
      inputType: "input",
      hasInfoPopup: true,
      infoPopup: "filledOutByAdminInfo",
      defaultValue: "",
      disabled: true,
      placeholder: "centername",
      name: "center_name",
    },
    {
      title: "firstname",
      type: "text",
      inputType: "input",
      required: true,
      defaultValue: "",
      errorMessage: "freetextErrorMsg",
      placeholder: "firstname",
      validationType: FORM_TYPES.FIRST_NAME,
      name: "firstname",
    },
    {
      title: "lastname",
      type: "text",
      inputType: "input",
      required: true,
      defaultValue: "",
      errorMessage: "freetextErrorMsg",
      placeholder: "lastname",
      validationType: FORM_TYPES.LAST_NAME,
      name: "lastname",
    },
    {
      title: "username",
      type: "text",
      inputType: "input",
      required: true,
      hasInfoPopup: true,
      infoPopup: "usernameInfoMsg",
      defaultValue: "",
      errorMessage: "usernameError",
      placeholder: "username",
      validationType: FORM_TYPES.USER_NAME,
      name: "username",
    },
    {
      title: "jobTitle",
      type: "text",
      inputType: "input",
      required: true,
      defaultValue: "",
      errorMessage: "freetextErrorMsg",
      placeholder: "jobTitle",
      validationType: FORM_TYPES.JOB_TITLE,
      name: "title",
    },
    {
      title: "phone",
      type: "text",
      inputType: "input",
      required: true,
      hasInfoPopup: true,
      infoPopup: "phoneNumberInfoMsg",
      defaultValue: "",
      errorMessage: "phoneErrorMsg",
      placeholder: "phoneNumber",
      validationType: FORM_TYPES.PHONE,
      name: "phone_no",
    },
    {
      title: "password",
      type: "password",
      inputType: "input",
      required: true,
      hasInfoPopup: true,
      infoPopup: "passwordInfo",
      defaultValue: "",
      errorMessage: "freetextErrorMsg",
      placeholder: "password",
      validationType: FORM_TYPES.PASSWORD,
      name: "password",
    },
    {
      title: "passwordConfirmation",
      type: "password",
      inputType: "input",
      required: true,
      defaultValue: "",
      errorMessage: "freetextErrorMsg",
      placeholder: "passwordConfirmation",
      validationType: FORM_TYPES.PASSWORD_CONFIRM,
      name: "password_confirmation",
    },
    {
      title: "userHasFilledOutForm",
      type: "checkbox",
      inputType: "checkbox_round",
      required: true,
      defaultValue: false,
      placeholder: "userHasFilledOutForm",
      name: "userHasFilledOutForm",
    },
  ],
};
const stockholmFormDefinition = {
  formData: [
    {
      title: "email",
      type: "email",
      inputType: "input",
      hasInfoPopup: true,
      infoPopup: "filledOutByAdminInfo",
      defaultValue: "",
      disabled: true,
      placeholder: "email",
      name: "email",
    },
    {
      title: "centername",
      type: "text",
      inputType: "input",
      hasInfoPopup: true,
      infoPopup: "filledOutByAdminInfo",
      defaultValue: "",
      disabled: true,
      placeholder: "centername",
      name: "center_name",
    },
    {
      title: "firstname",
      type: "text",
      inputType: "input",
      required: true,
      defaultValue: "",
      errorMessage: "freetextErrorMsg",
      placeholder: "firstname",
      validationType: FORM_TYPES.FIRST_NAME,
      name: "firstname",
    },
    {
      title: "lastname",
      type: "text",
      inputType: "input",
      required: true,
      defaultValue: "",
      errorMessage: "freetextErrorMsg",
      placeholder: "lastname",
      validationType: FORM_TYPES.LAST_NAME,
      name: "lastname",
    },
    {
      title: "username",
      type: "text",
      inputType: "input",
      required: true,
      hasInfoPopup: true,
      infoPopup: "usernameInfoMsg",
      defaultValue: "",
      errorMessage: "usernameError",
      placeholder: "username",
      validationType: FORM_TYPES.USER_NAME,
      name: "username",
    },
    {
      title: "hsaId",
      type: "text",
      inputType: "input",
      hasInfoPopup: true,
      infoPopup: "hsaIdInfo",
      defaultValue: "",
      errorMessage: "freetextErrorMsg",
      placeholder: "hsaIdPlaceholder",
      name: "hsa_id",
    },
    {
      title: "jobTitle",
      type: "text",
      inputType: "input",
      required: true,
      defaultValue: "",
      errorMessage: "freetextErrorMsg",
      placeholder: "jobTitle",
      validationType: FORM_TYPES.JOB_TITLE,
      name: "title",
    },
    {
      title: "phone",
      type: "text",
      inputType: "input",
      required: true,
      hasInfoPopup: true,
      infoPopup: "phoneNumberInfoMsg",
      defaultValue: "+46",
      errorMessage: "phoneErrorMsg",
      placeholder: "phoneNumber",
      validationType: FORM_TYPES.PHONE,
      name: "phone_no",
    },
    {
      title: "password",
      type: "password",
      inputType: "input",
      required: true,
      hasInfoPopup: true,
      infoPopup: "passwordInfo",
      defaultValue: "",
      errorMessage: "freetextErrorMsg",
      placeholder: "password",
      validationType: FORM_TYPES.PASSWORD,
      name: "password",
    },
    {
      title: "passwordConfirmation",
      type: "password",
      inputType: "input",
      required: true,
      defaultValue: "",
      errorMessage: "freetextErrorMsg",
      placeholder: "passwordConfirmation",
      validationType: FORM_TYPES.PASSWORD_CONFIRM,
      name: "password_confirmation",
    },
    {
      title: "rsLogin",
      inputType: "switch",
      defaultValue: false,
      placeholder: "rsLogin",
      name: "has_rikssar",
    },
    {
      title: "userHasFilledOutForm",
      type: "checkbox",
      inputType: "checkbox_round",
      required: true,
      defaultValue: false,
      placeholder: "userHasFilledOutForm",
      name: "userHasFilledOutForm",
    },
  ],
};
const skaneFormDefinition = {
  formData: [
    {
      title: "email",
      type: "email",
      inputType: "input",
      hasInfoPopup: true,
      infoPopup: "filledOutByAdminInfo",
      defaultValue: "",
      disabled: true,
      placeholder: "email",
      name: "email",
    },
    {
      title: "centername",
      type: "text",
      inputType: "input",
      hasInfoPopup: true,
      infoPopup: "filledOutByAdminInfo",
      defaultValue: "",
      disabled: true,
      placeholder: "centername",
      name: "center_name",
    },
    {
      title: "firstname",
      type: "text",
      inputType: "input",
      required: true,
      defaultValue: "",
      errorMessage: "freetextErrorMsg",
      placeholder: "firstname",
      validationType: FORM_TYPES.FIRST_NAME,
      name: "firstname",
    },
    {
      title: "lastname",
      type: "text",
      inputType: "input",
      required: true,
      defaultValue: "",
      errorMessage: "freetextErrorMsg",
      placeholder: "lastname",
      validationType: FORM_TYPES.LAST_NAME,
      name: "lastname",
    },
    {
      title: "username",
      type: "text",
      inputType: "input",
      required: true,
      hasInfoPopup: true,
      infoPopup: "usernameInfoMsg",
      defaultValue: "",
      errorMessage: "usernameError",
      placeholder: "username",
      validationType: FORM_TYPES.USER_NAME,
      name: "username",
    },
    {
      title: "hsaId",
      type: "text",
      inputType: "input",
      hasInfoPopup: true,
      infoPopup: "hsaIdInfo",
      defaultValue: "",
      errorMessage: "freetextErrorMsg",
      placeholder: "hsaIdPlaceholder",
      name: "hsa_id",
    },
    {
      title: "jobTitle",
      type: "text",
      inputType: "input",
      required: true,
      defaultValue: "",
      errorMessage: "freetextErrorMsg",
      placeholder: "jobTitle",
      validationType: FORM_TYPES.JOB_TITLE,
      name: "title",
    },
    {
      title: "phone",
      type: "text",
      inputType: "input",
      hasInfoPopup: true,
      infoPopup: "phoneNumberInfoMsg",
      defaultValue: "",
      errorMessage: "phoneErrorMsg",
      placeholder: "phoneNumber",
      validationType: FORM_TYPES.PHONE,
      name: "phone_no",
    },
    {
      title: "password",
      type: "password",
      inputType: "input",
      required: true,
      hasInfoPopup: true,
      infoPopup: "passwordInfo",
      defaultValue: "",
      errorMessage: "freetextErrorMsg",
      placeholder: "password",
      validationType: FORM_TYPES.PASSWORD,
      name: "password",
    },
    {
      title: "passwordConfirmation",
      type: "password",
      inputType: "input",
      required: true,
      defaultValue: "",
      errorMessage: "freetextErrorMsg",
      placeholder: "passwordConfirmation",
      validationType: FORM_TYPES.PASSWORD_CONFIRM,
      name: "password_confirmation",
    },
    {
      title: "rsLogin",
      inputType: "switch",
      defaultValue: false,
      placeholder: "rsLogin",
      disabled: true,
      name: "has_rikssar",
    },
    {
      title: "userHasFilledOutForm",
      type: "checkbox",
      inputType: "checkbox_round",
      required: true,
      defaultValue: false,
      placeholder: "userHasFilledOutForm",
      name: "userHasFilledOutForm",
    },
  ],
};

export const getRegistrationFormDefinition = () => {
  const platform = window.dermicusPlatform;
  switch (platform) {
    case "sweden":
      return defaultFormDefinition;
    case "uk":
      return ukFormDefinition;
    case "stockholm":
      return stockholmFormDefinition;
    case "skane":
      return skaneFormDefinition;
    default:
      return defaultFormDefinition;
  }
};
