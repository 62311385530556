import { practitionerHttpService } from "../../app/practitioner-portal/store/practitioner-http-service";
import {
  CentersSearchParams,
  CentersSearchResult,
} from "../../../../../app/Http/Controllers/Api/Internal/CentersController";

export function centersSearch(params: CentersSearchParams) {
  return {
    queryKey: ["centers", "search", params],
    queryFn: () =>
      practitionerHttpService
        .request<CentersSearchResult>({
          method: "POST",
          url: "/api/internal/centers/search",
          params,
        })
        .then((response) => response.data),
  };
}
