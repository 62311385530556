<template>
  <Users :data-center-id="centerId" :view-as-region-admin="true" />
</template>
<script setup lang="ts">
import { ROUTES, useRouteOf } from "../routing/routes";
import { ref, watch } from "vue";
import Users from "../../components/Center/Users/Users.vue";

const route = useRouteOf<typeof ROUTES.regionCenterSettings>();
/**
 * @todo use `useRouteParams` when we are on Vue 3 + Router 4
 * @see {@link https://vueuse.org/router/useRouteParams/}
 */
const centerId = ref<number>();
watch(
  () => route.params.id,
  (value) => (centerId.value = parseInt(value, 10)),
  { immediate: true },
);
</script>
