import { useMutation, useQuery } from "@tanstack/vue-query";
import { computed } from "vue";
import { queryClient } from ".";
import { lang } from "../../i18n";
import { userErrorMessage } from "../../mixins/UserErrorMessage.vue";
import {
  centerConnectConsentStudiesGet,
  centerConsentStudyAttach,
  centerConsentStudyDetach,
} from "../core/center-settings";
import { notify } from "@kyvg/vue3-notification";

export function useCenterConnectConsentStudiesResource(centerId: number) {
  const centerConnectConsentStudiesResource = useQuery(
    computed(() => centerConnectConsentStudiesGet(centerId)),
  );

  return centerConnectConsentStudiesResource;
}

export function useCenterConsentStudyAttach() {
  const mutation = useMutation({
    mutationFn: ({
      centerId,
      studyId,
    }: {
      centerId: number;
      studyId: number;
    }) => centerConsentStudyAttach(centerId, studyId),
    onSuccess() {
      notify({
        text: lang.get("general.notificationSaved"),
        type: "success",
        duration: 1000,
      });
    },
    onError: userErrorMessage,
    onSettled(data, error, { centerId }) {
      void queryClient.invalidateQueries([
        "center-connect-consent-studies",
        centerId,
      ]);
    },
  });

  return mutation;
}

export function useCenterConsentStudyDetach() {
  const mutation = useMutation({
    mutationFn: ({
      centerId,
      studyId,
    }: {
      centerId: number;
      studyId: number;
    }) => centerConsentStudyDetach(centerId, studyId),
    onSuccess() {
      notify({
        text: lang.get("general.notificationSaved"),
        type: "success",
        duration: 1000,
      });
    },
    onError: userErrorMessage,
    onSettled(data, error, { centerId }) {
      void queryClient.invalidateQueries([
        "center-connect-consent-studies",
        centerId,
      ]);
    },
  });

  return mutation;
}
