<template>
  <div
    :class="`alert alert-${dataVariant}`"
    :role="onCloseAlert ? 'alertdialog' : 'alert'"
    :aria-describedby="ariaDescriptionId"
  >
    <div
      v-if="!!dataHeading || !!onCloseAlert"
      :style="{
        display: 'grid',
        gridTemplateColumns: '1fr',
        gridAutoFlow: 'column',
        gridAutoColumns: 'auto',
        gap: '1rem',
      }"
    >
      <h4 class="alert-heading">
        {{ dataHeading }}
      </h4>
      <CloseButton v-if="onCloseAlert" @click="onCloseAlert" />
    </div>
    <div :id="ariaDescriptionId">
      <slot />
    </div>
  </div>
</template>
<script setup lang="ts">
import { DataVariant } from "./data-variant";
import CloseButton from "./CloseButton.vue";
import { v4 as uuidV4 } from "uuid";

/**
 * @see https://github.com/vuejs/rfcs/discussions/397
 */

withDefaults(
  defineProps<{
    dataVariant?: DataVariant;
    dataHeading?: string;
    onCloseAlert?: () => void;
  }>(),
  { dataVariant: "primary" },
);

const ariaDescriptionId = uuidV4();
</script>
