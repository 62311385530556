import { useMutation, useQuery } from "@tanstack/vue-query";
import { computed } from "vue";
import {
  examinationStudyConsentsGet,
  studiesEligibleGet,
  studyRequestConsent,
} from "../core/examinations";
import { studiesSearch } from "../core/studies";

export function useStudiesSearch() {
  const studiesSearchResource = useQuery(computed(() => studiesSearch()));

  return studiesSearchResource;
}

export function useStudyConsents(examinationId: number) {
  const studyConsentsResource = useQuery(
    computed(() => examinationStudyConsentsGet(examinationId)),
  );

  return studyConsentsResource;
}

export function useEligibleStudies(examinationId: number) {
  const eligibleStudiesResource = useQuery(
    computed(() => studiesEligibleGet(examinationId)),
  );

  return eligibleStudiesResource;
}

export function useStudyConsentRequest() {
  const mutation = useMutation(computed(() => studyRequestConsent()));

  return mutation;
}
