<template>
  <div id="admin-menu">
    <ul class="nav nav-tabs nav tabs-bordered">
      <li :class="{ active: activeSection == 1 }">
        <router-link to="/admin/centers">
          <span>{{ trans("admin.centers") }}</span>
        </router-link>
      </li>
      <li :class="{ active: activeSection == 9 }">
        <router-link to="/admin/regions">
          <span>{{ trans("admin.regions") }}</span>
        </router-link>
      </li>
      <li :class="{ active: activeSection == 2 }">
        <router-link to="/admin/users">
          <span>{{ trans("admin.users") }}</span>
        </router-link>
      </li>
      <li :class="{ active: activeSection == 3 }">
        <router-link to="/admin/analytics">
          <span>{{ trans("admin.analytics") }}</span>
        </router-link>
      </li>
      <li :class="{ active: activeSection == 8 }">
        <router-link to="/admin/patients">
          <span>{{ trans("admin.patients") }}</span>
        </router-link>
      </li>
      <li :class="{ active: activeSection == 5 }">
        <router-link to="/admin/educations">
          <span>{{ trans("admin.educations") }}</span>
        </router-link>
      </li>
      <li :class="{ active: activeSection == 6 }">
        <router-link to="/admin/logs">
          <span>{{ trans("admin.logs") }}</span>
        </router-link>
      </li>
      <li :class="{ active: activeSection == 7 }">
        <router-link to="/admin/exports">
          <span>{{ trans("admin.exports.title") }}</span>
        </router-link>
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  props: ["activeSection"],
};
</script>
