<template>
  <div
    class="tw-h-14 xl:tw-h-full xl:tw-grid xl:tw-grid-rows-[auto_1fr] xl:tw-w-sidebar"
  >
    <header
      class="tw-flex items-center tw-px-4 tw-bg-primary-100 tw-fixed tw-w-full tw-z-[2] tw-h-[--mobile-header-height] tw-border-solid tw-border-0 tw-border-b-[1px] tw-border-primary-300 xl:tw-relative xl:tw-t-0 xl:tw-h-auto xl:tw-border-0 xl:tw-p-8"
    >
      <div class="tw-flex tw-items-center tw-gap-2">
        <button
          type="button"
          class="tw-block tw-h-8 tw-w-8 tw-p-0 tw-bg-transparent tw-border-0 tw-text-primary xl:tw-hidden"
          @click="handleMobileNavToggle"
        >
          <MenuIcon />
        </button>
        <a href="/" class="tw-block tw-max-w-[8rem] xl:tw-max-w-[12rem]">
          <img
            src="/images/dermicus-logo-m.svg"
            alt="logo"
            class="tw-w-full xl:tw-hidden"
          />
          <img
            src="/images/dermicus-logo.svg"
            alt="logo"
            class="tw-w-full tw-hidden xl:tw-block"
          />
        </a>
      </div>
    </header>
    <nav
      class="tw-fixed tw-top-14 tw-w-sidebar tw-bg-primary-100 -tw-translate-x-full tw-h-[calc(100%-56px)] tw-grid tw-grid-rows-[1fr_auto] tw-z-[3] tw-transition-all tw-duration-300 tw-shadow-xl xl:tw-relative xl:tw-top-0 xl:tw-h-full xl:tw-overflow-hidden xl:tw-shadow-none xl:tw-translate-x-0"
      :class="{
        'tw-translate-x-0': sidebarIsOpen,
      }"
    >
      <ul class="tw-list-none tw-m-0 tw-px-0 tw-py-5 tw-overflow-y-auto">
        <li
          v-for="(navItem, index) in availableMainNavItems"
          :key="index"
          class="tw-px-8 tw-py-2"
        >
          <router-link
            :to="navItem.href"
            class="tw-block tw-text-primary-dark tw-text-lg tw-py-2 tw-px-4 tw-rounded hover:tw-bg-primary-300"
            active-class="tw-bg-primary-300 tw-font-normal"
            @click.native="navItem.onClick ?? null"
          >
            <span>{{ navItem.title }}</span>
          </router-link>
        </li>
      </ul>

      <div
        ref="userDetailsRef"
        class="tw-bg-primary tw-px-6 tw-py-8 tw-cursor-pointer tw-relative"
        @click="handleUserDetailsToggle"
      >
        <ul
          v-if="userDetailsIsOpen"
          class="tw-list-none tw-m-0 tw-p-0 tw-bg-white tw-absolute tw-top-0 tw-left-0 -tw-translate-y-full tw-w-full tw-border-solid tw-border-0 tw-border-r-[1px] tw-border-primary-100 after:tw-content-[''] after:tw-absolute after:tw-b-0 after:tw-left-1/2 after:tw-w-0 after:tw-h-0 after:tw-border-[20px] after:tw-border-t-white after:tw-border-transparent after:tw-border-solid after:-tw-ml-[20px] after:-tw-mb-[20px]"
        >
          <li
            v-for="(navItem, index) in availableSubNavItems"
            :key="index"
            class=""
          >
            <router-link
              v-if="navItem.href"
              :to="navItem.href"
              class="tw-color-primary-dark tw-block tw-px-4 tw-py-2 hover:tw-bg-primary-300"
              active-class=""
            >
              {{ navItem.title }}
            </router-link>
            <a
              v-if="navItem.onClick"
              href="#"
              class="tw-color-primary-dark tw-block tw-px-4 tw-py-2 hover:tw-bg-primary-300"
              @click="navItem.onClick"
              >{{ trans("menu.logout") }}</a
            >
          </li>
        </ul>

        <div class="tw-flex tw-gap-4 tw-items-center">
          <div
            class="tw-rounded-full tw-bg-neutral-100 tw-inline-flex tw-flex-shrink-0 tw-h-12 tw-w-12 tw-justify-center tw-items-center tw-overflow-hidden"
          >
            <img
              v-if="user.avatar"
              :src="user.avatar"
              alt="user-img"
              class="tw-block tw-w-full"
            />
            <span v-else>{{ user.initials }}</span>
          </div>

          <div class="tw-text-white">
            <span>{{ user.fullName }}</span>
            <p class="tw-m-0">
              {{ primaryCenterName }}
            </p>
          </div>
        </div>
      </div>
    </nav>
  </div>
</template>

<script>
import { lang } from "../../i18n";
import MenuIcon from "../Layout/Icons/MenuIcon.vue";

export default {
  components: {
    MenuIcon,
  },
  props: {
    user: {
      type: Object,
      required: true,
    },
    isAuthenticated: {
      type: Boolean,
      required: true,
    },
    primaryCenterName: {
      type: String,
      required: true,
    },
    sidebarIsOpen: {
      type: Boolean,
      required: true,
    },
  },
  emits: ["logout-user", "start-page", "mobile-nav-toggle"],
  data() {
    return {
      userDetailsIsOpen: false,
      mainNavItems: [
        {
          href: "/consultations",
          onClick: () => this.$emit("start-page"),
          title: lang.get("menu.examinations"),
        },
        {
          href: "/patients",
          title: lang.get("menu.patients"),
        },
        {
          href: "/statistics",
          title: lang.get("menu.statistics"),
        },
        {
          href: "/research",
          title: lang.get("menu.research"),
          condition: this.$can("participate_in_studies"),
        },
        {
          href: "/educations",
          title: lang.get("menu.education"),
        },
        {
          href: "/support",
          title: lang.get("menu.support"),
        },
      ],
      subNavItems: [
        {
          href: "/region",
          title: lang.get("menu.region"),
          condition:
            this.$can("manage_region") && this.user.primaryCenter.region_id,
        },
        {
          href: "/center",
          title: lang.get("menu.center"),
          condition: this.$can("manage_center"),
        },
        {
          href: "/research-admin",
          title: lang.get("menu.research"),
          condition: this.$can("manage_study"),
        },
        {
          href: "/auth/settings",
          title: lang.get("menu.settings"),
        },
        {
          href: "/admin",
          title: lang.get("menu.admin"),
          condition: this.$can("manage_dermicus"),
        },
        {
          title: lang.get("menu.logout"),
          onClick: () => this.$emit("logout-user"),
        },
      ],
    };
  },
  computed: {
    availableMainNavItems() {
      return this.mainNavItems.filter((item) => item.condition !== false);
    },
    availableSubNavItems() {
      return this.subNavItems.filter((item) => item.condition !== false);
    },
  },
  mounted() {
    document.addEventListener("click", this.handleClickOutside);
  },
  beforeUnmount() {
    document.removeEventListener("click", this.handleClickOutside);
  },
  methods: {
    handleMobileNavToggle() {
      this.$emit("mobile-nav-toggle");
    },
    handleUserDetailsToggle() {
      this.userDetailsIsOpen = !this.userDetailsIsOpen;
    },
    handleClickOutside(e) {
      if (
        this.$refs.userDetailsRef &&
        !this.$refs.userDetailsRef.contains(e.target)
      ) {
        this.userDetailsIsOpen = false;
      }
    },
  },
};
</script>
