import { NavigationGuard } from "vue-router";
import { locationOf, ROUTES } from "../routing/routes";
import _some from "lodash/fp/some";
import { userStudiesAsParticipantGet } from "../../store/core/users";
import { store } from "../../store";
import _get from "lodash/fp/get";
import _isInteger from "lodash/fp/isInteger";
import _toInteger from "lodash/fp/toInteger";
import _isString from "lodash/fp/isString";

/**
 * This hook will check the available studies, and redirect if needed, because:
 *
 * - Query param `studyId` was added for multiple study support.
 * - Access to a study could have been revoked, or study deleted.
 * - Navigated to the main/root of this module, no study selected.
 */
export const redirectByAvailableStudies: NavigationGuard = (to, from, next) => {
  const principalId = _get("state.user.id", store);

  if (!_isInteger(principalId)) {
    // Not logged in, should not happen at this point...
    // TODO: Use store to ensure there is a principal as dependency
    //   of resource, which will handle authorization for us then....
    next(false);
  } else {
    userStudiesAsParticipantGet(principalId)
      .then((studies) => {
        if (studies.length === 0) {
          // nothing available, user should not have ended up here...
          next(false);
        } else if (!_some({ id: _toInteger(to.query.studyId) }, studies)) {
          if (!_isString(to.name) || !(to.name in ROUTES)) {
            next(false); // must not happen.
            // TODO: update based on new router version, so we don't need to do
            //  any checks and can rely on general router workings....
          } else {
            // Should we redirect to first study or to "no access"?
            next(
              locationOf(ROUTES[to.name], {
                query: { studyId: studies[0].id.toString() },
              }),
            );
          }
        } else {
          next();
        }
      })
      .catch(next);
  }
};
