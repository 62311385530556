<template>
  <div id="api_in" class="tab-pane">
    <div id="custom-search-input" class="mb-3">
      <form class="input-group col-md-12" @submit.prevent="search">
        <input
          id="apiInSearchInput"
          type="text"
          class="form-control input-lg"
          :placeholder="trans('forms.searchForCaseNumber')"
          :title="trans('general.search')"
        />
        <input
          id="apiInSearchInputRequest"
          type="text"
          class="form-control input-lg"
          :placeholder="trans('forms.searchForDermicusAPI')"
          :title="trans('general.search')"
        />
        <span class="input-group-btn">
          <button
            v-if="isSearching"
            class="btn"
            type="button"
            @click.stop="clearSearch"
          >
            <i class="fal fa-times" />
          </button>
          <button class="btn" type="button" @click.stop="search">
            <i class="far fa-search" />
          </button>
        </span>
      </form>
    </div>

    <clip-loader v-if="loading" />

    <div id="faq" class="accordion my-2">
      <div class="card">
        <div id="faqhead1" class="card-header p-1">
          <a
            href="#"
            class="btn btn-header-link"
            data-toggle="collapse"
            data-target="#faq1"
            aria-expanded="false"
            aria-controls="faq1"
            @click="showResponseCodes = !showResponseCodes"
          >
            <i
              :class="[
                showResponseCodes ? 'fa-angle-up' : 'fa-angle-down',
                'fas',
              ]"
            />
            &nbsp; Read response code meanings</a
          >
        </div>

        <div
          id="faq1"
          class="collapse"
          aria-labelledby="faqhead1"
          data-parent="#faq"
        >
          <div class="card-body">
            <table class="table">
              <tr>
                <th>0</th>
                <td>
                  process started (no response sent yet, look at the next
                  request)
                </td>
              </tr>
              <tr>
                <th>200</th>
                <td>success</td>
              </tr>
              <tr>
                <th>409</th>
                <td>entity already exists</td>
              </tr>
              <tr>
                <th>422</th>
                <td>unprocessable (possibly empty request from the app)</td>
              </tr>
              <tr>
                <th>500</th>
                <td>server error</td>
              </tr>
            </table>
          </div>
        </div>
      </div>
    </div>

    <div class="table-responsive">
      <table class="table table-hover">
        <thead class="sortable-table-header">
          <tr>
            <th>API</th>
            <th>Request</th>
            <th>Response</th>
            <th>Response code</th>
            <th>Created at</th>
            <th>User/client</th>
            <th>Center</th>
            <th>Consultation</th>
            <th />
          </tr>
        </thead>
        <tbody>
          <tr v-for="(log, index) in api_in.logs" :key="index">
            <td>{{ log.dermicus_api }}</td>
            <td>{{ log.request }}</td>
            <td>{{ log.response }}</td>
            <td>{{ log.response_code }}</td>
            <td>{{ log.created_at }}</td>
            <td>{{ log.user }}</td>
            <td>{{ log.center }}</td>
            <td>{{ log.examination }}</td>
            <td>
              <a
                data-toggle="modal"
                data-target="#logDetail"
                class="btn"
                @click="getApiLogDetail(log.id)"
                >Details</a
              >
            </td>
          </tr>
        </tbody>
      </table>
    </div>

    <div class="row-fluid pull-right">
      <DerPaginate
        v-model="api_in.paginate.current_page"
        :page-count="api_in.paginate.last_page"
        @change="getApiInResults"
      />
    </div>

    <div
      id="logDetail"
      class="modal fade"
      tabindex="-1"
      role="dialog"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-dialog-centered modal-xl" role="document">
        <div class="modal-content">
          <div class="modal-body px-5">
            <strong>API</strong>
            <p class="text-muted">
              {{ api_in.logDetail.dermicus_api }}
            </p>
            <strong>Request</strong>
            <button
              class="btn btn-link"
              @click="copyObject(api_in.logDetail.parsedRequest)"
            >
              Copy
            </button>
            <p class="text-muted py-1">
              <vue-json-pretty
                :show-length="true"
                :show-double-quotes="false"
                :data="api_in.logDetail.parsedRequest"
              />
            </p>
            <strong>Response code</strong>
            <p class="text-muted">
              {{ api_in.logDetail.response_code }}
            </p>
            <strong>Response</strong>
            <button
              class="btn btn-link"
              @click="copyObject(api_in.logDetail.parsedResponse)"
            >
              Copy
            </button>
            <p class="text-muted py-1">
              <vue-json-pretty
                :show-length="true"
                :show-double-quotes="false"
                :data="api_in.logDetail.parsedResponse"
              />
            </p>
            <strong>User</strong>
            <p class="text-muted">
              {{ api_in.logDetail.user }}
            </p>
            <strong>Consultation</strong>
            <p class="text-muted">
              {{ api_in.logDetail.examination }}
            </p>
            <strong>Created at</strong>
            <p class="text-muted">
              {{ api_in.logDetail.created_at }}
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import VueJsonPretty from "vue-json-pretty";
import "vue-json-pretty/lib/styles.css";
import DerPaginate from "../../../design-system/DerPaginate.vue";

export default {
  filters: {
    pretty: function (value) {
      return JSON.stringify(JSON.parse(value), null, 2);
    },
  },
  components: {
    VueJsonPretty,
    DerPaginate,
  },
  data() {
    return {
      loading: true,
      api_in: {
        paginate: {
          current_page: 0,
          total: 0,
          last_page: 0,
        },
        logs: [],
        selectedLogRecord: 0,
        logDetail: [],
      },
      isSearching: false,
      showResponseCodes: false,
    };
  },
  created: function () {
    this.getApiInResults();
  },
  methods: {
    copyObject: function (object) {
      navigator.clipboard.writeText(JSON.stringify(object, null, 2));
    },
    getApiInResults(page) {
      this.loading = true;

      if (typeof page === "undefined") {
        page = 1;
      }

      axios
        .get("/api/internal/admin/logs/api-in?page=" + page)
        .then((data) => {
          var collection = data.data;
          this.api_in.paginate = collection.paginate;
          this.api_in.logs = collection.data;
          this.loading = false;
        })
        .catch((error) => {
          this.loading = false;
          this.userErrorMessage(error);
        });
    },
    search: function () {
      this.loading = true;
      this.isSearching = true;
      let caseNumber = $("#apiInSearchInput").val();
      let dermicusApi = $("#apiInSearchInputRequest").val();
      axios
        .get(
          "/api/internal/admin/logs/api-in?case_number=" +
            caseNumber +
            "&request=" +
            dermicusApi,
        )
        .then((data) => {
          var collection = data.data;
          this.api_in.logs = collection.data;
          this.api_in.paginate = collection.paginate;
          this.loading = false;
        })
        .catch((error) => {
          this.loading = false;
          this.userErrorMessage(error);
        });
    },
    clearSearch: function () {
      $("#apiInSearchInput").val("");
      $("#apiInSearchInputRequest").val("");
      this.isSearching = false;
      this.getApiInResults();
    },
    getApiLogDetail(id) {
      this.loading = true;

      axios
        .get("/api/internal/admin/log-detail/" + id)
        .then((data) => {
          let collection = data.data;
          this.api_in.logDetail = collection.data;
          try {
            this.api_in.logDetail.parsedResponse = JSON.parse(
              this.api_in.logDetail.response,
            );
          } catch (e) {
            this.api_in.logDetail.parsedResponse =
              this.api_in.logDetail.response;
          }

          try {
            this.api_in.logDetail.parsedRequest = JSON.parse(
              this.api_in.logDetail.request,
            );
          } catch (e) {
            this.api_in.logDetail.parsedRequest = this.api_in.logDetail.request;
          }

          this.loading = false;
        })
        .catch((error) => {
          this.loading = false;
          this.userErrorMessage(error);
        });
    },
    selectLogRecord(id) {
      this.api_in.selectedLogRecord = id;
    },
  },
};
</script>

<style lang="scss" scoped>
#faq .card .card-header .btn-header-link {
  color: #676a6c;
  display: block;
  text-align: left;
}

#faq .card {
  border: none;
}
</style>
