<template>
  <div>
    <header-admin :active-section="9" />

    <div class="text-right my-2">
      <router-link to="/admin/regions/new" class="btn btn-primary">
        {{ trans("region.newRegion") }}
      </router-link>
    </div>

    <div class="table-responsive">
      <clip-loader v-show="loading" />

      <table class="table table-hover interactive-table">
        <thead class="sortable-table-header">
          <tr>
            <th>{{ trans("region.regionName") }}</th>
            <th>{{ trans("region.connectedCenters") }}</th>
            <th>{{ trans("region.regionAdmin") }}</th>
            <th>{{ trans("examination.createdAtLabel") }}</th>
          </tr>
        </thead>
        <tbody>
          <tr
            v-for="(region, index) in regions"
            :key="index"
            @click="editRegion(region.id)"
          >
            <td>{{ region.name }}</td>
            <td>{{ region.centers.length }}</td>
            <td>{{ region.region_admins.length }}</td>
            <td>{{ region.created_at_formatted }}</td>
          </tr>
        </tbody>
      </table>
    </div>

    <div class="row-fluid float-right">
      <DerPaginate
        v-model="paginate.current_page"
        :page-count="paginate.last_page"
        @change="loadData"
      />
    </div>
  </div>
</template>

<script>
import DerPaginate from "../../../design-system/DerPaginate.vue";
import HeaderAdmin from "../Header.vue";

export default {
  components: {
    HeaderAdmin,
    DerPaginate,
  },
  data() {
    return {
      loading: false,
      paginate: {
        current_page: 0,
        total: 0,
        last_page: 0,
      },
      page: 1,
      regions: [],
    };
  },
  mounted: function () {
    this.loadData();
  },
  methods: {
    loadData: function (page) {
      this.loading = true;

      if (typeof page === "undefined") {
        page = 1;
      }

      axios
        .get("/api/internal/admin/regions?page=" + page)
        .then((data) => {
          let collection = data.data;
          this.paginate = collection.paginate;
          this.regions = collection.data;
          this.loading = false;
        })
        .catch((error) => {
          this.loading = false;
          this.userErrorMessage(error);
        });
    },
    editRegion: function (regionId) {
      this.$router.push({ name: "AdminRegionsEdit", params: { id: regionId } });
    },
  },
};
</script>
