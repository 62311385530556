<template>
  <UseStoreResource :data-resource="centerConnectConsentStudiesResource">
    <AdminCenterConnectedStudiesList
      v-if="centerConnectConsentStudiesResource.data.value"
      :studies="centerConnectConsentStudiesResource.data.value"
      :current-loading-connection="currentLoadingConnection"
      @connect-study="handleConnect"
    />
  </UseStoreResource>
</template>

<script setup lang="ts">
import { ref } from "vue";
import UseStoreResource from "../../../../design-system/UseStoreResource.vue";
import {
  useCenterConnectConsentStudiesResource,
  useCenterConsentStudyAttach,
  useCenterConsentStudyDetach,
} from "../../../../store/api/use-center-consent-studies-resource";
import AdminCenterConnectedStudiesList from "./AdminCenterConnectedStudiesList.vue";

const props = defineProps<{
  centerId: number;
}>();

const attachCenterConsentStudy = useCenterConsentStudyAttach();
const detachCenterConsentStudy = useCenterConsentStudyDetach();

const currentLoadingConnection = ref<number | undefined>();

function handleConnect(studyId: number, isConnected: boolean) {
  currentLoadingConnection.value = studyId;

  if (isConnected) {
    detachCenterConsentStudy.mutate(
      {
        centerId: props.centerId,
        studyId,
      },
      {
        onSettled: () => {
          currentLoadingConnection.value = undefined;
        },
      },
    );
  } else {
    attachCenterConsentStudy.mutate(
      {
        centerId: props.centerId,
        studyId,
      },
      {
        onSettled: () => {
          currentLoadingConnection.value = undefined;
        },
      },
    );
  }
}

const centerConnectConsentStudiesResource =
  useCenterConnectConsentStudiesResource(props.centerId);
</script>
