<template>
  <login-form-container v-if="errorMessage">
    <div class="text-center mt-4 mb-5">
      <div class="my-5 text-danger">
        <h6 class="mb-3">
          {{ trans("errorMessages.loginErrorHeader") }}
        </h6>
        <p>
          {{ errorMessage }}
        </p>
      </div>
      <router-link to="/" class="btn btn-primary">
        {{ trans("errorMessages.loginErrorButtonText") }}
      </router-link>
    </div>
  </login-form-container>
</template>

<script>
import LoginFormContainer from "./LoginFormContainer.vue";
import { navigateToHomepage } from "./helpers/authHelpers";
import { practitionerAuthToken } from "../../app/practitioner-portal/auth/practitioner-auth-token";

export default {
  components: {
    LoginFormContainer,
  },
  data: function () {
    return {
      errorMessage: null,
    };
  },
  mounted: function () {
    this.handleSithsLogin();
  },
  methods: {
    handleSithsLogin: function () {
      if (this.$route.query.grandidsession) {
        // siths login
        axios
          .get(
            "/api/internal/auth/siths/verify?grandidsession=" +
              this.$route.query.grandidsession,
          )
          .then((response) => {
            practitionerAuthToken.set(response.data.accessToken);
            navigateToHomepage(response);
          })
          .catch((error) => {
            this.errorMessage = error.response.data.error
              ? this.trans(error.response.data.error)
                ? this.trans(error.response.data.error)
                : error.response.data.error
              : this.trans("errorMessages.HTTPStatus.XXX");
          });
      } else if (this.$route.query.oauthCode) {
        axios
          .get(
            "/api/internal/auth/siths/verify-with-oauth-code?oauthCode=" +
              this.$route.query.oauthCode,
          )
          .then((response) => {
            practitionerAuthToken.set(response.data.accessToken);
            navigateToHomepage(response);
          })
          .catch((error) => {
            this.errorMessage = error.response.data.error
              ? this.trans(error.response.data.error)
                ? this.trans(error.response.data.error)
                : error.response.data.error
              : this.trans("errorMessages.HTTPStatus.XXX");
          });
      } else if (this.$route.query.SAMLResponse) {
        // local login
        axios
          .get(
            "/api/internal/auth/siths/verify?SAMLResponse=" +
              this.$route.query.SAMLResponse,
          )
          .then((response) => {
            practitionerAuthToken.set(response.data.accessToken);
            navigateToHomepage(response);
          })
          .catch((error) => {
            this.errorMessage = error.response.data.error
              ? this.trans(error.response.data.error)
                ? this.trans(error.response.data.error)
                : error.response.data.error
              : this.trans("errorMessages.HTTPStatus.XXX");
          });
      } else {
        this.$router.push({ name: "Auth" });
      }
    },
  },
};
</script>
