import { AxiosInstance } from "axios";
import { PatientPrincipalResource } from "../../../../../../app/Http/Resources/PatientPrincipalResource";
import { patientHttpService } from "./patient-http-service";

export function patientPrincipalGetProvider(config: {
  httpService: AxiosInstance;
}) {
  return function patientPrincipalGet(): Promise<PatientPrincipalResource> {
    return config.httpService
      .request<PatientPrincipalResource>({
        method: "GET",
        url: "/v1/auth/principal",
      })
      .then((response) => response.data);
  };
}

export const patientPrincipalGet = patientPrincipalGetProvider({
  httpService: patientHttpService,
});
