<template>
  <error-page
    :header-text="trans('errorMessages.404ErrorPageHeader')"
    :body-text="trans('errorMessages.404ErrorPageContent')"
    :button-text="trans('errorMessages.404ErrorPageButtonText')"
  />
</template>

<script>
import ErrorPage from "./ErrorPage.vue";

export default {
  components: {
    ErrorPage,
  },
};
</script>
