<template>
  <div>
    <strong>{{ title }}</strong>
    <br />
  </div>
</template>

<script>
export default {
  props: {
    title: String,
  },
};
</script>
