<template>
  <div class="statistics-info">
    <div class="text-center m-5">
      <h5 class="mb-3">
        {{ trans("errorMessages.noRegionHeader") }}
      </h5>
      <p>{{ trans("errorMessages.noRegionMessage") }}</p>
    </div>
  </div>
</template>
<script setup lang="ts"></script>
