<template>
  <div class="channel-row-icon">
    <span v-if="channelType(channel) === 'private'">
      {{ userInitials(channel.name) }}</span
    >
    <span v-else class="text-center">
      <i :class="channelIcon(channelType(channel))" />
    </span>
  </div>
</template>

<script>
export default {
  props: {
    channel: Object,
  },
  methods: {
    userInitials: function (userName) {
      let fullName = userName.split(" ");
      let initials = fullName[0][0];

      if (userName) {
        if (fullName.length >= 2 && fullName[1]) {
          return (initials += fullName[1][0]);
        }
      } else {
        return (initials = "");
      }
    },
    channelType: function (chat) {
      let type;

      if (
        chat.type === "manual" &&
        chat.participants &&
        chat.participants.length <= 1
      ) {
        type = "private";
      } else if (
        (chat.type === "manual" &&
          chat.participants &&
          chat.participants.length > 1) ||
        chat.type === "predefined"
      ) {
        type = "group";
      } else if (chat.type === "public") {
        type = "general";
      } else if (!chat.type) {
        // fix when channel type for predefined inactive groups exists
        type = "predefined-inactive";
      }

      return type;
    },
    channelIcon: function (channelType) {
      let icon;

      switch (channelType) {
        case "general":
          icon = "far fa-globe-europe";
          break;
        case "group":
          icon = "fas fa-users";
          break;
        case "private":
          break;
        case "predefined-inactive":
          // fix when channel type for predefined inactive groups exists
          icon = "fas fa-plus";
          break;
        default:
          icon = "";
      }

      return icon;
    },
  },
};
</script>

<style lang="scss" scoped>
@use "/resources/assets/sass/abstracts/variables";

.fas.fa-plus {
  color: variables.$dermicusBlue;
}
</style>
