<template>
  <div>
    <div class="d-flex justify-content-between align-items-top">
      <!-- avatar -->
      <div class="channel-row-icon my-2">
        <img v-if="message.avatar" :src="message.avatar" alt="user-img" />
        <span v-else class="text-center">
          {{ message.initials }}
        </span>
      </div>

      <!-- message info -->
      <div class="channel-row-text mr-auto" style="width: 100%">
        <div class="ml-3 my-2">
          <span class="font-weight-normal">
            {{ message.userName }}
          </span>
          <span class="chat-subtext">
            {{ message.created }}
          </span>
        </div>
        <div v-if="!editingMessage" class="ml-3">
          <div class="message-text">
            {{ message.comment }}
          </div>
          <small v-if="message.deleted" class="deleted-time">{{
            message.deleted
          }}</small>
        </div>

        <div v-if="editingMessage" class="ml-3">
          <div class="growing-message-container">
            <ResizeTextarea
              ref="myTextarea"
              v-model="editedMessage"
              :max-height="250"
            />
          </div>

          <div class="mt-2 edit-channel">
            <button class="btn btn-grey action-btn" @click="saveEditedMessage">
              <i class="far fa-check" />
            </button>
            <button
              class="btn btn-grey cancel-btn"
              @click="cancelEditingMessage"
            >
              <i class="fal fa-times" />
            </button>
          </div>
        </div>
      </div>

      <!-- message settings -->
      <div v-if="!message.deleted" class="hidden-dropdown">
        <div class="dropdown">
          <button
            type="button"
            class="btn icon-dropdown"
            data-toggle="dropdown"
            aria-haspopup="true"
            aria-expanded="false"
          >
            <i class="fas fa-ellipsis-v" />
          </button>

          <div class="dropdown-menu">
            <button
              v-if="message.isOwner"
              class="dropdown-item"
              @click="toggleEditingMessage(true, message.comment)"
            >
              {{ trans("chat.editMessageButton") }}
            </button>
            <button class="dropdown-item" @click="copyMessage(message.comment)">
              {{ trans("chat.copyMessageButton") }}
            </button>
            <button
              v-if="message.isOwner"
              class="dropdown-item"
              @click="deleteMessage(message.id)"
            >
              {{ trans("chat.deleteMessageButton") }}
            </button>
          </div>
        </div>
      </div>
    </div>
    <div v-if="message.readByUsers" class="d-flex justify-content-end">
      <span class="chat-subtext">
        <span
          v-for="(initials, i) in message.readByUsers"
          :key="i"
          class="chat-message-initials"
          >{{ initials }}</span
        >
      </span>
    </div>
  </div>
</template>

<script>
import { ref, computed } from "vue";
import { useAlertUnsavedChanges } from "../../../../../lib-vue/use-alert-unsaved-changes";
import ResizeTextarea from "../../../../Layout/ResizeTextarea.vue";

export default {
  components: { ResizeTextarea },
  props: {
    message: Object,
  },
  setup() {
    let editedMessage = ref("");
    let isDirty = computed(() => !!editedMessage.value);
    useAlertUnsavedChanges(isDirty);

    return { editedMessage };
  },
  data() {
    return {
      editingMessage: false,
    };
  },
  methods: {
    toggleEditingMessage: function (bool, messageText) {
      this.$emit("toggleEditMessage", true);
      this.editedMessage = messageText;
      this.editingMessage = bool;
    },
    cancelEditingMessage: function () {
      this.editedMessage = null;
      this.editingMessage = false;
    },
    saveEditedMessage: function () {
      this.$emit("saveEditedMessage", this.message, this.editedMessage);
      this.toggleEditingMessage(false);
    },
    copyMessage: function (messageText) {
      this.$emit("copyMessage", messageText);
    },
    deleteMessage: function (messageId) {
      this.$emit("deleteMessage", messageId);
    },
  },
};
</script>
