<template>
  <text-with-label :title="title" :data="value" />
</template>

<script>
import { Constants } from "../../../../../helpers";
import TextWithLabel from "./TextWithLabel.vue";
import { sanitizeAndConvertFieldValueStringToFloat } from "../../../../../helpers/misc";
export default {
  components: { TextWithLabel },
  props: {
    title: String,
    extraOptions: {
      valueOptions: Array,
      examination: Object,
    },
  },
  data() {
    return {
      value: undefined,
    };
  },
  mounted: function () {
    this.divideValues();
  },
  methods: {
    divideValues: function () {
      const { extraOptions } = this;
      if (
        extraOptions.valueOptions &&
        Array.isArray(extraOptions.valueOptions)
      ) {
        const { valueOptions, examination } = this.extraOptions;
        if (
          examination &&
          valueOptions.length === 1 &&
          Constants.WEIGHT_FIELD in valueOptions[0] &&
          Constants.LENGTH_FIELD in valueOptions[0]
        ) {
          const weightField = valueOptions[0][Constants.WEIGHT_FIELD];
          const lengthField = valueOptions[0][Constants.LENGTH_FIELD];

          if (examination[weightField] && examination[lengthField]) {
            const weight = sanitizeAndConvertFieldValueStringToFloat(
              examination[weightField],
            );
            const length = sanitizeAndConvertFieldValueStringToFloat(
              examination[lengthField],
            );
            const result = weight / (length * length);
            this.value = Math.round(result * 100000) / 10;
          }
        }
      }
    },
  },
};
</script>
