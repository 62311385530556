<template>
  <div class="mt-3 mb-4">
    <strong>{{ title }}</strong>
    <i v-if="required" class="far fa-asterisk" />
    <vue-slider
      v-model="value"
      class="my-2"
      :lazy="true"
      :tooltip="'none'"
      :min="0"
      :max="10"
      :interval="0.1"
      :disabled="disabled"
      @change="onChange"
    />
    <div
      v-if="marks && marks.length > 0"
      class="d-flex justify-content-between"
    >
      <div v-for="(mark, index) in marks" :key="index">
        <small>{{ mark.label }}</small>
      </div>
    </div>
  </div>
</template>

<script>
/**
 * `RangeSlider` is a slider component with fixed configuration.
 *  Values range from 0 to 10 with a 0.1 interval.
 */
export default {
  props: {
    title: String,
    data: Array,
    disabled: Boolean,
    required: Boolean,
    formValue: [Object, String],
    extraOptions: {
      key: String,
      onValueChange: Function,
    },
  },
  emits: ["isValid"],
  data() {
    return {
      value: undefined,
      marks: [],
    };
  },
  computed: {
    isValid: function () {
      return (
        !this.required || (this.value !== undefined && this.value !== null)
      );
    },
  },
  watch: {
    isValid: {
      handler: function (newValue) {
        this.$emit("isValid", newValue);
      },
      immediate: true,
    },
  },
  mounted: function () {
    this.value = this.formValue;
    this.marks = this.data;
  },
  methods: {
    onChange: function (event) {
      this.value = event;
      this.extraOptions.onValueChange({
        key: this.extraOptions.key,
        value: event,
      });
    },
  },
};
</script>

<style></style>
