<template>
  <div class="panel panel-default panel-fill">
    <div class="panel-heading panel-heading-underline">
      <h3 class="panel-title">
        {{ trans("examination.imagesHeadline") }}
        <info-popup
          v-if="!product.toLowerCase().includes('photo')"
          :text-content="getInfoPopupText()"
        />
      </h3>
    </div>
    <div class="panel-body">
      <image-gallery
        :images="images"
        :data-image-metadata="dataImageMetadata"
        :product-type="productType"
        :case-id="caseId"
        :use-column="false"
        :is-loading="false"
      >
        <div
          v-if="
            $can('test_ai') &&
            product === 'mole' &&
            $store.state.dermicusPlatform === 'sweden'
          "
          class="row"
        >
          <div id="imagesWithAiFiltersCollapse" class="collapse">
            <ai-filter-images
              v-if="imagesWithAiFilters && imagesWithAiFilters.length !== 0"
              :images-with-ai-filters="imagesWithAiFilters"
              @update:images-with-ai-filters="imagesWithAiFilters = $event"
            />
          </div>
          <clip-loader v-if="imagesWithAiFiltersLoading" class="mt-5" />
          <button
            class="btn btn-grey btn-lg btn-block mt-3"
            type="button"
            data-toggle="collapse"
            data-target="#imagesWithAiFiltersCollapse"
            aria-expanded="false"
            aria-controls="collapseExample"
            @click="toggleShowImgWithAiFilters"
          >
            {{
              showImgWithAiFilters
                ? trans("examination.hideAiFilterImages")
                : trans("examination.showAiFilterImages")
            }}
            <i
              :class="[
                showImgWithAiFilters ? 'fa-angle-up' : 'fa-angle-down',
                'far fa-lg',
              ]"
            />
          </button>
        </div>
      </image-gallery>
    </div>
  </div>
</template>

<script>
import AiFilterImages from "./AiFilterImages.vue";
import ImageGallery from "./ImageGallery.vue";
import InfoPopup from "../../Utils/InfoPopup.vue";

import { PRODUCTS } from "../../../helpers/definitions";
import { infoPopupText } from "../../../helpers/misc";

export default {
  components: {
    AiFilterImages,
    ImageGallery,
    InfoPopup,
  },
  props: {
    images: {},
    dataImageMetadata: Object,
    product: String,
    imagesWithAiFilters: Array,
    imagesWithAiFiltersLoading: Boolean,
    caseId: Number,
  },
  data: function () {
    return {
      showImgWithAiFilters: false,
      PRODUCTS: PRODUCTS,
      isLoadingAi: false,
    };
  },
  methods: {
    toggleShowImgWithAiFilters: function () {
      this.showImgWithAiFilters = !this.showImgWithAiFilters;
      if (this.imagesWithAiFilters.length === 0) this.$emit("getAiFilters");
    },
    getInfoPopupText: function () {
      return Object.values(this.images)
        .map((image) => `${this.trans("infoText." + image.type)}`)
        .join("<br />");
    },
  },
};
</script>

<style lang="scss" scoped>
.image-container {
  min-height: 200px;
}
</style>
