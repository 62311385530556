<template>
  <div>
    <div v-if="channel.participants && channel.participants.length >= 1">
      <span class="chat-subtext">{{
        commaSeparatedUsersList(channel.participants)
      }}</span>
    </div>

    <div v-else>
      <span v-if="channel.type === 'public'" class="chat-subtext">{{
        trans("chat.generalChannelUsersInfo")
      }}</span>
      <span v-else class="chat-subtext">{{
        trans("chat.channelHasNoUsers")
      }}</span>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    channel: {
      participants: Array,
    },
  },
  methods: {
    commaSeparatedUsersList: function (users) {
      if (users.length > 0) {
        let userNames = users.map((user) => {
          return user.name;
        });

        return userNames.join(", ");
      }
      return;
    },
  },
};
</script>
