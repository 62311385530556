<template>
  <div>
    <ul class="nav nav-tabs nav tabs-bordered">
      <li :class="{ active: activeSection == 1 }">
        <router-link to="/support/region-support">
          <span>{{ trans("menu.support") }}</span>
        </router-link>
      </li>
      <li :class="{ active: activeSection == 4 }">
        <router-link to="/support/contact">
          <span>{{ trans("support.tabLabelContact") }}</span>
        </router-link>
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  props: ["activeSection"],
};
</script>
