<template>
  <div>
    <strong v-if="!hideLabel">
      {{ trans("examination.caseStatusLabel") }}
    </strong>
    <div id="status-badge" class="text-muted">
      <span
        v-if="
          examinationInfo &&
          examinationInfo.statusName &&
          examinationInfo.statusName.key
        "
        class="d-inline-flex align-content-center"
      >
        <span
          :class="`badge badge-${examinationInfo.statusName.key} align-self-center`"
          >{{ examinationInfo.statusName.trans }}</span
        >
        <span
          v-if="
            (examinationInfo.case_close_at || examinationInfo.caseCloseAt) &&
            !hideLabel
          "
          class="pl-1"
        >
          – ({{
            formatDate(examinationInfo.case_close_at) ||
            formatDate(examinationInfo.caseCloseAt)
          }})</span
        >
        <span v-if="!hideLabel">
          <a
            v-if="canCloseCaseOnExaminationOverview()"
            href="#"
            class="pl-1"
            @click="closeCase"
          >
            – {{ trans("examination." + closeCaseText) }}</a
          >
        </span>
      </span>
    </div>
  </div>
</template>

<script>
import { customDateFormatter } from "../../../../../helpers/platform";
import { STORE_CONSTANTS } from "../../../../../helpers/definitions";
import { examinationCloseConfirm } from "../../../examination-close-confirm";

export default {
  props: {
    extraOptions: Object,
    hideLabel: Boolean,
    examination: Object,
    viewOption: String,
  },
  data: function () {
    return {
      examinationInfo: null,
    };
  },
  computed: {
    closeCaseText: function () {
      if (
        this.examinationInfo.status === 1 ||
        this.examinationInfo.status === 2
      ) {
        return "caseStatusAttestCaseLabel";
      } else if (this.examinationInfo.status === 5) {
        // user should be able to cancel Stockholm tentative cases
        return "caseStatusCancelCase";
      }
      return;
    },
  },
  watch: {
    examination: function () {
      // patient
      this.setExamination();
    },
    extraOptions: function () {
      // examination
      this.setExamination();
    },
  },
  mounted: function () {
    this.setExamination();
  },
  methods: {
    setExamination: function () {
      if (this.extraOptions && this.extraOptions.examination) {
        this.examinationInfo = this.extraOptions.examination;
      } else if (this.examination) {
        this.examinationInfo = this.examination;
      }
    },
    closeCase: function () {
      // mole and mole uk don't have examination.type
      if (!this.examinationInfo.type) {
        this.$emit("closeCase");
      } else {
        examinationCloseConfirm(this.$vueAlert.confirm, this.examinationInfo)
          .then(() => {
            this.loading = true;
            axios
              .get(
                `/api/internal/tumour-wound/close/${this.examinationInfo.id}`,
              )
              .then(async () => {
                this.loading = false;

                await this.$store.dispatch(STORE_CONSTANTS.RELOAD_EXAMINATION);
              })
              .catch((error) => {
                this.loading = false;
                this.userErrorMessage(error);
              });
          })
          .catch(() => {});
      }
    },
    formatDate(date) {
      return customDateFormatter(date);
    },
    canCloseCase: function () {
      if (
        this.$can("close_cases") &&
        (this.examinationInfo.isErasable || this.examinationInfo.is_erasable) &&
        !this.examinationInfo.caseCloseAt &&
        !this.examinationInfo.case_close_at
      ) {
        return true;
      } else {
        return false;
      }
    },
    canCloseCaseOnExaminationOverview: function () {
      if (this.viewOption === "patient_overview") return false;

      if (this.canCloseCase()) {
        // only generic products have extra options
        if (this.extraOptions) {
          return Boolean(this.extraOptions.closeCasesGeneralInfo);
        }
        return true;
      }
      return false;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../../../../../../sass/abstracts/_variables.scss";

#status-badge {
  .badge.badge-new,
  .badge.badge-ready {
    background-color: $dermicusBlue;
  }

  .badge.badge-answered,
  .badge.badge-ongoing,
  .badge.badge-invited {
    background-color: $dermicusYellow;
  }

  .badge.badge-closed,
  .badge.badge-research-answered {
    background-color: $dermicusGreen;
  }

  .badge.badge-invisible,
  .badge.badge-selected {
    background-color: #e1e1e1;
    color: $grey !important;
  }

  .badge.badge-tentative {
    background-color: #3db9dc;
  }
}
</style>
