import { practitionerHttpService } from "../../app/practitioner-portal/store/practitioner-http-service";
import { StudySummary } from "../../../../../app/Dermicus/Studies/StudySummary";

export function studiesGet() {
  return practitionerHttpService
    .get<StudySummary[]>("/api/internal/studies")
    .then((response) => response.data);
}

export function userStudiesAsAdminGet(userId: number) {
  return practitionerHttpService
    .get<StudySummary[]>(`/api/internal/users/${userId}/studies/as-admin`)
    .then((response) => response.data);
}

export function studyAdminAdd(data: { studyId: number; userId: number }) {
  return practitionerHttpService
    .post(
      `/api/internal/admin/studies/users/admin/${data.studyId}/${data.userId}`,
    )
    .then((response) => response.data);
}

export function studyAdminRemove(data: { studyId: number; userId: number }) {
  return practitionerHttpService
    .delete(
      `/api/internal/admin/studies/users/admin/${data.studyId}/${data.userId}`,
    )
    .then((response) => response.data);
}

export function studySendInvites(studyId: number) {
  return practitionerHttpService
    .post(`/api/internal/studies/${studyId}/send-invites`)
    .then((response) => response.data);
}

export function studiesSearch() {
  return {
    queryKey: ["studies-search"],
    queryFn: () =>
      practitionerHttpService
        .post(`/api/internal/studies/search`)
        .then((response) => response.data),
  };
}
