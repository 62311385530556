let roles = [];

const RoleId = async (roleName) => {
  if (roles.length === 0) {
    await axios
      .get("/api/internal/admin/roles")
      .then((data) => {
        roles = data.data;
      })
      .catch((error) => {
        console.log("oops get role id error: ", error);
      });
  }

  let roleId = roles.find((role) => {
    if (role.name === roleName) {
      return role.id;
    }
  });

  return roleId;
};

export default RoleId;
