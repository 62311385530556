<template>
  <div>
    <div class="panel panel-default panel-fill">
      <div class="panel-heading">
        <h3 class="panel-title">
          {{ trans("users.editProfileHeader") }}
        </h3>
      </div>
      <div class="panel-body">
        <form role="form">
          <div class="row">
            <div class="m-b-20 col-md-6">
              <div class="form-group" :class="{ 'has-error': errors.username }">
                <label for="username">{{ trans("users.usernameLabel") }}</label>
                <i class="far fa-asterisk" />
                <input
                  id="username"
                  v-model="user.username"
                  type="text"
                  class="form-control"
                />
                <form-error v-if="errors.username" :errors="errors.username" />
              </div>
            </div>

            <div class="m-b-20 col-md-6">
              <div class="form-group" :class="{ 'has-error': errors.email }">
                <label for="email">{{ trans("users.emailLabel") }}</label>
                <i class="far fa-asterisk" />
                <input
                  id="email"
                  v-model="user.email"
                  type="email"
                  class="form-control"
                  autocomplete="email"
                />
                <form-error v-if="errors.email" :errors="errors.email" />
              </div>
            </div>
          </div>

          <div class="row">
            <div class="m-b-20 col-md-6">
              <div
                class="form-group"
                :class="{ 'has-error': errors.firstname }"
              >
                <label for="first_name">{{
                  trans("users.firstNameLabel")
                }}</label>
                <i class="far fa-asterisk" />
                <input
                  id="first_name"
                  v-model="user.firstname"
                  type="text"
                  name="first_name"
                  class="form-control"
                />
                <form-error
                  v-if="errors.firstname"
                  :errors="errors.firstname"
                />
              </div>
            </div>

            <div class="m-b-20 col-md-6">
              <div class="form-group" :class="{ 'has-error': errors.lastname }">
                <label for="last_name">{{
                  trans("users.lastNameLabel")
                }}</label>
                <i class="far fa-asterisk" />
                <input
                  id="last_name"
                  v-model="user.lastname"
                  type="text"
                  name="last_name"
                  class="form-control"
                />
                <form-error v-if="errors.lastname" :errors="errors.lastname" />
              </div>
            </div>
          </div>

          <div class="row">
            <div class="m-b-20 col-md-6">
              <div class="form-group" :class="{ 'has-error': errors.phone_no }">
                <label for="phone_no">{{
                  trans("users.contactNoLabel")
                }}</label>
                <i
                  v-if="$store.state.dermicusPlatform === 'stockholm'"
                  class="far fa-asterisk"
                />
                <input
                  id="phone_no"
                  v-model="user.phone_no"
                  type="text"
                  name="phone_no"
                  class="form-control"
                  :placeholder="trans('forms.phoneNumber')"
                />
                <form-error v-if="errors.phone_no" :errors="errors.phone_no" />
              </div>
            </div>

            <div class="m-b-20 col-md-6">
              <div class="form-group" :class="{ 'has-error': errors.title }">
                <label for="title">{{ trans("users.jobTitleLabel") }}</label>
                <input
                  id="title"
                  v-model="user.title"
                  type="text"
                  name="title"
                  class="form-control"
                />
              </div>
            </div>
          </div>

          <div class="row">
            <div class="m-b-20 col-md-6">
              <div class="form-group" :class="{ 'has-error': errors.comment }">
                <label for="comment">{{ trans("users.commentLabel") }}</label>
                <input
                  id="comment"
                  v-model="user.comment"
                  type="text"
                  name="comment"
                  class="form-control"
                />
              </div>
            </div>

            <div class="m-b-20 col-md-6">
              <div class="form-group" :class="{ 'has-error': errors.hsa_id }">
                <label for="hsa_id">{{ trans("users.hsaIdLabel") }}</label>
                <i
                  v-if="dermicusPlatform === 'stockholm'"
                  class="far fa-asterisk"
                />
                <input
                  id="hsa_id"
                  v-model="user.hsa_id"
                  type="text"
                  name="hsa_id"
                  class="form-control"
                  :placeholder="
                    dermicusPlatform === 'stockholm'
                      ? trans('forms.hsaIdPlaceholderStockholmPlatform')
                      : trans('forms.hsaIdPlaceholder')
                  "
                />
                <small
                  v-if="dermicusPlatform === 'stockholm'"
                  id="hsa_id"
                  class="form-text text-muted"
                  >{{ trans("forms.hsaIdPlaceholderStockholmPlatform") }}</small
                >
                <form-error v-if="errors.hsa_id" :errors="errors.hsa_id" />
              </div>
            </div>
          </div>
        </form>
        <span class="text-left"
          ><i class="far fa-asterisk" />
          {{ trans("center.requiredField") }}</span
        >
      </div>
    </div>

    <div class="panel panel-default panel-fill">
      <div class="panel-heading">
        <h3 class="panel-title">
          {{ trans("users.changePasswordHeadline") }}
        </h3>
      </div>
      <div class="panel-body">
        <form role="form">
          <input
            type="text"
            autocomplete="username"
            ng-hide="true"
            style="display: none"
          />
          <div class="m-b-20 col-md-12">
            <div class="m-b-20">
              <div class="form-group" :class="{ 'has-error': errors.password }">
                <label for="Password">{{
                  trans("users.changePasswordLabel")
                }}</label>
                <input
                  id="Password"
                  v-model="user.password"
                  type="password"
                  name="password"
                  class="form-control"
                  autocomplete="new-password"
                />
                <small class="form-text text-muted">{{
                  trans("auth.recommendedPasswordLength")
                }}</small>

                <form-error v-if="errors.password" :errors="errors.password" />
              </div>
              <div
                class="form-group"
                :class="{
                  'has-error': errors.password_confirmation,
                }"
              >
                <label for="RePassword">{{
                  trans("users.repeatChangePasswordLabel")
                }}</label>
                <input
                  id="RePassword"
                  v-model="user.password_confirmation"
                  type="password"
                  name="password_confirmation"
                  class="form-control"
                  autocomplete="new-password"
                />

                <form-error
                  v-if="errors.password_confirmation"
                  :errors="errors.password_confirmation"
                />
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    errors: {},
    user: {
      username: String,
      firstname: String,
      phone_no: String,
      comment: String,
      email: String,
      lastname: String,
      title: String,
      hsa_id: String,
      password: String,
      password_confirmation: String,
    },
  },
  data: function () {
    return {
      dermicusPlatform: this.$store.state.dermicusPlatform,
    };
  },
};
</script>
