<script>
export default {
  methods: {
    $can: function (permissionName) {
      const userPermissions = JSON.parse(this.$store.state.user.permissions);
      const hasPermission = userPermissions.includes(permissionName);

      return hasPermission;
    },
  },
};
</script>
