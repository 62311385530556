import { store } from "./store";
import { app } from "./app";
import { lang } from "./i18n";
import { practitionerAuthToken } from "./app/practitioner-portal/auth/practitioner-auth-token";
import { appRouter } from "./app/routing/app-router";

const router = appRouter;
app.routes.forEach((route) => router.addRoute(route));

router.beforeEach((to, from, next) => {
  $("body").removeAttr("style");
  // Identify IE and show browser-not-supported message

  const ua = window.navigator.userAgent;
  const msie = ua.indexOf("MSIE ");
  if (msie > 0 || !!navigator.userAgent.match(/Trident.*rv\:11\./)) {
    if (to.path !== "/unsupported-browser") {
      // `next` can never be called twice, so we need to return here.
      return next({ name: "BrowserNotSupported" });
    }
  }

  // TODO: Practitioner portal authentication should be further improved!
  //  for now, we only activate it when NOT in the patient portal routes.
  if (!/^\/patient($|\/)/.test(to.path)) {
    if (!localStorage.getItem("lang_code")) {
      var availableLanguages = ["en", "sv"];
      var detectedLocale = _.chain(window.navigator)
        .pick([
          "languages",
          "language",
          "browserLanguage",
          "userLanguage",
          "systemLanguage",
        ]) //Get only language properties
        .values() //Get values of the properties
        .flatten() //flatten all arrays
        .compact() //Remove undefined values
        .map(function (x) {
          return x.substr(0, 2); //Shorten strings to use two chars (en-US ->
          // en)
        })
        .find(function (x) {
          return x;
        })
        .value();

      if (detectedLocale != "sv") {
        detectedLocale = "en";
      }

      lang.setLocale(detectedLocale);
    }

    let token = practitionerAuthToken.get();

    if (to.meta.requiresAuth) {
      localStorage.setItem("prev_ref_uri", from.path);
      localStorage.setItem("ref_uri", to.path);

      if (token) {
        axios
          .get("/api/internal/auth/information")
          .then((response) => {
            // If token is removed from datebase but not token from browser
            if (response.name == "Auth") {
              return;
            }

            store.commit("setUser", response.data);
            store.commit("setAuthenticated", true);
            store.commit(
              "setPrimaryCenterName",
              response.data.primaryCenter.name,
            );
            lang.setLocale(response.data.culture);
            store.state.isSplashScreen = false;

            if (
              to.meta.adminAuth &&
              store.state.user.permissions.indexOf("manage_dermicus") === -1
            ) {
              next({
                name: "Evaluation",
              });
            } else if (
              to.meta.centerAuth &&
              store.state.user.permissions.indexOf("manage_center") === -1
            ) {
              next({
                name: "Evaluation",
              });
            } else if (
              to.meta.regionAuth &&
              store.state.user.permissions.indexOf("manage_region") === -1
            ) {
              next({
                name: "Evaluation",
              });
            } else if (
              to.meta.researchAdminAuth &&
              store.state.user.permissions.indexOf("manage_study") === -1
            ) {
              next({
                name: "Evaluation",
              });
            } else if (
              to.meta.researchParticipantAuth &&
              store.state.user.permissions.indexOf("participate_in_studies") ===
                -1
            ) {
              next({
                name: "Evaluation",
              });
            } else if (
              to.meta.platformDependant &&
              to.meta.platformDependant.indexOf(
                store.state.dermicusPlatform,
              ) === -1
            ) {
              next({
                name: "Evaluation",
              });
            } else {
              next();
            }
          })
          .catch((error) => {
            if (
              error?.response?.status === 401 ||
              error?.response?.status === 403
            ) {
              practitionerAuthToken.remove();
              store.state.isSplashScreen = false;
              store.commit("setAuthenticated", false);
              next({
                name: "Auth",
              });
            }
          });
      } else if (!store.isAuthenticated) {
        practitionerAuthToken.remove();

        // Don't show login screen first on IE
        if (msie > 0 || !!navigator.userAgent.match(/Trident.*rv\:11\./)) {
          if (to.path !== "/unsupported-browser")
            next({ name: "BrowserNotSupported" });
        } else {
          next({
            name: "Auth",
          });
        }
        return;
      }
    } else if (token && to.meta.disallowAuthed) {
      // if user is authenticated don't allow user to renavigate to auth
      next({
        name: "Evaluation",
      });
    } else {
      next();
    }
  } else {
    next();
  }
});

export default router;
