<template>
  <div>
    <strong>{{ title }}</strong>
    <br />
    <p v-if="data && data.length === 0">-</p>
    <p
      v-for="(value, key) in data"
      v-else
      :key="key"
      class="text-muted consentList"
    >
      <i v-if="value.value === true" class="fal fa-check-circle" />
      <i
        v-else-if="value.value === false || value.value === null"
        class="fal fa-circle"
      />
      {{ key }}<br />
    </p>
  </div>
</template>

<script>
export default {
  props: {
    title: String,
    /**
     * @type {Record<string, {value: boolean | null}>}
     */
    data: [Object, Array],
  },
};
</script>
