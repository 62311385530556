<template>
  <div>
    <div class="statistics-background-image" />
    <div class="statistics-info">
      <div class="text-center m-5">
        <h5 class="mb-3">
          {{ trans("statistics.featureNotAvailableHeader") }}
        </h5>
        <p v-if="hasActiveProducts">
          {{ trans("statistics.featureNotAvailableBody") }}
          {{ activeUserProducts }}.
        </p>
        <p v-else>{{ trans("errorMessages.userHasNoActiveProducts") }}.</p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  computed: {
    hasActiveProducts: function () {
      const activeProducts = this.$store.state.user.products.filter(
        (product) => product.active === true,
      );
      return activeProducts.length;
    },
    activeUserProducts: function () {
      const userProducts = this.$store.state.user.products;
      let activeProductsList = userProducts
        .filter((product) => product.active)
        .map((product) => product.name);

      return activeProductsList.join(", ");
    },
  },
};
</script>

<style lang="scss" scoped>
.statistics-background-image {
  background-image: url("../images/StatisticsBackground.jpg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  background-color: #fff;
  filter: blur(7px) opacity(30%) grayscale(50%);
  -webkit-filter: blur(7px) opacity(30%) grayscale(50%);
  height: 100vh;
}

.statistics-info {
  background-color: white;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 2;
  width: 50%;
  padding: 20px;
  border: 2px solid #f5f5f5;
  border-radius: 3px;
}

@media (max-width: 768px) {
  .statistics-info {
    width: 90%;
  }
}
</style>
