import { ValueOf } from "type-fest";
import { RouteLocation, RouteRecordRedirectOption, useRoute } from "vue-router";

/**
 * Listing of all the app routes, with path and name interface.
 *
 * Urls and Route names have to be unique for the application, and are defined
 * as part of the functional requirements. The actual app modules will implement
 * these as an actual route, linking components and functionality through hooks.
 *
 * Please follow the naming guidelines in order to have a clear indication of
 * the indented route tree structure, because the path definition is specified
 * based on it, and thus children will "extend" the parent path further.
 *
 * - Keys define the actual route path up until the defined route.
 * - Keys are `.`-separated paths of camelCased route name parts.
 * - Keys are used as the `name` of the route, to help in logging.
 * - Keys mimic the actual URL, for clarity and to help in logging.
 *
 * Route options, like path and query params, are defined below in a type,
 * to enable some type help from our editor with helper functions.
 */
export const ROUTES: { [P in keyof OPTIONS]: { name: P; path: string } } = {
  researchAdmin: {
    name: "researchAdmin",
    path: "/research-admin",
  },
  "researchAdmin.studyCases": {
    name: "researchAdmin.studyCases",
    path: ":studyId(\\d+)/study-cases",
  },
  "researchAdmin.studyParticipants": {
    name: "researchAdmin.studyParticipants",
    path: ":studyId(\\d+)/study-participants",
  },
  "researchAdmin.studyExport": {
    name: "researchAdmin.studyExport",
    path: ":studyId(\\d+)/study-export",
  },
  research: {
    name: "research",
    path: "/research",
  },
  researchResearchCase: {
    name: "researchResearchCase",
    path: "/research/research-case/:id(\\d+)",
  },
  centerSettings: {
    name: "centerSettings",
    path: "/center/settings",
  },
  centerUsers: {
    name: "centerUsers",
    path: "/center/users",
  },
  regionCenterSettings: {
    name: "regionCenterSettings",
    path: "/region/centers/:id(\\d+)/settings",
  },
  regionCenterUsers: {
    name: "regionCenterUsers",
    path: "/region/centers/:id(\\d+)/users",
  },
  patientLogin: {
    name: "patientLogin",
    path: "/patient/login",
  },
  patientLoginConfirm: {
    name: "patientLoginConfirm",
    path: "/patient/login-confirm",
  },
  patientLogout: {
    name: "patientLogout",
    path: "/patient/logout",
  },
  patient: {
    name: "patient",
    path: "/patient",
  },
  "patient.studyConsents": {
    name: "patient.studyConsents",
    path: "study-consents",
  },
  "patient.studyConsent": {
    name: "patient.studyConsent",
    path: "study-consents/:studyConsentId(\\d+)",
  },
};

/**
 * The typed options for each route, to enable some type help.
 *
 * There might be a smarter way to do this and combine the actual value with
 * extra information added through its type, but this will work for now....
 *
 * (!) The reason we need this is to get the extra help, and the route configs
 * of vue-router doesn't specify these options for processing, as these params
 * are handled in a generic way, and thus it doesn't matter for vue-router.
 */
export type OPTIONS = {
  researchAdmin: Record<string, unknown>;
  "researchAdmin.studyCases": { params: { studyId: string } };
  "researchAdmin.studyParticipants": { params: { studyId: string } };
  "researchAdmin.studyExport": { params: { studyId: string } };
  research: { query?: { studyId?: string } };
  researchResearchCase: { params: { id: string } };
  centerSettings: Record<string, never>;
  centerUsers: Record<string, never>;
  regionCenterSettings: { params: { id: string } };
  regionCenterUsers: { params: { id: string } };
  patientLogin: { query: { callbackUrl: string } };
  patientLoginConfirm: Record<string, never>;
  patientLogout: { query?: { force?: "1" } };
  patient: Record<string, never>;
  "patient.studyConsents": Record<string, never>;
  "patient.studyConsent": { params: { studyConsentId: string } };
};

/**
 * Helper function to get the type of Route with known path and query params.
 */
export type RouteOf<T extends ValueOf<typeof ROUTES>> = RouteLocation & {
  [P in Extract<
    keyof OPTIONS[T["name"]],
    "params" | "query"
  >]: OPTIONS[T["name"]][P];
};

/**
 * Helper function to get a location object for use in routing.
 *
 * The params aren't required, and vue-router will keep existing params!
 * So if you want the location of a sibling page, no params needed.
 */
export function locationOf<T extends ValueOf<typeof ROUTES>>(
  route: T,
  options?: OPTIONS[T["name"]],
) {
  return { name: route.name, ...options };
}

/**
 * Helper function to get route with type of path- and query-params for it.
 */
export function useRouteOf<T extends ValueOf<typeof ROUTES>>() {
  return useRoute() as RouteOf<T>;
}

/**
 * Helper function to configure redirects when landing directly on the route.
 *
 * The native way that this can be configured is to add a child route with an
 * empty path, but that only works when the route is resolved based on the path.
 * When you navigate based on route name, then the parent name has to be placed
 * on this "empty/default" child route, which isn't very practical when using
 * our route definitions. With this we can just add the redirect on the parent.
 *
 * In other routing libs out there, a "redirect" config is usually only done
 * when you land exactly on that route, otherwise you should use a hook, this
 * is of course a way more intuitive way of doing redirects.
 */
export function redirect(
  toExact: ValueOf<typeof ROUTES>,
  redirect: ValueOf<typeof ROUTES>,
): RouteRecordRedirectOption {
  // @ts-expect-error unexpected undefined returned here
  return (to: Route) =>
    to.name === toExact.name ? locationOf(redirect, {}) : undefined;
}
