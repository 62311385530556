<template>
  <div>
    <UseStoreResources :data-resource="studyConsentsResource">
      <field-panel
        v-if="!!studyConsents?.length || canRequestStudyConsent"
        class="mt-3"
        :title="'model.examination.studyConsents.label'"
        :info-popup-text="infoPopup()"
      >
        <div class="panel-body">
          <span v-if="studyConsents?.length === 0" class="tw-block tw-mb-4">
            {{ lang.get("model.examination.studyConsents.empty") }}
          </span>
          <div v-if="!!studyConsents?.length" class="row">
            <div
              v-for="studyConsent in studyConsents"
              :key="studyConsent.id"
              class="col-md-6 mb-3"
            >
              <strong>{{ studyConsent.study_name }}</strong>
              <div>
                <StudyConsentStatusBadge :data-value="studyConsent.status" />
              </div>
              <button
                v-if="studyConsent.status === 'active'"
                type="button"
                class="btn btn-secondary mt-3"
                @click="
                  examinationStudyConsentDownload({
                    id: dataExaminationId,
                    studyConsentId: studyConsent.id,
                  })
                "
              >
                {{ lang.get("patientPortal.action.download") }}
              </button>
            </div>
          </div>
          <div v-if="canRequestStudyConsent">
            <Button type="button" @click="handleRequestStudyConsent">
              {{ lang.get("admin.requestStudyConsent") }}
            </Button>
          </div>
        </div>
      </field-panel>
    </UseStoreResources>

    <DerDialog
      :visible="visible"
      :header="lang.get('admin.requestStudyConsent')"
      :dismissable-mask="true"
      @update:visible="visible = $event"
    >
      <form @submit.prevent="handleSubmit">
        <div
          class="form-group tw-space-y-2"
          :class="{ 'has-error': errorMessages.length }"
        >
          <div class="tw-flex tw-gap-2 tw-items-center">
            <label :for="phoneInputId" class="tw-m-0">{{
              lang.get("examination.telephone_number")
            }}</label>
            <InfoPopup
              :text-content="lang.get('action.study.sendSMS.tooltip')"
            />
          </div>
          <div class="tw-flex tw-gap-2">
            <input
              :id="phoneInputId"
              v-model="phoneNumber"
              type="text"
              class="form-control"
              required
            />
            <Button
              class="tw-whitespace-nowrap"
              :is-loading="requestStudyConsent.isLoading.value"
            >
              {{ lang.get("action.study.sendSMS.label") }}
            </Button>
          </div>
          <form-error
            v-if="errorMessages.length"
            class="tw-block tw-mt-2"
            :errors="errorMessages"
          ></form-error>
        </div>
      </form>
    </DerDialog>
  </div>
</template>

<script setup lang="ts">
import { useQueries } from "@tanstack/vue-query";
import { v4 as uuidV4 } from "uuid";
import { computed, ref, toRefs } from "vue";
import { examinationStudyConsentDownload } from "../../../../app/practitioner-portal/store/examination-study-consent-download";
import DerDialog from "../../../../design-system/DerDialog.vue";
import UseStoreResources from "../../../../design-system/UseStoreResources.vue";
import { lang } from "../../../../i18n";
import { useStudyConsentRequest } from "../../../../store/api/use-studies-resource";
import {
  examinationStudyConsentsGet,
  studiesEligibleGet,
} from "../../../../store/core/examinations";
import Button from "../../../Layout/Button.vue";
import StudyConsentStatusBadge from "../../../StudyConsentStatusBadge.vue";
import InfoPopup from "../../../Utils/InfoPopup.vue";
import FieldPanel from "./components/FieldPanel.vue";

const props = defineProps<{
  dataExaminationId: number;
  dataPrimaryCenterId: number;
  dataPatientPhone?: string;
}>();
const { dataExaminationId, dataPrimaryCenterId } = toRefs(props);

const phoneInputId = uuidV4();

const visible = ref(false);
const phoneNumber = ref(props.dataPatientPhone);
const errorMessages = ref([]);

const infoPopup = function () {
  return (
    lang.get("infoText.study_consent_status_requested") +
    "<br />" +
    lang.get("infoText.study_consent_status_signed") +
    "<br />" +
    lang.get("infoText.study_consent_status_withdrawn")
  );
};

const studyConsentsResource = useQueries({
  queries: computed(() => [
    studiesEligibleGet(dataExaminationId.value),
    examinationStudyConsentsGet(dataExaminationId.value),
  ]),
});

const canRequestStudyConsent = computed(() => {
  const [studiesEligible] = studyConsentsResource;
  return studiesEligible.data.eligible;
});

const studyConsents = computed(() => {
  const [, examinationStudyConsents] = studyConsentsResource;
  return examinationStudyConsents.data;
});

const requestStudyConsent = useStudyConsentRequest();

function handleRequestStudyConsent() {
  visible.value = true;
}

function handleSubmit() {
  if (phoneNumber.value) {
    requestStudyConsent.mutate(
      {
        examinationId: dataExaminationId.value,
        phoneNumber: phoneNumber.value,
      },
      {
        onSuccess: () => {
          visible.value = false;
        },
        onError: (e) => {
          if (e.response.status === 422) {
            errorMessages.value = e.response.data.phone_number;
          }
        },
      },
    );
  }
}
</script>
