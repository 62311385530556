<template>
  <div>
    <header-admin :active-section="2" />

    <clip-loader v-show="loading" />

    <div v-if="!loading">
      <div class="col-md-12 text-left">
        <button class="btn btn-grey mx-1 mt-4" @click="$router.back()">
          {{ trans("general.back") }}
        </button>
      </div>

      <div class="row justify-content-center">
        <div class="tab-content col-md-6">
          <div id="profile" class="tab-pane active">
            <div class="panel panel-default panel-fill">
              <div class="panel-heading">
                <h3 class="panel-title">
                  {{ trans("users.inviteNewUserHeader") }}
                </h3>
              </div>

              <div class="panel-body">
                <form role="form">
                  <div class="row px-3">
                    <div class="mb-2 w-100 flex-column flex-fill">
                      <div
                        class="form-group"
                        :class="{ 'has-error': errors.center }"
                      >
                        <label for="center">{{
                          trans("users.primaryCenterLabel")
                        }}</label>
                        <i class="far fa-asterisk" />
                        <select-2
                          id="center"
                          v-model="center"
                          type="center"
                          class="form-control"
                          autocomplete="center"
                        >
                          <option />
                          <option
                            v-for="(center, key) in centers"
                            :key="key"
                            :value="center.id"
                          >
                            {{ center.name }}
                          </option>
                        </select-2>
                      </div>
                    </div>
                    <div class="mb-2 w-100 flex-column flex-fill">
                      <div
                        class="form-group"
                        :class="{ 'has-error': errors.emailValues }"
                      >
                        <label for="emailValues">{{
                          trans("forms.userEmailsLabel")
                        }}</label>
                        <i class="far fa-asterisk" />
                        <v-select
                          v-model="emailValues"
                          taggable
                          multiple
                          no-drop
                          :select-on-key-codes="[9, 32, 13, 188]"
                          :placeholder="trans('forms.enterEmailsPlaceholder')"
                          @update:model-value="atEmailInput(emailValues)"
                        />
                        <!-- select on tab, space, return, or comma -->

                        <form-error
                          v-for="(emailError, index) in errors.emailValues"
                          :key="index"
                          :errors="`• '${emailError.email}' ${emailError.message} `"
                        />
                      </div>
                    </div>
                  </div>
                </form>

                <clip-loader v-show="sending" />

                <div class="row px-3 justify-content-center">
                  <div class="flex-column flex-fill my-2">
                    <button
                      class="d-flex btn btn-primary w-100 justify-content-center"
                      :disabled="!isValid || loading || sending"
                      @click="sendInvitation"
                    >
                      {{ trans("users.sendNewUserInvitation") }}
                    </button>
                  </div>
                </div>
                <p>
                  <i class="far fa-asterisk" />
                  {{ trans("forms.requiredStar") }}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import HeaderAdmin from "../../Header.vue";
import UserHeader from "../../../User/UserHeader.vue";
import { validateInput, FORM_TYPES } from "../../../../helpers/formValidation";

export default {
  components: {
    UserHeader,
    HeaderAdmin,
  },
  data() {
    return {
      emailValues: [],
      loading: false,
      sending: false,
      center: undefined,
      centers: [],
      errors: {
        emailValues: [],
      },
      tentative_users: [],
      currentEmailArrayLength: 0,
    };
  },
  computed: {
    isValid: function () {
      if (!this.center || this.emailValues.length <= 0) {
        return false;
      } else {
        return true;
      }
    },
  },
  created: function () {
    this.loadData();
  },
  methods: {
    loadData: async function () {
      this.loading = true;
      try {
        const response = await axios.get("/api/internal/admin/all-centers");
        this.centers = response.data;
        this.loading = false;
      } catch (error) {
        this.userErrorMessage(error);
        this.loading = false;
      }
    },
    atEmailInput: function (emails) {
      if (emails.length > this.currentEmailArrayLength) {
        const email = emails[emails.length - 1];
        this.validateEmail(email);
      } else {
        this.updateEmailErrors();
      }
      this.currentEmailArrayLength = emails.length;
    },
    validateEmail: function (email) {
      const error = validateInput(FORM_TYPES.EMAIL, email, true);
      if (error) {
        const emailError = { email: email, message: `${error}.` };

        this.errors.emailValues.push(emailError);
      }
    },
    updateEmailErrors: function () {
      if (this.emailValues.length === 0) {
        this.errors.emailValues = [];
      } else {
        const emailErrors = this.errors.emailValues;
        emailErrors.forEach((error) => {
          if (!this.emailValues.includes(error.email)) {
            this.errors.emailValues.splice(emailErrors.indexOf(error), 1);
          }
        });
      }
    },
    sendInvitation: async function () {
      this.sending = true;
      this.errors.emailValues = [];
      this.emailValues.forEach((email) => {
        const userObj = { email: email, center_id: this.center };

        this.tentative_users.push(userObj);
      });

      try {
        const payload = {
          tentative_users: this.tentative_users,
        };
        await axios.post("/api/internal/admin/tentative/users", payload);

        this.center = "";
        this.emailValues = [];
        this.$notify({
          text: this.trans("users.invitationSuccess"),
          type: "success",
          duration: 2000,
        });
      } catch (error) {
        this.userErrorMessage(error);
        const data = error.response.data;
        if (data.exception) {
          const emailError = {
            email: this.emailValues,
            message: data.message,
          };
          this.errors.emailValues.push(emailError);
        } else {
          Object.keys(data.tentative_users).forEach((item) => {
            const emailError = {
              email: this.emailValues[item],
              message: _.get(data, `tentative_users[${item}].email`),
            };
            this.errors.emailValues.push(emailError);
          });
        }
        this.sending = false;
        this.tentative_users = [];
      }
      this.sending = false;
      this.tentative_users = [];
    },
  },
};
</script>

<style scoped></style>
