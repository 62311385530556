<template>
  <div
    v-if="showEditChannelName"
    class="mb-3 mx-3 d-flex justify-content-apart align-items-center edit-channel"
  >
    <div class="col-9">
      <div class="growing-message-container" style="width: 100%">
        <ResizeTextarea
          ref="myTextarea"
          v-model="newChannelName"
          :max-height="250"
        />
      </div>
    </div>
    <div>
      <button
        type="button"
        class="btn btn-grey action-btn"
        @click="changeChannelName"
      >
        <i class="far fa-check" />
      </button>
      <button
        type="button"
        class="btn btn-grey cancel-btn"
        @click="toggleChangeChannelName(false)"
      >
        <i class="fal fa-times" />
      </button>
    </div>
  </div>
</template>

<script>
import ResizeTextarea from "../../../../Layout/ResizeTextarea.vue";

export default {
  components: { ResizeTextarea },
  props: {
    showEditChannelName: Boolean,
    channelName: String,
  },
  data: function () {
    return {
      newChannelName: this.channelName,
    };
  },
  methods: {
    changeChannelName: function () {
      this.$emit("changeChannelName", this.newChannelName);
    },
    toggleChangeChannelName: function (bool) {
      this.$emit("toggleChangeChannelName", bool);
    },
  },
};
</script>
