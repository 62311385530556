<template>
  <div
    v-if="removingPeopleFromChannel"
    class="mb-3 mx-3 d-flex justify-content-apart align-items-center edit-channel"
  >
    <div class="col-9">
      <v-select
        v-model="usersToKeep"
        multiple
        name="participants"
        :options="channelParticipants"
        label="name"
        :reduce="(participant) => participant.id"
        :placeholder="trans('chat.chooseParticipants')"
      />
    </div>

    <div>
      <button class="btn btn-grey action-btn" @click="removeParticipants">
        <i class="far fa-check" />
      </button>
      <button
        class="btn btn-grey cancel-btn"
        @click="toggleRemovingPeopleFromChannel(false)"
      >
        <i class="fal fa-times" />
      </button>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    removingPeopleFromChannel: Boolean,
    channelParticipants: Array,
  },
  data: function () {
    return {
      usersToKeep: null,
    };
  },
  created: function () {
    this.usersToKeep = this.channelParticipants;
  },
  methods: {
    removeParticipants: function () {
      this.$emit("removeParticipants", this.usersToKeep);
    },
    toggleRemovingPeopleFromChannel: function (bool) {
      this.$emit("toggleRemovingPeopleFromChannel", bool);
    },
  },
};
</script>
