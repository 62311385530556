<template>
  <div>
    <label>{{ title }}</label>
    <br />
    <Datepicker
      name="date"
      :date="value"
      :class="{ disabled: disabled }"
      @on-date-change="onInput"
    />
  </div>
</template>

<script setup>
import { ref, watch } from "vue";
import Datepicker from "../../../../Utils/Datepicker.vue";

const props = defineProps({
  title: String,
  data: [String, Number],
  disabled: Boolean,
  formValue: [Array, Object, String],
  extraOptions: Object({
    key: String,
    onValueChange: Function,
  }),
});

let value = ref();

function onInput(event) {
  props.extraOptions.onValueChange({
    key: props.extraOptions.key,
    value: event ? new Date(event) : event,
  });
}

watch(
  () => props.formValue,
  (newValue) => {
    value.value = newValue?.value;
  },
  { immediate: true },
);
</script>
