import {
  displaysFor,
  IntentVariant,
  StatusVariant,
  variantsFor,
  VisualVariant,
} from "../design-system/tokens/variants";
import { StudyExaminationStatus } from "../../../../app/Dermicus/Studies/StudyExaminationStatus";
import { lang } from "../i18n";

export const studyExaminationStatusVariant = variantsFor<
  StudyExaminationStatus,
  IntentVariant | StatusVariant | VisualVariant
>({
  selected: "light",
  ready: "primary",
  invited: "warning",
  "research-answered": "success",
});

export const studyExaminationStatusDisplay =
  displaysFor<StudyExaminationStatus>({
    selected: () => lang.get("model.studyExaminationStatus.selected.display"),
    ready: () => lang.get("model.studyExaminationStatus.ready.display"),
    invited: () => lang.get("model.studyExaminationStatus.invited.display"),
    "research-answered": () =>
      lang.get("model.studyExaminationStatus.research-answered.display"),
  });
