<template>
  <div class="col-md-12">
    <div class="tab-content">
      <clip-loader v-show="loading" />
      <RegionReportOptionsForm v-if="region.id" :data-region-id="region.id" />
      <div class="m-t-10">
        <div class="panel panel-default">
          <div class="panel-heading">
            <h3 class="panel-title">
              {{ trans("region.region") }}
            </h3>
          </div>
          <div class="panel-body">
            <div class="row">
              <div class="m-b-20 col-md-12">
                <div class="tw-flex tw-gap-2 tw-items-center tw-mb-2">
                  <label class="tw-mb-0" for="center-logo">{{
                    trans("region.logo")
                  }}</label>
                  <VDropdown>
                    <template #popper>
                      {{ trans("region.logoInfo") }}
                    </template>
                    <i class="fal fa-info-circle" />
                  </VDropdown>
                </div>
                <RegionSettingsLogo
                  v-if="region.id"
                  :data-region-id="region.id"
                />
              </div>

              <div class="m-b-20 col-md-12">
                <label for="regionName">{{ trans("region.regionName") }}</label>

                <br />
                <input
                  id="regionName"
                  v-model="region.name"
                  type="text"
                  name="regionName"
                  class="form-control"
                  placeholder="Regionnamn..."
                  disabled
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- Region admin -->
      <div
        v-if="!loading && $route.params.id !== 'new'"
        class="panel panel-default panel-fill"
      >
        <div class="panel-heading">
          <h3 class="panel-title">
            {{ trans("region.regionAdmin") }}
          </h3>
        </div>
        <div class="panel-body table-responsive">
          <table class="table table-hover">
            <thead>
              <tr>
                <th>{{ trans("forms.firstname") }}</th>
                <th>{{ trans("forms.lastname") }}</th>
                <th>{{ trans("forms.email") }}</th>
                <th />
              </tr>
            </thead>
            <tbody>
              <tr v-for="(admin, index) in regionAdmins" :key="index">
                <td @click="openRegionAdmin(admin.id)">
                  {{ admin.firstname }}
                </td>
                <td @click="openRegionAdmin(admin.id)">
                  {{ admin.lastname }}
                </td>
                <td @click="openRegionAdmin(admin.id)">
                  {{ admin.email }}
                </td>
                <td>
                  <button
                    class="btn btn-danger float-right"
                    @click="removeRegionAdmin(admin.id)"
                  >
                    <span>{{ trans("general.formDeleteLabel") }}</span>
                  </button>
                </td>
              </tr>
            </tbody>
          </table>

          <div class="text-right mb-4 mx-2">
            <button
              v-if="!addNewAdmin"
              class="btn btn-primary"
              @click="toggleAddingAdmin"
            >
              <i class="fas fa-user-plus" />
              <span>{{ trans("region.addNewAdmin") }}</span>
            </button>
          </div>

          <div v-if="addNewAdmin">
            <div
              class="m-b-20 col-md-12"
              :class="{ 'has-error': errors.message }"
            >
              <label for="primaryCenter">
                {{ trans("admin.users") }}
              </label>
              <select2
                v-model="selectedUserId"
                :data-placeholder="trans('forms.chooseUserPlaceholder')"
                name="users"
              >
                <option value="" />
                <option
                  v-for="(user, index) in activeRegionUsers"
                  :key="index"
                  :value="user.id"
                >
                  {{ user.firstname }} {{ user.lastname }}
                </option>
              </select2>
              <form-error v-if="errors.message" :errors="errors.message" />
            </div>

            <div class="text-right">
              <button href="#" class="btn btn-grey" @click="cancelAddingAdmin">
                {{ trans("general.modalCancelButton") }}
              </button>
              <button
                href="#"
                class="btn btn-primary"
                :disabled="!selectedUserId"
                @click="connectRegionAdmin"
              >
                {{ trans("region.addNewAdmin") }}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import RegionSettingsLogo from "./RegionSettingsLogo.vue";
import RegionReportOptionsForm from "../../app/region/RegionReportOptionsForm.vue";

export default {
  components: { RegionReportOptionsForm, RegionSettingsLogo },
  data: function () {
    return {
      loading: false,
      addNewAdmin: false,
      selectedCenterId: null,
      selectedUserId: null,
      region: {
        id: null,
        name: null,
      },
      regionUsers: [],
      regionAdmins: [],
      errors: [],
    };
  },
  computed: {
    activeRegionUsers: function () {
      let activeUsers = this.regionUsers.filter((user) => !user.blocked);

      let usersWithoutAdminRole = [];

      activeUsers.forEach((user) => {
        let userWithoutAdminRole = user.roles.find(
          (role) => role.name === "region_admin",
        );
        if (userWithoutAdminRole === undefined) {
          usersWithoutAdminRole.push(user);
        }
      });

      return usersWithoutAdminRole;
    },
  },
  mounted: function () {
    this.getRegion();
  },
  methods: {
    getRegion: function () {
      this.loading = true;

      axios
        .get("/api/internal/region")
        .then((response) => {
          let data = response.data;
          this.region = data.region;
          this.regionUsers = data.region.users;
          this.regionAdmins = data.region.region_admins;
          this.loading = false;
        })
        .catch((error) => {
          this.$router.push({ name: "NoRegion" });
        });
    },
    connectRegionAdmin: function () {
      this.errors = [];

      axios
        .post(
          "/api/internal/region-admin/users/make-region-admin/" +
            this.selectedUserId,
        )
        .then((response) => {
          this.$notify({
            text: this.trans("general.notificationSaved"),
            type: "success",
            duration: 1000,
          });
          this.selectedUserId = null;
          this.addNewAdmin = false;
          this.getRegion();
        })
        .catch((error) => {
          this.userErrorMessage(error);
          this.errors = error.response.data;
        });
    },
    removeRegionAdmin: function (adminId) {
      this.$vueAlert
        .confirm(
          this.trans("region.modalConfirmationDeleteAdminFromRegion"),
          undefined,
          undefined,
          {
            confirmButtonText: this.trans("general.modalConfirmButton"),
            cancelButtonText: this.trans("general.modalCancelButton"),
          },
        )
        .then(() => {
          axios
            .post(
              "/api/internal/region-admin/users/remove-region-admin/" + adminId,
            )
            .then((response) => {
              this.$notify({
                text: this.trans("general.notificationSaved"),
                type: "success",
                duration: 1000,
              });
              this.getRegion();
            })
            .catch((error) => {
              this.userErrorMessage(error);
            });
        });
    },
    toggleAddingAdmin: function () {
      this.addNewAdmin = !this.addNewAdmin;
    },
    cancelAddingAdmin: function () {
      this.addNewAdmin = false;
      this.selectedUserId = null;
      this.errors = [];
    },
    openRegionAdmin: function (adminId) {
      console.log("open admin id: ", adminId);
    },
  },
};
</script>
