<template>
  <form v-bind="$attrs">
    <div class="form-group" :class="{ 'has-error': !!dataFormErrors?.ssn }">
      <label :for="ssnInputId">{{ trans("patient.ssn") }} (ssn)</label>
      <i class="far fa-asterisk" />
      <input
        :id="ssnInputId"
        name="ssn"
        class="form-control"
        :placeholder="trans('forms.placeholderForPersonalNumberFormat')"
      />
      <form-error v-if="!!dataFormErrors?.ssn" :errors="dataFormErrors?.ssn" />
    </div>
    <DateRangeFieldset :data-form-errors="dataFormErrors" />
    <slot />
    <RequiredFieldsNotice class="mt-3" />
  </form>
</template>
<script setup>
import { v4 as uuidV4 } from "uuid";
import RequiredFieldsNotice from "../../admin/comp/RequiredFieldsNotice.vue";
import DateRangeFieldset from "./comp/DateRangeFieldset.vue";

const ssnInputId = uuidV4();

defineProps({
  dataFormErrors: {
    type: /** @type {Record<'ssn'|'fromDate'|'untilDate', string|string[]>} */ Object,
  },
});
</script>
