import _get from "lodash/fp/get";

/**
 * Triggers the logout procedure on 401 UNAUTHORIZED errors.
 *
 * Uses the promise from the logout procedure as return value in the response
 * handler chain. This is a workaround to avoid imperative non-isolated code to
 * change global things while logging out the user. This problem is non-existent
 * when using reactive isolated code, as the handler chain has no impact on any
 * "unrelated" global code and can be safely executed, if not already ignored.
 *
 * (!) Just stopping the handler chain is unsupported, as it's impossible in JS.
 * The main reason to need this, is because of imperative non-isolated code that
 * would be executed and changing "unrelated" things that it shouldn't, which is
 * a practice we see used out there. Using reactive isolated code removes this
 * need and is in most use-cases the better option. As an example of such code:
 *
 * @example
 * // Use-case: The user can search for data based on keywords.
 *
 * // `search` is a function that when executed triggers an HTTP request,
 * // and returns the result, which can be a promise or an observable.
 * import { search } from '../store/search.ts
 *
 * // The RxJS operators we use to configure the expected behaviour.
 * import { debounceTime, distinctUntilChanged, switchMap } from 'rxjs';
 *
 * // `data$` can be used to display the result, which is based on `keywords$`,
 * // which is the observable that collects the values from the search input.
 * const data$ = keywords$.pipe(
 *    // Wait for the user to stop typing for 100ms and emit the last value.
 *    debounceTime(100),
 *    // Ignore identical successive values, like pasting the same value.
 *    distinctUntilChanged(),
 *    // When keywords are emitted, unsubscribe from the previous search (which
 *    // will ignore the underlying HTTP request results if not yet received,
 *    // and thus is isolated code), and subscribe to a new search request.
 *    switchMap(keywords => search(keywords)),
 * );
 */
export function httpResponse401UnauthorizedHandlerProvider(config: {
  logout: () => Promise<unknown>;
}) {
  return (error: unknown) =>
    _get("response.status", error) === 401
      ? config.logout()
      : Promise.reject(error);
}
