<template>
  <UseStoreResource :data-resource="examinationAttachmentsResource">
    <div class="tw-space-y-2">
      <strong>{{ lang.get("model.examination.attachments.label") }}</strong>
      <p v-if="!attachments.length">
        {{ lang.get("model.examination.attachments.empty") }}
      </p>
      <ExaminationAttachmentsList
        v-else
        :data-attachments="attachments"
        @attachment-click="handleAttachmentClick"
      />
    </div>
  </UseStoreResource>
</template>

<script setup lang="ts">
import { computed } from "vue";
import { lang } from "../../../../i18n";
import UseStoreResource from "../../../../design-system/UseStoreResource.vue";
import {
  useExaminationAttachmentDownload,
  useExaminationAttachments,
} from "../../../../store/api/use-examinations-resource";
import { ExaminationAttachment } from "../../../../../../../app/Dermicus/Examinations/ExaminationAttachment";
import ExaminationAttachmentsList from "./ExaminationAttachmentsList.vue";

const props = defineProps<{ dataExaminationId: number }>();

const examinationAttachmentsResource = useExaminationAttachments(
  props.dataExaminationId,
);

const attachments = computed(
  () => examinationAttachmentsResource.data.value ?? [],
);

const examinationAttachmentDownload = useExaminationAttachmentDownload();

function handleAttachmentClick(attachment: ExaminationAttachment): void {
  examinationAttachmentDownload.mutate({
    examinationId: props.dataExaminationId,
    attachmentId: attachment.id,
  });
}
</script>
