import { AppModule } from "../routing/app-module";
import { ROUTES } from "../routing/routes";
import RegionCenterSettingsRoute from "./RegionCenterSettingsRoute.vue";
import RegionCenterUsersRoute from "./RegionCenterUsersRoute.vue";

export const region: AppModule = {
  routes: [
    {
      ...ROUTES.regionCenterSettings,
      component: RegionCenterSettingsRoute,
      meta: {
        requiresAuth: true,
        regionAuth: true,
        breadcrumb: "breadcrumbs.regionEditCenter",
      },
    },
    {
      ...ROUTES.regionCenterUsers,
      component: RegionCenterUsersRoute,
      meta: {
        requiresAuth: true,
        regionAuth: true,
        breadcrumb: "breadcrumbs.regionEditCenterUsers",
      },
    },
  ],
};
