import { AxiosInstance } from "axios";
import { saveAs } from "file-saver";
import contentDisposition from "content-disposition";
import { practitionerHttpService } from "./practitioner-http-service";

export function examinationStudyConsentDownloadProvider(config: {
  httpService: AxiosInstance;
}) {
  return function examinationStudyConsentDownload(identity: {
    id: number;
    studyConsentId: number;
  }) {
    return config.httpService
      .request({
        method: "GET",
        url: `/api/internal/examinations/${identity.id}/study-consents/${identity.studyConsentId}/document`,
        responseType: "arraybuffer",
      })
      .then((response) => {
        saveAs(
          new Blob([response.data], { type: "application/pdf;charset=utf-8" }),
          contentDisposition.parse(response.headers["content-disposition"])
            .parameters.filename,
        );
      });
  };
}

export const examinationStudyConsentDownload =
  examinationStudyConsentDownloadProvider({
    httpService: practitionerHttpService,
  });
