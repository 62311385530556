import router from "../../../routes";

export const navigateToHomepage = (loginResponse) => {
  const homepage = "/";

  router.push({
    path:
      localStorage.getItem("ref_uri") && localStorage.getItem("ref_uri") !== "/"
        ? localStorage.getItem("ref_uri")
        : loginResponse
          ? loginResponse.data.homepage
          : homepage,
    query: { validate: true },
  });
};
