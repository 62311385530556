<template>
  <div class="panel panel-default">
    <div class="panel-heading panel-heading-underline">
      <h3 class="panel-title">
        {{ trans("patient.patientVievWoundLabel") }}
      </h3>
    </div>
    <div v-if="examination" class="panel-body">
      <div class="row">
        <div class="m-b-20 col-md-12">
          <strong>{{ trans("examination.woundType") }}</strong>
          <p class="text-muted">
            <span v-if="isEditing">
              <select2
                v-model="examination.case.ulcerType"
                class="select2 form-control responsive groups"
              >
                <option value="ulcerTypeTest">
                  {{ trans("examination.test") }}
                </option>
                <option value="FOOT_LEG_ULCER">
                  {{ trans("examination.FOOT_LEG_ULCER") }}
                </option>
                <option value="PRESSURE_ULCER">
                  {{ trans("examination.PRESSURE_ULCER") }}
                </option>
                <option value="ulderTypeMalignancy">
                  {{ trans("examination.maligantUlcer") }}
                </option>
                <option value="ulcerTypeOther">
                  {{ trans("examination.other") }}
                </option>
              </select2>
            </span>
            <span v-else class="lesionDiagnosis">{{
              trans("examination." + examination.case.ulcerType)
            }}</span>
          </p>
        </div>

        <div class="m-b-20 col-md-6">
          <strong>{{ trans("patient.patientVievWoundAnamnes") }}</strong>
          <p class="text-muted">
            {{ trans("examination.woundExistence") }}:
            <span v-if="isEditing">
              <select2
                v-model="examination.case.lesionExistence"
                class="select2 form-control responsive groups"
              >
                <option value="kLesionExistenceOneWeek">
                  {{ trans("examination.kLesionExistenceOneWeek") }}
                </option>
                <option value="kLesionExistenceTwoWeeks">
                  {{ trans("examination.kLesionExistenceTwoWeeks") }}
                </option>
                <option value="kLesionExistenceThreeWeeks">
                  {{ trans("examination.kLesionExistenceThreeWeeks") }}
                </option>
                <option value="kLesionExistenceFourWeeks">
                  {{ trans("examination.kLesionExistenceFourWeeks") }}
                </option>
                <option value="kLesionExistenceFiveWeeks">
                  {{ trans("examination.kLesionExistenceFiveWeeks") }}
                </option>
                <option value="kLesionExistenceSixWeeks">
                  {{ trans("examination.kLesionExistenceSixWeeks") }}
                </option>
                <option value="kLesionExistenceEightWeeks">
                  {{ trans("examination.kLesionExistenceEightWeeks") }}
                </option>
                <option value="kLesionExistenceNineWeeks">
                  {{ trans("examination.kLesionExistenceNineWeeks") }}
                </option>
                <option value="kLesionExistenceTenWeeks">
                  {{ trans("examination.kLesionExistenceTenWeeks") }}
                </option>
                <option value="kLesionExistenceElevenWeeks">
                  {{ trans("examination.kLesionExistenceElevenWeeks") }}
                </option>
                <option value="kLesionExistenceTwelveWeeks">
                  {{ trans("examination.kLesionExistenceTwelveWeeks") }}
                </option>
                <option value="kLesionExistenceFourMonths">
                  {{ trans("examination.kLesionExistenceFourMonths") }}
                </option>
                <option value="kLesionExistenceFiveMonths">
                  {{ trans("examination.kLesionExistenceFiveMonths") }}
                </option>
                <option value="kLesionExistenceSixMonths">
                  {{ trans("examination.kLesionExistenceSixMonths") }}
                </option>
                <option value="kLesionExistenceSevenMonths">
                  {{ trans("examination.kLesionExistenceSevenMonths") }}
                </option>
                <option value="kLesionExistenceEightMonths">
                  {{ trans("examination.kLesionExistenceEightMonths") }}
                </option>
                <option value="kLesionExistenceNineMonths">
                  {{ trans("examination.kLesionExistenceNineMonths") }}
                </option>
                <option value="kLesionExistenceTenMonths">
                  {{ trans("examination.kLesionExistenceTenMonths") }}
                </option>
                <option value="kLesionExistenceElevenMonths">
                  {{ trans("examination.kLesionExistenceElevenMonths") }}
                </option>
                <option value="kLesionExistenceOneYear">
                  {{ trans("examination.kLesionExistenceOneYear") }}
                </option>
                <option value="kLesionExistenceMore">
                  {{ trans("examination.kLesionExistenceMore") }}
                </option>
              </select2>
            </span>
            <span v-else class="lesionExistence">{{
              trans("examination." + examination.case.lesionExistence)
            }}</span>
            <br />
            {{ trans("patient.patientVievWoundTheOriginOfTheWound") }}:
            <span v-if="isEditing">
              <select2
                v-model="examination.case.woundOrigin"
                class="select2 form-control responsive groups"
              >
                <option value="spontaneous">
                  {{ trans("examination.spontaneous") }}
                </option>
                <option value="pressure">
                  {{ trans("examination.pressure") }}
                </option>
                <option value="traumaticInjury">
                  {{ trans("examination.traumaticInjury") }}
                </option>
              </select2>
            </span>
            <span v-else class="woundOrigin">{{
              trans("examination." + examination.case.woundOrigin)
            }}</span>

            <br />
            {{ trans("examination.antibioticsLatestSixMonths") }}:
            <span v-if="isEditing">
              <select2
                v-model="examination.case.antibioticsLatestSixMonths"
                class="select2 form-control responsive groups"
              >
                <option value="YES">{{ trans("examination.yes") }}</option>
                <option value="NO">{{ trans("examination.no") }}</option>
              </select2>
            </span>
            <span v-else class="antibioticsLatestSixMonths">{{
              examination.case.antibioticsLatestSixMonthsText
            }}</span>
          </p>
        </div>

        <div class="m-b-20 col-md-6">
          <strong>{{ trans("patient.patientVievWoundStatus") }}</strong>
          <p class="text-muted">
            {{ trans("patient.patientVievWoundInfection") }}:
            <span v-if="isEditing">
              <select2
                v-model="examination.case.signWoundInfection"
                class="select2 form-control responsive groups"
              >
                <option value="YES">{{ trans("examination.yes") }}</option>
                <option value="NO">{{ trans("examination.no") }}</option>
              </select2>
            </span>
            <span v-else class="signWoundInfection">{{
              trans("examination." + examination.case.signWoundInfection)
            }}</span>
            <br />

            {{ trans("patient.patientVievWoundSize") }}:
            <span class="lesionSize"
              >{{
                examination.case.lesionSizeLength
                  ? examination.case.lesionSizeLength + " x"
                  : ""
              }}
              {{ examination.case.lesionSizeWidth }}</span
            >
            <span v-if="isEditing">
              <br />
              {{ trans("examination.lesionSizeLength") }}:
              <input
                v-model="examination.case.lesionSizeLength"
                type="number"
                class="form-control"
              />
              {{ trans("examination.lesionSizeWidth") }}:
              <input
                v-model="examination.case.lesionSizeWidth"
                type="number"
                class="form-control"
              />
            </span>
            <span v-else class="ulcerSize"
              >{{ trans("examination." + examination.case.ulcerSize) }} <br
            /></span>

            {{ trans("patient.patientVievUlcerArea") }}:
            <span v-if="isEditing">
              <input
                v-model="examination.case.ulcerArea"
                type="text"
                class="form-control"
              />
            </span>
            <span v-else class="ulcerArea">{{
              examination.case.ulcerArea
            }}</span>
          </p>
        </div>

        <div class="m-b-20 col-md-6">
          <strong>{{ trans("examination.hasPain") }}</strong>
          <p class="text-muted">
            {{ trans("patient.patientViewWoundPainDayVas") }}:
            <span v-if="isEditing">
              <select2
                v-model="examination.case.vas"
                class="select2 form-control responsive groups"
              >
                <option v-for="index in 10" :key="index" :value="index">
                  {{ index }}
                </option>
              </select2>
            </span>
            <span v-else class="vas">{{ examination.case.vas }}</span>
            <br />
            {{ trans("examination.nocturnalPain") }}:
            <span v-if="isEditing">
              <select2
                v-model="examination.case.nocturnalPain"
                class="select2 form-control responsive groups"
              >
                <option value="YES">{{ trans("examination.yes") }}</option>
                <option value="NO">{{ trans("examination.no") }}</option>
              </select2>
            </span>
            <span v-else class="nocturnalPain">{{
              trans("examination." + examination.case.nocturnalPain)
            }}</span>
          </p>
        </div>

        <div class="m-b-20 col-md-6">
          <strong>{{ trans("patient.patientVievWoundTreatment") }}</strong>
          <p class="text-muted">
            {{ trans("examination.compressionTitle") }}:
            <span v-if="isEditing">
              <select2
                v-model="examination.case.compression"
                class="select2 form-control responsive groups"
              >
                <option value="compressionNone">
                  {{ trans("examination.compressionNone") }}
                </option>
                <option value="compressionSockPads">
                  {{ trans("examination.compressionSockPads") }}
                </option>
                <option value="compressionStockBoot">
                  {{ trans("examination.compressionStockBoot") }}
                </option>
              </select2>
            </span>
            <span v-else class="compression">{{
              trans("examination." + examination.case.compression)
            }}</span>
            <br />
            {{ trans("patient.patientViewWoundCcmpressionShiftFrequency") }}:
            <span v-if="isEditing">
              <select2
                v-model="examination.case.frequencyCare"
                class="select2 form-control responsive groups"
              >
                <option v-for="index in 7" :key="index" :value="index">
                  {{ index }}
                </option>
                <option value="moreThenSevenWeeks">
                  {{ trans("examination.moreThenSevenWeeks") }}
                </option>
              </select2>
            </span>
            <span v-else class="frequencyCare">{{
              examination.case.frequencyCare
            }}</span>
          </p>
        </div>
      </div>
    </div>

    <div v-else class="panel-body">
      {{ trans("general.emptyDataReuslt") }}
    </div>
  </div>
</template>

<script>
export default {
  props: {
    examination: {
      case: {
        ulcerType: String,
        lesionExistence: String,
        woundOrigin: String,
        antibioticsLatestSixMonths: String,
        signWoundInfection: String,
        lesionSizeLength: String,
        lesionSizeWidth: String,
        ulcerArea: String,
        vas: String,
        nocturnalPain: String,
        compression: String,
        frequencyCare: String,
      },
    },
    isEditing: Boolean,
  },
};
</script>
