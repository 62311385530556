<template>
  <div id="email" class="tab-pane">
    <div class="mb-3 advanced-search">
      <form @submit.prevent="getEmailLogs(1)">
        <div class="row mb-3">
          <div class="col-md-3">
            <label for="subject">(Part of) subject</label>
            <input
              id="subject"
              v-model="searchFields.subject"
              type="text"
              class="form-control input-lg"
              name="subject"
            />
          </div>

          <div class="col-md-3">
            <label for="body">(Part of) body</label>
            <input
              id="body"
              v-model="searchFields.body"
              type="text"
              class="form-control input-lg"
              name="body"
            />
          </div>

          <div class="col-md-3">
            <label for="to">(Part of) e-mail address</label>
            <input
              id="to"
              v-model="searchFields.to"
              type="text"
              class="form-control input-lg"
              name="to"
            />
          </div>

          <div class="col-md-3">
            <label for="username">Username</label>
            <input
              id="username"
              v-model="searchFields.username"
              type="text"
              class="form-control input-lg"
              name="username"
            />
          </div>

          <div class="col-md-3">
            <label>{{ trans("evaluation.tableColumnCreatedAtFrom") }}</label>
            <custom-datepicker
              :date="searchFields.dateFrom"
              :placeholder="trans('evaluation.tableColumnCreatedAtFrom')"
              @on-date-change="changeDates('dateFrom', $event)"
            />
          </div>

          <div class="col-md-3">
            <label>{{ trans("evaluation.tableColumnCreatedAtTo") }}</label>
            <custom-datepicker
              :date="searchFields.dateUntil"
              :placeholder="trans('evaluation.tableColumnCreatedAtTo')"
              @on-date-change="changeDates('dateUntil', $event)"
            />
          </div>

          <div class="col-md-6 pt-2 mt-3 text-right">
            <button
              v-if="isSearching"
              class="btn btn-danger mr-2"
              type="button"
              @click.stop="clearSearch"
            >
              <span>{{ trans("general.clearSearch") }}</span>
              <i class="fal fa-times" />
            </button>
            <button
              class="btn btn-primary"
              type="submit"
              @click.stop="getEmailLogs(1)"
            >
              <span>{{ trans("general.search") }}</span>
              <i class="far fa-search" />
            </button>
          </div>
        </div>
      </form>
    </div>

    <clip-loader v-if="loading" />

    <div class="table-responsive">
      <table class="table table-hover">
        <thead class="sortable-table-header">
          <tr>
            <th>#</th>
            <th>To</th>
            <th>User</th>
            <th>Subject</th>
            <th>Date</th>
            <th />
            <th />
          </tr>
        </thead>
        <tbody>
          <tr v-for="(log, index) in email.logs" :key="index">
            <td>{{ log.id }}</td>
            <td>{{ log.to }}</td>
            <td>{{ log.user }}</td>
            <td>{{ log.subject }}</td>
            <td>{{ log.created_at_formatted }}</td>
            <td>
              <a
                data-toggle="modal"
                data-target="#emailBody"
                aria-hidden="true"
                class="btn"
                @click="showEmailBody(log.body)"
                >Body</a
              >
            </td>
            <td>
              <a
                data-toggle="modal"
                data-target="#emailHeaders"
                aria-hidden="true"
                class="btn"
                @click="showEmailHeaders(log.headers)"
                >Headers</a
              >
            </td>
          </tr>
        </tbody>
      </table>
    </div>

    <div class="row-fluid pull-right">
      <DerPaginate
        v-model="paginate.current_page"
        :page-count="paginate.last_page"
        @change="getEmailLogs"
      />
    </div>

    <div
      id="emailBody"
      class="modal fade"
      tabindex="-1"
      role="dialog"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
        <div class="modal-content">
          <div class="modal-body" style="text-align: center">
            <iframe height="700" width="600" :srcdoc="emailBody" />
          </div>
        </div>
      </div>
    </div>

    <div
      id="emailHeaders"
      class="modal fade"
      tabindex="-1"
      role="dialog"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
        <div class="modal-content">
          <div class="modal-body">
            <span style="white-space: pre">{{ emailHeaders }}</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import DerPaginate from "../../../design-system/DerPaginate.vue";
import { queryStringBuilder } from "../../../helpers/misc";

export default {
  components: { DerPaginate },
  data() {
    return {
      loading: true,
      paginate: {
        current_page: 0,
        total: 0,
        last_page: 0,
      },
      email: {
        logs: [],
      },
      isSearching: false,
      searchFields: {
        subject: "",
        to: "",
        username: "",
        body: "",
        dateFrom: "",
        dateUntil: "",
        page: 1,
      },
      emailBody: "",
      emailHeaders: "",
    };
  },
  created: function () {
    this.getEmailLogs(1);
  },
  methods: {
    getEmailLogs(page) {
      this.loading = true;
      if (typeof page === "undefined") {
        page = 1;
      }
      this.searchFields.page = page ? page : this.searchFields.page;
      this.isSearchFieldsPopulated();
      axios
        .get(
          "/api/internal/admin/logs/email?" +
            queryStringBuilder(this.searchFields),
        )
        .then((data) => {
          const collection = data.data;
          this.paginate = collection.paginate;
          this.email.logs = collection.data;
          this.loading = false;
        })
        .catch((error) => {
          this.loading = false;
          this.userErrorMessage(error);
        });
    },
    showEmailBody: function (body) {
      this.emailBody = body;
    },
    showEmailHeaders: function (headers) {
      this.emailHeaders = headers;
    },
    clearSearch: function () {
      Object.assign(
        this.$data.searchFields,
        this.$options.data.call(this).searchFields,
      );
      this.isSearching = false;
      this.getEmailLogs(1);
    },
    changeDates: function (target, date) {
      if (target === "dateFrom") {
        this.searchFields.dateFrom = date;
      } else {
        this.searchFields.dateUntil = date;
      }
    },
    isSearchFieldsPopulated: function () {
      Object.keys(this.searchFields).forEach((key) => {
        if (key !== "page" && this.searchFields[key] !== "") {
          this.isSearching = true;
        }
      });
    },
  },
};
</script>
