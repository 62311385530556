<template>
  <div>
    <header-admin :active-section="3" />

    <clip-loader v-show="loading" />
    <div v-for="bucket in collection">
      <h3 class="my-3">
        {{ bucket.year }}
      </h3>
      <table class="table table-bordered my-2 table-actions-bar table-hover">
        <thead>
          <tr>
            <th>{{ trans("center.name") }}</th>
            <th>{{ trans("center.deletedAt.label") }}</th>
            <th v-for="month in months">
              {{ month }}
            </th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(center, index) in bucket.data" :key="index">
            <td>{{ center.centerName }}</td>
            <td>{{ center.deletedAt }}</td>
            <td v-for="monthEng in monthsEng">
              <b v-if="center[monthEng.toLowerCase()]">{{
                formatCounters(center[monthEng.toLowerCase()])
              }}</b>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
import HeaderAdmin from "./Header.vue";
import { reverse } from "lodash";

export default {
  components: {
    HeaderAdmin,
  },
  data() {
    return {
      loading: true,
      collection: [
        {
          yearTitle: {
            year: "",
            data: {},
          },
        },
      ],
      months: moment
        .localeData(localStorage.getItem("lang_code", "en"))
        .monthsShort(),
      monthsEng: moment.localeData("en").monthsShort(),
    };
  },
  created: function () {
    this.loading = true;
    axios.get("/api/internal/admin/analytics").then((data) => {
      this.parseData(data);
      this.loading = false;
    });
  },
  methods: {
    formatCounters(month) {
      return month
        ? `${month.EXAMINATIONS ?? ""} (${month.PATIENTS ?? ""})`
        : "";
    },
    parseData(data) {
      this.collection = [];
      let i = 0;
      for (let x in data.data) {
        this.collection[i] = data.data[x];
        i++;
      }
      this.collection.reverse();
    },
  },
};
</script>
