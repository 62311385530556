import "./bootstrap";
import { translator } from "./helpers/misc";
import router from "./routes";
import { store } from "./store";
import "jquery-cropbox";
import "./global";
import "query-string";
import bugsnag from "./bugsnag";
import breadcrumbs from "vue-3-breadcrumbs";

import { app } from "./vue-app";
import { practitionerHttpService } from "./app/practitioner-portal/store/practitioner-http-service";

app.config.globalProperties.trans = translator;

practitionerHttpService.interceptors.response.use(
  function (response) {
    return response;
  },
  function (error) {
    if (error.response?.status === 401) {
      return router.push({ name: "Logout" });
    }
    return Promise.reject(error);
  },
);

window.Vue = app;

app.use(store);
app.use(router);

// The following was needed for the vue 3 breadcrumbs plugin replacement, and we
// might remove this later if we replace that plugin with a better alternative.
app.config.globalProperties.$router = router;
app.use(breadcrumbs);

app.mount("#app");

/**
 * Initialize Bugsnag
 */
bugsnag(window.bugsnagConfig);
