<template>
  <AsyncState :data-state="studiesState">
    <h5 class="mb-3">
      <TitleSelect
        :data-items="studies"
        :data-item-display="(item) => item.name"
        :data-placeholder="lang.get('view.research.selectedStudy.placeholder')"
        :data-selected-item="selectedItem"
        @update:data-selected-item="onUpdateDataSelectedItem"
      />
    </h5>
    <ResearchView v-if="!!selectedItem" :data-research-id="selectedItem?.id" />
  </AsyncState>
</template>
<script setup lang="ts">
import ResearchView from "./ResearchView.vue";
import { useAsyncState } from "@vueuse/core";
import { userStudiesAsParticipantGet } from "../../store/core/users";
import AsyncState from "../../design-system/AsyncState.vue";
import { computed, ref, watch } from "vue";
import _find from "lodash/fp/find";
import { ROUTES, useRouteOf } from "../routing/routes";
import { useRouter } from "vue-router";
import { lang } from "../../i18n";
import TitleSelect from "../../design-system/TitleSelect.vue";
import { store } from "../../store";
import { StudySummary } from "../../../../../app/Dermicus/Studies/StudySummary";

const router = useRouter();
const route = useRouteOf<typeof ROUTES.research>();
const selectedId = ref<number>();
watch(
  () => route.query.studyId,
  (value) => (selectedId.value = parseInt(value ?? "1", 10)),
  { immediate: true },
);

const studiesState = useAsyncState(
  () => userStudiesAsParticipantGet(store.state.user.id),
  undefined,
);
const studies = computed(() => studiesState.state.value);
const selectedItem = computed(() =>
  _find({ id: selectedId.value }, studies.value),
);

function onUpdateDataSelectedItem(item: StudySummary) {
  router.replace({ query: { studyId: item.id.toString() } });
}
</script>
