<template>
  <Alert
    :data-variant="fromVariantErrorFeedback(feedback.variant)"
    :data-heading="feedback.heading"
  >
    {{ feedback.message }}
  </Alert>
</template>
<script setup lang="ts">
import Alert from "./Alert.vue";
import { errorFeedback } from "../lib/error-feedback";
import { fromVariantErrorFeedback } from "./data-variant";
import { computed, toRefs } from "vue";

const props = defineProps<{ dataError: unknown }>();
const { dataError } = toRefs(props);
const feedback = computed(() => errorFeedback(dataError.value));
</script>
