import { translator } from "./misc";
import Notification from "../components/Utils/notifications";

const platform = window.dermicusPlatform;

export const isSafari =
  /constructor/i.test(window.HTMLElement) ||
  (function (p) {
    return p.toString() === "[object SafariRemoteNotification]";
  })(
    !window["safari"] ||
      (typeof safari !== "undefined" && window["safari"].pushNotification),
  );

export const customDateFormatter = (date) => {
  if (date) {
    let isLongFormat = moment(date, window.dateFormat, true).isValid();
    return moment(date).format(
      isLongFormat ? window.dateFormat : shortDateFormat(),
    );
  } else {
    return date;
  }
};

export const shortDateFormat = () => {
  const dateFormat = window.shortDateFormat;

  switch (dateFormat) {
    case "Y-m-d":
      return "YYYY-MM-DD";
    case "d/m/Y":
      return "DD/MM/YYYY";
    default:
      return "YYYY-MM-DD";
  }
};

/**
 * Uses date-fns formats
 */
export const shortDateFormatDateFns = () => {
  const dateFormat = window.shortDateFormat;

  switch (dateFormat) {
    case "Y-m-d":
      return "yyyy-MM-dd";
    case "d/m/Y":
      return "dd/MM/yyyy";
    default:
      return "yyyy-MM-dd";
  }
};

export const getPlatformSpecificSupportUrl = (platform) => {
  let url;

  switch (platform) {
    case "stockholm":
      url = "https://gnosco.atlassian.net/servicedesk/customer/portal/9";
      break;
    case "skane":
      url = translator("support.skaneSupportPage");
      break;
    default:
      url = translator("support.euSupportPage");
      break;
  }
  return url;
};

export const getPlatformSpecificSupportImg = (platform) => {
  let imgPath;

  switch (platform) {
    case "stockholm":
      imgPath = "/images/StockholmSupportPage.png";
      break;
    case "skane":
      imgPath = "/images/SkaneSupportPage.png";
      break;
    default:
      imgPath = "/images/DermicusSupportPage.png";
      break;
  }
  return imgPath;
};

export const copyPatientId = (patientId) => {
  const swedenFormat = patientId.replace(/[\s-]+/g, "").slice(2);
  const formattedPatientId = platform === "uk" ? patientId : swedenFormat;

  navigator.clipboard.writeText(formattedPatientId);

  Notification.saveSuccessCustomText("general.notificationTextCopy");
};

export function yyyymmddFormatter(date) {
  var month = "" + (date.getMonth() + 1);
  var day = "" + date.getDate();
  var year = date.getFullYear();

  if (month.length < 2) month = "0" + month;
  if (day.length < 2) day = "0" + day;

  return [year, month, day].join("-");
}
