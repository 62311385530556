<template>
  <AvatarLogo
    :data-resource="regionAvatarResource"
    @avatar-upload="onAvatarUpload.mutate"
    @avatar-delete="onAvatarDelete.mutate"
  />
</template>
<script setup lang="ts">
// TODO: Move hookup up towards a "Frame" or "Route" component once we refactor the region settings.
//    For now, we hookup store resources and actions in here for ease of implementation of the whole region logo.

import { toRefs } from "vue";
import {
  useRegionAvatar,
  useRegionAvatarDelete,
  useRegionAvatarUpload,
} from "../../store/api/use-avatar-resource";
import AvatarLogo from "../AvatarLogo.vue";

const props = defineProps<{ dataRegionId: number }>();
const { dataRegionId } = toRefs(props);

const regionAvatarResource = useRegionAvatar(dataRegionId.value);
const onAvatarUpload = useRegionAvatarUpload();
const onAvatarDelete = useRegionAvatarDelete();
</script>
