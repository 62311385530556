<template>
  <UseStoreResource :data-resource="centerCanRequestStudyConsentResource">
    <AdminCenterConsentStudiesPanel
      :has-consent="hasConsent"
      :has-optimistic-consent="hasOptimisticConsent"
      @toggle-consent="handleConsentToggle"
    >
      <AdminCenterConnectedStudies :center-id="centerId" />
    </AdminCenterConsentStudiesPanel>
  </UseStoreResource>
</template>

<script setup lang="ts">
import {
  useCenterFeature,
  useCenterFeatureDisable,
  useCenterFeatureEnable,
} from "../../../../store/api/use-center-feature-resource";
import { CenterFeatureName } from "../../../../../../../app/Dermicus/Centers/CenterFeatureName";
import { computed } from "vue";
import UseStoreResource from "../../../../design-system/UseStoreResource.vue";
import AdminCenterConnectedStudies from "./AdminCenterConnectedStudies.vue";
import AdminCenterConsentStudiesPanel from "./AdminCenterConsentStudiesPanel.vue";
import { lang } from "../../../../i18n";
import VueSimpleAlert from "vue3-simple-alert";

const props = defineProps<{
  centerId: number;
}>();

const centerCanRequestStudyConsentResource = useCenterFeature(
  props.centerId,
  CenterFeatureName.REQUEST_STUDY_CONSENT,
);

const hasOptimisticConsent = computed(() => {
  return centerCanRequestStudyConsentResource.data.value?.active;
});

const hasConsent = computed(() => {
  return (
    centerCanRequestStudyConsentResource.data.value?.active &&
    !centerCanRequestStudyConsentResource.data.value?.optimistic
  );
});

const enableCenterFeature = useCenterFeatureEnable();
const disableCenterFeature = useCenterFeatureDisable();

function handleConsentToggle(value: boolean) {
  if (value === true) {
    enableCenterFeature.mutate({
      centerId: props.centerId,
      featureName: CenterFeatureName.REQUEST_STUDY_CONSENT,
    });
  } else {
    // @ts-ignore
    VueSimpleAlert.confirm(
      lang.get("admin.disableRequestStudyConsentConfirmation"),
      undefined,
      undefined,
      {
        confirmButtonText: lang.get("general.modalConfirmButton"),
        cancelButtonText: lang.get("general.modalCancelButton"),
      },
    ).then(() => {
      disableCenterFeature.mutate({
        centerId: props.centerId,
        featureName: CenterFeatureName.REQUEST_STUDY_CONSENT,
      });
    });
  }
}
</script>
