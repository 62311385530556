<template>
  <login-form-container>
    <!-- Send password reset  -->
    <div v-if="!resetToken" class="account-content mx-3">
      <form class="form-horizontal" @submit.prevent="sendPasswordReset">
        <div class="form-group">
          <div v-if="!userMessage" class="col-xs-12 my-4">
            <div>
              <label for="emailaddress">{{
                trans("auth.resetPasswordInputLabelEmail")
              }}</label>
              <input
                id="emailaddress"
                v-model="email"
                type="email"
                class="form-control"
                name="email"
                required
                autofocus
                :placeholder="trans('auth.emailConnectedToDermicus')"
              />
            </div>
          </div>
          <div v-else class="text-center m-5">
            {{ userMessage }}
          </div>
        </div>

        <div class="form-group account-btn text-center mt-1">
          <div v-if="!userMessage" class="col-xs-12">
            <clip-loader v-show="loading" />
            <button
              v-if="!loading"
              type="submit"
              class="btn btn-custom btn-block btn-lg"
            >
              {{ trans("passwords.resetPasswordBtnText") }}
            </button>
          </div>
          <div class="col-xs-12 mt-2">
            <router-link to="/auth" class="btn btn-grey btn-block btn-lg">
              {{ trans("auth.forgotPasswordBackButton") }}
            </router-link>
          </div>
        </div>
      </form>
    </div>

    <!-- Password reset form -->
    <div v-if="resetToken" class="account-content mx-3">
      <div v-if="!passwordChangeSuccessfully">
        <div class="text-center my-4">
          <p class="text-muted">
            {{ trans("auth.forgotPasswordResetLabel") }}
          </p>
        </div>

        <form class="form-horizontal" @submit.prevent="submitResetPassword">
          <div
            class="form-group col-xs-12"
            :class="{ 'has-error': errors.email }"
          >
            <label for="emailaddress">{{
              trans("auth.resetPasswordInputLabelEmail")
            }}</label>
            <input
              id="email"
              v-model="email"
              type="email"
              class="form-control"
              name="email"
              required
              autofocus
            />

            <form-error v-if="errors.email" :errors="errors.email" />
          </div>

          <div
            class="form-group col-xs-12"
            :class="{ 'has-error': errors.password }"
          >
            <label for="password">{{
              trans("auth.resetPasswordInputLabelPassword")
            }}</label>
            <input
              id="password"
              v-model="password"
              type="password"
              class="form-control"
              name="password"
              required
            />
            <small class="form-text text-muted">{{
              trans("auth.recommendedPasswordLength")
            }}</small>

            <form-error v-if="errors.password" :errors="errors.password" />
          </div>

          <div
            class="form-group col-xs-12"
            :class="{ 'has-error': errors.password_confirmation }"
          >
            <label for="password-confirm">{{
              trans("auth.resetPasswordInputLabelPasswordConfirm")
            }}</label>
            <input
              id="password-confirm"
              v-model="passwordConfirm"
              type="password"
              class="form-control"
              name="password_confirmation"
              required
            />

            <form-error
              v-if="errors.password_confirmation"
              :errors="errors.password_confirmation"
            />
          </div>

          <div class="form-group">
            <div class="col-xs-12">
              <clip-loader v-show="loading" />
              <button
                v-if="!loading"
                type="submit"
                class="btn btn-custom btn-block btn-lg"
              >
                {{ trans("auth.resetPasswordConfirmButton") }}
              </button>

              <router-link to="/auth" class="btn btn-grey btn-block btn-lg">
                {{ trans("auth.forgotPasswordBackButton") }}
              </router-link>
            </div>
          </div>
        </form>
      </div>

      <div v-if="passwordChangeSuccessfully" class="text-center m-5">
        {{ trans("auth.resetPasswordSuccess") }}
      </div>
    </div>
  </login-form-container>
</template>

<script>
import LoginFormContainer from "./LoginFormContainer.vue";
import Notifications from "../Utils/notifications";

export default {
  components: {
    LoginFormContainer,
  },
  data: function () {
    return {
      loading: false,
      email: "",
      password: "",
      passwordConfirm: "",
      resetToken: this.$route.params.token,
      passwordChangeSuccessfully: false,
      userMessage: null,
      errors: {},
    };
  },
  methods: {
    sendPasswordReset: function () {
      this.loading = true;

      axios
        .post("/api/internal/auth/password/reset", {
          email: this.email,
          lang_code: localStorage.getItem("lang_code", "en"),
        })
        .then((response) => {
          this.loading = false;
          this.userMessage = response.data.email;
          Notifications.saveSuccess();
        })
        .catch((error) => {
          this.loading = false;
          this.userErrorMessage(error);
        });
    },
    submitResetPassword: function () {
      this.loading = true;
      this.errors = [];

      axios
        .post("/api/internal/auth/password/reset/" + this.resetToken, {
          email: this.email,
          password: this.password,
          password_confirmation: this.passwordConfirm,
          token: this.resetToken,
          lang_code: localStorage.getItem("lang_code", "en"),
        })
        .then(() => {
          this.loading = false;
          this.passwordChangeSuccessfully = true;
          Notifications.saveSuccess();
          setTimeout(() => this.$router.push({ name: "Auth" }), 3000);
        })
        .catch((error) => {
          this.loading = false;
          this.errors = error.response.data;
          this.userErrorMessage(error);
        });
    },
  },
};
</script>
