<template>
  <div>
    <strong v-if="location === 'general-info'">{{
      trans("examination.aiPrioritizedCaseLabel")
    }}</strong>
    <div>
      <span
        class="badge badge-danger ai-prioritization-icon"
        :title="trans('general.caseAiPrioritizedTitle')"
        >AI</span
      >
    </div>
  </div>
</template>

<script>
export default {
  props: {
    location: String,
  },
};
</script>

<style lang="scss" scoped>
.badge-danger.ai-prioritization-icon {
  background-color: #b92844 !important;
  font-size: 75% !important;
  font-weight: normal;
  padding-left: 0.3em;
}
</style>
