<template>
  <div>
    <div class="form-group">
      <label :for="exportTypeInputId">
        {{ trans("forms.exportTypeLabel") }}
      </label>
      <der-select
        :id="exportTypeInputId"
        v-model="selectedExportType"
        :data-placeholder="trans('forms.chooseExportPlaceholder')"
        :data-options="dataAvailableExports"
        :data-get-option-value="(option) => option.type"
        :data-get-option-label="(option) => option.name"
        :clearable="false"
      />
    </div>
    <component
      :is="selectedExport.form"
      v-if="!!selectedExport"
      v-bind="selectedExport.props"
      :data-form-errors="dataFormErrors"
      @submit.prevent="onSubmit"
    >
      <div class="text-right">
        <input type="hidden" name="type" :value="selectedExport.type" />
        <Button>{{ trans("center.exportToExcel") }}</Button>
      </div>
    </component>
  </div>
</template>
<script setup>
import _find from "lodash/fp/find";
import { computed, ref, toRefs } from "vue";
import { v4 as uuidV4 } from "uuid";
import Button from "../../../../components/Layout/Button.vue";
import DerSelect from "../../../../components/Utils/DerSelect.vue";

const exportTypeInputId = uuidV4();
const selectedExportType = ref();

const props = defineProps({
  dataAvailableExports: {
    type: /** @type {Array<{ 
      type: string;
      name: string;
      form: import("vue").Component;
      props?: Record<string, unknown>;
    }>} */ Array,
    required: true,
  },
  dataFormErrors: {
    type: /** @type {Record<string, string|string[]>} */ Object,
  },
});
const { dataAvailableExports } = toRefs(props);

const selectedExport = computed(() =>
  _find({ type: selectedExportType.value }, dataAvailableExports.value),
);

const emit = defineEmits({
  exportData: () => {},
});
const onSubmit = ($event) => emit("exportData", new FormData($event.target));
</script>
