<template>
  <div>
    <strong v-if="location === 'general-info'">{{
      trans("examination.bookmarkLabel")
    }}</strong>

    <div class="text-muted">
      <i class="fas fa-star" :title="trans('general.caseBookmarkedTitle')" />
    </div>
  </div>
</template>

<script>
export default {
  props: {
    location: String,
  },
};
</script>
