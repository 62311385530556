<template>
  <div>
    <strong>{{ title }}</strong>
    <br />
    <p class="text-muted">
      {{ displayValue }}
    </p>
  </div>
</template>

<script>
export default {
  props: {
    title: String,
    /**
     * @type {Record<string, { value: any, comment?: string }>}
     */
    data: [Object, Array, String],
  },
  data() {
    return {
      displayValue: "",
    };
  },
  mounted: function () {
    this.mapData();
  },
  methods: {
    mapData: function () {
      let result = "";

      if (this.data && this.data.length !== 0) {
        Object.keys(this.data).forEach((val) => {
          if (this.data[val].value) {
            result += result === "" ? val : `, ${val}`;

            if (this.data[val].comment) {
              result += ` (${this.data[val].comment})`;
            }
          }
        });
      } else {
        result = "-";
      }

      this.displayValue = result;
    },
  },
};
</script>

<style lang="scss" scoped>
#hash-para {
  display: inline-block;
}
</style>
