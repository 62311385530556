import _isString from "lodash/fp/isString";
import { patientAuthService } from "./patient-auth-service";
import { locationOf, ROUTES } from "../../routing/routes";
import { NavigationGuard } from "vue-router";

/**
 * Confirms the patient login, and navigates to requested page.
 *
 * When the access token is not given, we don't do the actual log in, to avoid
 * needing to throw an error. The redirection will still happen, and thus we'll
 * end up at the login page again, which is good, as it shouldn't happen.
 */
export const patientLoginConfirm: NavigationGuard = (to, from, next) => {
  const accessToken = to.query.accessToken;
  if (_isString(accessToken)) {
    patientAuthService.loginConfirm({ accessToken });
  }

  const callbackUrl = to.query.callbackUrl;
  if (_isString(callbackUrl)) {
    next(callbackUrl);
  } else {
    next(locationOf(ROUTES.patient, {}));
  }
};
