import { onMounted, onUnmounted, Ref } from "vue";
import { onBeforeRouteLeave, onBeforeRouteUpdate } from "vue-router";
import { lang } from "../i18n";

export function useAlertUnsavedChanges(isDirty: Ref<boolean>) {
  onBeforeRouteUpdate(alertUserNavigation);
  onBeforeRouteLeave(alertUserNavigation);

  function alertUserNavigation() {
    // If not dirty, do nothing
    if (!isDirty.value) return true;

    /**
     * If dirty, show a confirmation dialog.
     * Using a regular `window.confirm` dialog, so that the behavior is consistent
     * with the `beforeUnload` alert.
     */
    const answer = window.confirm(lang.get("alert.unsaved_changes"));

    if (!answer) return false;
  }

  const alertUserUnload = (e: BeforeUnloadEvent) => {
    // If not dirty, do nothing
    if (!isDirty.value) return;

    /**
     * If dirty, show a confirmation dialog.
     * @see {@link https://developer.mozilla.org/en-US/docs/Web/API/Window/beforeunload_event}
     */
    e.preventDefault();
    e.returnValue = "";
  };

  onMounted(() => window.addEventListener("beforeunload", alertUserUnload));
  onUnmounted(() =>
    window.removeEventListener("beforeunload", alertUserUnload),
  );
}
