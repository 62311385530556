<template>
  <div class="tab-content">
    <users-list users-type="active" />
  </div>
</template>

<script>
import UsersList from "./UsersList.vue";

export default {
  components: {
    UsersList,
  },
};
</script>
