<template>
  <Badge :value="dataValue" :pt="preset" />
</template>
<script setup lang="ts">
import Badge from "primevue/badge";
import {
  classValuesFor,
  IntentVariant,
  StatusVariant,
  VisualVariant,
} from "./tokens/variants";
import { computed, toRefs } from "vue";
import { FixedProps } from "../lib-vue/fixed-props";
import { cva } from "class-variance-authority";

/**
 * A badge is used as a status indicator for another element.
 */
defineOptions({});

type DerBadgeVariant = IntentVariant | StatusVariant | VisualVariant;

const props = withDefaults(
  defineProps<{
    dataValue: string;
    dataVariant?: DerBadgeVariant;
  }>(),
  { dataVariant: "light" },
);

const { dataVariant } = toRefs(props as FixedProps<typeof props>);

/**
 * @see {@link https://tailwind.primevue.org/badge/#presets.wind}
 */
const rootClass = cva(
  [
    "tw-font-medium",
    "tw-text-xs tw-leading-[1.5rem]",
    "tw-text-center tw-inline-block",
    "tw-p-0 tw-px-2",
    "tw-min-w-[1.5rem] tw-h-[1.5rem]",
    "tw-rounded-full",
  ],
  {
    variants: {
      dataVariant: classValuesFor<DerBadgeVariant>({
        primary: ["tw-bg-primary", "tw-text-white"],
        secondary: ["tw-bg-secondary", "tw-text-white"],
        success: ["tw-bg-success", "tw-text-white"],
        info: ["tw-bg-info", "tw-text-white"],
        warning: ["tw-bg-warning", "tw-text-white"],
        danger: ["tw-bg-danger", "tw-text-white"],
        light: "tw-bg-light",
        dark: ["tw-bg-dark", "tw-text-white"],
      }),
    },
  },
);
const preset = computed(() => ({
  root: rootClass({ dataVariant: dataVariant.value }),
}));
</script>
