<template>
  <text-with-label :title="title" :data="value" />
</template>

<script>
import { Constants } from "../../../../../helpers";
import TextWithLabel from "./TextWithLabel.vue";
import { sanitizeAndConvertFieldValueStringToFloat } from "../../../../../helpers/misc";
export default {
  components: { TextWithLabel },
  props: {
    title: String,
    data: [String, Number],
    extraOptions: {
      valueOptions: Array,
      examination: Object,
    },
  },
  computed: {
    value() {
      const { extraOptions } = this;
      if (
        extraOptions.valueOptions &&
        Array.isArray(extraOptions.valueOptions)
      ) {
        const { valueOptions, examination } = this.extraOptions;
        if (
          examination &&
          valueOptions.length === 1 &&
          Constants.WIDTH_FIELD in valueOptions[0] &&
          Constants.LENGTH_FIELD in valueOptions[0]
        ) {
          const widthField = valueOptions[0][Constants.WIDTH_FIELD];
          const lengthField = valueOptions[0][Constants.LENGTH_FIELD];
          if (examination[widthField] && examination[lengthField]) {
            const area =
              sanitizeAndConvertFieldValueStringToFloat(
                examination[widthField],
              ) *
              sanitizeAndConvertFieldValueStringToFloat(
                examination[lengthField],
              );
            const val = Math.round(area * 100) / 100;
            const postFix =
              Constants.POSTFIX in valueOptions[0]
                ? valueOptions[0][Constants.POSTFIX]
                : "";
            return `${val} ${postFix}`;
          }
        }
      }
    },
  },
};
</script>
