<template>
  <div id="mole" class="col-lg-12">
    <center-header :selected-menu-item="3" />

    <div class="tab-content">
      <div class="text-right mb-2">
        <router-link
          :to="{
            name: `New${assignUrlandCenterId.adminType}CenterGroup`,
          }"
          class="btn btn-primary"
        >
          {{ trans("center.addNewGroupBtn") }}
        </router-link>
      </div>

      <div class="table-responsive">
        <table class="table table-hover interactive-table">
          <thead class="sortable-table-header">
            <tr>
              <th>{{ trans("center.groupTableGroupName") }}</th>
              <th>{{ trans("center.groupTableConnectedUsers") }}</th>
            </tr>
          </thead>
          <tbody v-if="!loading">
            <tr
              v-for="(group, index) in groups"
              :key="index"
              @click="openGroup(group)"
            >
              <td>{{ group.name }}</td>
              <td>{{ group.totalUsers }}</td>
            </tr>
          </tbody>
        </table>
      </div>
      <clip-loader v-if="loading" />

      <div class="row-fluid float-right">
        <DerPaginate
          v-model="paginate.current_page"
          :page-count="paginate.last_page"
          @change="getGroups"
        />
      </div>
    </div>
  </div>
</template>

<script>
import CenterHeader from "../CenterHeader.vue";
import DerPaginate from "../../../design-system/DerPaginate.vue";

export default {
  components: {
    CenterHeader,
    DerPaginate,
  },
  data() {
    return {
      loading: false,
      groups: [],
      paginate: {
        current_page: 0,
        last_page: 0,
      },
    };
  },
  computed: {
    assignUrlandCenterId: function () {
      if (
        this.$can("manage_region") &&
        this.$store.state.user.primaryCenter.region_id &&
        (!this.$can("manage_center") || this.$route.path.includes("region"))
      ) {
        return {
          centerId: this.$route.params.id,
          url: "/api/internal/region-admin",
          adminType: "Region",
        };
      } else {
        return {
          centerId: this.$store.state.user.primaryCenter.id,
          url: "/api/internal/center-admin",
          adminType: "",
        };
      }
    },
  },
  created: function () {
    this.getGroups();
  },
  methods: {
    getGroups(page) {
      this.loading = true;

      axios
        .get(
          `${this.assignUrlandCenterId.url}/groups/${this.assignUrlandCenterId.centerId}`,
          { params: { page } },
        )
        .then((data) => {
          let collection = data.data;
          this.paginate = collection.paginate;
          this.groups = collection.data;
          this.loading = false;
        })
        .catch((error) => {
          this.userErrorMessage(error);
          this.loading = false;
        });
    },
    openGroup: function (group) {
      this.$router.push({
        name: `Edit${this.assignUrlandCenterId.adminType}CenterGroup`,
        params: {
          groupId: group.id,
          id: this.assignUrlandCenterId.centerId,
        },
      });
    },
  },
};
</script>
