<template>
  <Dialog
    :visible="visible"
    modal
    :pt="preset"
    :header="header"
    :dismissable-mask="dismissableMask"
    :draggable="false"
    @update:visible="$emit('update:visible', $event)"
  >
    <slot />
  </Dialog>
</template>

<script setup lang="ts">
import Dialog, { DialogPassThroughMethodOptions } from "primevue/dialog";

const props = defineProps<{
  visible: boolean;
  header?: string;
  dismissableMask?: boolean;
}>();

type DialogOptions = DialogPassThroughMethodOptions<typeof props>;

const preset = {
  root: ({ state }: DialogOptions) => ({
    class: [
      "tw-rounded-lg",
      "tw-shadow-xl",
      "tw-border-0",
      "tw-max-h-[90vh]",
      { "sm:tw-w-full sm:tw-max-w-lg": !state.maximized },
      "tw-m-0",
      "tw-transform",
      "tw-scale-100",
      {
        "tw-transition-none": state.maximized,
        "tw-transform-none": state.maximized,
        "!tw-w-screen": state.maximized,
        "!tw-h-screen": state.maximized,
        "!tw-max-h-full": state.maximized,
        "!tw-top-0": state.maximized,
        "!tw-left-0": state.maximized,
      },
    ],
  }),
  header: {
    class: [
      "tw-flex tw-items-center tw-justify-between",
      "tw-shrink-0",
      "tw-p-6 tw-pt-4",
      "tw-rounded-tl-lg",
      "tw-rounded-tr-lg",
      "tw-bg-surface-0 ",
      "tw-text-surface-700 ",
    ],
  },
  title: {
    class: ["tw-font-semibold tw-text-base tw-leading-6"],
  },
  icons: {
    class: ["tw-flex tw-items-center"],
  },
  closeButton: {
    class: [
      "tw-relative",
      "tw-flex tw-items-center tw-justify-center",
      "tw-mr-2",
      "last:tw-mr-0",
      "tw-w-6 tw-h-6",
      "tw-border-0",
      "tw-rounded-full",
      "tw-text-surface-500",
      "tw-bg-transparent",
      "tw-transition tw-duration-200 tw-ease-in-out",
      "hover:tw-text-surface-700 ",
      "hover:tw-bg-surface-100 ",
      "focus:tw-outline-none focus:tw-outline-offset-0 focus:tw-ring-1 focus:tw-ring-inset",
      "focus:tw-ring-primary-500 ",
      "tw-overflow-hidden",
    ],
  },
  maximizableButton: {
    class: [
      "tw-relative",
      "tw-flex tw-items-center tw-justify-center",
      "tw-mr-2",
      "last:tw-mr-0",
      "tw-w-6 tw-h-6",
      "tw-border-0",
      "tw-rounded-full",
      "tw-text-surface-500",
      "tw-bg-transparent",
      "tw-transition tw-duration-200 tw-ease-in-out",
      "hover:tw-text-surface-700 ",
      "hover:tw-bg-surface-100 ",
      "focus:tw-outline-none focus:tw-outline-offset-0 focus:tw-ring-1 focus:tw-ring-inset",
      "focus:tw-ring-primary-500 ",
      "tw-overflow-hidden",
    ],
  },
  closeButtonIcon: {
    class: ["tw-inline-block", "tw-w-3", "tw-h-3"],
  },
  maximizableIcon: {
    class: ["tw-inline-block", "tw-w-3", "tw-h-3"],
  },
  content: ({ state, instance }: DialogOptions) => ({
    class: [
      "tw-text-sm",
      "tw-px-6",
      "tw-pb-3",
      "tw-pt-0",
      {
        "tw-grow": state.maximized,
        "tw-rounded-bl-lg": !instance.$slots.footer,
        "tw-rounded-br-lg": !instance.$slots.footer,
      },
      "tw-bg-surface-0 ",
      "tw-text-surface-600 ",
      "tw-overflow-y-auto",
    ],
  }),
  footer: {
    class: [
      "tw-flex tw-items-center tw-justify-end",
      "tw-shrink-0",
      "tw-text-right",
      "tw-gap-3",
      "tw-px-6",
      "tw-py-3",
      "tw-border-t-0",
      "tw-rounded-b-lg",
      "tw-bg-surface-50 ",
      "tw-text-surface-700 ",
    ],
  },
  mask: ({ props }: DialogOptions) => ({
    class: [
      "tw-transition",
      "tw-duration-300",
      { "tw-p-5": props.position !== "center" },
      {
        "has-[.mask-active]:tw-bg-transparent  tw-bg-surface-500/70 ":
          props.modal,
        "has-[.mask-active]:tw-backdrop-blur-none tw-backdrop-blur-sm":
          props.modal,
      },
    ],
  }),
  transition: ({ props }: DialogOptions) =>
    props.position === "top"
      ? {
          enterFromClass:
            "tw-opacity-0 tw-scale-75 tw-translate-x-0 -tw-translate-y-full tw-translate-z-0 mask-active",
          enterActiveClass: "tw-transition-all tw-duration-200 tw-ease-out",
          leaveActiveClass: "tw-transition-all tw-duration-200 tw-ease-out",
          leaveToClass:
            "tw-opacity-0 tw-scale-75 tw-translate-x-0 -tw-translate-y-full tw-translate-z-0 mask-active",
        }
      : props.position === "bottom"
        ? {
            enterFromClass:
              "tw-opacity-0 tw-scale-75 tw-translate-y-full mask-active",
            enterActiveClass: "tw-transition-all tw-duration-200 tw-ease-out",
            leaveActiveClass: "tw-transition-all tw-duration-200 tw-ease-out",
            leaveToClass:
              "tw-opacity-0 tw-scale-75 tw-translate-x-0 tw-translate-y-full tw-translate-z-0 mask-active",
          }
        : props.position === "left" ||
            props.position === "topleft" ||
            props.position === "bottomleft"
          ? {
              enterFromClass:
                "tw-opacity-0 tw-scale-75 -tw-translate-x-full tw-translate-y-0 tw-translate-z-0 mask-active",
              enterActiveClass: "tw-transition-all tw-duration-200 tw-ease-out",
              leaveActiveClass: "tw-transition-all tw-duration-200 tw-ease-out",
              leaveToClass:
                "tw-opacity-0 tw-scale-75 -tw-translate-x-full tw-translate-y-0 tw-translate-z-0 mask-active",
            }
          : props.position === "right" ||
              props.position === "topright" ||
              props.position === "bottomright"
            ? {
                enterFromClass:
                  "tw-opacity-0 tw-scale-75 tw-translate-x-full tw-translate-y-0 tw-translate-z-0 mask-active",
                enterActiveClass:
                  "tw-transition-all tw-duration-200 tw-ease-out",
                leaveActiveClass:
                  "tw-transition-all tw-duration-200 tw-ease-out",
                leaveToClass:
                  "tw-opacity-0 tw-scale-75 tw-opacity-0 tw-scale-75 tw-translate-x-full tw-translate-y-0 tw-translate-z-0 mask-active",
              }
            : {
                enterFromClass: "tw-opacity-0 tw-scale-75 mask-active",
                enterActiveClass:
                  "tw-transition-all tw-duration-200 tw-ease-out",
                leaveActiveClass:
                  "tw-transition-all tw-duration-200 tw-ease-out",
                leaveToClass: "tw-opacity-0 tw-scale-75 mask-active",
              },
};
</script>
