import { avatarGet } from "./avatar";
import { practitionerHttpService } from "../../app/practitioner-portal/store/practitioner-http-service";
import { RegionReportOptions } from "../../../../../app/Dermicus/Reporting/RegionReportOptions";

const regionUri = (regionId: number) => `/api/internal/regions/${regionId}`;

export function regionAvatarGet(regionId: number) {
  return avatarGet(`${regionUri(regionId)}/logo`);
}

export function regionReportOptionsGet(regionId: number) {
  return practitionerHttpService
    .get<RegionReportOptions>(`${regionUri(regionId)}/report-options`)
    .then((response) => response.data);
}

export function regionReportOptionsUpdate({
  region_id,
  ...data
}: RegionReportOptions) {
  return practitionerHttpService
    .put<RegionReportOptions>(`${regionUri(region_id)}/report-options`, data)
    .then((response) => response.data);
}
