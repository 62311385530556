<template>
  <ResearchAdminParticipantsList :key="studyId" />
</template>
<script setup lang="ts">
import { ref, watch } from "vue";
import { ROUTES, useRouteOf } from "../routing/routes";
import ResearchAdminParticipantsList from "../../components/Research/ResearchAdmin/ResearchAdminParticipantsList.vue";

const route = useRouteOf<(typeof ROUTES)["researchAdmin.studyParticipants"]>();
/**
 * @todo Remove force re-render once old component uses best-practices on data loading!
 */
const studyId = ref<string>();
watch(
  () => route.params.studyId,
  (value) => (studyId.value = value),
  { immediate: true },
);
</script>
