<template>
  <div class="tw-space-x-2 xl:tw-pr-20">
    <Button
      :disabled="isFirstIndex"
      :is-loading="pageLoading === 'previous'"
      @click="handleNavigation('previous', prevIndex)"
    >
      {{ lang.get("pagination.previous") }}
    </Button>
    <Button
      type="button"
      :disabled="isLastIndex"
      :is-loading="pageLoading === 'next'"
      @click="handleNavigation('next', nextIndex)"
    >
      {{ lang.get("pagination.next") }}
    </Button>
  </div>
</template>

<script setup lang="ts">
import { useMutation } from "@tanstack/vue-query";
import { computed } from "vue";
import { useRouter } from "vue-router";
import { lang } from "../../../../../i18n";
import {
  HistoryState,
  setHistoryState,
} from "../../../../../lib/history-state";
import { examinationsGet } from "../../../../../store/core/examinations";
import Button from "../../../../Layout/Button.vue";

export type ExaminationNavigationDetails = {
  id: string;
  editUrl: string;
}[];

const props = defineProps<{
  dataExaminationId: string;
  dataExaminationNavigation: {
    examinations: ExaminationNavigationDetails;
    paginationInfo: {
      current_page: number;
      last_page: number;
    };
  };
}>();

const router = useRouter();

const currentIndex = computed(() => {
  return props.dataExaminationNavigation.examinations.findIndex(
    (examination) => examination.id === props.dataExaminationId,
  );
});

const prevIndex = computed(() => {
  const prevIndex = currentIndex.value - 1;
  return prevIndex >= 0 ? prevIndex : null;
});

const nextIndex = computed(() => {
  const nextIndex = currentIndex.value + 1;
  return nextIndex < props.dataExaminationNavigation.examinations.length
    ? nextIndex
    : null;
});

const isFirstIndex = computed(() => {
  return (
    currentIndex.value === 0 &&
    props.dataExaminationNavigation.paginationInfo.current_page === 1
  );
});

const isLastIndex = computed(() => {
  return (
    currentIndex.value ===
      props.dataExaminationNavigation.examinations.length - 1 &&
    props.dataExaminationNavigation.paginationInfo.current_page ===
      props.dataExaminationNavigation.paginationInfo.last_page
  );
});

const useExaminations = useMutation({
  mutationFn: ({ filters }: { filters: Record<string, string | number> }) =>
    examinationsGet(filters),
});

const pageLoading = computed(() => {
  const page = useExaminations.variables.value?.filters.page;
  if (page) {
    return (
      useExaminations.isLoading.value &&
      (page < props.dataExaminationNavigation.paginationInfo.current_page
        ? "previous"
        : "next")
    );
  }
});

function navigate(
  url: string,
  state: HistoryState["examinationNavigationDetails"],
) {
  router.push({
    path: `/consultations${url}`,
    state: setHistoryState("examinationNavigationDetails", state),
  });
}

function handleNavigation(
  direction: "previous" | "next",
  index: number | null,
) {
  if (index !== null) {
    navigate(
      props.dataExaminationNavigation.examinations[index].editUrl,
      props.dataExaminationNavigation,
    );
  } else {
    const paginate = props.dataExaminationNavigation.paginationInfo;
    if (
      (direction === "previous" && paginate.current_page > 1) ||
      (direction === "next" && paginate.current_page < paginate.last_page)
    ) {
      const filters = localStorage.getItem("last_query_uri");
      if (filters) {
        const parsedFilters = JSON.parse(filters);
        parsedFilters.page =
          direction === "previous"
            ? paginate.current_page - 1
            : paginate.current_page + 1;
        useExaminations.mutate(
          { filters: parsedFilters },
          {
            onSuccess(data) {
              const pathIndex =
                direction === "previous" ? data.data.length - 1 : 0;
              navigate(data.data[pathIndex].editUrl, {
                examinations: data.data.map((examination) => ({
                  id: examination.id,
                  editUrl: examination.editUrl,
                })),
                paginationInfo: data.paginate,
              });
            },
          },
        );
      }
    }
  }
}
</script>
