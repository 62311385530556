import { useMutation, useQuery } from "@tanstack/vue-query";
import { computed } from "vue";
import {
  examinationAttachmentDownload,
  examinationAttachmentsGet,
} from "../core/examinations";

export function useExaminationAttachments(examinationId: number) {
  const examinationAttachmentsResource = useQuery(
    computed(() => examinationAttachmentsGet(examinationId)),
  );
  return examinationAttachmentsResource;
}

export function useExaminationAttachmentDownload() {
  const download = useMutation({
    mutationFn: ({
      examinationId,
      attachmentId,
    }: {
      examinationId: number;
      attachmentId: number;
    }) => examinationAttachmentDownload(examinationId, attachmentId),
  });

  return download;
}
