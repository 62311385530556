<template>
  <div class="tab-content mt-4">
    <div class="mt-4">
      <h5 class="text-center text-uppercase">Kontakta oss</h5>
      <p class="text-center w-75 m-auto">
        Har du frågor om tjänsten kontakta vår funktionsbrevlåda, för felanmälan
        och teknisk support vänligen ring supporten.
      </p>
    </div>
    <section class="contact my-3 pt-5">
      <div class="container">
        <div class="row text-center">
          <div class="col-md-6">
            <div class="card border-0">
              <div class="card-body text-center">
                <i class="fas fa-phone fa-2x mb-3" aria-hidden="true" />
                <h6 class="text-uppercase mb-3">
                  Ring oss för felanmälan och teknisk support
                </h6>
                <p>+46(0)776730000</p>
              </div>
            </div>
          </div>
          <div class="col-md-6">
            <div class="card border-0">
              <div class="card-body text-center">
                <i class="fas fa-headset fa-2x mb-3" aria-hidden="true" />
                <h6 class="text-uppercase mb-3">
                  För frågor kring teledermatoskopitjänsten, hur den används
                  samt ej akuta fel maila till
                </h6>
                <p>
                  <a href="mailto:teledermatoskopi.hud.sus@skane.se"
                    >teledermatoskopi.hud.sus@skane.se</a
                  >
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

    <section class="gnosco-contact-info py-5">
      <div class="row text-center mx-3">
        <div class="col-md-4 col-lg-6 mb-3">
          <img
            src="/images/dermicus-logo.svg"
            alt=""
            height="25"
            class="mb-3"
          />
          <p>
            {{ trans("support.gnoscoSupportDescription") }}
          </p>
        </div>

        <div v-if="appConfig" class="col-md-4 col-lg-6">
          <h6 class="mb-3">
            {{ trans("support.contactUs") }}
          </h6>
          <div>
            <div>{{ appConfig.company.support.phone }}</div>
            <a :href="`mailto:${appConfig.company.support.email}`">
              {{ appConfig.company.support.email }}
            </a>
            <p class="mt-2">
              {{ trans("support.gnoscoGothenburgAddress") }}
            </p>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>
<script setup>
import { useAsyncState } from "@vueuse/core";
import { appConfigGet } from "../../../app/practitioner-portal/store/app-config";

const { state: appConfig } = useAsyncState(
  appConfigGet().then((response) => response.data),
  undefined,
);
</script>
<script>
import HeaderSupport from "../Header.vue";

export default {
  components: {
    HeaderSupport,
  },
};
</script>

<style lang="scss" scoped>
@use "/resources/assets/sass/abstracts/variables";

.card:hover i,
.card:hover h5 {
  color: variables.$dermicusBlue;
  transition: all 0.3s;
}

.tab-content {
  min-height: 90vh;
  display: flex;
  flex-direction: column;
}

.gnosco-contact-info {
  border-top: 1px solid #dee2e6;
  background-color: #f5f5f5;
  margin-top: auto;
  margin-right: -20px;
  margin-left: -20px;
  margin-bottom: -20px;
}
</style>
