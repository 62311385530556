<template>
  <div>
    <centers-list center-type="active" />
  </div>
</template>

<script>
import CentersList from "./CentersList.vue";

export default {
  components: {
    CentersList,
  },
};
</script>
