<template>
  <div class="tab-content">
    <div v-if="!isAddingStudyParticipant" class="text-right">
      <button class="btn btn-primary" @click="toggleAddingStudyParticipant">
        {{ trans("aiFeatures.addParticipantToResearchToggleBtn") }}
      </button>
    </div>

    <add-resource-panel
      v-if="isAddingStudyParticipant"
      resource-type="participants"
      :loading="loading"
      :available-resources="potentialStudyParticipants"
      :add-resource-panel-title="
        trans('aiFeatures.addParticipantToResearchPanelHeader')
      "
      :add-resource-button-text="
        trans('aiFeatures.addParticipantToResearchBtn')
      "
      @add-resource="addParticipantToStudy"
      @cancel-adding-resource="onCancel"
    />

    <div class="study-participants-list-container">
      <div
        v-if="
          !loading &&
          studyParticipantsList &&
          studyParticipantsList.length === 0
        "
        class="overlay"
      >
        <div class="overlay-content">
          {{ trans("aiFeatures.noStudyParticipantsInfoText") }}
        </div>
      </div>

      <div class="mt-3 study-participants-list">
        <div v-if="loading" class="overlay">
          <clip-loader />
        </div>
        <div class="table-responsive">
          <table class="table table-hover table-actions-bar">
            <thead class="sortable-table-header">
              <tr>
                <th>{{ trans("forms.fullName") }}</th>
                <th>{{ trans("forms.email") }}</th>
                <th>{{ trans("forms.phone") }}</th>
                <th>{{ trans("evaluation.tableColumnCenter") }}</th>
                <th />
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="(participant, index) in studyParticipantsList"
                :key="index"
              >
                <td>{{ participant.full_name }}</td>
                <td>{{ participant.email }}</td>
                <td>{{ participant.phone_no }}</td>
                <td>{{ participant.primary_center.name }}</td>
                <td v-if="!participant.is_invited" class="text-right">
                  <button
                    class="btn btn-danger"
                    @click="removeParticipant(participant.id)"
                  >
                    {{ trans("general.formDeleteLabel") }}
                  </button>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import AddResourcePanel from "../Utils/AddResourcePanel.vue";
import notifications from "../../Utils/notifications";

export default {
  components: {
    AddResourcePanel,
  },
  data() {
    return {
      loading: false,
      potentialStudyParticipants: [],
      studyParticipantsList: null,
      isAddingStudyParticipant: false,
    };
  },
  computed: {
    researchId() {
      return this.$route.params.studyId;
    },
  },
  created() {
    this.getStudyParticipants();
  },
  methods: {
    getStudyParticipants: function () {
      this.loading = true;

      axios
        .get(`/api/internal/study-admin/users/participants/${this.researchId}`)
        .then((data) => {
          this.studyParticipantsList = data.data;

          this.loading = false;
        })
        .catch((error) => {
          this.loading = false;
          this.userErrorMessage(error);
        });
    },
    getPotentianStudyParticipants: function () {
      axios
        .get(`/api/internal/study-admin/users/candidates/${this.researchId}`)
        .then((response) => {
          this.potentialStudyParticipants = response.data;
        })
        .catch((error) => {
          this.userErrorMessage(error);
        });
    },
    toggleAddingStudyParticipant: function () {
      // dont save state since it updates after resource has been added ?
      this.getPotentianStudyParticipants();
      this.isAddingStudyParticipant = !this.isAddingStudyParticipant;
    },
    addParticipantToStudy: function (newParticipantId) {
      this.loading = true;
      axios
        .post(
          `/api/internal/study-admin/users/participant/${this.researchId}/${newParticipantId}`,
        )
        .then(() => {
          this.isAddingStudyParticipant = false;
          this.getStudyParticipants();
          this.loading = false;
          notifications.saveSuccess();
        })
        .catch((error) => {
          this.loading = false;
          this.userErrorMessage(error);
        });
    },
    removeParticipant: function (participantId) {
      this.$vueAlert
        .confirm(
          this.trans(
            "aiFeatures.removeParticipantFromStudyConfirmationMessage",
          ),
          undefined,
          undefined,
          {
            confirmButtonText: this.trans("general.modalConfirmButton"),
            cancelButtonText: this.trans("general.modalCancelButton"),
          },
        )
        .then(() => {
          (this.loading = true),
            axios
              .delete(
                `/api/internal/study-admin/users/participant/${this.researchId}/${participantId}`,
              )
              .then(() => {
                this.loading = false;
                this.getStudyParticipants();
                notifications.saveSuccess();
              })
              .catch((error) => {
                this.loading = false;
                this.userErrorMessage(error);
              });
        })
        .catch(() => {
          console.log("on cancel");
        });
    },
    onCancel: function () {
      this.isAddingStudyParticipant = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.study-participants-list-container {
  position: relative;

  .study-participants-list {
    min-height: 300px;
  }
}

.overlay-content {
  position: relative;
  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
  margin: 20px 0px;
  text-align: center;
  font-weight: bold;
}
</style>
