import { NavigationGuard } from "vue-router";
import { patientAuthService } from "./patient-auth-service";
import { locationOf, ROUTES } from "../../routing/routes";

/**
 * Logs out the current patient. Forced assumes the token is revoked/expired.
 */
export const patientLogout: NavigationGuard = async (to, from, next) => {
  await patientAuthService.logout(to.query.force === "1");
  next(
    locationOf(ROUTES.patientLogin, {
      query: { callbackUrl: from.fullPath },
    }),
  );
};
