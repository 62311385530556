<template>
  <div
    class="lg:tw-flex tw-justify-between tw-items-center tw-gap-2 tw-py-4 max-lg:tw-space-y-2"
  >
    <div class="tw-flex-1">
      <v-select
        label="name"
        multiple
        name="centerPicker"
        :options="availableCentersWithAll"
        :model-value="selectedCenters"
        :reduce="(selectedCenter) => selectedCenter.id"
        @update:model-value="updateSelectedCenter($event)"
      />
    </div>

    <time-pickers-menu
      ref="statisticsTimePickers"
      class="tw-flex-1"
      :loading="loading"
      @update-date="updateDate"
    />

    <div class="space-x-2">
      <button
        class="btn btn-danger"
        type="button"
        @click="clearSelectedCenters"
      >
        <span>{{ trans("general.clearSearch") }}</span>
        <i class="fal fa-times" />
      </button>
      <button
        class="btn btn-primary"
        :disabled="selectedCenters.length === 0"
        @click="getData"
      >
        {{ trans("statistics.seeDataButtonText") }}
      </button>
    </div>
  </div>
</template>

<script>
import TimePickersMenu from "./TimePicker.vue";

export default {
  components: {
    TimePickersMenu,
  },
  props: {
    availableCenters: Array,
    loading: Boolean,
  },
  data: function () {
    return {
      availableCentersWithAll: [],
      selectedCenters: [this.$store.state.user.primaryCenter],
      dateFrom: "",
      dateTo: "",
    };
  },
  watch: {
    availableCenters: function () {
      const optionAllCenters = [
        JSON.parse(
          JSON.stringify({
            name: this.trans("statistics.allCenters"),
            id: "all",
          }),
        ),
      ];

      this.availableCentersWithAll = optionAllCenters.concat(
        this.availableCenters,
      );
    },
  },
  methods: {
    getData: function () {
      this.$emit("getData", this.selectedCenters, this.dateFrom, this.dateTo);
      this.$emit(
        "getPatientsStatistics",
        this.selectedCenters,
        this.dateFrom,
        this.dateTo,
      );
    },
    updateSelectedCenter: function (centers) {
      if (centers.includes("all")) {
        const allCentersIds = this.availableCenters.map((center) => center.id);
        this.selectedCenters = allCentersIds;
      } else {
        this.selectedCenters = centers.filter((c) => c !== "all");
      }
    },
    clearSelectedCenters: function () {
      this.selectedCenters = [];
      this.$refs.statisticsTimePickers.clearDate();
      this.getData();
    },
    updateDate: function (from, to) {
      this.dateFrom = from;
      this.dateTo = to;
    },
  },
};
</script>
