<template>
  <div class="col-md-12 consultation-box">
    <div class="panel panel-default panel-fill">
      <div class="panel-heading panel-heading-underline">
        <h3 class="panel-title">
          {{ trans("examination.consultationHeadline") }}
        </h3>
      </div>
      <div class="panel-body">
        <div class="m-b-20 col-md-12 row">
          <div class="m-b-20 col-md-4">
            <strong>{{ trans("examination.medicalHistory") }}</strong>
            <br />
            <p class="text-muted">
              <span v-if="examination.medicalHistory.smoker" class="smoker">
                {{ trans("examination.medicalHistorySmoker") }}:
                {{ examination.medicalHistory.smoker }}
                <br />
              </span>
              <span v-if="examination.medicalHistory.diabetes" class="diabetes">
                {{ trans("examination.medicalHistoryDiabetes") }}:
                {{ examination.medicalHistory.diabetes }}
                <br />
              </span>
              <span
                v-if="examination.medicalHistory.heartLungVascDis"
                class="heartLungVascDis"
              >
                {{ trans("examination.medicalHistoryHeartLungVascDis") }}:
                {{ examination.medicalHistory.heartLungVascDis }}
                <br />
              </span>
              <span
                v-if="examination.medicalHistory.deepVentrombosis"
                class="deepVentrombosis"
              >
                {{ trans("examination.medicalHistoryDeepVentrombosis") }}:
                {{ examination.medicalHistory.deepVentrombosis }}
                <br />
              </span>
              <span
                v-if="examination.medicalHistory.multiResistantBacteria"
                class="multiResistantBacteria"
              >
                {{ trans("examination.medicalHistoryMultiResistantBacteria") }}:
                {{ examination.medicalHistory.multiResistantBacteria }}
                <br />
              </span>
            </p>
          </div>
          <div class="m-b-20 col-md-4">
            <strong>
              {{ trans("examination.compressionTitle") }}
            </strong>
            <br />
            <p class="text-muted compression">
              {{ examination.case.compression }}
            </p>
          </div>
          <div class="m-b-20 col-md-4">
            <strong>{{ trans("examination.woundExistence") }}</strong>
            <br />
            <p class="text-muted lesionExistence">
              {{ examination.case.lesionExistence }}
            </p>
          </div>
        </div>

        <div class="m-b-20 col-md-12 row">
          <div class="m-b-20 col-md-4">
            <strong>
              {{ trans("examination.antibioticsLatestSixMonths") }}
            </strong>
            <br />
            <p class="text-muted antibioticsLatestSixMonths">
              {{ examination.case.antibioticsLatestSixMonths }}
            </p>
          </div>
          <div class="m-b-20 col-md-4">
            <strong>{{ trans("examination.woundSize") }}</strong>
            <br />
            <p class="text-muted">
              {{ trans("examination.lesionSizeLength") }}:
              <span class="woundLength">
                {{ examination.case.lesionSizeLength }}
              </span>
              <br />
              {{ trans("examination.lesionSizeWidth") }}:
              <span class="woundWidth">
                {{ examination.case.lesionSizeWidth }}
              </span>
            </p>
          </div>
          <div class="m-b-20 col-md-4">
            <strong>
              {{ trans("examination.lesionDiagnosis") }}
            </strong>
            <br />
            <p class="text-muted lesionDiagnosis">
              {{ examination.case.ulcerType }}
            </p>
          </div>
        </div>

        <div class="m-b-20 col-md-12 row">
          <div class="m-b-20 col-md-4">
            <strong>{{ trans("examination.hasPain") }}</strong>
            <br />
            <p class="text-muted hasPain">
              {{ examination.case.hasPain }}
            </p>
          </div>
          <div class="m-b-20 col-md-4">
            <strong>{{ trans("examination.nocturnalPain") }}</strong>
            <br />
            <p class="text-muted nocturnalPain">
              {{ examination.case.nocturnalPain }}
            </p>
          </div>
          <div class="m-b-20 col-md-4">
            <strong>{{ trans("examination.vas") }}</strong>
            <br />
            <p class="text-muted vas">
              {{ examination.case.vas }}
            </p>
          </div>
        </div>

        <div class="m-b-20 col-md-12 row">
          <div class="m-b-20 col-md-4">
            <strong>
              {{ trans("examination.systolicBloodPressure") }}
            </strong>
            <br />
            <p class="text-muted systolicBloodPressure">
              {{ examination.case.systolicBloodPressure }}
            </p>
          </div>
          <div class="m-b-20 col-md-4">
            <strong>{{ trans("examination.anklePressure") }}</strong>
            <br />
            <p class="text-muted anklePressure">
              {{ examination.case.anklePressure }}
            </p>
          </div>

          <div class="m-b-20 col-md-4">
            <strong>{{ trans("examination.ankleIndex") }}</strong>
            <br />
            <p class="text-muted ankleIndex">
              {{ examination.case.ankleIndex }}
            </p>
            <br />
            {{ trans("examination.armAnkleIndexPerformed") }}
            <span class="armAnkleIndexPerformed">
              {{ examination.case.armAnkleIndexPerformed }}
            </span>
          </div>
        </div>

        <div class="m-b-20 col-md-12 row">
          <div class="m-b-20 col-md-4">
            <strong>
              {{ trans("examination.sensibilityMonofilament") }}
            </strong>
            <br />
            <p class="text-muted">
              {{ trans("examination.sensibilityMonofilamentDx") }}:
              <span class="sensibilityMonofilamentDx">
                {{ examination.case.sensibilityMonofilamentDx }}
              </span>
              <br />
              {{ trans("examination.sensibilityMonofilamentSin") }}:
              <span class="sensibilityMonofilamentSin">
                {{ examination.case.sensibilityMonofilamentSin }}
              </span>
            </p>
          </div>
          <div class="m-b-20 col-md-4">
            <strong>{{ trans("examination.vascularStatus") }}</strong>
            <br />
            <p class="text-muted">
              {{ trans("examination.peripheralPulsesDorsalisPedis") }}
              <span class="peripheralPulsesDorsalisPedis">
                {{ examination.case.peripheralPulsesDorsalisPedis }}
              </span>
              <br />
              {{ trans("examination.peripheralPulsesTibialisPosterior") }}
              <span class="peripheralPulsesTibialisPosterior">
                {{ examination.case.peripheralPulsesTibialisPosterior }}
              </span>
              <br />
            </p>
          </div>

          <div class="m-b-20 col-md-4">
            <strong>{{ trans("examination.otherComment") }}</strong>
            <br />
            <p class="text-muted otherComment">
              {{ examination.case.otherComment }}
            </p>
          </div>
        </div>

        <div class="m-b-20 col-md-12 row">
          <div class="m-b-20 col-md-12">
            <strong>{{ trans("examination.ulcerLocation") }}</strong>
            <br />
            <p class="text-muted">
              {{ examination.case.ulcerLocation }}
            </p>
          </div>
        </div>

        <div class="m-b-20 col-md-12 row">
          <div class="m-b-20 col-md-12">
            <strong>{{ trans("examination.approval") }}</strong>
            <br />
            <p class="text-muted consentList">
              <i
                v-if="examination.case.consentPhoto"
                class="fal fa-check-circle consentPhoto"
              />
              <i
                v-else-if="!examination.case.consentPhoto"
                class="fal fa-circle"
              />
              {{ trans("examination.consentPhoto") }}
              <br />
              <i
                v-if="examination.case.consentJournal"
                class="fal fa-check-circle consentJournal"
              />
              <i
                v-else-if="!examination.case.consentJournal"
                class="fal fa-circle"
              />
              {{ trans("examination.consentJournal") }}
              <br />
              <i
                v-if="examination.case.consentUcr"
                class="fal fa-check-circle consentUcr"
              />
              <i
                v-else-if="!examination.case.consentUcr"
                class="fal fa-circle"
              />
              {{ trans("examination.consentUcr") }}
              <br />
              <i
                v-if="examination.case.consentEducation"
                class="fal fa-check-circle consentEducation"
              />
              <i
                v-else-if="!examination.case.consentEducation"
                class="fal fa-circle"
              />
              {{ trans("examination.consentEducation") }}
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ["examination"],
};
</script>
