import { datadogRum } from "@datadog/browser-rum";

const init = ({
  applicationId,
  clientToken,
  env,
  version,
  defaultPrivacyLevel,
  sessionSampleRate,
  sessionReplaySampleRate,
  trackUserInteractions,
}) => {
  if (!applicationId || !clientToken) {
    console.info(
      "Missing Datadog application id and/or client token - skipping Datadog RUM integration",
    );
    return;
  }

  datadogRum.init({
    applicationId,
    clientToken,
    env,
    version,
    defaultPrivacyLevel: defaultPrivacyLevel || "mask",
    site: "datadoghq.eu",
    service: "dermicus-web",
    sessionSampleRate: parseInt(sessionSampleRate || 0),
    sessionReplaySampleRate: parseInt(sessionReplaySampleRate || 0),
    trackResources: true,
    trackLongTasks: true,
    trackUserInteractions: Boolean(trackUserInteractions),
  });
};

export default init;
