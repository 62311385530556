<template>
  <div class="panel panel-default">
    <div class="panel-heading panel-heading-underline">
      <h3 class="panel-title">
        {{ trans("educations.educationGenralLabel") }}
      </h3>
    </div>
    <div class="panel-body">
      <div class="row">
        <div class="m-b-20 col-md-4">
          <strong>{{ trans("examination.patientCaseLabel") }}</strong>
          <br />
          <p class="text-muted">
            {{ education.caseNo }}
          </p>
        </div>
        <div class="m-b-20 col-md-4">
          <strong>{{ trans("patient.gender") }}</strong>
          <br />
          <p class="text-muted">
            {{ examination.patient.gender }}
          </p>
        </div>
        <div class="m-b-20 col-md-4">
          <strong>{{ trans("patient.age") }}</strong>
          <br />
          <p class="text-muted">
            {{ examination.patient.age }}
          </p>
        </div>

        <div class="m-b-20 col-md-4">
          <strong>{{ trans("examination.createdAtLabel") }}</strong>
          <br />
          <p class="text-muted">
            {{ examination.createdAt }}
          </p>
        </div>
        <div class="m-b-20 col-md-4">
          <case-status :examination="education" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import CaseStatus from "../../Examinations/Examination/ExaminationComponents/components/CaseStatus.vue";
export default {
  components: { CaseStatus },
  props: {
    education: {
      caseNo: String,
    },
    examination: {
      createdAt: String,
      patient: {
        age: String,
        gender: String,
      },
    },
  },
};
</script>
