<template>
  <div>
    <div class="panel-heading">
      <h3 class="panel-title">Excluded authentication methods</h3>
    </div>
    <div class="panel-body table-responsive">
      <h6>{{ trans("general.webApplication") }}</h6>
      <div v-for="method in webAuthMethods" :key="method" class="form-check">
        <input
          class="form-check-input"
          :checked="selectedWebMethods.includes(method)"
          type="checkbox"
          @change="toggleWebMethod(method)"
        />
        <label class="form-check-label" :for="method">{{
          trans(`general.authentication-method.${method}`)
        }}</label>
      </div>

      <p />
      <p />

      <h6>{{ trans("general.mobileApp") }}</h6>
      <div v-for="method in mobileAuthMethods" :key="method" class="form-check">
        <input
          class="form-check-input"
          :checked="selectedMobileMethods.includes(method)"
          type="checkbox"
          @change="toggleMobileMethod(method)"
        />
        <label class="form-check-label" :for="method">{{
          trans(`general.authentication-method.${method}`)
        }}</label>
      </div>
    </div>
  </div>
</template>

<script>
import { practitionerHttpService } from "../../../app/practitioner-portal/store/practitioner-http-service";

export default {
  props: {
    region: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      webAuthMethods: [],
      mobileAuthMethods: [],
      selectedWebMethods: [],
      selectedMobileMethods: [],
    };
  },
  computed: {
    excludedWebAuthMethods() {
      return this.region.named_features?.find(
        (feature) => feature.feature === "excluded-web-authentication-methods",
      );
    },
    excludedMobileAuthMethods() {
      return this.region.named_features?.find(
        (feature) =>
          feature.feature === "excluded-mobile-authentication-methods",
      );
    },
  },
  created() {
    this.fetchAuthMethods();
    this.selectedWebMethods = [...(this.excludedWebAuthMethods?.config || [])];
    this.selectedMobileMethods = [
      ...(this.excludedMobileAuthMethods?.config || []),
    ];
  },
  methods: {
    fetchAuthMethods() {
      practitionerHttpService
        .get("/api/internal/admin/auth-methods")
        .then((response) => {
          this.webAuthMethods = response.data.auth_methods;
          this.mobileAuthMethods = response.data.mobile_auth_methods;
        });
    },
    toggleWebMethod(method) {
      if (this.selectedWebMethods.includes(method)) {
        this.selectedWebMethods = this.selectedWebMethods.filter(
          (m) => m !== method,
        );
      } else {
        this.selectedWebMethods.push(method);
      }
      this.updateExcludedAuthMethods();
    },
    toggleMobileMethod(method) {
      if (this.selectedMobileMethods.includes(method)) {
        this.selectedMobileMethods = this.selectedMobileMethods.filter(
          (m) => m !== method,
        );
      } else {
        this.selectedMobileMethods.push(method);
      }
      this.updateExcludedAuthMethods();
    },
    updateExcludedAuthMethods() {
      practitionerHttpService
        .post(
          `/api/internal/admin/regions/${this.region.id}/update-excluded-authentication-methods`,
          {
            excluded_web_auth_methods: this.selectedWebMethods,
            excluded_mobile_auth_methods: this.selectedMobileMethods,
          },
        )
        .then(() => {
          this.$notify({
            text: this.trans("admin.excludedAuthenticationMethodsSaved"),
            duration: 2000,
            type: "success",
          });
        });
    },
  },
};
</script>
