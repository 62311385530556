<template>
  <Panel :title="lang.get('admin.requestStudyConsent')">
    <template #header>
      <div class="tw-flex tw-items-center tw-pr-4">
        <DerToggle
          class="tw-mb-0"
          :model-value="hasOptimisticConsent"
          @update:model-value="handleConsentToggle"
        />
      </div>
    </template>

    <Alert v-if="!hasConsent">
      {{ lang.get("admin.noStudyConsent") }}
    </Alert>
    <slot v-else />
  </Panel>
</template>

<script setup lang="ts">
import Alert from "../../../../design-system/Alert.vue";
import DerToggle from "../../../../design-system/DerToggle.vue";
import { lang } from "../../../../i18n";
import Panel from "../../../Layout/Panel.vue";

defineProps<{
  hasConsent: boolean;
  hasOptimisticConsent: boolean;
}>();

const emit = defineEmits<{
  (event: "toggleConsent", value: boolean): void;
}>();

function handleConsentToggle(value: boolean) {
  emit("toggleConsent", value);
}
</script>
