<template>
  <div>
    <strong>{{ title }}</strong>
    <br />
    <VDropdown v-if="hasTooltip">
      <template #popper>
        <div v-for="(value, key) in tooltipData">
          <b>{{ key }}:</b>
          {{ value }}
        </div>
      </template>

      <p class="popover-link tw-inline">
        {{ data ? data : "-" }}
      </p>
    </VDropdown>

    <p v-else class="text-muted">
      {{ data ? data : "-" }}
    </p>
  </div>
</template>

<script>
export default {
  props: {
    title: String,
    data: [String, Number],
    extraOptions: Object,
  },
  computed: {
    hasTooltip() {
      return this.extraOptions && this.extraOptions.tooltip;
    },
    tooltipData() {
      return this.extraOptions.tooltip;
    },
  },
};
</script>
