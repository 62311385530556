<template>
  <div>
    <div class="titleContainer">
      <strong>{{ title }}</strong>
      <span class="aiLabel">A</span>
    </div>
    <p class="text-muted aiHighlight">
      {{ data ? data : "-" }}
    </p>
  </div>
</template>

<script>
export default {
  props: {
    title: String,
    data: [String, Number],
  },
};
</script>

<style lang="scss" scoped>
@use "/resources/assets/sass/abstracts/variables";

.titleContainer {
  display: flex;
  gap: 0.5em;
}

.aiHighlight {
  color: variables.$dermicusPurple !important;
  font-weight: variables.$font-weight-regular;
}

.aiLabel {
  color: variables.$dermicusPurple !important;
  border: 1px solid variables.$dermicusPurple;
  border-radius: 5px;
  padding: 0.2rem 0.3rem;
  display: flex;
  align-items: center;
  font-size: 12px;
  font-weight: variables.$font-weight-regular;
  line-height: 1;
}
</style>
