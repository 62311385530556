<template>
  <div>
    <div id="evaluations">
      <clip-loader v-show="loading" />

      <div class="table-responsive">
        <table class="table table-hover table-actions-bar cases-list">
          <thead class="sortable-table-header">
            <tr>
              <th v-if="$can('prioritise_cases')" />
              <th
                class="sort-by"
                :class="sortBySelection === 'id' ? 'table-active' : ''"
                @click="sortBy('id', $event)"
              >
                {{ trans("evaluation.tableColumnCaseNo") }}
                <i class="fas fa-caret-down" />
              </th>
              <th
                class="sort-by"
                :class="sortBySelection === 'status' ? 'table-active' : ''"
                @click="sortBy('status', $event)"
              >
                {{ trans("evaluation.tableColumnStatus") }}
                <i class="fas fa-caret-down" />
              </th>
              <th>{{ trans("evaluation.tableColumnProduct") }}</th>
              <th>{{ trans("evaluation.tableColumnLocation") }}</th>
              <th>{{ trans("evaluation.tableColumnPersonalNo") }}</th>
              <th
                class="sort-by created-at"
                :class="sortBySelection === 'createdAt' ? 'table-active' : ''"
                @click="sortBy('createdAt', $event)"
              >
                {{ trans("evaluation.tableColumnCreatedAt") }}
                <i class="createdAtSortIcon fas fa-caret-down" />
              </th>
              <th>{{ trans("evaluation.tableColumnCreatedBy") }}</th>
              <th
                class="sort-by"
                :class="sortBySelection === 'centerId' ? 'table-active' : ''"
                @click="sortBy('centerId', $event)"
              >
                {{ trans("evaluation.tableColumnCenter") }}
                <i class="fas fa-caret-down" />
              </th>
              <th>{{ trans("evaluation.tableColumnConsultReply") }}</th>
              <th>{{ trans("evaluation.tableColumnInternalConsultReply") }}</th>
            </tr>
          </thead>
          <tbody>
            <tr
              v-for="(examination, index) in examinations"
              :key="index"
              :class="{
                opened: examination.hasRead,
                last: isLastOpend(examination),
              }"
              @click="openExamination(examination)"
            >
              <td v-if="$can('prioritise_cases')">
                <i
                  v-if="examination.isBookmarked"
                  class="fas fa-star"
                  :title="trans('general.caseBookmarkedTitle')"
                />
                <i
                  v-if="examination.isFlagged"
                  class="fas fa-flag"
                  :title="trans('general.caseFlaggedTitle')"
                />
                <i
                  v-if="examination.isPrioritized"
                  class="fas fa-exclamation-circle"
                  :title="trans('general.casePrioritizedTitle')"
                />
              </td>
              <td>{{ examination.caseNo }}</td>
              <td>
                <case-status :examination="examination" :hide-label="true" />
              </td>
              <td>{{ examination.type }}</td>
              <td>
                {{ styleText(examination.lesionLocation) }}
                <span v-if="examination.lesionLocationLaterality"
                  >({{ examination.lesionLocationLaterality }})</span
                >
              </td>
              <td>{{ examination.patient.ssn }}</td>
              <td>{{ examination.createdAt }}</td>
              <td :title="examination.createdUserFullName">
                {{ examination.createdUser }}
              </td>
              <td>{{ examination.center }}</td>
              <td>{{ examination.lastComment }}</td>
              <td>
                <span
                  v-for="(
                    consultCommentUsers, index
                  ) in examination.consultCommentUsers"
                  :key="index"
                  v-dompurify-html="consultCommentUsers"
                />
              </td>
            </tr>
          </tbody>
        </table>
      </div>

      <div class="row-fluid float-right">
        <DerPaginate
          v-model="paginate.current_page"
          :page-count="paginate.last_page"
          @change="getResults"
        />
      </div>
    </div>
  </div>
</template>

<script>
import DerPaginate from "../../design-system/DerPaginate.vue";
import { queryStringBuilder } from "../../helpers/misc";
import CaseStatus from "../Examinations/Examination/ExaminationComponents/components/CaseStatus.vue";

export default {
  components: { CaseStatus, DerPaginate },
  data() {
    return {
      loading: true,
      user: this.$store.state.user,
      paginate: {
        current_page: 0,
        total: 0,
        last_page: 0,
      },
      searchFields: {
        sort: {
          field: "id",
          orderBy: "DESC",
        },
        page: 1,
      },
      examinations: [],
      sortBySelection: "createdAt",
    };
  },
  mounted() {
    this.getResults();
  },
  methods: {
    getResults: function (page) {
      this.loading = true;

      this.searchFields.page = page ? page : this.searchFields.page;

      localStorage.setItem(
        "last_patients_related_examinations_query_uri",
        JSON.stringify(this.searchFields),
      );

      axios
        .get(
          "/api/internal/patients/" +
            this.$route.params.id +
            "/examinations?" +
            queryStringBuilder(this.searchFields),
        )
        .then((data) => {
          this.loading = false;
          let collection = data.data;
          this.examinations = collection.data;
          this.paginate = collection.paginate;
        })
        .catch((error) => {
          this.loading = false;
          this.userErrorMessage(error);
        });
    },
    sortBy: function (field, event) {
      this.loading = true;
      this.sortBySelection = field;

      // Reset all to bottom
      if (field !== this.searchFields.sort.field) {
        $("i.fas.fa-caret-up")
          .removeClass("fa-caret-up")
          .addClass("fa-caret-down");

        this.searchFields.sort.orderBy = "DESC";
      }

      this.searchFields.sort.field = field;

      if (this.searchFields.sort.orderBy == "ASC") {
        this.searchFields.sort.orderBy = "DESC";
        $(event.currentTarget)
          .find("i.fas.fa-caret-up")
          .removeClass("fa-caret-up")
          .addClass("fa-caret-down");
      } else {
        this.searchFields.sort.orderBy = "ASC";
        $(event.currentTarget)
          .find("i.fas.fa-caret-down")
          .removeClass("fa-caret-down")
          .addClass("fa-caret-up");
      }

      localStorage.setItem(
        "last_patients_related_examinations_query_uri",
        JSON.stringify(this.searchFields),
      );

      this.getResults(this.currentPage);
    },
    // Event functions
    isLastOpend: function (examination) {
      return (
        localStorage.getItem("last_visited_examination_id") == examination.id
      );
    },
    openExamination: function (examination) {
      this.$router.push({ path: `/consultations${examination.editUrl}` });
    },
    styleText: function (string) {
      return string.split("/").join(" / ");
    },
  },
};
</script>
