<template>
  <div>
    <Alert
      v-show="_every({ isConnected: false }, studies)"
      data-variant="warning"
    >
      {{ lang.get("view.consentStudies.requestStudyConsentAlert.description") }}
    </Alert>
    <Table
      :headings="[
        { title: lang.get('model.studyConsent.status.label') },
        { title: lang.get('model.studyConsent.study.label') },
        { title: '' },
      ]"
    >
      <tr v-for="study in studies" :key="study.id">
        <td>
          <DerBadge
            v-if="study.isConnected"
            :data-value="lang.get('model.studyConsentStatus.connected.display')"
            data-variant="success"
          />
          <DerBadge
            v-else
            :data-value="
              lang.get('model.studyConsentStatus.disconnected.display')
            "
            data-variant="danger"
          />
        </td>
        <td>{{ study.name }}</td>
        <td class="tw-text-right tw-w-[200px]">
          <Button
            :is-loading="currentLoadingConnection === study.id"
            @click="handleConnect(study.id, study.isConnected)"
          >
            {{
              study.isConnected
                ? lang.get("action.study.adminRemove.label")
                : lang.get("action.study.adminAdd.label")
            }}
          </Button>
        </td>
      </tr>
    </Table>
  </div>
</template>

<script setup lang="ts">
import { StudySummary } from "../../../../../../../app/Dermicus/Studies/StudySummary";
import { lang } from "../../../../i18n";
import DerBadge from "../../../../design-system/DerBadge.vue";
import Button from "../../../Layout/Button.vue";
import Table from "../../../Layout/Table.vue";
import Alert from "../../../../design-system/Alert.vue";
import _every from "lodash/fp/every";

defineProps<{
  studies: Array<StudySummary & { isConnected: boolean }>;
  currentLoadingConnection?: number;
}>();

const emit = defineEmits<{
  (event: "connectStudy", studyId: number, isConnected: boolean): void;
}>();

function handleConnect(studyId: number, isConnected: boolean) {
  emit("connectStudy", studyId, isConnected);
}
</script>
