<template>
  <field-panel
    :title="title"
    :info-popup-text="getInfoPopupText()"
    :new-documentation-button="
      !addNewDocumentation
        ? {
            onAddNewDocumentation: onAddNewDocumentation,
          }
        : null
    "
    :total-comments="totalComments"
  >
    <examination-documentation
      v-if="mappedFields.length"
      :examination-id="examination.id"
      :enabled-types="mappedFields[0].extraOptions.enabledTypes"
      :add-new-documentation="addNewDocumentation"
      :product-field="documentationProductField"
      @dismiss-add-new-documentation="dismissAddNewDocumentation"
      @set-total-comments="setTotalComments"
    />
  </field-panel>
</template>

<script>
import FieldPanel from "./components/FieldPanel.vue";
import ExaminationDocumentation from "./ExaminationDocumentation.vue";

import { getFieldType } from "../../../../helpers/product";
import { canRead, canWrite } from "../componentMapper";
import { infoPopupText } from "../../../../helpers/misc";
import _find from "lodash/fp/find";

export default {
  components: {
    FieldPanel,
    ExaminationDocumentation,
  },
  props: {
    /** @type ProductField[] */
    fields: Array,
    examination: Object,
    fieldGroupName: String,
  },
  data() {
    return {
      title: "examination.consultBoxLabel",
      mappedFields: [],
      addNewDocumentation: false,
      totalComments: null,
    };
  },
  computed: {
    documentationProductField() {
      return _find({ name: "documentation" }, this.fields);
    },
  },
  mounted: function () {
    this.mapData();
  },
  methods: {
    mapData: function () {
      const { fields } = this;
      const userPermissions = JSON.parse(this.$store.state.user.permissions);
      const mappedFields = [];
      fields.forEach((field) => {
        const mappedField = {
          title: field.title,
          type: getFieldType(field),
        };
        if ("value_options" in field) {
          const enabledTypes = [];
          JSON.parse(field.value_options).forEach((option) => {
            enabledTypes.push({
              name: option.name,
              edit:
                "write_permission" in option
                  ? canWrite({ name: option.write_permission }, userPermissions)
                  : true,
              read:
                "read_permission" in option
                  ? canRead({ name: option.read_permission }, userPermissions)
                  : true,
            });
          });
          mappedField.extraOptions = {
            enabledTypes: enabledTypes,
          };
        }
        mappedFields.push(mappedField);
      });
      this.mappedFields = mappedFields;
    },
    onAddNewDocumentation: function () {
      this.addNewDocumentation = true;
    },
    dismissAddNewDocumentation: function (bool) {
      this.addNewDocumentation = bool;
    },
    setTotalComments: function (num) {
      this.totalComments = num;
    },
    getInfoPopupText: function () {
      return this.mappedFields.length > 0
        ? infoPopupText(
            this.mappedFields[0].extraOptions.enabledTypes,
            this.fieldGroupName,
          )
        : "";
    },
  },
};
</script>
