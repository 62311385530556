<template>
  <div>
    <div class="panel panel-default panel-fill mt-4">
      <div class="panel-heading">
        <h3 class="panel-title">
          {{ trans("center.addNewCenter") }}
        </h3>
      </div>
      <div class="panel-body">
        <div class="row">
          <div class="m-b-20 col-md-6" :class="{ 'has-error': errors.name }">
            <label for="FullName">{{ trans("center.name") }}</label>
            <i class="far fa-asterisk" />
            <input
              id="center_name"
              v-model="center.name"
              type="text"
              name="name"
              class="form-control"
              :placeholder="trans('center.name')"
            />
            <form-error v-if="errors.name" :errors="errors.name" />
          </div>

          <div class="m-b-20 col-md-6">
            <label for="FullName">{{ trans("center.hsaId") }}</label>
            <input
              id="hsa-id"
              v-model="center.hsa_id"
              type="text"
              name="hsaid"
              class="form-control"
              :placeholder="trans('center.hsaId')"
            />
          </div>

          <div class="m-b-20 col-md-6">
            <label for="contactPerson">{{
              trans("center.contactPerson")
            }}</label>
            <input
              id="contactPerson"
              v-model="center.contact_person"
              type="text"
              name="contactPerson"
              class="form-control"
              :placeholder="trans('center.contactPerson')"
            />
          </div>

          <div class="m-b-20 col-md-6">
            <label for="FullName">{{ trans("center.contactNo") }}</label>
            <input
              id="contact_no"
              v-model="center.contact_no"
              type="text"
              name="address"
              class="form-control"
              :placeholder="trans('center.contactNo')"
            />
          </div>

          <div class="m-b-20 col-md-6" :class="{ 'has-error': errors.email }">
            <label for="FullName">{{ trans("center.email") }}</label>
            <input
              id="email"
              v-model="center.email"
              type="text"
              name="name"
              class="form-control"
              :placeholder="trans('center.email')"
            />
            <form-error v-if="errors.email" :errors="errors.email" />
          </div>

          <div class="m-b-20 col-md-6">
            <label for="FullName">{{ trans("center.fax") }}</label>
            <input
              id="fax"
              v-model="center.fax"
              type="text"
              name="fax"
              class="form-control"
              :placeholder="trans('center.fax')"
            />
          </div>

          <div class="row m-b-20 col-md-12">
            <div class="col">
              <hr />
            </div>
            <div class="col-auto">
              <span class="hr-text">{{ trans("patient.address") }}</span>
            </div>
            <div class="col">
              <hr />
            </div>
          </div>

          <div class="m-b-20 col-md-12">
            <label for="FullName">{{ trans("center.address") }}</label>
            <input
              id="address"
              v-model="center.address"
              type="text"
              name="address"
              class="form-control"
              :placeholder="trans('center.address')"
            />
          </div>

          <div class="m-b-20 col-md-6">
            <label for="FullName">{{ trans("center.city") }}</label>
            <input
              id="city"
              v-model="center.city"
              type="text"
              name="address"
              class="form-control"
              :placeholder="trans('center.city')"
            />
          </div>

          <div class="m-b-20 col-md-6">
            <label for="FullName">{{ trans("center.postcode") }}</label>
            <input
              id="postcode"
              v-model="center.postcode"
              type="text"
              name="postcode"
              class="form-control"
              :placeholder="trans('center.postcode')"
            />
          </div>

          <div class="m-b-20 col-md-6" :class="{ 'has-error': errors.country }">
            <label for="FullName">{{ trans("center.country") }}</label>
            <i class="far fa-asterisk" />
            <country-select
              v-model="center.country"
              :country="center.country"
              top-country="SE"
              class="form-control"
            />
            <form-error v-if="errors.country" :errors="errors.country" />
          </div>

          <div class="m-b-20 col-md-6">
            <label for="FullName">{{ trans("center.province") }}</label>
            <region-select
              v-model="center.province"
              :country="center.country"
              :region="center.province"
              class="form-control"
            />
          </div>
        </div>
      </div>

      <div class="m-3">
        <span class="text-left"
          ><i class="far fa-asterisk" />
          {{ trans("center.requiredField") }}</span
        >
      </div>
    </div>

    <div class="panel panel-default panel-fill">
      <div class="panel-heading">
        <h3 class="panel-title">
          {{ trans("users.tabMenuLabelProducts") }}
        </h3>
      </div>
      <div class="panel-body">
        <div class="row col-md-12">
          <div v-if="features.mole.active" class="col-md-3 col-sm-3">
            <DerToggleWithLabel
              v-model="center.features.mole.active"
              :label="trans('general.mole')"
            />
          </div>

          <div v-if="features.moleuk.active" class="col-md-3 col-sm-3">
            <DerToggleWithLabel
              v-model="center.features.moleuk.active"
              :label="trans('general.moleuk')"
            />
          </div>

          <div v-if="features.regionSkaneMole.active" class="col-md-3 col-sm-3">
            <DerToggleWithLabel
              v-model="center.features.regionSkaneMole.active"
              :label="trans('general.regionSkaneMole')"
            />
          </div>

          <div v-if="features.photo.active" class="col-md-3 col-sm-3">
            <DerToggleWithLabel
              v-model="center.features.photo.active"
              :label="trans('general.photo')"
            />
          </div>

          <div v-if="features.photouk.active" class="col-md-3 col-sm-3">
            <DerToggleWithLabel
              v-model="center.features.photouk.active"
              :label="trans('general.photouk')"
            />
          </div>

          <div v-if="features.wound.active" class="col-md-3 col-sm-3">
            <DerToggleWithLabel
              v-model="center.features.wound.active"
              :label="trans('general.wound')"
            />
          </div>

          <div v-if="features.woundlegacy.active" class="col-md-3 col-sm-3">
            <DerToggleWithLabel
              v-model="center.features.woundLegacy.active"
              :label="trans('general.woundLegacy')"
            />
          </div>

          <div v-if="features.tumourWound.active" class="col-md-3 col-sm-3">
            <DerToggleWithLabel
              v-model="center.features.tumourWound.active"
              :label="trans('general.tumourWound')"
            />
          </div>

          <div v-if="features.wounduk.active" class="col-md-3 col-sm-3">
            <DerToggleWithLabel
              v-model="center.features.wounduk.active"
              :label="trans('general.woundUK')"
            />
          </div>

          <div v-if="features.woundlite.active" class="col-md-3 col-sm-3">
            <label>
              {{ trans("general.woundLite") }}:
              <DerToggle v-model="center.features.woundlite.active" />
            </label>
          </div>
        </div>

        <clip-loader v-if="loading" />

        <div v-if="!hasProducts && !loading">
          <h6 class="text-center">
            {{ trans("region.noProducts") }}
          </h6>
        </div>
      </div>
    </div>

    <platform-attributes-editor
      :platform="platform"
      :platform-attributes="center.platform_attributes"
      :platform-attribute-values="center.platform_attribute_values"
    />

    <div class="text-right mb-5">
      <button class="btn btn-grey" @click="cancelAddingCenter">
        {{ trans("general.modalCancelButton") }}
      </button>
      <button
        class="btn btn-primary"
        :disabled="!isValid || loading"
        @click="addCenterToRegion"
      >
        {{ trans("center.addNewCenter") }}
      </button>
    </div>
  </div>
</template>

<script>
import PlatformAttributesEditor from "../PlatformAttributes/PlatformAttributesEditor.vue";
import "vue3-country-region-select";
import DerToggleWithLabel from "../../design-system/DerToggleWithLabel.vue";

export default {
  components: {
    PlatformAttributesEditor,
    DerToggleWithLabel,
  },
  data: function () {
    return {
      loading: false,
      platform: undefined,
      center: {
        id: null,
        name: "",
        hsa_id: "",
        contact_person: "",
        email: "",
        contact_no: "",
        fax: "",
        address: "",
        city: "",
        postcode: "",
        country: "",
        province: "",
        features: {
          mole: {
            active: false,
            config: {},
          },
          moleuk: {
            active: false,
            config: {},
          },
          wound: {
            active: false,
            config: {},
          },
          wounduk: {
            active: false,
            config: {},
          },
          woundlite: {
            active: false,
            config: {},
          },
          woundlegacy: {
            active: false,
            config: {},
          },
          photo: {
            active: false,
            config: {},
          },
          photouk: {
            active: false,
            config: {},
          },
          tumourWound: {
            active: false,
            config: {},
          },
          regionSkaneMole: {
            active: false,
            config: {},
          },
          image_quality_assurance: {
            active: false,
            config: {},
          },
          image_polarisation_check: {
            active: false,
            config: {},
          },
          request_study_consent: {
            active: false,
            config: {},
          },
        },
        platform_attributes: {},
        platform_attribute_values: {},
      },
      // organisation products possible to deselect
      features: {
        mole: {
          active: false,
          config: {},
        },
        moleuk: {
          active: false,
          config: {},
        },
        wound: {
          active: false,
          config: {},
        },
        wounduk: {
          active: false,
          config: {},
        },
        woundlite: {
          active: false,
          config: {},
        },
        woundlegacy: {
          active: false,
          config: {},
        },
        photo: {
          active: false,
          config: {},
        },
        photouk: {
          active: false,
          config: {},
        },
        tumourWound: {
          active: false,
          config: {},
        },
        regionSkaneMole: {
          active: false,
          config: {},
        },
      },
      errors: [],
    };
  },
  computed: {
    isValid: function () {
      if (!this.center.name || !this.center.country) {
        return false;
      } else {
        return true;
      }
    },
    hasProducts: function () {
      if (
        !this.features.wound.active &&
        !this.features.wounduk.active &&
        !this.features.photo.active &&
        !this.features.photouk.active &&
        !this.features.mole.active &&
        !this.features.moleuk.active &&
        !this.features.tumourWound.active &&
        !this.features.regionSkaneMole.active
      ) {
        return false;
      } else return true;
    },
  },
  mounted: function () {
    this.getRegionFeatures();
  },
  methods: {
    getRegionFeatures: function () {
      this.loading = true;

      Promise.all([
        axios.get("/api/internal/region-admin/center/new"),
        axios.get("/api/internal/region"),
      ])
        .then(([newCenterResponse, regionResponse]) => {
          let newCenterData = newCenterResponse.data;
          this.platform = newCenterData.platform;
          this.center.platform_attributes = newCenterData.platform_attributes;
          this.center.platform_attribute_values =
            newCenterData.platform_attribute_values;

          let regionData = regionResponse.data;

          regionData.region.features.push({
            type: 14,
          });

          this.setFeaturesData(regionData.region.features);
          this.loading = false;
        })
        .catch((error) => {
          console.log("Get region features error", error);
          this.loading = false;
        });
    },
    setFeaturesData: function (features) {
      for (let key in features) {
        let featureType = features[key].type;
        if (featureType == 1) {
          this.features.mole.active = true;
          this.center.features.mole.active = true;
        } else if (featureType == 2) {
          this.features.woundlegacy.active = true;
          this.center.features.woundlegacy.active = true;
        } else if (featureType == 3) {
          this.features.photo.active = true;
          this.center.features.photo.active = true;
        } else if (featureType == 11) {
          this.features.moleuk.active = true;
          this.center.features.moleuk.active = true;
        } else if (featureType == 14) {
          this.center.features.image_quality_assurance.active = true;
        } else if (featureType == 18) {
          this.center.features.image_polarisation_check.active = true;
        } else if (featureType == 19) {
          this.center.features.request_study_consent.active = true;
        } else if (featureType == 100) {
          this.features.tumourWound.active = true;
          this.center.features.tumourWound.active = true;
        } else if (featureType == 105) {
          this.features.wounduk.active = true;
          this.center.features.wounduk.active = true;
        } else if (featureType == 110) {
          this.features.regionSkaneMole.active = true;
          this.center.features.regionSkaneMole.active = true;
        } else if (featureType == 115) {
          this.features.photouk.active = true;
          this.center.features.photouk.active = true;
        } else if (featureType == 120) {
          this.features.wound.active = true;
          this.center.features.wound.active = true;
        } else if (featureType == 125) {
          this.features.woundlite.active = true;
          this.center.features.woundlite.active = true;
        }
      }
    },
    addCenterToRegion: function () {
      this.errors = [];
      this.loading = true;

      axios
        .post("/api/internal/region-admin/center/new", this.center)
        .then((response) => {
          this.$notify({
            text: this.trans("general.notificationSaved"),
            type: "success",
            duration: 1000,
          });
          this.loading = false;
          this.$router.push({ path: `/region/centers` });
        })
        .catch((error) => {
          this.errors = error.response.data;
          this.loading = false;
          this.userErrorMessage(error);
        });
    },
    cancelAddingCenter: function () {
      this.$router.go(-1);
    },
  },
};
</script>

<style lang="scss" scoped>
.hr-text {
  display: inline-block;
  vertical-align: middle;
  line-height: 2em;
  font-weight: bold;
}
</style>
