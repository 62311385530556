import { StudyExaminationStatus } from "./StudyExaminationStatus";
import { StudyWorkflowType } from "./StudyWorkflowType";
import _flow from "lodash/fp/flow";
import _over from "lodash/fp/over";
import _join from "lodash/fp/join";
import _sumBy from "lodash/fp/sumBy";
import _map from "lodash/fp/map";
import _defaultTo from "lodash/fp/defaultTo";

export type StatusCounts = {
  invited: number;
  answered: number;
};

export type StudyExaminationAttributeStatus = {
  status: StudyExaminationStatus;
  status_counts: StatusCounts;
  status_workflow_counts: Partial<Record<StudyWorkflowType, StatusCounts>>;
};

export const displayStatusCounts: (value: StatusCounts) => string = _flow(
  _over([
    _flow(_sumBy("answered"), _defaultTo(0)),
    _flow(_sumBy("invited"), _defaultTo(0)),
  ]),
  _join(" / ")
);

export const displayStatusCountsFor: (
  value: StudyExaminationAttributeStatus[]
) => string = _flow(_map("status_counts"), displayStatusCounts);

export const displayStatusCountsForWorkflow: (
  type: StudyWorkflowType
) => (value: StudyExaminationAttributeStatus[]) => string = type =>
  _flow(_map(`status_workflow_counts.${type}`), displayStatusCounts);
