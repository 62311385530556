<template>
  <div>
    <strong>{{ title }}</strong>
    <br />
    <p v-dompurify-html="data ? data : '-'" class="text-muted" />
  </div>
</template>

<script>
export default {
  props: {
    title: String,
    data: [String, Number],
  },
};
</script>
