import lodash from "lodash";
window._ = lodash;

/**
 * We'll load jQuery and the Bootstrap jQuery plugin which provides support
 * for JavaScript based Bootstrap features such as modals and tabs. This
 * code may be modified to fit the specific needs of your application.
 */

import jQuery from "jquery";
window.$ = window.jQuery = jQuery;

import popper from "popper.js";
window.Popper = popper;
import "bootstrap";

import select2 from "select2";
window.select2 = select2();
// window.select2 = Select2;
import moment from "moment";
// Current import is not a "default" one, but solution for combo with vite,
// See https://github.com/vitejs/vite/issues/5359#issuecomment-954655191.
// That said, the app doesn't use moment with locales, nor is any format based
// on the locale, which isn't good nor the norm. It's imported here for using
// e.g. translated month names. Although moment is still supported, it's not
// active anymore and should be replaced by newer libraries.
import "moment/dist/locale/sv";
import "moment/dist/locale/de";
import "moment/dist/locale/fr";
import "moment/dist/locale/it";
import "moment/dist/locale/nl";
window.moment = moment;

import { app } from "./vue-app";

import { practitionerHttpService } from "./app/practitioner-portal/store/practitioner-http-service";

/**
 * @deprecated use `practitionerHttpService` directly instead of this global wrapper.
 */
window.axios = practitionerHttpService;
window.axios.defaults.headers.common = {
  /**
   * It appears that we inject this token through the "app.blade.php" view.
   * Maybe requesting the token can be improved somewhere in the future.
   * @see {@link https://laravel.com/docs/9.x/passport#consuming-your-api-with-javascript}
   */
  "X-CSRF-TOKEN": Laravel.csrfToken,
  "X-Requested-With": "XMLHttpRequest",
  "Content-Type": "application/json",
  "Access-Control-Allow-Origin": "*",
};

/**
 * Application plugins
 */
import vueCookies from "vue-cookies";
app.use(vueCookies);
import vueAutosize from "vue-autosize";
app.use(vueAutosize);
import FloatingVue from "floating-vue";
app.use(FloatingVue);
import { VueQueryPlugin } from "@tanstack/vue-query";
import { queryPluginOptions } from "./store/api";
app.use(VueQueryPlugin, queryPluginOptions);

/**
 * Initialize Datadog
 */
import datadog from "./datadog-rum";
datadog(window.datadogConfig);

/**
 * Application components
 */
import VueClipboard from "vue-clipboard2";
VueClipboard.config.autoSetContainer = true; // add this line
import vueCountryRegionSelect from "vue3-country-region-select";
import VueSelect2 from "./components/Utils/select2.vue";
import Notifications from "@kyvg/vue3-notification";
import ClipLoader from "./components/Utils/ClipLoader.vue";
import vSelect from "vue-select";
import FormError from "./components/Utils/FormError.vue";
import Permissions from "./mixins/Permissions.vue";
import UserErrorMessage from "./mixins/UserErrorMessage.vue";
import VueSlider from "vue-slider-component";
import CustomDatepicker from "./components/Utils/Datepicker.vue";
import { applyDOMPurify } from "./dompurify";
import PortalVue from "portal-vue";
import Field from "./components/Examinations/Examination/ExaminationComponents/components/Field.vue";
import VueSimpleAlert from "vue3-simple-alert";

import "sweetalert2/dist/sweetalert2.min.css";

app.mixin(UserErrorMessage);
app.mixin(Permissions);

app.component("Select2", VueSelect2);
app.component("ClipLoader", ClipLoader);
app.component("VSelect", vSelect);
app.component("FormError", FormError);
app.component("VueSlider", VueSlider);
app.component("CustomDatepicker", CustomDatepicker);

// Global registration due to circular dependency currently!
// TODO: Look in how we best solve this. Should be included in generic product forms v2.
app.component("Field", Field);

app.config.globalProperties.$vueAlert = VueSimpleAlert;
app.use(VueSimpleAlert);
app.use(VueClipboard);
app.use(vueCountryRegionSelect);
app.use(Notifications);

applyDOMPurify(app);

app.use(PortalVue);

app.directive("focus", {
  mounted(el) {
    el.focus();
  },
});

// NOT USED ANYMORE
// I think bootstrap sass was replaced by newer bootstrap, so it's not used:
// require("bootstrap-sass");

// not sure what that is:
// require("jquery.panzoom");
