<script>
import Notifications from "../components/Utils/notifications";
import { errorFeedback } from "../lib/error-feedback";

export function userErrorMessage(error) {
  const { variant, heading, message } = errorFeedback(error);

  const notifier =
    variant === "error" ? Notifications.error : Notifications.warn;

  notifier(message, heading);
}

/**
 * @deprecated import methods directly instead of using this mixin!
 * @todo remove this mixin once we use the method directly everywhere.
 */
export default {
  methods: {
    /**
     * @deprecated import directly instead of using it from the Vue prototype!
     * @todo refactor usage from prototype, and maybe improve the overall usage.
     */
    userErrorMessage,
  },
};
</script>
