<script>
export default {
  methods: {
    dropdownRole: function (user) {
      let userRole = user.roles.some((role) => role.name === "user");
      let consultantRole = user.roles.some(
        (role) => role.name === "consultant",
      );
      let woundUserRole = user.roles.some((role) => role.name === "wound_user");
      let seniorConsultantRole = user.roles.some(
        (role) => role.name === "senior_consultant",
      );
      let consultationPhotographerRole = user.roles.some(
        (role) => role.name === "consultation_photographer",
      );

      if (consultantRole) {
        return "consultant";
      } else if (seniorConsultantRole) {
        return "senior_consultant";
      } else if (woundUserRole) {
        return "wound_user";
      } else if (consultationPhotographerRole) {
        if (this.$store.state.dermicusPlatform === "stockholm") {
          return "consultation_photographer";
        } else if (userRole) {
          return "user";
        }
        return;
      } else if (
        !consultantRole &&
        !seniorConsultantRole &&
        !consultationPhotographerRole &&
        !woundUserRole &&
        userRole
      ) {
        return "user";
      }
      return "";
    },
  },
};
</script>
