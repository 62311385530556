<template>
  <div>
    <header-support :active-section="4" />

    <stockholm-support-contact-info
      v-if="$store.state.dermicusPlatform === 'stockholm'"
    />

    <skane-support-contact-info
      v-else-if="$store.state.dermicusPlatform === 'skane'"
    />

    <div v-else>
      <div class="mt-4">
        <h5 class="text-center text-uppercase">
          {{ trans("support.contactUs") }}
        </h5>
        <p class="text-center w-75 m-auto">
          {{ trans("support.contactUsInfoText") }}
        </p>
      </div>

      <section v-if="appConfig" class="contact my-3 tab-content">
        <div class="container">
          <div class="row text-center">
            <div class="col-sm-12 col-md-6 col-lg-4 my-3">
              <div class="card border-0">
                <div class="card-body text-center">
                  <i class="fas fa-phone fa-2x mb-3" aria-hidden="true" />
                  <h6 class="text-uppercase mb-3">
                    {{ trans("support.callUs") }}
                  </h6>
                  <p>
                    {{ appConfig.company.support.phone }}
                    <br />
                    {{ trans("support.supportTime") }}: 8:00 - 17:00
                  </p>
                </div>
              </div>
            </div>
            <div class="col-sm-12 col-md-6 col-lg-4 my-3">
              <div class="card border-0">
                <div class="card-body text-center">
                  <i class="fas fa-envelope fa-2x mb-3" aria-hidden="true" />
                  <h6 class="text-uppercase mb-3">Email</h6>
                  <p>
                    <a :href="`mailto:${appConfig.company.support.email}`">
                      {{ appConfig.company.support.email }}
                    </a>
                  </p>
                </div>
              </div>
            </div>
            <div class="col-sm-12 col-md-12 col-lg-4 my-3">
              <div class="card border-0">
                <div class="card-body text-center">
                  <i class="fas fa-headset fa-2x mb-3" aria-hidden="true" />
                  <h6 class="text-uppercase mb-3">
                    {{ trans("support.serviceDesk") }}
                  </h6>
                  <p>
                    <a
                      href="https://gnosco.atlassian.net/servicedesk/customer/portal/10"
                    >
                      {{ trans("support.serviceDesk") }}
                    </a>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section>
        <div class="container">
          <div class="row">
            <div class="col-sm-12 col-md-12 col-lg-6 text-center card border-0">
              <i class="fas fa-map-marker-alt fa-2x mb-3" />
              <h6 class="text-uppercase mb-3 text-center">Göteborg</h6>
              <p>
                Kungsgatan 4
                <br />411 19 Göteborg <br />Sweden
                <br />
                <a :href="`mailto:${appConfig.company.contact.email}`">
                  {{ appConfig.company.contact.email }}
                </a>
              </p>

              <div class="google-map mb-3">
                <iframe
                  src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2131.799609664464!2d11.954337915978929!3d57.70287428111955!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x464ff36816410915%3A0x95d1610efb39c3a3!2sKungsgatan%204%2C%20411%2019%20G%C3%B6teborg%2C%20Sverige!5e0!3m2!1ssv!2sus!4v1594043917980!5m2!1sen!2sus"
                  width="400"
                  height="300"
                  frameborder="0"
                  style="border: 0"
                  allowfullscreen
                  aria-hidden="false"
                  tabindex="0"
                />
              </div>
            </div>
            <div class="col-sm-12 col-md-12 col-lg-6 text-center card border-0">
              <i class="fas fa-map-marker-alt fa-2x mb-3" />
              <h6 class="text-uppercase mb-3 text-center">Kortrijk</h6>
              <p>
                Beneluxpark 26
                <br />8500 Kortrijk <br />Belgium <br />
                <a :href="`mailto:${appConfig.company.contact.email}`">
                  {{ appConfig.company.contact.email }}
                </a>
              </p>

              <div class="google-map mb-3">
                <iframe
                  src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2521.584998176131!2d3.278077399999999!3d50.80179919999999!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x47c33b32f325c25b%3A0x70e70b94222ee658!2sBeneluxpark%2026%2C%208500%20Kortrijk!5e0!3m2!1sen!2sbe!4v1685016559545!5m2!1sen!2sbe"
                  width="400"
                  height="300"
                  frameborder="0"
                  style="border: 0"
                  allowfullscreen
                  aria-hidden="false"
                  tabindex="0"
                />
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  </div>
</template>
<script setup>
import { useAsyncState } from "@vueuse/core";
import { appConfigGet } from "../../app/practitioner-portal/store/app-config";

const { state: appConfig } = useAsyncState(
  appConfigGet().then((response) => response.data),
  undefined,
);
</script>
<script>
import HeaderSupport from "./Header.vue";
import SkaneSupportContactInfo from "./RegionsSupport/SkaneSupportContactInfo.vue";
import StockholmSupportContactInfo from "./RegionsSupport/StockholmSupportContactInfo.vue";

export default {
  components: {
    HeaderSupport,
    StockholmSupportContactInfo,
  },
};
</script>

<style lang="scss" scoped>
@use "/resources/assets/sass/abstracts/variables";

.card:hover i,
.card:hover h5 {
  color: variables.$dermicusBlue;
  transition: all 0.3s;
}
</style>
