<template>
  <div>
    <div class="row equal-height row-eq-height">
      <div
        v-for="component in componentsFirstRow"
        :class="getRowClass(componentsFirstRow)"
      >
        <component :is="component.element" v-bind="component.props" />
      </div>
    </div>
    <div class="row">
      <div
        v-for="component in componentsSecondRow"
        :class="getRowClass(componentsSecondRow)"
      >
        <component :is="component.element" v-bind="component.props" />
      </div>
    </div>
  </div>
</template>
<script>
import { mapComponents } from "../../../Examinations/Examination/componentMapper";
import _drop from "lodash/fp/drop";
import _take from "lodash/fp/take";

export default {
  props: {
    examination: Object,
    product: Object,
  },
  computed: {
    components() {
      return this.product && this.examination
        ? mapComponents(
            this.product,
            JSON.parse(this.$store.state.user.permissions),
            this.examination,
            "patient_overview",
          )
        : [];
    },
    componentsFirstRow() {
      return _take(2, this.components);
    },
    componentsSecondRow() {
      return _drop(2, this.components);
    },
  },
  methods: {
    getRowClass(components) {
      return components.length > 1
        ? "col-md-12 col-lg-6 mb-4"
        : "col-md-12 mb-4";
    },
  },
};
</script>
