import { CenterFeatureName } from "./../../../../../app/Dermicus/Centers/CenterFeatureName";
import { practitionerHttpService } from "../../app/practitioner-portal/store/practitioner-http-service";
import { CenterSettings } from "../../../../../app/Http/Controllers/Api/Internal/CentersController";
import { avatarGet } from "./avatar";
import { CenterReportOptions } from "../../../../../app/Dermicus/Reporting/CenterReportOptions";
import { StudySummary } from "../../../../../app/Dermicus/Studies/StudySummary";

const centerUri = (centerId: number) => `/api/internal/centers/${centerId}`;

export function centerSettingsGet(centerId: number) {
  return practitionerHttpService
    .get<CenterSettings>(`${centerUri(centerId)}/settings`)
    .then((response) => response.data.center);
}

export function centerSettingsUpdate(centerId: number, data: CenterSettings) {
  return practitionerHttpService.post(`${centerUri(centerId)}/settings`, data);
}

export function centerAvatarGet(centerId: number) {
  return avatarGet(`${centerUri(centerId)}/logo`);
}

export function centerReportOptionsGet(centerId: number) {
  return practitionerHttpService
    .get<CenterReportOptions>(`${centerUri(centerId)}/report-options`)
    .then((response) => response.data);
}

export function centerReportOptionsUpdate({
  center_id,
  ...data
}: CenterReportOptions) {
  return practitionerHttpService
    .put<CenterReportOptions>(`${centerUri(center_id)}/report-options`, data)
    .then((response) => response.data);
}

export function centerFeatureGet(
  centerId: number,
  featureName: CenterFeatureName,
) {
  return {
    queryKey: ["center-feature", centerId, featureName],
    queryFn: () =>
      practitionerHttpService
        .get(`${centerUri(centerId)}/features/${featureName}`)
        .then((response) => response.data),
  };
}

export function centerFeatureEnable(
  centerId: number,
  featureName: CenterFeatureName,
) {
  return practitionerHttpService
    .post(`${centerUri(centerId)}/features/${featureName}`)
    .then((response) => response.data);
}

export function centerFeatureDisable(
  centerId: number,
  featureName: CenterFeatureName,
) {
  return practitionerHttpService
    .delete(`${centerUri(centerId)}/features/${featureName}`)
    .then((response) => response.data);
}

export function centerConsentStudiesGet(centerId: number) {
  return {
    queryKey: ["center-consent-studies", centerId],
    queryFn: () =>
      practitionerHttpService
        .get(`${centerUri(centerId)}/consent-studies`)
        .then((response) => response.data),
  };
}

export function centerConnectConsentStudiesGet(centerId: number) {
  return {
    queryKey: ["center-connect-consent-studies", centerId],
    queryFn: () =>
      Promise.all([
        practitionerHttpService.get<StudySummary[]>(
          `${centerUri(centerId)}/consent-studies`,
        ),
        practitionerHttpService.post<{ data: StudySummary[] }>(
          `/api/internal/studies/search`,
          { hasConsentPolicy: true },
        ),
      ]).then(([consentStudies, studies]) => {
        return studies.data.data.map((study) => ({
          ...study,
          isConnected: consentStudies.data.some(
            (consentStudy) => consentStudy.id === study.id,
          ),
        }));
      }),
  };
}

export function centerConsentStudyAttach(centerId: number, studyId: number) {
  return practitionerHttpService
    .post(`${centerUri(centerId)}/consent-studies/${studyId}`)
    .then((response) => response.data);
}

export function centerConsentStudyDetach(centerId: number, studyId: number) {
  return practitionerHttpService
    .delete(`${centerUri(centerId)}/consent-studies/${studyId}`)
    .then((response) => response.data);
}
