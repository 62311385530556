<template>
  <UseStoreResource :data-resource="patientResource">
    <PatientView
      :data-patient="patientResource.data.value"
      @update-top-navigation="handleUpdateTopNavigation"
    />
  </UseStoreResource>
</template>

<script setup lang="ts">
import { useRoute } from "vue-router";
import UseStoreResource from "../../design-system/UseStoreResource.vue";
import { usePatient } from "../../store/api/use-patient-resource";
import PatientView from "./PatientView.vue";
import { queryClient } from "../../store/api";
import { ref, watch } from "vue";

const route = useRoute();
const patientId = ref(route.params.id);

watch(
  () => route.params.id,
  (value) => (patientId.value = value),
  { immediate: true },
);

const patientResource = usePatient(patientId.value);

function handleUpdateTopNavigation() {
  queryClient.invalidateQueries(["patient", patientId]);
}
</script>
