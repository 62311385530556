<template>
  <div class="app-patient-portal-layout">
    <div class="app-patient-portal-header">
      <h4 class="app-patient-portal-title">
        {{ lang.get("patientPortal.title") }}
      </h4>
      <div class="app-patient-portal-user">
        <span>{{ dataPrincipal?.data.name }}</span>
        <button type="button" class="btn" @click="emit('logout')">
          <i class="fa fa-sign-out" />
        </button>
      </div>
    </div>

    <main class="app-patient-portal-main content-scroll">
      <slot />
    </main>

    <footer class="app-patient-portal-footer">
      <PatientPortalBrandLogo />
    </footer>
  </div>
</template>

<script setup lang="ts">
import Button from "../../components/Layout/Button.vue";
import PatientPortalBrandLogo from "./comp/PatientPortalBrandLogo.vue";
import { PatientPrincipalResource } from "../../../../../app/Http/Resources/PatientPrincipalResource";
import { toRefs } from "vue";
import { lang } from "../../i18n";

const props = defineProps<{ dataPrincipal?: PatientPrincipalResource }>();
const { dataPrincipal } = toRefs(props);

const emit = defineEmits({
  logout: () => {},
});
</script>
<style lang="scss">
@use "/resources/assets/sass/abstracts/variables";

.app-patient-portal-layout {
  position: absolute;
  width: 100%;
  height: 100%;
  display: grid;
  grid-template-rows: auto 1fr auto;
}

.app-patient-portal-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: variables.$dermicusBlue;
  color: variables.$dermicusWhite;
  padding: 1em;
}

.app-patient-portal-title {
  font-size: 1.2em;
  font-weight: variables.$font-weight-light !important;
  margin: 0;
}

.app-patient-portal-user {
  display: flex;
  align-items: center;

  .btn {
    color: variables.$dermicusWhite;
    padding: 0.2em 0.5em;
    line-height: 1;

    i {
      font-weight: variables.$font-weight-regular;
    }
  }
}

.app-patient-portal-main {
  padding: 1em;
}

.app-patient-portal-footer {
  display: flex;
  justify-content: center;
  padding: 1em;
  svg {
    width: 12em;
  }
}
</style>
