<template>
  <form novalidate class="panel panel-default" @submit.prevent="onSubmit">
    <div class="panel-heading">
      <h3 class="panel-title">
        {{ lang.get("model.region.reportOptions.label") }}
      </h3>
    </div>
    <AsyncState class="panel-body" :data-state="reportOptionsGet">
      <input v-model="reportOptions.region_id" name="region_id" type="hidden" />
      <div class="form-group" :class="{ 'has-error': !!formErrors.header }">
        <label :for="headerInputId">
          {{ lang.get("model.regionReportOptions.header.label") }}
        </label>
        <textarea
          :id="headerInputId"
          v-model="reportOptions.header"
          name="header"
          class="form-control"
        />
        <form-error v-if="!!formErrors.header" :errors="formErrors.header" />
      </div>
      <div class="text-right">
        <button type="submit" class="btn btn-primary">
          {{ lang.get("general.formSaveLabel") }}
        </button>
      </div>
    </AsyncState>
  </form>
</template>
<script setup lang="ts">
import { computed, shallowRef, toRefs, watchEffect } from "vue";
import { useAsyncState } from "@vueuse/core";
import {
  regionReportOptionsGet,
  regionReportOptionsUpdate,
} from "../../store/core/region-settings";
import AsyncState from "../../design-system/AsyncState.vue";
import { lang } from "../../i18n";
import { v4 as uuidV4 } from "uuid";
import { zfd } from "zod-form-data";
import { RegionReportOptions } from "../../../../../app/Dermicus/Reporting/RegionReportOptions";
import _cloneDeep from "lodash/fp/cloneDeep";
import { userErrorMessage } from "../../mixins/UserErrorMessage.vue";
import { z } from "zod";
import { notify } from "@kyvg/vue3-notification";

const FORM_SCHEMA = zfd.formData({
  region_id: zfd.numeric(),
  header: zfd.text(z.string().optional().nullable().default(null)),
});
const formErrors = shallowRef({});
const headerInputId = uuidV4();
const props = defineProps<{ dataRegionId: number }>();
const { dataRegionId } = toRefs(props);
const reportOptionsGet = useAsyncState(regionReportOptionsGet, undefined, {
  immediate: false,
});
watchEffect(() => reportOptionsGet.execute(0, dataRegionId.value));
const reportOptions = computed(() =>
  _cloneDeep(reportOptionsGet.state.value ?? {}),
);

function onSubmit({ target }: SubmitEvent) {
  formErrors.value = {};
  regionReportOptionsUpdate(
    FORM_SCHEMA.parse(new FormData(target as HTMLFormElement)),
  )
    .then(() => {
      notify({
        text: lang.get("general.notificationSaved"),
        type: "success",
        duration: 1000,
      });
    })
    .catch((error) => {
      userErrorMessage(error);
      if (error?.response?.status === 422) {
        formErrors.value = error.response.data;
      }
    });
}
</script>
