<template>
  <text-with-label :title="title" :data="value" />
</template>
<script>
import TextWithLabel from "./TextWithLabel.vue";
import _compact from "lodash/fp/compact";
import _join from "lodash/fp/join";
import { max } from "lodash";

const getOption = (key, opts) => opts?.valueOptions?.[0]?.[key];
const getValue = (key, opts) => opts?.examination?.[getOption(key, opts)];
const getSuffix = (opts) => getOption("postfix", opts);
const parseValue = (value) => parseInt(/(?<val>\d+)/.exec(value)?.groups?.val);
const formatValue = (val, opts) => _join(" ", _compact([val, getSuffix(opts)]));

/**
 * @typedef {Object} DividerFieldProps
 * @property {string} [title]
 * @property {Object} [extraOptions]
 * @property {Array<DividerFieldPropsValueOptions>} [extraOptions.valueOptions]
 * @property {Object<string, *>} [extraOptions.examination] - keys equal "valueOptions" values
 *
 * @typedef {Object} DividerFieldPropsValueOptions
 * @property {string} [dividend]
 * @property {string} [dividend_secondary]
 * @property {string} [divisor]
 * @property {string} [postfix]
 */
export default {
  components: { TextWithLabel },
  props: ["title", "extraOptions"],
  computed: {
    value() {
      const dividend = parseValue(
        max([
          getValue("dividend", this.extraOptions),
          getValue("dividend_secondary", this.extraOptions),
        ]),
      );
      const divisor = parseValue(getValue("divisor", this.extraOptions));
      const value = Math.round((dividend / divisor) * 100) / 100;
      return isNaN(value) ? "" : formatValue(value, this.extraOptions);
    },
  },
};
</script>
