<template>
  <div class="tw-flex tw-align-middle tw-gap-2">
    <label :for="inputId" class="tw-m-0">{{ label }}</label>
    <DerToggle
      v-bind="$attrs"
      :id="inputId"
      :model-value="modelValue"
      @update:model-value="$emit('update:modelValue', $event)"
    />
  </div>
</template>

<script setup lang="ts">
import DerToggle from "./DerToggle.vue";
import { v4 as uuidV4 } from "uuid";
import { computed } from "vue";

defineOptions({
  inheritAttrs: false,
});

const props = defineProps<{
  modelValue: boolean;
  id?: string;
  label: string;
}>();

const inputId = computed(() => props.id ?? uuidV4());

const emit = defineEmits<{
  (event: "update:modelValue", value: boolean): void;
}>();
</script>
