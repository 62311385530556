<template>
  <div class="col-12">
    <div class="tab-content">
      <clip-loader v-if="isLoading" />
      <Panel v-else :title="trans('region.regionExports')">
        <clip-loader v-if="isExporting" />
        <div v-show="!isExporting">
          <ExportView
            :data-available-exports="availableExports"
            :data-form-errors="errors"
            @export-data="exportCsv"
          />
        </div>
      </Panel>
    </div>
  </div>
</template>

<script>
import Panel from "../Layout/Panel.vue";
import ExportView from "../../app/practitioner-portal/generic/export/ExportView.vue";
import ExportExaminationsForm from "../../app/practitioner-portal/generic/export/ExportExaminationsForm.vue";
import ExportPatientLogForm from "../../app/practitioner-portal/generic/export/ExportPatientLogForm.vue";
import ExportUserLogForm from "../../app/practitioner-portal/generic/export/ExportUserLogForm.vue";
import contentDisposition from "content-disposition";
import { shortDateFormat } from "../../helpers/platform";
import ExportWithoutOptionsForm from "../../app/practitioner-portal/generic/export/ExportWithoutOptionsForm.vue";

export default {
  components: {
    ExportView,
    Panel,
  },
  data() {
    return {
      isLoading: true,
      isExporting: false,
      centers: [],
      errors: {},
    };
  },
  computed: {
    availableExports() {
      return [
        {
          type: "mole_consultations",
          name: this.trans(`admin.exports.types.mole_consultations.label`),
          form: ExportExaminationsForm,
          props: { dataAvailableCenters: this.centers },
        },
        {
          type: "users",
          name: this.trans(`admin.exports.types.users.label`),
          form: ExportWithoutOptionsForm,
        },
        {
          type: "patient_log",
          name: this.trans(`admin.exports.types.patient_log.label`),
          form: ExportPatientLogForm,
        },
        {
          type: "user_log",
          name: this.trans(`admin.exports.types.user_log.label`),
          form: ExportUserLogForm,
        },
      ];
    },
  },
  mounted: function () {
    this.getCenters();
  },
  methods: {
    getCenters: function () {
      this.isLoading = true;
      axios
        .get("/api/internal/region")
        .then((response) => {
          let data = response.data;
          this.centers = data.region.centers.map((center) => ({
            id: center.id,
            text: center.name,
          }));
          this.isLoading = false;
        })
        .catch((error) => {
          console.log("Region export error", error);
          this.isLoading = false;
        });
    },
    /**
     * @param {FormData} formData
     */
    exportCsv: function (formData) {
      this.isExporting = true;
      this.errors = {};

      formData.set("responseType", "arraybuffer");

      // TODO: This form data must be aligned, so all exports are using the
      //    same keys for the values, which would make so much more sense,
      //    and make code re-usable, as this is functionally the same....
      if (formData.has("centerId")) {
        formData.set("center", formData.get("centerId"));
        formData.delete("centerId");
      }
      if (formData.has("dateFrom")) {
        formData.set("fromDate", formData.get("dateFrom"));
        formData.delete("dateFrom");
      }
      if (formData.has("dateUntil")) {
        formData.set("untilDate", formData.get("dateUntil"));
        formData.delete("dateUntil");
      }

      // TODO: the data should be returned in ISO date format, so this conversion is not needed
      const format = shortDateFormat();
      if (formData.get("fromDate")) {
        formData.set(
          "fromDate",
          moment(formData.get("fromDate"), format).format("YYYY-MM-DD"),
        );
      }
      if (formData.get("untilDate")) {
        formData.set(
          "untilDate",
          moment(formData.get("untilDate"), format).format("YYYY-MM-DD"),
        );
      }

      axios
        .post("/api/internal/region-admin/export", formData)
        .then((response) => {
          const blob = new Blob(
            [new Uint8Array([0xef, 0xbb, 0xbf]), response.data],
            {
              type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
            },
          );

          const fileName = contentDisposition.parse(
            response.headers["content-disposition"],
          ).parameters.filename;

          if (window.navigator && window.navigator.msSaveOrOpenBlob) {
            window.navigator.msSaveOrOpenBlob(blob, fileName);
          }

          const data = window.URL.createObjectURL(blob);
          var link = document.createElement("a");
          link.href = data;
          link.download = fileName;
          link.click();

          setTimeout(function () {
            // For Firefox it is necessary to delay revoking the ObjectURL
            window.URL.revokeObjectURL(data);
          }, 100);

          this.isExporting = false;
        })
        .catch((error) => {
          this.errors = error.response.data;
          this.isExporting = false;
          this.userErrorMessage(error);
        });
    },
  },
};
</script>
