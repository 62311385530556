<template>
  <div>
    <div v-if="numberOfUnreadMessages">
      <span class="badge badge-pill badge-primary">{{
        numberOfUnreadMessages
      }}</span>
    </div>
    <div v-else-if="hasNotification && channelType === 'public'">
      <span class="badge badge-pill badge-primary">&nbsp;</span>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    numberOfUnreadMessages: [Number, Boolean],
    hasNotification: Boolean,
    channelType: String,
  },
};
</script>
