import multiguard from "vue-router-multiguard";
import PatientLoginRoute from "./auth/PatientLoginRoute.vue";
import PatientPortalRoute from "./PatientPortalRoute.vue";
import StudyConsentsRoute from "./StudyConsentsRoute.vue";
import StudyConsentRoute from "./StudyConsentRoute.vue";
import { AppModule } from "../routing/app-module";
import { redirect, ROUTES } from "../routing/routes";
import { patientLoginConfirm } from "./auth/patient-login-confirm";
import { patientLogout } from "./auth/patient-logout";
import { isPatientAuthenticated } from "./auth/is-patient-authenticated";
import { resolvePatientLocale } from "./resolve-patient-locale";

export const patientPortal: AppModule = {
  routes: [
    {
      ...ROUTES.patientLogin,
      beforeEnter: resolvePatientLocale,
      component: PatientLoginRoute,
    },
    {
      ...ROUTES.patientLoginConfirm,
      beforeEnter: multiguard([resolvePatientLocale, patientLoginConfirm]),
    },
    {
      ...ROUTES.patientLogout,
      beforeEnter: multiguard([resolvePatientLocale, patientLogout]),
    },
    {
      ...ROUTES.patient,
      redirect: redirect(ROUTES.patient, ROUTES["patient.studyConsents"]),
      beforeEnter: multiguard([resolvePatientLocale, isPatientAuthenticated]),
      component: PatientPortalRoute,
      children: [
        {
          ...ROUTES["patient.studyConsents"],
          component: StudyConsentsRoute,
        },
        {
          ...ROUTES["patient.studyConsent"],
          component: StudyConsentRoute,
        },
      ],
    },
  ],
};
