<template>
  <span class="float-right">
    <VDropdown>
      <template #popper>
        <div v-dompurify-html="textContent" />
      </template>

      <i class="fal fa-info-circle popover-link" />
    </VDropdown>
  </span>
</template>

<script>
export default {
  props: {
    textContent: String,
  },
};
</script>

<style lang="scss" scoped>
.popover-link {
  border: none;
}
</style>
