<template>
  <div id="reply-wrapper" class="panel panel-default panel-fill panel-actions">
    <div class="panel-heading panel-heading-underline">
      <h3 class="panel-title">
        {{ trans("examination.consultBoxLabel") }} ({{ totalComments }})

        <button
          v-if="!isEditOrAddedComment && !disableNewAnswer"
          type="button"
          class="btn btn-custom float-right"
          @click="openAddComment()"
        >
          {{ trans("examination.consultBoxAddNewButton") }}
        </button>

        <button
          v-if="isEditOrAddedComment"
          type="button"
          class="btn btn-custom float-right submit"
          :disabled="!text.value || savingDocumentation"
          :title="[
            !text.value
              ? trans('general.emptyComment')
              : trans('general.formSaveLabel'),
          ]"
          @click="submitComment()"
        >
          <i v-if="savingDocumentation" class="fas fa-circle-notch fa-spin" />
          <span>{{ trans("examination.modelSaveButtonText") }}</span>
        </button>
        <button
          v-if="isEditOrAddedComment"
          type="button"
          class="btn btn-custom float-right cancel"
          :title="trans('general.modalCancelButton')"
          @click="cancelEditComment()"
        >
          {{ trans("examination.modelCancelButtonText") }}
        </button>
        <info-popup :text-content="trans('tooltip.moleDocumentationBox')" />
      </h3>
    </div>
    <div class="panel-body chat-body">
      <div v-if="!isListHidden && !isEditOrAddedComment" class="chat-rows">
        <clip-loader v-show="loading" />
        <div v-for="(comment, index) in comments" :key="index" class="chat-row">
          <div v-if="comment.current">
            <div
              v-if="
                comment.current.isOwner &&
                ($can('edit_consultation_answers') || comment.current.type != 3)
              "
              class="toolbar float-right"
            >
              <a href="#" @click.prevent="editComment(comment.current)">{{
                trans("examination.diagnosReplyEditButton")
              }}</a>
              <br />
            </div>

            <div class="textholder">
              <label v-if="comment.current.type == 1">{{
                trans("examination.consultCommentTypeNotes")
              }}</label>
              <label v-if="comment.current.type == 2">{{
                trans("examination.consultCommentTypePatholgyAnswers")
              }}</label>
              <label v-if="comment.current.type == 3">{{
                trans("examination.consultCommentTypeConsultantResponse")
              }}</label>
              <label v-if="comment.current.type == 4">{{
                trans("examination.consultCommentTypeReferralAnswer")
              }}</label>

              <div>
                <VDropdown
                  :disabled="dermicusPlatform !== 'stockholm'"
                  class="d-inline"
                >
                  <template v-if="!fetchingUserContactInfo" #popper>
                    <div>
                      <b>{{ trans("users.personalInfoFullName") }}: </b
                      >{{ userContactInfo[0].firstname }}
                      {{ userContactInfo[0].lastname }}
                    </div>
                    <div>
                      <b>{{ trans("users.emailLabel") }}: </b
                      >{{ userContactInfo[0].email }}
                    </div>
                    <div>
                      <b>{{ trans("users.contactNoLabel") }}: </b
                      >{{ userContactInfo[0].phone_no }}
                    </div>
                  </template>
                  <template v-if="fetchingUserContactInfo" #popper>
                    <clip-loader />
                  </template>

                  <span
                    :class="[
                      dermicusPlatform === 'stockholm' ? 'popover-link' : '',
                      'text-muted font-weight-normal',
                    ]"
                    @click="
                      getUserContactInfo(
                        $route.params.id,
                        comment.current.user_id,
                      )
                    "
                  >
                    {{ comment.current.userName }}
                  </span>
                </VDropdown>
                <span class="font-weight-normal"
                  >({{ comment.current.created }})</span
                >
                <a
                  v-if="comment.current.editedCommentId > 0"
                  class="toolbar font-weight-bold"
                  data-toggle="modal"
                  :data-target="'#commentHistory'"
                  @click="getCommentHistory(comment.current)"
                >
                  {{ trans("examination.modelCommentHasBeenEdited") }}
                  {{ comment.current.updated }}</a
                >
              </div>

              <div
                v-if="
                  dermicusPlatform === 'stockholm' &&
                  comment.current.sentToTakeCare
                "
                class="font-weight-bold"
              >
                {{ trans("examination.diagnoseReplySent") }}
              </div>

              <div class="comment-text pt-1">
                {{ comment.current.comment }}
              </div>
            </div>
          </div>
          <!-- / div comment holder -->
          <div
            id="commentHistory"
            class="modal fade comment-history-modal"
            tabindex="-1"
            role="dialog"
            aria-hidden="true"
          >
            <div class="modal-dialog" role="document">
              <div class="modal-content">
                <div class="modal-header">
                  <h5 id="exampleModalLabel" class="modal-title">
                    {{ trans("examination.modelCommentHasBeenEditedlabel") }}
                  </h5>
                  <button
                    type="button"
                    class="close"
                    data-dismiss="modal"
                    aria-label="Close"
                  >
                    <span aria-hidden="true">&times;</span>
                  </button>
                </div>
                <div class="modal-body">
                  <div class="row">
                    <div class="m-b-20 col-md-12">
                      <div
                        v-for="(comment, index) in previousComments"
                        :key="index"
                        class="comment-line"
                      >
                        <b>{{ comment.userName }} ({{ comment.created }})</b>
                        <div class="text">
                          {{ comment.comment }}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div v-if="isEditOrAddedComment" class="write">
        <div
          class="form-group col-md-12"
          :class="{ 'has-error': type.hasError }"
        >
          <select2 v-model="type.value" name="type">
            <option v-if="$can('consult')" value="3">
              {{ trans("examination.consultCommentTypeConsultantResponse") }}
            </option>
            <option value="1">
              {{ trans("examination.consultCommentTypeNotes") }}
            </option>
            <option value="2">
              {{ trans("examination.consultCommentTypePatholgyAnswers") }}
            </option>
          </select2>

          <small v-if="type.value == 1" class="form-text pl-2">{{
            trans("forms.noNotificationNoteAnswer")
          }}</small>
          <small v-if="type.value == 2" class="form-text pl-2">{{
            trans("forms.noNotificationPathologyAnswer")
          }}</small>
          <small v-if="type.value == 3" class="form-text pl-2">{{
            trans("forms.warningAboutAnswerSavedInPatientRecords")
          }}</small>

          <span v-if="type.hasError" class="help-block">
            <strong>{{ type.errorMessage }}</strong>
          </span>
        </div>

        <div
          class="form-group col-md-12 textbox"
          :class="{ 'has-error': text.hasError }"
        >
          <textarea
            ref="documentation"
            v-model="text.value"
            :placeholder="trans('examination.modelTextAreaPlaceholder')"
          />
          <span v-if="text.hasError" class="help-block">
            <strong>{{ text.errorMessage }}</strong>
          </span>
        </div>
      </div>

      <div v-if="isListHidden && !loading">
        <button
          type="button"
          class="btn btn-primary m-t-10 center"
          :disabled="loading"
          @click="isListHidden = false"
        >
          {{ trans("examination.diagnosReplyShowConsultAnswers") }}
        </button>
      </div>

      <button
        v-if="
          totalComments >= 10 &&
          paginate.current_page !== paginate.last_page &&
          !isEditOrAddedComment
        "
        type="button"
        class="btn btn-primary m-t-10 center"
        :disabled="loading"
        @click="loadMoreComments()"
      >
        {{ trans("examination.imageCompoareLoadMoreButton") }}
      </button>
    </div>
  </div>
</template>

<script>
import InfoPopup from "../../Utils/InfoPopup.vue";

export default {
  components: {
    InfoPopup,
  },
  props: ["editSaveUri", "productType", "disableNewAnswer"],
  data() {
    return {
      loading: true,
      dermicusPlatform: this.$store.state.dermicusPlatform,
      savingDocumentation: false,
      comments: [],
      previousComments: [],
      totalComments: 0,
      isListHidden: true,
      currentCommentId: null,
      type: {
        value: "",
        hasError: false,
        errorMessage: "",
      },
      text: {
        value: "",
        hasError: false,
        errorMessage: "",
      },
      isEditOrAddedComment: false,
      paginate: {},
      userContactInfo: [
        {
          firstname: "",
          lastname: "",
          email: "",
          phone_no: "",
        },
      ],
      fetchingUserContactInfo: false,
    };
  },

  mounted: function () {
    this.getComments();
  },
  methods: {
    getComments: function () {
      var editUrl = this.currentCommentId
        ? this.editSaveUri + "/" + this.currentCommentId
        : this.editSaveUri;
      axios.get(editUrl).then((response) => {
        this.comments = response.data.comments;
        this.loading = false;
        this.paginate = response.data.paginate;
        this.isListHidden = response.data.hideList;
        this.totalComments = response.data.totalComments;
      });
    },
    getCommentHistory: function (comment) {
      this.previousComments = [];
      axios
        .get(this.editSaveUri + "/comment-history/" + comment.id)
        .then((response) => {
          this.previousComments = response.data.comments;
        });
    },
    loadMoreComments: function () {
      axios.get(this.paginate.next_page_url).then((response) => {
        this.comments.push.apply(this.comments, response.data.comments);
        this.paginate = response.data.paginate;
        this.loading = false;
      });
    },
    editComment: function (comment) {
      this.currentCommentId = comment.id;
      this.text.value = comment.comment.replace(/<br\s*[\/]?>/gi, "\r");
      this.type.value = comment.type;
      this.isEditOrAddedComment = true;
    },
    cancelEditComment: function () {
      this.currentCommentId = null;
      this.text.value = "";
      this.type.value = "";
      this.isEditOrAddedComment = false;

      this.getComments();
      this.resetFieldErrors();
    },
    openAddComment: function () {
      this.isEditOrAddedComment = true;
      this.$nextTick(() => {
        this.$refs.documentation.focus();
      });

      this.type.value = this.mayAddConsultationAnswer() ? 3 : 1;
    },
    mayAddConsultationAnswer: function () {
      return this.$can("consult") || this.productType == "wound";
    },
    resetFieldErrors: function () {
      this.type.errorMessage = null;
      this.type.hasError = false;
      this.text.errorMessage = null;
      this.text.hasError = false;
    },
    submitComment: function () {
      this.loading = true;
      this.savingDocumentation = true;
      let instance = this;
      var editUrl = this.currentCommentId
        ? this.editSaveUri + "/" + this.currentCommentId
        : this.editSaveUri;

      let comment = this.text.value;
      this.text.value = "";

      axios
        .post(editUrl, {
          _token: Laravel.csrfToken,
          type: this.type.value,
          text: comment,
        })
        .then((response) => {
          this.loading = false;
          this.savingDocumentation = false;
          this.isEditOrAddedComment = false;
          this.currentCommentId = null;
          this.text.value = "";
          comment = "";

          this.getComments();
          this.resetFieldErrors();
        })
        .catch(function (error) {
          instance.text.value = comment;
          instance.savingDocumentation = false;

          if (error.response) {
            let status = error.response.status;
            let data = error.response.data;

            if (status == 422) {
              if (data.type) {
                instance.type.errorMessage = data.type[0];
                instance.type.hasError = true;
              }

              if (data.text) {
                instance.text.errorMessage = data.text[0];
                instance.text.hasError = true;
              }
              instance.userErrorMessage(error);
              instance.loading = false;
            }
          }
        });
    },
    getUserContactInfo: function (caseId, userId) {
      this.fetchingUserContactInfo = true;

      axios
        .get(`/api/internal/examination-user/${caseId}/${userId}`)
        .then((response) => {
          const data = response.data;
          this.userContactInfo = data;
          this.fetchingUserContactInfo = false;
        })
        .catch((error) => {
          this.fetchingUserContactInfo = false;
          this.userErrorMessage(error);
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.comment-text {
  word-break: break-word;
  word-wrap: break-word;
  white-space: break-spaces;
}
</style>
