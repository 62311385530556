<template>
  <div class="write mb-2 mx-2">
    <div
      v-if="selectedDocumentationType && editType === 'editDocumentation'"
      class="col-md-12 my-3"
    >
      <input
        type="text"
        :value="selectedDocumentationType.typeLabel"
        disabled
        class="form-control"
      />
    </div>

    <div
      class="form-group col-md-12 textbox"
      :class="{ 'has-error': error || (errors && errors.text) }"
    >
      <small class="form-text description">{{ description }}</small>
      <span v-if="error" class="help-block">
        <strong>{{ error }}</strong>
      </span>

      <textarea
        ref="documentation"
        v-model="text"
        :placeholder="trans('examination.modelTextAreaPlaceholder')"
        class="text-area"
      />
      <span v-if="error" class="help-block">
        <strong>{{ error }}</strong>
      </span>
      <form-error v-if="errors && errors.text" :errors="errors.text" />
    </div>

    <div class="col-md-12 text-right space-x-2">
      <button
        type="button"
        class="btn btn-secondary"
        :title="trans('general.modalCancelButton')"
        @click="onCancel"
      >
        {{ trans("examination.modelCancelButtonText") }}
      </button>
      <button
        type="button"
        class="btn btn-primary"
        :disabled="!text || isSaving"
        :title="
          !text ? trans('general.emptyComment') : trans('general.formSaveLabel')
        "
        @click="onSubmit"
      >
        <i v-if="isSaving" class="fas fa-circle-notch fa-spin" />
        <span>{{ trans("examination.modelSaveButtonText") }}</span>
      </button>
    </div>
  </div>
</template>

<script setup>
import { computed, ref } from "vue";
import { useAlertUnsavedChanges } from "../../../../../lib-vue/use-alert-unsaved-changes";

const props = defineProps({
  comment: String || undefined,
  error: String,
  isSaving: Boolean,
  description: String,
  selectedDocumentationType: [Object, String],
  errors: [Object, Array],
  editType: String,
});

const emit = defineEmits(["on-submit", "on-cancel"]);

let text = ref(props.comment ?? "");

let isDirty = computed(() => !!text.value);

useAlertUnsavedChanges(isDirty);

const onSubmit = () => {
  emit("on-submit", text.value);
};

const onCancel = () => {
  emit("on-cancel");
};
</script>

<style lang="scss" scoped>
.description {
  margin-bottom: 10px;
}

.text-area {
  min-height: 200px;
  border: 1px solid #dee2e6;
}

.form-group.has-error textarea {
  border-color: #d57171;
}
</style>
