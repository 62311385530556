<template>
  <div>
    <chart-header :header-name="elementsHeaderName" />

    <div>
      <clip-loader v-if="loading" />
      <div
        v-if="
          !loading &&
          elements &&
          elements.length === 1 &&
          elements[0].value === 0
        "
        class="text-center m-3"
      >
        {{ trans("statistics.noResponseData") }}
      </div>
      <div
        v-for="(action, index) in elementsList"
        v-else-if="!loading"
        :key="index"
        class="px-3"
      >
        <progress-bar :diagnosis="action" :color="barColor" :type="chartType" />
      </div>
      <button
        v-if="!loading && elements && Object.keys(elements).length > 5"
        class="btn btn-lg btn-block mt-2 show-all-button"
        @click="showAll = !showAll"
      >
        <i :class="[showAll ? 'fa-angle-up' : 'fa-angle-down', 'fas']" />
        {{
          showAll
            ? trans("center.showLess")
            : trans("examination.consultationShowAllButton")
        }}
      </button>
    </div>
  </div>
</template>

<script>
import ProgressBar from "./ProgressBar.vue";
import ChartHeader from "../StatisticsUtils/ChartHeader.vue";

export default {
  components: {
    ProgressBar,
    ChartHeader,
  },
  props: {
    loading: Boolean,
    elements: [Array, Object],
    elementsHeaderName: String,
    barColor: String,
    chartType: String,
  },
  data: function () {
    return {
      showAll: false,
    };
  },
  computed: {
    elementsList: function () {
      if (this.elements) {
        if (!this.showAll && Object.keys(this.elements).length > 5) {
          return Array.from(Object.values(this.elements)).slice(0, 5);
        } else {
          return this.elements;
        }
      }
    },
  },
};
</script>
