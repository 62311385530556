import { ExaminationNavigationDetails } from "../components/Examinations/Examination/ExaminationComponents/components/ExaminationNavigator.vue";

/**
 * Small utility to store data in the browser history state in a typesafe way.
 */

export type HistoryState = {
  examinationNavigationDetails?: {
    examinations: ExaminationNavigationDetails;
    paginationInfo: {
      current_page: number;
      last_page: number;
    };
  };
};

/**
 * Used to create a history state object to pass to Vue Router's `push` method.
 */
export function setHistoryState<T extends keyof HistoryState>(
  key: T,
  data: HistoryState[T],
) {
  return { [key]: data };
}

/**
 * Used to get a value from the history state object.
 */
export function getHistoryState<T extends keyof HistoryState>(
  key: T,
): HistoryState[T] {
  return history.state[key];
}
