import { StudyResultStatus } from "./StudyResultStatus";

type Rectangle = { x: number; y: number; width: number; height: number };

export function rectangleAsString(value: Rectangle) {
  return `${value.x} ${value.y} ${value.width} ${value.height}`;
}

export type ImageMetadata = {
  viewBox?: null | Rectangle;
  boundingBox?: null | Rectangle;
};

export type BoundingBoxMetadata = {
  viewBox?: null | Rectangle;
  boundingBox: Rectangle;
};

export function getViewBoxAsString(value: BoundingBoxMetadata) {
  return value.viewBox && rectangleAsString(value.viewBox);
}

export function hasBoundingBox(
  metadata: ImageMetadata | undefined
): metadata is BoundingBoxMetadata {
  return !!metadata?.boundingBox;
}

export type StudyResult = {
  id: number;
  study_examination_id: number;
  type: "external_result" | "participant_result";
  status: StudyResultStatus;
  metadata: null | {
    images?: Record<string, ImageMetadata>;
  };
  created_at: string;
};
