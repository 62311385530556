<template>
  <div>
    <div class="row">
      <div class="col-md-4 mb-2">
        <label>{{ trans("evaluation.tableColumnCreatedAtFrom") }}</label>
        <custom-datepicker
          :date="searchFields.dateFrom"
          :placeholder="trans('evaluation.tableColumnCreatedAtFrom')"
          @on-date-change="changeDates('dateFrom', $event)"
        />
      </div>
      <div class="col-md-4 mb-2">
        <label>{{ trans("evaluation.tableColumnCreatedAtTo") }}</label>
        <custom-datepicker
          :date="searchFields.dateTo"
          :placeholder="trans('evaluation.tableColumnCreatedAtTo')"
          @on-date-change="changeDates('dateTo', $event)"
        />
      </div>

      <div class="col-md-4 mb-2">
        <label>{{ trans("evaluation.tableColumnCenter") }}</label>
        <v-select
          :options="searchOptions.centers"
          :model-value="center"
          label="text"
          :reduce="(country) => country.id"
          :placeholder="trans('examination.externalResourceSelectPlaceholder')"
          @update:model-value="searchFields.center = $event"
        />
      </div>

      <div class="col-md-4 mb-2">
        <label>{{ trans("evaluation.tableColumnUserTypes") }}</label>
        <v-select
          :options="searchOptions.userTypes"
          :model-value="searchFields.userType"
          label="text"
          :reduce="(userType) => userType.value"
          :placeholder="trans('examination.externalResourceSelectPlaceholder')"
          @update:model-value="updateUserType($event)"
        />
      </div>

      <div class="col-md-4 mb-2">
        <label>{{ trans("evaluation.tableColumnUsers") }}</label>
        <v-select
          :options="computedUsersList"
          :model-value="user"
          label="text"
          :reduce="(user) => user.id"
          :placeholder="trans('examination.externalResourceSelectPlaceholder')"
          @update:model-value="searchFields.user = $event"
        />
      </div>

      <div class="col-md-4 mb-2">
        <label>{{ trans("evaluation.tableColumnStatus") }}</label>
        <v-select
          v-model="searchFields.status"
          name="status"
          :options="searchOptions.statuses"
          label="text"
          :reduce="(status) => status.id"
          :placeholder="trans('examination.externalResourceSelectPlaceholder')"
        />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ["searchFields", "searchOptions"],
  data: function () {
    return {
      selectedUserType: null,
    };
  },
  computed: {
    center: function () {
      let center = this.searchOptions.centers.some(
        (center) => center.id === this.searchFields.center,
      );
      if (center) {
        return this.searchFields.center;
      }
      return;
    },
    user: function () {
      let user = this.searchOptions.users.some(
        (user) => user.id === this.searchFields.user,
      );
      if (user) {
        return this.searchFields.user;
      }
      return;
    },
    computedUsersList: function () {
      if (this.selectedUserType) {
        return this.searchOptions.users.filter((user) => {
          return user.types.includes(this.selectedUserType);
        });
      }
      return this.searchOptions.users;
    },
  },
  methods: {
    changeDates: function (target, date) {
      if (target == "dateFrom") {
        this.searchFields.dateFrom = date;
      } else {
        this.searchFields.dateTo = date;
      }
    },
    updateUserType: function (userType) {
      this.searchFields.userType = userType;
      this.searchFields.user = "";
      this.selectedUserType = userType;
    },
  },
};
</script>
