<template>
  <div>
    <label
      >{{ title }}
      <i v-if="required" class="far fa-asterisk" />
    </label>
    <der-slider
      :model-value="value"
      :disabled="disabled"
      :data-options="options"
      :data-option-value="extraOptions?.fieldOptions?.value"
      :data-option-label="extraOptions?.fieldOptions?.label"
      :data-option-min="extraOptions?.fieldOptions?.min"
      :data-option-max="extraOptions?.fieldOptions?.max"
      :data-option-interval="extraOptions?.fieldOptions?.interval"
      :data-option-marks="marks"
      class="tw-px-4"
      @update:model-value="onChange"
    />
  </div>
</template>

<script setup lang="ts">
import DerSlider from "../../../../Utils/DerSlider.vue";
import { computed, onBeforeUnmount, ref, toRefs, watch } from "vue";
import { lang } from "../../../../../i18n";
import { FixedProps } from "../../../../../lib-vue/fixed-props";

/**
 * `DerRangeSlider` is a slider component with customizable configuration.
 */
defineOptions({});

const emit = defineEmits<{
  (e: "field-unmount"): void;
  (e: "isValid", payload: boolean): void;
}>();

onBeforeUnmount(() => {
  emit("field-unmount");
});

const props = defineProps<{
  title?: string;
  required?: boolean;
  disabled?: boolean;
  formValue?: string | number;
  extraOptions?: {
    key?: string;
    onValueChange: (payload: { key?: string; value?: number }) => void;
    fieldOptions?: {
      options?: { value: number; label: string }[];
      marks?: { [key: number]: string };
      value?: string;
      label?: string;
      min?: number;
      max?: number;
      interval?: number;
    };
  };
}>();

const { formValue, extraOptions, required } = toRefs(
  props as FixedProps<typeof props>,
);

const options = computed(() =>
  extraOptions.value?.fieldOptions?.options?.map(({ label, ...option }) => ({
    ...option,
    /**
     * We have added the ability to be able to translate DB data in the field
     * definitions. These begin with `data.`. This is a temporary solution until
     * we add something like the FHIR translation extension to our data sources.
     *
     * @see {import("../../../../../../../../app/Dermicus/Products/Field").Field}
     */
    label: label.startsWith("data.") ? lang.get(label) : label,
  })),
);

/**
 * Same as above for marks.
 */
const marks = computed(() => {
  const marksObj = extraOptions.value?.fieldOptions?.marks;
  if (!marksObj) return;

  return Object.fromEntries(
    Object.entries(marksObj).map(([key, value]) => [
      key,
      value.startsWith("data.") ? lang.get(value) : value,
    ]),
  );
});

const value = ref();

watch(
  formValue,
  () => {
    value.value = formValue?.value;
  },
  { immediate: true },
);

function onChange(v: number) {
  value.value = v;
  extraOptions?.value?.onValueChange({
    key: extraOptions?.value.key,
    value: v,
  });
}

const isValid = computed(
  () => !required.value || (value.value !== undefined && value.value !== null),
);
watch(isValid, (newValue) => emit("isValid", newValue), { immediate: true });
</script>
