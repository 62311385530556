import { AppModule } from "../routing/app-module";
import { ROUTES } from "../routing/routes";
import { checkStudiesAndRedirect } from "./check-studies-and-redirect";
import ResearchAdminRoute from "./ResearchAdminRoute.vue";
import ResearchAdminStudyCasesRoute from "./ResearchAdminStudyCasesRoute.vue";
import ResearchAdminStudyParticipantsRoute from "./ResearchAdminStudyParticipantsRoute.vue";
import ResearchAdminStudyExportRoute from "./ResearchAdminStudyExportRoute.vue";

export const researchAdmin: AppModule = {
  routes: [
    {
      ...ROUTES.researchAdmin,
      beforeEnter: checkStudiesAndRedirect,
      component: ResearchAdminRoute,
      meta: {
        requiresAuth: true,
        researchAdminAuth: true,
      },
      children: [
        {
          ...ROUTES["researchAdmin.studyCases"],
          component: ResearchAdminStudyCasesRoute,
          meta: {
            requiresAuth: true,
            researchAdminAuth: true,
          },
        },
        {
          ...ROUTES["researchAdmin.studyParticipants"],
          component: ResearchAdminStudyParticipantsRoute,
          meta: {
            requiresAuth: true,
            researchAdminAuth: true,
          },
        },
        {
          ...ROUTES["researchAdmin.studyExport"],
          component: ResearchAdminStudyExportRoute,
          meta: {
            requiresAuth: true,
            researchAdminAuth: true,
          },
        },
        /**
         * Redirect to root of module for deprecated URLs.
         * Path param `studyId` added to allow multiple studies.
         */
        { path: "*", redirect: ROUTES["researchAdmin"] },
      ],
    },
  ],
};
