<template>
  <div>
    <form role="form">
      <div class="form-row">
        <div v-if="user.id" class="m-b-20 col-md-6">
          <div class="form-group" :class="{ 'has-error': errors.username }">
            <label for="username">{{ trans("users.usernameLabel") }}</label>
            <i class="far fa-asterisk" />
            <input v-model="user.username" type="text" class="form-control" />
            <form-error v-if="errors.username" :errors="errors.username" />
          </div>
        </div>
        <div :class="[user.id ? 'col-md-6' : 'col-md-12', 'm-b-20']">
          <div class="form-group" :class="{ 'has-error': errors.email }">
            <label for="email">{{ trans("users.emailLabel") }}</label>
            <i class="far fa-asterisk" />
            <input
              v-model="user.email"
              type="email"
              class="form-control"
              :placeholder="trans('forms.email')"
            />
            <form-error v-if="errors.email" :errors="errors.email" />
          </div>
        </div>
      </div>

      <div class="form-row">
        <div class="m-b-20 col-md-6">
          <div class="form-group" :class="{ 'has-error': errors.firstname }">
            <label for="first_name">{{ trans("users.firstNameLabel") }}</label>
            <i class="far fa-asterisk" />
            <input
              v-model="user.firstname"
              type="text"
              name="first_name"
              class="form-control"
              :placeholder="trans('forms.firstname')"
            />
            <form-error v-if="errors.firstname" :errors="errors.firstname" />
          </div>
        </div>
        <div class="m-b-20 col-md-6">
          <div class="form-group" :class="{ 'has-error': errors.lastname }">
            <label for="last_name">{{ trans("users.lastNameLabel") }}</label>
            <i class="far fa-asterisk" />
            <input
              v-model="user.lastname"
              type="text"
              name="last_name"
              class="form-control"
              :placeholder="trans('forms.lastname')"
            />
            <form-error v-if="errors.lastname" :errors="errors.lastname" />
          </div>
        </div>
      </div>

      <div class="form-row">
        <div class="m-b-20 col-md-6">
          <div class="form-group" :class="{ 'has-error': errors.phone_no }">
            <label for="phone_no">{{ trans("users.contactNoLabel") }}</label>
            <i
              v-if="$store.state.dermicusPlatform === 'stockholm'"
              class="far fa-asterisk"
            />
            <input
              v-model="user.phone_no"
              type="text"
              name="phone_no"
              class="form-control"
              :placeholder="trans('forms.phoneNumber')"
            />
            <form-error v-if="errors.phone_no" :errors="errors.phone_no" />
          </div>
        </div>
        <div class="col-md-6 m-b-20">
          <div class="form-group" :class="{ 'has-error': errors.culture }">
            <label for="culture">{{ trans("users.languageLabel") }}</label>
            <i class="far fa-asterisk" />
            <select2
              v-model="user.culture"
              name="culture"
              :placeholder="trans('forms.language')"
            >
              <option v-if="$store.state.dermicusPlatform === 'uk'" value="en">
                {{ trans("users.languageOptionEnglish") }}
              </option>
              <option value="sv">
                {{ trans("users.languageOptionSwedish") }}
              </option>
              <option v-if="$store.state.dermicusPlatform !== 'uk'" value="en">
                {{ trans("users.languageOptionEnglish") }}
              </option>
              <option value="nl">
                {{ trans("users.languageOptionDutch") }}
              </option>
              <option value="de">
                {{ trans("users.languageOptionGerman") }}
              </option>
              <option value="fr">
                {{ trans("users.languageOptionFrench") }}
              </option>
              <option value="it">
                {{ trans("users.languageOptionItalian") }}
              </option>
            </select2>
            <form-error v-if="errors.culture" :errors="errors.culture" />
          </div>
        </div>
      </div>

      <div class="form-row">
        <div class="m-b-20 col-md-6">
          <div class="form-group">
            <label for="title">{{ trans("users.jobTitleLabel") }}</label>
            <input
              v-model="user.title"
              type="text"
              name="title"
              class="form-control"
              :placeholder="trans('forms.jobTitle')"
            />
          </div>
        </div>
        <div class="m-b-20 col-md-6">
          <div class="form-group" :class="{ 'has-error': errors.hsa_id }">
            <label for="hsa_id">{{ trans("users.hsaIdLabel") }}</label>
            <i
              v-if="dermicusPlatform === 'stockholm'"
              class="far fa-asterisk"
            />
            <input
              v-model="user.hsa_id"
              type="text"
              name="hsa_id"
              class="form-control"
              :placeholder="
                dermicusPlatform === 'stockholm'
                  ? trans('forms.hsaIdPlaceholderStockholmPlatform')
                  : trans('forms.hsaIdPlaceholder')
              "
            />
            <small
              v-if="dermicusPlatform === 'stockholm'"
              id="hsa_id"
              class="form-text text-muted"
              >{{ trans("forms.hsaIdPlaceholderStockholmPlatform") }}</small
            >
            <form-error v-if="errors.hsa_id" :errors="errors.hsa_id" />
          </div>
        </div>
      </div>

      <div class="form-row">
        <div class="m-b-20 col-md-6">
          <div class="form-group">
            <label for="role">{{ trans("users.userRoleLabel") }}</label>
            <i class="far fa-asterisk" />
            <select2
              name="role"
              :model-value="dropdownRole(user)"
              @update:model-value="selectDropdownRole($event)"
            >
              <option value />
              <option value="user">
                {{ trans("roles.user") }}
              </option>
              <option value="consultant">
                {{ trans("roles.consultant") }}
              </option>
              <option value="senior_consultant">
                {{ trans("roles.senior_consultant") }}
              </option>
              <option value="wound_user">
                {{ trans("roles.wound_user") }}
              </option>
              <option
                v-if="$store.state.dermicusPlatform === 'stockholm'"
                value="consultation_photographer"
              >
                {{ trans("roles.consultation_photographer") }}
              </option>
            </select2>
          </div>
        </div>
        <div class="m-b-20 col-md-6">
          <DerToggleWithLabel
            :model-value="!userRoles.user"
            :label="trans('users.blockedUser')"
            @update:model-value="userRoles.user = !$event"
          />
        </div>
      </div>

      <div class="row m-b-20 col-md-12">
        <div class="col">
          <hr />
        </div>
        <div class="col-auto">
          <span class="hr-text">{{
            trans("center.userSettingsManagedByUserLabel")
          }}</span>
        </div>
        <div class="col">
          <hr />
        </div>
      </div>

      <div class="col-md-12 row">
        <div class="m-b-20 col-md-4">
          <div class="form-group tw-space-y-2">
            <DerToggleWithLabel
              :label="trans('roles.external_resource')"
              :model-value="hasAnyExternalResourceRole"
              @update:model-value="updateHasAnyExternalResourceRole"
            />

            <select2
              v-if="hasAnyExternalResourceRole"
              name="externalResourceRole"
              :model-value="selectedExternalResourceRole"
              disable-clear="true"
              @update:model-value="updateSelectedExternalResourceRole"
            >
              <option value="external_resource">
                {{ trans("roles.external_resource_full_title") }}
              </option>
              <option value="region_external_resource">
                {{ trans("roles.region_external_resource_full_title") }}
              </option>
            </select2>
          </div>
        </div>
        <div class="m-b-20 col-md-4">
          <DerToggleWithLabel
            v-model="user.hide_case_answers"
            :label="trans('users.hideConsultAnswerLabel')"
          />
        </div>
        <div class="m-b-20 col-md-4">
          <DerToggleWithLabel
            v-model="user.hide_personal_data"
            :label="trans('users.hidePersonalNoLabel')"
          />
        </div>
      </div>

      <div class="row m-b-20 col-md-12">
        <div class="col">
          <hr />
        </div>
        <div class="col-auto">
          <span class="hr-text">{{
            trans("center.userSettingsManagedByAdminLabel")
          }}</span>
        </div>
        <div class="col">
          <hr />
        </div>
      </div>

      <div class="col-md-12 row">
        <div class="m-b-20 col-md-4">
          <DerToggleWithLabel
            v-model="userRoles.center_admin"
            :label="trans('roles.center_admin')"
          />
        </div>
        <div class="m-b-20 col-md-4">
          <DerToggleWithLabel
            v-model="userRoles.case_admin"
            :label="trans('roles.case_admin')"
          />
        </div>
      </div>

      <div class="m-b-20 col-md-12">
        <div class="form-group">
          <label for="comment">{{ trans("users.commentLabel") }}</label>
          <input
            v-model="user.comment"
            type="text"
            name="comment"
            class="form-control"
            :placeholder="trans('forms.comment')"
          />
        </div>
      </div>

      <!-- Also used to create new users, so primary center does not always exist. -->
      <div v-if="viewAsRegionAdmin && user?.primary_center">
        <div class="m-b-20 col-md-12">
          <div class="form-group">
            <label class="tw-block">
              {{ trans("users.primaryCenterLabel") }}:
              <span class="tw-font-light">{{ user.primary_center.name }}</span>
            </label>
            <button type="button" class="btn btn-primary" @click="showModal">
              <i class="fa fa-external-link-alt" />
              <span>{{ trans("users.changePrimaryCenterLabel") }}</span>
            </button>
          </div>
        </div>

        <VueJsDialogMixinWrapper
          v-if="showMoveUserDialog"
          :data-component="moveUserDialogComponent"
          :data-options="moveUserDialogOptions"
          @user-settings-changes="$emit('userSettingsChanges')"
          @close="closeModal"
        />
      </div>
    </form>
  </div>
</template>

<script>
import DerToggleWithLabel from "../../../design-system/DerToggleWithLabel.vue";
import DropdownRole from "../../../mixins/DropdownRole.vue";
import VueJsDialogMixinWrapper from "../../../mixins/VueJsDialogMixinWrapper.vue";
import CenterMoveUser from "./MoveUser.vue";

export default {
  components: {
    VueJsDialogMixinWrapper,
    DerToggleWithLabel,
  },
  mixins: [DropdownRole],
  props: {
    user: {},
    errors: {},
    userRoles: {
      consultant: Boolean,
      senior_consultant: Boolean,
      wound_user: Boolean,
      user: Boolean,
      center_admin: Boolean,
      region_admin: Boolean,
      external_resource: Boolean,
      region_external_resource: Boolean,
      admin: Boolean,
      case_admin: Boolean,
      consultation_creator: Boolean,
      consultation_photographer: Boolean,
    },
    viewAsRegionAdmin: {
      type: Boolean,
      required: true,
    },
  },
  emits: ["userSettingsChanges"],
  data: function () {
    return {
      role: "",
      dermicusPlatform: this.$store.state.dermicusPlatform,
      lastSelectedExternalResourceRole: "external_resource",
      showMoveUserDialog: false,
      moveUserDialogComponent: CenterMoveUser,
    };
  },
  computed: {
    hasAnyExternalResourceRole() {
      return !!(
        this.userRoles["external_resource"] ||
        this.userRoles["region_external_resource"]
      );
    },
    selectedExternalResourceRole() {
      if (this.userRoles["external_resource"]) {
        this.lastSelectedExternalResourceRole = "external_resource";
      } else if (this.userRoles["region_external_resource"]) {
        this.lastSelectedExternalResourceRole = "region_external_resource";
      }

      return this.lastSelectedExternalResourceRole;
    },
    moveUserDialogOptions() {
      return {
        userId: this.user.id,
        primaryCenter: this.user.primary_center,
      };
    },
  },
  methods: {
    showModal() {
      this.showMoveUserDialog = true;
    },
    closeModal() {
      this.showMoveUserDialog = false;
    },
    selectDropdownRole: function (selectedRole) {
      this.role = selectedRole;

      this.userRoles.consultant = selectedRole === "consultant";
      this.userRoles.consultation_creator = [
        "consultant",
        "senior_consultant",
        "user",
      ].includes(selectedRole);
      this.userRoles.consultation_photographer =
        selectedRole === "consultation_photographer";
      this.userRoles.senior_consultant = selectedRole === "senior_consultant";
      this.userRoles.wound_user = selectedRole === "wound_user";
      // WARNING: the 'user' role is controlled by the 'inactivated' toggle
    },
    updateHasAnyExternalResourceRole(value) {
      this.userRoles[this.selectedExternalResourceRole] = !!value;
    },
    updateSelectedExternalResourceRole(role) {
      this.lastSelectedExternalResourceRole = role;
      this.userRoles["external_resource"] = false;
      this.userRoles["region_external_resource"] = false;
      this.userRoles[role] = true;
    },
  },
};
</script>

<style lang="scss" scoped>
.hr-text {
  display: inline-block;
  vertical-align: middle;
  line-height: 2.5em;
  font-weight: bold;
  font-size: 12px;
  color: #676a6c;
}
</style>
