<template>
  <div class="panel panel-default panel-fill">
    <div class="panel-heading">
      <h3 class="panel-title">
        {{ title }}
      </h3>
      <div v-if="$slots.header" class="panel-heading__actions">
        <slot name="header" />
      </div>
    </div>
    <div class="panel-body">
      <slot />
    </div>
  </div>
</template>

<script>
export default {
  props: ["title"],
};
</script>

<style lang="scss" scoped>
.panel {
  border: 0;
}

.panel-heading {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0;
  margin: 0;

  &__tooltip {
    margin-right: 1em;
  }
}

.panel-body {
  border: 2px solid #f5f5f5;
  border-top: 0;
}

.panel-title {
  padding: 15px 20px;
}

.panel-heading__actions {
  line-height: 1.2;
  display: flex;
  align-self: stretch;
  align-items: center;

  .btn {
    border-radius: 0;
    font-size: 12px !important;
    background: #7a7d84;
    border: none;
    height: 100%;

    &:hover,
    &:active,
    &:focus {
      border: 0 !important;
    }
  }
}
</style>
