<template>
  <div class="table-responsive">
    <table
      class="table"
      :class="isInteractive && 'table-hover interactive-table'"
    >
      <thead class="sortable-table-header">
        <tr>
          <th v-for="(heading, index) in headings" :key="index">
            {{ heading.title }}
            <i v-if="heading.sortable" class="fas fa-caret-down" />
          </th>
        </tr>
      </thead>
      <tbody>
        <slot />
      </tbody>
    </table>
  </div>
</template>

<script>
export default {
  props: {
    headings: Array,
    isInteractive: Boolean,
  },
};
</script>

<style lang="scss" scoped></style>
