<script setup lang="ts">
import { useRouter } from "vue-router";
import { useStore } from "vuex";
import { resetLocalStorage } from "../../helpers/misc";
import swal from "sweetalert2";

const store = useStore();
const router = useRouter();

resetLocalStorage();
store.commit("setAuthenticated", false);
store.commit("setUser", {});
swal.close();
$(".pswp").remove();

router.push({ name: "Auth" });
</script>
