import { practitionerHttpService } from "../app/practitioner-portal/store/practitioner-http-service";

export function userPrimaryCenterUpdate(userId: number, centerId: number) {
  return practitionerHttpService.put(
    `/api/internal/users/${userId}/center-connections/primary`,
    {
      id: centerId,
    },
  );
}
