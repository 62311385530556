<template>
  <UseStoreResource :data-resource="userQuicksilvaFeatureResource">
    <UserQuicksilvaConnectionPanel v-if="hasQuicksilva" />
  </UseStoreResource>
</template>

<script setup lang="ts">
import { computed } from "vue";
import UseStoreResource from "../../design-system/UseStoreResource.vue";
import { useUserQuicksilvaFeature } from "../../store/api/use-user-quicksilva-resource";
import UserQuicksilvaConnectionPanel from "./UserQuicksilvaConnectionPanel.vue";

const userQuicksilvaFeatureResource = useUserQuicksilvaFeature();

const hasQuicksilva = computed(
  () => userQuicksilvaFeatureResource.data.value?.active,
);
</script>
