<template>
  <div class="form-group">
    <label :for="centerIdInputId">
      {{ trans("examination.centerLabel") }}
    </label>
    <select2
      :id="centerIdInputId"
      name="centerId"
      :options="dataAvailableCenters"
      :placeholder="trans('forms.chooseCenterPlaceholderOptional')"
    />
  </div>
</template>
<script setup>
import { v4 as uuidV4 } from "uuid";

const centerIdInputId = uuidV4();

defineProps({
  dataAvailableCenters: {
    type: /** @type {Array<{ id: number, text: string }>} */ Array,
    required: true,
  },
});
</script>
