<template>
  <div class="custom-view-wrapper">
    <div class="dg-content-body">
      <clip-loader v-show="loading" />

      <div v-if="!loading" class="dg-content">
        <b>{{ trans("examination.tabLabelPrint") }}</b> <br /><br />

        <div
          v-if="
            type == 'mole' || type == 'mole-uk' || type == 'RegionSkaneMole'
          "
        >
          <button
            class="dg-btn dg-btn--ok mb-2 w-100"
            @click="printRemiss('referral', 'examination.printTypeRemiss')"
          >
            <span>{{ trans("examination.printCaseRemiss") }}</span>
          </button>
          <button
            v-if="type == 'mole' || type == 'RegionSkaneMole'"
            class="dg-btn dg-btn--ok mb-2 w-100"
            @click="printRemiss('images', 'examination.printTypeImages')"
          >
            <span>{{ trans("examination.printCaseImages") }}</span>
          </button>
          <button
            v-if="type == 'mole-uk'"
            class="dg-btn dg-btn--ok mb-1 w-100"
            @click="printRemiss('summary', 'examination.printTypeSummary')"
          >
            <span>{{ trans("examination.printCaseSummary") }}</span>
          </button>
        </div>

        <div v-if="type == 'wound' || type == 'photo' || type == 'TumourWound'">
          <button
            class="dg-btn dg-btn--ok mb-1 w-100"
            @click="printRemiss('images', 'examination.printTypeImages')"
          >
            <span>{{ trans("examination.printCaseImages") }}</span>
          </button>
        </div>
      </div>
    </div>

    <div class="dg-content-footer">
      <button class="dg-btn dg-btn--cancel w-100" @click="handleDismiss()">
        <span>{{ trans("examination.printCaseCancel") }}</span>
      </button>
      <div class="dg-clear" />
    </div>
  </div>
</template>

<script>
import DialogMixin from "../../../mixins/vuejs-dialog-mixin"; // Include mixin

export default {
  mixins: [DialogMixin],
  data() {
    return {
      loading: false,
      type: "",
    };
  },
  mounted: function () {
    this.type = this.options.data.type;
  },
  methods: {
    handleDismiss() {
      this.cancel(); // included in DialogMixin
    },
    printRemiss: function (pdfType, translationKey) {
      this.loading = true;

      axios
        .get(`${this.options.data.uri}?type=${pdfType}`, {
          responseType: "arraybuffer",
        })
        .then((response) => {
          const blob = new Blob([response.data], {
            type: "application/pdf",
          });

          let fileName = `${this.options.data.caseNo}-${this.trans(
            translationKey,
          )}`;

          if (window.navigator && window.navigator.msSaveOrOpenBlob) {
            window.navigator.msSaveOrOpenBlob(blob, fileName);
            //return;
          }

          const data = window.URL.createObjectURL(blob);
          var link = document.createElement("a");
          link.href = data;
          link.download = fileName;
          link.click();

          this.loading = false;

          setTimeout(function () {
            // For Firefox it is necessary to delay revoking the ObjectURL
            window.URL.revokeObjectURL(data), 100;
          });
        })
        .catch((error) => {
          this.userErrorMessage(error);
          this.loading = false;
        });
    },
  },
};
</script>
