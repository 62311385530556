<template>
  <div class="tw-relative">
    <img v-bind="$attrs" class="tw-w-full" />
    <svg
      xmlns="http://www.w3.org/2000/svg"
      :viewBox="getViewBoxAsString(dataMetadata)"
      class="tw-absolute tw-top-0 tw-left-0 tw-w-full tw-h-full tw-pointer-events-none"
    >
      <rect
        v-bind="dataMetadata.boundingBox"
        fill="none"
        stroke="#4d7dbb"
        stroke-width="3px"
        vector-effect="non-scaling-stroke"
      />
    </svg>
  </div>
</template>

<script lang="ts">
export default {
  inheritAttrs: false,
};
</script>

<script setup lang="ts">
import {
  BoundingBoxMetadata,
  getViewBoxAsString,
} from "../../../../../app/Dermicus/Studies/StudyResult";

const props = defineProps<{
  dataMetadata: BoundingBoxMetadata;
}>();
</script>
