<template>
  <div>
    <center-header :selected-menu-item="3" />

    <div class="tab-content space-y-2">
      <div class="clear">
        <button class="btn btn-grey" @click="back">
          <span>{{ trans("general.back") }}</span>
        </button>
      </div>

      <clip-loader v-show="loading" />

      <div v-if="!loading">
        <div class="panel panel-default">
          <div class="panel-heading">
            <h3 class="panel-title">
              {{ trans("center.groupUsersHeadline") }}
            </h3>
          </div>
          <div
            v-if="group.id || $route.path.includes('new')"
            class="panel-body"
          >
            <div class="row">
              <div
                class="form-group m-b-20 col-md-6"
                :class="{ 'has-error': errors.groupName }"
              >
                <label>{{ trans("center.groupUsersGroupNameLabel") }}</label>
                <i class="far fa-asterisk" />
                <input
                  id="title"
                  v-model="group.name"
                  type="text"
                  name="title"
                  class="form-control"
                  :placeholder="trans('center.groupTableGroupName')"
                />
                <form-error
                  v-if="errors.groupName"
                  :errors="errors.groupName"
                />
              </div>

              <div class="m-b-20 col-md-6">
                <label>{{ trans("center.groupUsersLabel") }}</label>

                <select2
                  class="users select2 form-control responsive"
                  multiple="multiple"
                  :model-value="group.users"
                >
                  <optgroup :label="trans('forms.chooseUserPlaceholder')">
                    <option
                      v-for="(user, index) in users"
                      :key="index"
                      :value="user.id"
                    >
                      {{ user.name }} ({{ user.primary_center }})
                    </option>
                  </optgroup>
                </select2>
              </div>
            </div>

            <span class="text-left"
              ><i class="far fa-asterisk" />
              {{ trans("center.requiredField") }}</span
            >

            <div class="text-right space-x-2">
              <button
                v-if="group.name && group.id"
                class="btn btn-danger"
                @click="removeGroup"
              >
                {{ trans("general.formDeleteLabel") }}
              </button>
              <button
                v-if="!loading"
                class="btn btn-primary"
                :disabled="!isValid || loading"
                :title="
                  isValid ? '' : trans('examination.requiredFieldMissing')
                "
                @click="saveGroupChanges"
              >
                {{ trans("general.formSaveLabel") }}
              </button>
            </div>
          </div>
          <div v-else class="panel-body">
            <div class="text-center">
              {{ trans("chat.groupDeletedMessage") }}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import CenterHeader from "../CenterHeader.vue";

export default {
  components: {
    CenterHeader,
  },
  data() {
    return {
      loading: false,
      group: {
        name: "",
        users: "",
        id: "",
      },
      users: [],
      errors: [],
      centerId: null,
      dataUrl: null,
    };
  },
  computed: {
    isValid: function () {
      if (!this.group.name) {
        return false;
      } else {
        return true;
      }
    },
    assignUrlandCenterId: function () {
      if (
        this.$can("manage_region") &&
        this.$store.state.user.primaryCenter.region_id &&
        (!this.$can("manage_center") || this.$route.path.includes("region"))
      ) {
        return {
          centerId: this.$route.params.id,
          url: "/api/internal/region-admin",
          adminType: "Region",
        };
      } else {
        return {
          centerId: this.$store.state.user.primaryCenter.id,
          url: "/api/internal/center-admin",
          adminType: "",
        };
      }
    },
  },
  mounted: function () {
    this.dataUrl = this.assignUrlandCenterId.url;
    this.centerId = this.assignUrlandCenterId.centerId;
    this.group.id = this.$route.params.groupId;

    this.getGroup();
  },
  methods: {
    getGroup: function () {
      this.loading = true;

      let url;

      if (this.$route.path.includes("new")) {
        url = this.dataUrl + "/groups/" + this.centerId + "/new";
      } else {
        url = this.dataUrl + "/groups/" + this.centerId + "/" + this.group.id;
      }

      axios
        .get(url)
        .then((data) => {
          let collection = data.data;
          this.group = collection.group;
          this.users = collection.users;
          this.loading = false;
        })
        .catch((error) => {
          this.loading = false;
          this.userErrorMessage(error);
        });
    },
    saveGroupChanges: function () {
      this.loading = true;
      this.errors = [];

      let saveUri =
        this.dataUrl + "/groups/" + this.centerId + "/" + this.group.id;
      axios
        .post(saveUri, {
          groupName: this.group.name,
          resources: $(".users").val(),
        })
        .then((response) => {
          this.$notify({
            text: this.trans("general.notificationSaved"),
            type: "success",
            duration: 1000,
          });

          if (response.data.isNew) {
            this.$router.push({
              name: `${this.assignUrlandCenterId.adminType}CenterGroups`,
            });
          }

          this.group = response.data.group;
          this.users = response.data.users;

          this.loading = false;
        })
        .catch((error) => {
          this.loading = false;
          this.errors = error.response.data;
          this.userErrorMessage(error);
        });
    },
    removeGroup: function () {
      let instance = this;

      this.$vueAlert
        .confirm(
          this.trans("general.modalRemoveGroupMessage"),
          undefined,
          undefined,
          {
            confirmButtonText: this.trans("general.modalConfirmButton"),
            cancelButtonText: this.trans("general.modalCancelButton"),
          },
        )
        .then(() => {
          axios
            .delete(
              instance.dataUrl +
                "/groups/" +
                instance.centerId +
                "/" +
                instance.group.id,
            )
            .then((response) => {
              instance.$notify({
                text: this.trans("general.notificationSaved"),
                type: "success",
                duration: 1000,
              });

              this.$router.push({
                name: `${this.assignUrlandCenterId.adminType}CenterGroups`,
              });
            });
        })
        .catch((error) => {
          instance.userErrorMessage(error);
        });
    },
    back: function () {
      this.$router.go(-1);
    },
  },
};
</script>
