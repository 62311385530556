<template>
  <div>
    <horizontal-bars-chart
      :elements="dermatoscopicDiagnoses"
      elements-header-name="dermatoskopicDiagnoses"
      :bar-color="color"
      chart-type="diagnoses"
      :loading="loading"
    />
  </div>
</template>

<script>
import HorizontalBarsChart from "./ChartTypes/HorizontalBarsChart.vue";

export default {
  components: {
    HorizontalBarsChart,
  },
  props: {
    dermatoscopicDiagnoses: {
      label: String,
      value: Number,
      percentage: Number,
    },
    loading: Boolean,
  },
  data: function () {
    return {
      color: "#b92844",
    };
  },
};
</script>
