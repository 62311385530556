import _get from "lodash/fp/get";
import _replace from "lodash/fp/replace";
import { lang } from "../i18n";
import Lang from "lang.js";

export type ErrorFeedback = {
  variant: "error" | "warning";
  heading?: string;
  message: string;
};

export function errorFeedbackProvider(config: { lang: Lang }) {
  return function errorFeedback(error: unknown): ErrorFeedback {
    const httpStatus = _get("response.status", error);
    const variant = httpStatus === 422 ? "warning" : "error";

    const httpErrorCode = _get("response.data.errorCode", error);
    const httpErrorCodeMessageKey = `errorMessages.HTTPStatus.${httpStatus}_${httpErrorCode}.message`;
    if (config.lang.has(httpErrorCodeMessageKey)) {
      const httpErrorCodeHeadingKey = `errorMessages.HTTPStatus.${httpStatus}_${httpErrorCode}.heading`;
      return {
        variant: variant,
        heading: config.lang.has(httpErrorCodeHeadingKey)
          ? config.lang.get(httpErrorCodeHeadingKey)
          : `${httpStatus}`,
        message: config.lang.get(httpErrorCodeMessageKey),
      };
    }

    const httpStatusMessageKey = `errorMessages.HTTPStatus.${httpStatus}`;
    if (config.lang.has(httpStatusMessageKey)) {
      return {
        variant: variant,
        heading: `${httpStatus}`,
        message: config.lang.get(httpStatusMessageKey),
      };
    }

    const httpGroup = _replace(/(\d)\d+/, "$1XX", httpStatus);
    const httpGroupMessageKey = `errorMessages.HTTPStatus.${httpGroup}`;
    if (config.lang.has(httpGroupMessageKey)) {
      return {
        variant: variant,
        heading: `${httpStatus}`,
        message: config.lang.get(httpGroupMessageKey),
      };
    }

    return {
      variant: variant,
      message: config.lang.get("errorMessages.HTTPStatus.XXX"),
    };
  };
}

export const errorFeedback = errorFeedbackProvider({ lang });
