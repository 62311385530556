import { AppModule } from "../routing/app-module";
import { ROUTES } from "../routing/routes";
import ResearchRoute from "./ResearchRoute.vue";
import ResearchCaseRoute from "./ResearchCaseRoute.vue";
import { redirectByAvailableStudies } from "./redirect-by-available-studies";
import { RouterView } from "vue-router";

export const research: AppModule = {
  routes: [
    {
      path: ROUTES.research.path,
      component: RouterView,
      children: [
        {
          path: "",
          name: ROUTES.research.name,
          beforeEnter: redirectByAvailableStudies,
          component: ResearchRoute,
          meta: {
            requiresAuth: true,
            researchParticipantAuth: true,
          },
        },
        {
          ...ROUTES.researchResearchCase,
          component: ResearchCaseRoute,
          meta: {
            requiresAuth: true,
            researchParticipantAuth: true,
          },
        },
      ],
    },
  ],
};
