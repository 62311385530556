<template>
  <div class="panel panel-default">
    <div class="panel-heading panel-heading-underline">
      <h3 class="panel-title">
        {{ trans("patient.patientVievVascularLabel") }}
      </h3>
    </div>
    <div v-if="examination" class="panel-body">
      <div class="row">
        <div class="m-b-20 col-md-12">
          <strong>{{ trans("patient.patientViewBoxLabelAnamnes") }}</strong>
          <p class="text-muted">
            {{ trans("examination.medicalHistoryDeepVentrombosis") }}:
            <span v-if="isEditing">
              <select2
                v-model="examination.case.medicalHistoryDeepVentrombosis"
                class="select2 form-control responsive groups"
              >
                <option value="UNKNOWN">
                  {{ trans("examination.unknown") }}
                </option>
                <option value="YES">{{ trans("examination.yes") }}</option>
                <option value="NO">{{ trans("examination.no") }}</option>
              </select2>
            </span>
            <span v-else class="deepVentrombosis">{{
              trans(
                "examination." +
                  examination.case.medicalHistoryDeepVentrombosis,
              )
            }}</span>
            <br />
            {{ trans("patient.patientVievVaricer") }}:
            <span v-if="isEditing">
              <select2
                v-model="examination.case.varicoseVeins"
                class="select2 form-control responsive groups"
              >
                <option value="UNKNOWN">
                  {{ trans("examination.unknown") }}
                </option>
                <option value="YES">{{ trans("examination.yes") }}</option>
                <option value="NO">{{ trans("examination.no") }}</option>
              </select2>
            </span>
            <span v-else class="varicoseVeins">{{
              trans("examination." + examination.case.varicoseVeins)
            }}</span>
            <br />
            {{ trans("patient.patientVievVaricoseVeinOperatedLabel") }}:
            <span v-if="isEditing">
              <select2
                v-model="examination.case.varicoseVeinsSurgery"
                class="select2 form-control responsive groups"
              >
                <option value="UNKNOWN">
                  {{ trans("examination.unknown") }}
                </option>
                <option value="YES">{{ trans("examination.yes") }}</option>
                <option value="NO">{{ trans("examination.no") }}</option>
              </select2>
            </span>
            <span v-else class="varicoseVeinsSurgery">{{
              trans("examination." + examination.case.varicoseVeinsSurgery)
            }}</span>
            <br />
            {{ trans("patient.patientVievArterialSurgery") }}:
            <span v-if="isEditing">
              <select2
                v-model="examination.case.arterialSurgery"
                class="select2 form-control responsive groups"
              >
                <option value="UNKNOWN">
                  {{ trans("examination.unknown") }}
                </option>
                <option value="YES">{{ trans("examination.yes") }}</option>
                <option value="NO">{{ trans("examination.no") }}</option>
              </select2>
            </span>
            <span v-else class="arterialSurgery">{{
              trans("examination." + examination.case.arterialSurgery)
            }}</span>
          </p>
        </div>

        <div class="m-b-20 col-md-12">
          <strong>{{ trans("patient.patientVievSensibilityLabel") }}</strong>
          <p class="text-muted">
            {{ trans("patient.patientVievImpairedSensoryWoundAreaLabel") }}:
            <span v-if="isEditing">
              <select2
                v-model="examination.case.impairedSensibilityInWoundArea"
                class="select2 form-control responsive groups"
              >
                <option value="YES">{{ trans("examination.yes") }}</option>
                <option value="NO">{{ trans("examination.no") }}</option>
              </select2>
            </span>
            <span v-else>
              <VDropdown>
                {{
                  trans(
                    "examination." +
                      examination.case.impairedSensibilityInWoundArea,
                  )
                }}
                <!-- <template v-slot:popper>
                  <div
                    v-for="(impairedSensibilityInWoundAreaHistory,
                    index) in examination.history.woundArea"
                    :key="index"
                  >
                    {{
                      trans(
                        "examination." +
                          impairedSensibilityInWoundAreaHistory.value
                      )
                    }}
                    -
                    {{ impairedSensibilityInWoundAreaHistory.createdAt }}
                  </div>
                </template> -->
              </VDropdown>
            </span>
            <br />

            {{ trans("examination.sensibilityMonofilament") }}:
            <br />
            {{ trans("examination.sensibilityMonofilamentDx") }}:
            <span v-if="isEditing">
              <select2
                v-model="examination.case.sensibilityMonofilamentDx"
                class="select2 form-control responsive groups"
              >
                <option value="sensibilityMonofilamentFootNormal">
                  {{ trans("examination.sensibilityMonofilamentFootNormal") }}
                </option>
                <option value="sensibilityMonofilamentFootReduced">
                  {{ trans("examination.sensibilityMonofilamentFootReduced") }}
                </option>
                <option value="sensibilityMonofilamentFootNone">
                  {{ trans("examination.sensibilityMonofilamentFootNone") }}
                </option>
              </select2>
            </span>
            <span v-else>
              <VDropdown>
                <span class="sensibilityMonofilamentDx">{{
                  trans(
                    "examination." + examination.case.sensibilityMonofilamentDx,
                  )
                }}</span>
                <template #popper>
                  <div
                    v-for="(
                      sensibilityMonofilamentDxHistory, index
                    ) in examination.history.sensibilityMonofilamentDx"
                    :key="index"
                  >
                    {{
                      trans(
                        "examination." + sensibilityMonofilamentDxHistory.value,
                      )
                    }}
                    - {{ sensibilityMonofilamentDxHistory.createdAt }}
                  </div>
                </template>
              </VDropdown>
            </span>
            <br />

            {{ trans("examination.sensibilityMonofilamentSin") }}:
            <span v-if="isEditing">
              <select2
                v-model="examination.case.sensibilityMonofilamentSin"
                class="select2 form-control responsive groups"
              >
                <option value="sensibilityMonofilamentFootNormal">
                  {{ trans("examination.sensibilityMonofilamentFootNormal") }}
                </option>
                <option value="sensibilityMonofilamentFootReduced">
                  {{ trans("examination.sensibilityMonofilamentFootReduced") }}
                </option>
                <option value="sensibilityMonofilamentFootNone">
                  {{ trans("examination.sensibilityMonofilamentFootNone") }}
                </option>
              </select2>
            </span>
            <span v-else>
              <VDropdown>
                <span class="sensibilityMonofilamentSin">{{
                  trans(
                    "examination." +
                      examination.case.sensibilityMonofilamentSin,
                  )
                }}</span>
                <template #popper>
                  <div
                    v-for="(
                      sensibilityMonofilamentSinHistory, index
                    ) in examination.history.sensibilityMonofilamentSin"
                    :key="index"
                  >
                    {{
                      trans(
                        "examination." +
                          sensibilityMonofilamentSinHistory.value,
                      )
                    }}
                    - {{ sensibilityMonofilamentSinHistory.createdAt }}
                  </div>
                </template>
              </VDropdown>
            </span>
          </p>
        </div>

        <div class="clearfix" />

        <div class="m-b-20 col-md-12">
          <strong>{{ trans("examination.vascularStatus") }}</strong>
          <p class="text-muted">
            {{ trans("examination.peripheralPulsesDorsalisPedis") }}
            <span v-if="isEditing">
              <select2
                v-model="examination.case.peripheralPulsesDorsalisPedis"
                class="select2 form-control responsive groups"
              >
                <option value="YES">{{ trans("examination.yes") }}</option>
                <option value="NO">{{ trans("examination.no") }}</option>
              </select2>
            </span>
            <span v-else>
              <VDropdown>
                <span class="peripheralPulsesDorsalisPedis">{{
                  trans(
                    "examination." +
                      examination.case.peripheralPulsesDorsalisPedis,
                  )
                }}</span>
                <template #popper>
                  <div
                    v-for="(
                      peripheralPulsesDorsalisPedisHistory, index
                    ) in examination.history.peripheralPulsesDorsalisPedis"
                    :key="index"
                  >
                    {{
                      trans(
                        "examination." +
                          peripheralPulsesDorsalisPedisHistory.value,
                      )
                    }}
                    -
                    {{ peripheralPulsesDorsalisPedisHistory.createdAt }}
                  </div>
                </template>
              </VDropdown>
            </span>
            <br />

            {{ trans("examination.peripheralPulsesTibialisPosterior") }}
            <span v-if="isEditing">
              <select2
                v-model="examination.case.peripheralPulsesTibialisPosterior"
                class="select2 form-control responsive groups"
              >
                <option value="YES">{{ trans("examination.yes") }}</option>
                <option value="NO">{{ trans("examination.no") }}</option>
              </select2>
            </span>
            <span v-else>
              <VDropdown>
                <span class="peripheralPulsesTibialisPosterior">{{
                  trans(
                    "examination." +
                      examination.case.peripheralPulsesTibialisPosterior,
                  )
                }}</span>
                <template #popper>
                  <div
                    v-for="(
                      peripheralPulsesTibialisPosteriorHistory, index
                    ) in examination.history.peripheralPulsesTibialisPosterior"
                    :key="index"
                  >
                    {{
                      trans(
                        "examination." +
                          peripheralPulsesTibialisPosteriorHistory.value,
                      )
                    }}
                    -
                    {{ peripheralPulsesTibialisPosteriorHistory.createdAt }}
                  </div>
                </template>
              </VDropdown>
            </span>
            <br />

            {{ trans("examination.ankleIndex") }}:
            <span v-if="isEditing">
              <input
                v-model="examination.case.ankleIndex"
                type="text"
                class="form-control"
              />
            </span>
            <span v-else>
              <VDropdown>
                <span class="ankleIndex">{{
                  examination.case.ankleIndex
                }}</span>
                <template #popper>
                  <div
                    v-for="(ankelIndexHistory, index) in examination.history
                      .ankleIndex"
                    :key="index"
                  >
                    {{ ankelIndexHistory.value }} -
                    {{ ankelIndexHistory.createdAt }}
                  </div>
                </template>
              </VDropdown>
            </span>
          </p>
        </div>
      </div>
    </div>

    <div v-else class="panel-body">
      {{ trans("general.emptyDataReuslt") }}
    </div>
  </div>
</template>

<script>
export default {
  props: {
    isEditing: Boolean,
    examination: {
      case: {
        medicalHistoryDeepVentrombosis: String,
        varicoseVeins: String,
        varicoseVeinsSurgery: String,
        arterialSurgery: String,
        impairedSensibilityInWoundArea: String,
      },
      history: {
        // woundArea: {
        //     value: ,
        //     createdAt:
        // },
        sensibilityMonofilamentDx: [
          {
            value: String,
            createdAt: String,
          },
        ],
        sensibilityMonofilamentSin: [
          {
            value: String,
            createdAt: String,
          },
        ],
        peripheralPulsesDorsalisPedis: [
          {
            value: String,
            createdAt: String,
          },
        ],
        peripheralPulsesTibialisPosterior: [
          {
            value: String,
            createdAt: String,
          },
        ],
        ankleIndex: [
          {
            value: String,
            createdAt: String,
          },
        ],
      },
    },
  },
};
</script>
