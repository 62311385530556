import { patientAuthService } from "./patient-auth-service";
import { locationOf, ROUTES } from "../../routing/routes";
import { NavigationGuard } from "vue-router";

/**
 * Checks if patient is authenticated, redirect to login page if not.
 */
export const isPatientAuthenticated: NavigationGuard = (to, from, next) => {
  if (patientAuthService.isAuthenticated()) {
    next();
  } else {
    next(
      locationOf(ROUTES.patientLogin, {
        query: { callbackUrl: to.redirectedFrom?.fullPath || to.fullPath },
      }),
    );
  }
};
