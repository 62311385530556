<template>
  <div id="activeCenters" class="tab-content">
    <div class="table-responsive">
      <div id="custom-search-input" class="mb-3">
        <form class="input-group col-md-12" @submit.prevent="search">
          <input
            id="search-field"
            v-model="searchFields.q"
            type="text"
            class="form-control input-lg"
            :placeholder="trans('forms.searchForCenterPlaceholder')"
          />
          <span class="input-group-btn">
            <button
              v-if="!isSearching"
              class="btn"
              type="button"
              @click.stop="search"
            >
              <i class="far fa-search" />
            </button>
            <button
              v-if="isSearching"
              class="btn"
              type="button"
              @click.stop="clearSearch"
            >
              <i class="fal fa-times" />
            </button>
          </span>
        </form>
      </div>

      <clip-loader v-show="loading" />

      <table class="table table-hover interactive-table">
        <thead class="sortable-table-header">
          <tr>
            <th>{{ trans("center.name") }}</th>
            <th>{{ trans("forms.hsaId") }}</th>
            <th>{{ trans("center.primaryUsers") }}</th>
            <th>{{ trans("examination.createdAtLabel") }}</th>
          </tr>
        </thead>
        <tbody>
          <tr
            v-for="(center, index) in centers"
            :key="index"
            :class="{
              last: isLastVisited(center.id),
            }"
            @click="openCenter(center.id)"
          >
            <td>{{ center.name }}</td>
            <td>{{ center.hsa_id }}</td>
            <td>{{ center.total_users }}</td>
            <td>
              {{ center.created_at_formatted }}
            </td>
          </tr>
        </tbody>
      </table>
    </div>

    <div class="row-fluid float-right">
      <DerPaginate
        v-model="paginate.current_page"
        :page-count="paginate.last_page"
        @change="getCenters"
      />
    </div>
  </div>
</template>

<script>
import DerPaginate from "../../../../design-system/DerPaginate.vue";
import { queryStringBuilder } from "../../../../helpers/misc";

export default {
  components: { DerPaginate },
  props: {
    centerType: String,
  },
  data() {
    return {
      loading: true,
      centers: [],
      paginate: {
        current_page: 0,
        total: 0,
        last_page: 0,
      },
      isSearching: false,
      searchFields: {
        page: 1,
        q: null,
        lastVisitedId: null,
      },
    };
  },
  created: function () {
    this.setQueryFields();
    this.getCenters();
  },
  methods: {
    getCenters: function (page) {
      this.loading = true;
      this.searchFields.page = page || this.searchFields.page;
      localStorage.setItem(
        `${this.centerType}_centers`,
        JSON.stringify(this.searchFields),
      );
      let queryCenterType =
        this.centerType === "active" ? "centers" : "deleted-centers";
      axios
        .get(
          `/api/internal/admin/${queryCenterType}?${queryStringBuilder(
            this.searchFields,
          )}`,
        )
        .then((data) => {
          const collection = data.data;
          this.paginate = collection.paginate;
          this.centers = collection.data;
          this.loading = false;
          this.setQueryFields();
        })
        .catch((error) => {
          this.userErrorMessage(error);
          this.loading = false;
        });
    },
    setQueryFields: function () {
      const queriesFromLocalStorage = localStorage.getItem(
        `${this.centerType}_centers`,
      );
      if (queriesFromLocalStorage) {
        this.searchFields = JSON.parse(queriesFromLocalStorage);
      }
      if (this.searchFields.q) {
        this.isSearching = true;
      }
    },
    openCenter: function (id) {
      this.$router.push({ name: "AdminCentersEdit", params: { id: id } });
      this.searchFields.lastVisitedId = id;
      localStorage.setItem(
        `${this.centerType}_centers`,
        JSON.stringify(this.searchFields),
      );
    },
    clearSearch: function () {
      this.searchFields = {
        page: 1,
        q: null,
      };
      this.isSearching = false;
      this.getCenters(1);
    },
    search: function () {
      this.loading = true;
      this.isSearching = true;
      this.getCenters(1);
    },
    isLastVisited: function (centerId) {
      return (
        JSON.parse(localStorage.getItem(`${this.centerType}_centers`))
          .lastVisitedId == centerId
      );
    },
  },
};
</script>
