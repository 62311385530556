import { practitionerHttpService } from "../../app/practitioner-portal/store/practitioner-http-service";
import { StudySummary } from "../../../../../app/Dermicus/Studies/StudySummary";
import { StudyResultParticipantSummary } from "../../../../../app/Dermicus/Studies/StudyResultParticipantSummary";
import { StudyExaminationSummary } from "../../../../../app/Dermicus/Studies/StudyExaminationSummary";

export function userStudiesAsAdminGet(userId: number) {
  return practitionerHttpService
    .get<StudySummary[]>(`/api/internal/users/${userId}/studies/as-admin`)
    .then((response) => response.data);
}

export function userStudiesAsParticipantGet(userId: number) {
  return practitionerHttpService
    .get<StudySummary[]>(`/api/internal/users/${userId}/studies/as-participant`)
    .then((response) => response.data);
}

export function userStudyResultsGet(userId: number, studyId?: number) {
  return practitionerHttpService
    .get<
      StudyResultParticipantSummary[]
    >(`/api/internal/users/${userId}/study-results`, { params: { studyId } })
    .then((response) => response.data);
}

export function userStudyResultGet(
  userId: number,
  studyExaminationId: number,
  params?: { refresh?: boolean },
) {
  return practitionerHttpService
    .get<{
      workflow_definition: Record<string, unknown>;
      study_examination: Record<string, unknown>;
    }>(`/api/internal/users/${userId}/study-results/${studyExaminationId}`, {
      params,
    })
    .then((response) => response.data);
}

export function userStudyExaminationsGet(userId: number, studyId?: number) {
  return practitionerHttpService
    .get<
      StudyExaminationSummary[]
    >(`/api/internal/users/${userId}/study-examinations`, { params: { studyId } })
    .then((response) => response.data);
}
